import img404 from "@assets/404.png";
import imgError from "@assets/error.png";
import Button from "@form/Button";
import React from "react";
import { twMerge } from "tailwind-merge";

const RouteError: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-red-50 text-red-800">
      <div className="max-w-[1200px] w-full text-center">
        <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
          <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
            <div className="relative">
              <div className="absolute">
                <div className="">
                  <h1 className="my-2 text-gray-800 font-bold text-2xl">Oops. This page has gone missing</h1>
                  <p className="my-2 text-gray-800">You may have mistyped the address or the page may have moved.</p>

                  <Button
                    type="submit"
                    className={twMerge("min-w-[175px] mt-10")}
                    onClick={() => (window.location.href = "/")}
                  >
                    Go Home
                  </Button>
                </div>
              </div>
              <div>
                <img src={img404} />
              </div>
            </div>
          </div>
          <div>
            <img src={imgError} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RouteError;
