import { PremiumToolSections } from "@/pages/Premium";
import React from "react";
import { NavLink } from "react-router-dom";

interface VerticalNavProps {
  sections: PremiumToolSections[];
}

const VerticalNav: React.FC<VerticalNavProps> = ({ sections }) => {
  return (
    <nav className="">
      <ul className="flex flex-col space-y-2 pl-4 pr-2 text-sm">
        {sections.map((section, index) => (
          <li key={index}>
            <NavLink
              to={section.navigate}
              className={({ isActive }) =>
                isActive
                  ? "block px-4 py-2 text-white bg-[#243665] rounded"
                  : "block px-4 py-2 text-gray-800 rounded bg-white hover:bg-[#243665] hover:text-white"
              }
            >
              {section.heading}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default VerticalNav;
