import Currency from "@/components/Form/Currency";
import Input from "@/components/Form/Input";
import Percentage from "@/components/Form/Percentage";
import PercentageSwitch from "@/components/Form/PercentageSwitch";
import Select from "@/components/Form/Select";

export interface InputCellProps {
  type: "currency" | "text" | "number" | "date" | "percentage" | "percentageSwitch" | "select" | "none";
  variant?: "green" | "red";
  disabled?: boolean;
  primaryRepresentation?: string;
  realField?: string;
  nominalField?: string;
  formData?: any;
  onChange?: (value: string, name: string) => void;
  required?: boolean;
  name?: string;
  selectItems?: { id: string | number; name: string }[];
}

export const InputCell: React.FC<InputCellProps> = ({
  type,
  variant = "green",
  disabled = false,
  primaryRepresentation,
  realField,
  nominalField,
  formData,
  onChange,
  required,
  name,
  selectItems,
}) => {
  switch (type) {
    case "currency":
      return (
        <Currency
          variant={variant}
          disabled={disabled}
          name={name}
        />
      );
    case "text":
      return (
        <Input
          type="text"
          variant={variant}
          disabled={disabled}
          name={name}
        />
      );
    case "number":
      return (
        <Input
          type="number"
          variant={variant}
          disabled={disabled}
          name={name}
        />
      );
    case "date":
      return (
        <Input
          type="date"
          variant={variant}
          disabled={disabled}
          name={name}
        />
      );
    case "percentage":
      return (
        <Percentage
          variant={variant}
          disabled={disabled}
          name={name}
        />
      );
    case "percentageSwitch":
      return (
        <PercentageSwitch
          variant={variant}
          disabled={disabled}
          primaryRepresentation={primaryRepresentation}
          realField={realField}
          nominalField={nominalField}
          formData={formData}
          onChange={onChange}
          required={required}
        />
      );
    case "select":
      return (
        <Select
          variant={variant}
          disabled={disabled}
          primaryRepresentation={primaryRepresentation}
          realField={realField}
          nominalField={nominalField}
          formData={formData}
          required={required}
          items={selectItems || []}
          name={name}
          onChange={(e) => onChange && onChange(e.target.value, name || "")}
        />
      );
    default:
      return null;
  }
};
