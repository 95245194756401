import createAxiosInstance from "@api/Api";
import numbers from "@services/algorithms/numbers";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

interface FormData {
  effective_date: string;
  retirement_age: number | string;
  annual_pre_tax_income: number | string;
  annual_bonus: number | string;
  employment_status_id: string;
  life_expectancy: string | number;
  current_tax_year: string;
  average_tax_rate: number | string;
  marginal_tax_rate: number | string;
  current_age: number | string;
  years_to_retirement: number | string;
  projected_retirement_date: string;
  retirement_longevity: number | string;
  retirement_longevity_custom: boolean;
  post_retirement_income_percentage: number | string;
  date_of_birth: string;
  id_number?: string;
}

interface EmploymentStatus {
  id: string;
  name: string;
}

const useGeneralInformation = () => {
  const api = createAxiosInstance("user/");
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementDate, calculateTaxYear } = numbers();

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>(null);
  const [employmentStatuses, setEmploymentStatuses] = useState<EmploymentStatus[]>([]);

  const [formData, setFormData] = useState<FormData>({
    effective_date: "",
    retirement_age: 0,
    annual_pre_tax_income: 0,
    annual_bonus: 0,
    employment_status_id: "",
    life_expectancy: "",
    current_tax_year: "",
    average_tax_rate: 0,
    marginal_tax_rate: 0,
    current_age: 0,
    years_to_retirement: 0,
    projected_retirement_date: "",
    retirement_longevity: 0,
    retirement_longevity_custom: false,
    post_retirement_income_percentage: 0,
    date_of_birth: "",
  });

  const calculatedValuesRef = useRef<Partial<FormData>>({});

  const calculateRetirementLongevity = (retirementAge: number | string, lifeExpectancy: number | string): number => {
    const retirementAgeParsed = typeof retirementAge === "string" ? parseInt(retirementAge, 10) : retirementAge;
    const lifeExpectancyParsed = typeof lifeExpectancy === "string" ? parseInt(lifeExpectancy, 10) : lifeExpectancy;

    if (isNaN(retirementAgeParsed) || isNaN(lifeExpectancyParsed)) {
      console.error("Invalid inputs for calculateRetirementLongevity");
      return 0;
    }

    const retirementLongevity = lifeExpectancyParsed - retirementAgeParsed;
    return Math.max(0, retirementLongevity);
  };

  const calculateAndSetValues = useCallback(() => {
    if (formData.date_of_birth && formData.retirement_age && formData.effective_date) {
      try {
        const birthDate = new Date(formData.date_of_birth);
        const effectiveDate = new Date(formData.effective_date);
        const retirementAge =
          typeof formData.retirement_age === "string" ? parseInt(formData.retirement_age, 10) : formData.retirement_age;

        const currentAge = calculateCurrentAge(birthDate, effectiveDate);
        const yearsToRetirement = calculateYearsToRetirement(
          formData.date_of_birth,
          retirementAge,
          formData.effective_date
        );
        const projectedRetirementDate = calculateRetirementDate(birthDate.getTime(), retirementAge.toString());
        const retirementLongevity = calculateRetirementLongevity(retirementAge, formData.life_expectancy);

        calculatedValuesRef.current = {
          current_age: currentAge,
          years_to_retirement: yearsToRetirement,
          projected_retirement_date: projectedRetirementDate,
          current_tax_year: calculateTaxYear(formData.effective_date),
        };

        if (!formData.retirement_longevity_custom) {
          calculatedValuesRef.current.retirement_longevity = retirementLongevity;
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          ...calculatedValuesRef.current,
        }));
      } catch (error) {
        console.error("Error in calculations:", error);
      }
    }
  }, [formData.date_of_birth, formData.retirement_age, formData.effective_date, formData.life_expectancy]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>, includeIdNumber: boolean) => {
      e.preventDefault();
      const requestData = {
        ...formData,
        ...calculatedValuesRef.current,
        include_id_number: includeIdNumber,
      };
      toast.promise(saveGeneralInformation(requestData), {
        loading: "Saving...",
        success: (data) => data.message,
        error: "Could not save.",
      });
    },
    [formData]
  );

  const getGeneralInformation = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`general-information`);

      setFormData((prevData) => ({
        ...prevData,
        effective_date: data.ufs.effective_date || "",
        retirement_date: data.ufs.retirement_date || "",
        date_of_birth: data.user.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || 0,
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || 0,
        annual_bonus: data.ufs.annual_bonus || 0,
        employment_status_id: data.ufs.employment_status_id || "",
        life_expectancy: data.ufs.life_expectancy || 0,
        average_tax_rate: data.ufs.average_tax_rate || 0,
        marginal_tax_rate: data.ufs.marginal_tax_rate || 0,
        post_retirement_income_percentage: data.ufs.post_retirement_income_percentage || 0,
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        id_number: data.user.id_number || "",
        current_tax_year: calculateTaxYear(data.ufs.effective_date) || "",
      }));

      if (data.ufs.retirement_longevity) {
        setFormData((prevData) => ({
          ...prevData,
          retirement_longevity: data.ufs.retirement_longevity || 0,
        }));
      }

      setEmploymentStatuses(data.employmentStatuses);
    } catch (error) {
      toast.error(error as any);
    } finally {
      setLoading(false);
    }
  };

  const saveGeneralInformation = async (formData: FormData) => {
    setLoading(true);
    setErrors(null);
    try {
      const { data } = await api.put(`general-information`, formData);
      return Promise.resolve(data);
    } catch (error) {
      if (error && typeof error === "object" && "response" in error) {
        setErrors((error as any).response.data.errors);
      } else {
        setErrors({ general: "An unexpected error occurred" });
      }
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const getTaxBrackets = async () => {
    setLoading(true);
    setErrors(null);

    const year = formData.current_tax_year;
    const income = parseFloat(formData.annual_pre_tax_income.toString()) + parseFloat(formData.annual_bonus.toString());
    const age = formData.current_age;

    const requestData = { year, income, age };

    try {
      const { data } = await api.get(`financial-settings/tax-brackets`, { params: requestData });

      const { average_tax_rate, marginal_tax_rate } = data;

      setFormData((prevData) => ({
        ...prevData,
        average_tax_rate: average_tax_rate || 0,
        marginal_tax_rate: marginal_tax_rate || 0,
      }));

      return Promise.resolve(data);
    } catch (error) {
      setErrors((error as any).response?.data?.errors || "An error occurred");
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTaxBrackets();
  }, [formData.current_tax_year, formData.annual_pre_tax_income, formData.annual_bonus]);

  useEffect(() => {
    getGeneralInformation();
  }, []);

  useEffect(() => {
    calculateAndSetValues();
  }, [calculateAndSetValues]);

  return {
    formData,
    setFormData,
    handleSubmit,
    errors,
    employmentStatuses,
    loading,
    calculateAndSetValues,
  };
};

export default useGeneralInformation;
