import usePasswordInformation from "@api/User/usePasswordInformation";
import Button from "@form/Button";
import Error from "@form/Error";
import Password from "@form/Password";
import usePasswords from "@services/algorithms/usePasswords";

const PasswordReset = () => {
  const { showPasswords, togglePasswordVisibility } = usePasswords();
  const { formData, handleChange, handleSubmit, errors, loading } = usePasswordInformation();

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="h-full relative pb-14"
      >
        <div className="mb-4">
          <Password
            label="Current password"
            showPassword={showPasswords.currentPassword}
            name="currentPassword"
            id="currentPassword"
            required
            value={formData.currentPassword}
            onChange={handleChange}
            togglePasswordVisibility={() => togglePasswordVisibility("currentPassword")}
          />
          <Error error={errors && errors.current_password} />
        </div>
        <div className="mb-4">
          <Password
            label="New password"
            showPassword={showPasswords.password}
            name="password"
            id="password"
            required
            value={formData.password}
            onChange={handleChange}
            togglePasswordVisibility={() => togglePasswordVisibility("password")}
          />
          <Error error={errors && errors.password} />
        </div>
        <div className="mb-4">
          <Password
            label="Confirm password"
            showPassword={showPasswords.confirmPassword}
            name="confirmPassword"
            id="confirmPassword"
            required
            value={formData.confirmPassword}
            onChange={handleChange}
            togglePasswordVisibility={() => togglePasswordVisibility("confirmPassword")}
          />
          <Error error={errors && errors.password_confirmation} />
        </div>
        <div className="absolute bottom-0 w-full">
          <Button
            type="submit"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default PasswordReset;
