import useFinancialSettings from "@api/User/useFinancialSettings";
import Button from "@form/Button";
import Currency from "@form/Currency";
import Error from "@form/Error";
import Percentage from "@form/Percentage";
import Select from "@form/Select";

const FinancialSettings = () => {
  const {
    formData,
    handleChange,
    handleCurrencyChange,
    handleSubmit,
    handleCheckboxChange,
    handleSelectChange,
    errors,
    currencies,
    taxDomiciles,
    primaryRepresentations,
    loading,
    handleNominalChange,
    handleRealChange,
  } = useFinancialSettings();

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="h-full relative pb-14"
      >
        <div className="flex items-center mb-4 gap-2">
          <div className="text-xs font-semibold text-gray-900">Use Defaults</div>
          <label
            className="relative inline-flex items-center cursor-pointer"
            htmlFor="default_financial_settings"
          >
            <input
              type="checkbox"
              name="default_financial_settings"
              className="sr-only peer"
              id="default_financial_settings"
              value="default_financial_settings"
              checked={formData.default_financial_settings}
              onChange={handleCheckboxChange}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#243665]"></div>
          </label>
        </div>
        <div className="mb-4 flex items-center w-full">
          <div className="min-w-[calc(50%-5px)]">
            <h1 className="font-bold">Primary information</h1>
          </div>
          <div className="w-full">
            <Select
              id="primary_representation_id"
              name="primary_representation_id"
              value={formData.primary_representation_id}
              onChange={handleSelectChange}
              items={primaryRepresentations}
              disabled={formData.default_financial_settings ? true : false}
              variant={"white"}
              inLine={true}
              tooltipText="The default way you wish to show the analysis real (net of inflation) i.e. removes the impact of inflation, or nominal (gross of inflation)"
            />
            <Error error={errors && errors.primary_representation_id} />
          </div>
        </div>
        <div className="mb-4">
          <Select
            label="Currency"
            defaultItem="Select Currency"
            id="currency_id"
            name="currency_id"
            value={formData.currency_id}
            onChange={handleChange}
            items={currencies}
            disabled={formData.default_financial_settings ? true : false}
            variant={"white"}
            inLine={true}
            tooltipText="The primary currency you wish to display the analysis"
          />
          <Error error={errors && errors.currency_id} />
        </div>
        <div className="mb-4">
          <Select
            label="Tax Domicile"
            defaultItem="Select Tax Domicile"
            id="tax_domicile_id"
            name="tax_domicile_id"
            value={formData.tax_domicile_id}
            onChange={handleChange}
            items={taxDomiciles}
            disabled={formData.default_financial_settings ? true : false}
            variant={"white"}
            inLine={true}
            tooltipText="Which primary domicile you are taxed in - different domiciles have different tax rates /tables"
          />
          <Error error={errors && errors.tax_domicile_id} />
        </div>
        <div className="mb-4">
          <Percentage
            label="Projected CPI Inflation Rate"
            name="projected_inflation_rate"
            id="projected_inflation_rate"
            value={formData.projected_inflation_rate}
            onChange={handleCurrencyChange}
            disabled={formData.default_financial_settings ? true : false}
            variant="white"
            inLine={true}
            tooltipText="CPI Inflation is the expected average annualised change in the Consumer Price Index over the long term"
          />
          <Error error={errors && errors.projected_inflation_rate} />
        </div>
        <div className="mb-4 mt-6 grid grid-cols-2 items-center gap-2 text-sm font-bold">
          <p>Growth environment</p>
          <p className="text-center">
            Estimated <u>pre-</u>retirement growth rate
          </p>
        </div>
        {/* REAL Pre Retirement */}
        {formData.primary_representation_id === 1 ? (
          <>
            <div className="mb-4">
              <Percentage
                label="Low"
                name="real_pre_retirement_capital_growth_low"
                id="real_pre_retirement_capital_growth_low"
                value={formData.real_pre_retirement_capital_growth_low}
                onChange={handleRealChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Real Pre-retirement growth rate under a low scenario over extended periods - typically 1-3% p.a."
              />
              <Error error={errors && errors.real_pre_retirement_capital_growth_low} />
            </div>
            <div className="mb-4">
              <Percentage
                label="Medium"
                name="real_pre_retirement_capital_growth_medium"
                id="real_pre_retirement_capital_growth_medium"
                value={formData.real_pre_retirement_capital_growth_medium}
                onChange={handleRealChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Real Pre-retirement growth rate under a medium scenario over extended periods - typically 3-6% p.a."
              />
              <Error error={errors && errors.real_pre_retirement_capital_growth_medium} />
            </div>
            <div className="mb-4">
              <Percentage
                label="High"
                name="real_pre_retirement_capital_growth_high"
                id="real_pre_retirement_capital_growth_high"
                value={formData.real_pre_retirement_capital_growth_high}
                onChange={handleRealChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Real Pre-retirement growth rate under a high scenario over extended periods - typically 6-9% p.a."
              />
              <Error error={errors && errors.real_pre_retirement_capital_growth_high} />
            </div>
          </>
        ) : (
          <>
            <div className="mb-4">
              <Percentage
                label="Low"
                name="nominal_pre_retirement_capital_growth_low"
                id="nominal_pre_retirement_capital_growth_low"
                value={formData.nominal_pre_retirement_capital_growth_low}
                onChange={handleNominalChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Nominal Pre-retirement growth rate under a low scenario over extended periods - typically Inflation + 1-3% p.a."
              />
              <Error error={errors && errors.nominal_pre_retirement_capital_growth_low} />
            </div>
            <div className="mb-4">
              <Percentage
                label="Medium"
                name="nominal_pre_retirement_capital_growth_medium"
                id="nominal_pre_retirement_capital_growth_medium"
                value={formData.nominal_pre_retirement_capital_growth_medium}
                onChange={handleNominalChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Nominal Pre-retirement growth rate under a medium scenario over extended periods - typically Inflation + 3-6% p.a."
              />
              <Error error={errors && errors.nominal_pre_retirement_capital_growth_medium} />
            </div>
            <div className="mb-4">
              <Percentage
                label="High"
                name="nominal_pre_retirement_capital_growth_high"
                id="nominal_pre_retirement_capital_growth_high"
                value={formData.nominal_pre_retirement_capital_growth_high}
                onChange={handleNominalChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Nominal Pre-retirement growth rate under a high scenario over extended periods - typically Inflation + 6-9% p.a."
              />
              <Error error={errors && errors.nominal_pre_retirement_capital_growth_high} />
            </div>
          </>
        )}
        <div className="mb-4">
          <Percentage
            label="Annual costs"
            name="pre_retirement_costs"
            id="pre_retirement_costs"
            value={formData.pre_retirement_costs}
            onChange={handleCurrencyChange}
            disabled={formData.default_financial_settings ? true : false}
            variant="white"
            inLine={true}
            tooltipText="Costs associated with investments including management, platform and trading fees (if unsure, use defaults)."
          />
          <Error error={errors && errors.pre_retirement_costs} />
        </div>
        <div className="mb-4 mt-6 grid grid-cols-2 items-center gap-2 text-sm font-bold">
          <p>Growth environment</p>
          <p className="text-center">
            Estimated <u>post-</u>retirement growth rate
          </p>
        </div>
        {/* REAL Post Retirement */}
        {formData.primary_representation_id === 1 ? (
          <>
            <div className="mb-4">
              <Percentage
                label="Low"
                name="real_post_retirement_capital_growth_low"
                id="real_post_retirement_capital_growth_low"
                value={formData.real_post_retirement_capital_growth_low}
                onChange={handleRealChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Real Post-retirement growth rate under a low scenario over extended periods - typically 1-3% p.a. Post-retirement rates are usually lower than pre-retirement due to lower capacity to assume risk"
              />
              <Error error={errors && errors.real_post_retirement_capital_growth_low} />
            </div>
            <div className="mb-4">
              <Percentage
                label="Medium"
                name="real_post_retirement_capital_growth_medium"
                id="real_post_retirement_capital_growth_medium"
                value={formData.real_post_retirement_capital_growth_medium}
                onChange={handleRealChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Real Post-retirement growth rate under a medium scenario over extended periods - typically 3-6% p.a. Post-retirement rates are usually lower than pre-retirement due to lower capacity to assume risk"
              />
              <Error error={errors && errors.real_post_retirement_capital_growth_medium} />
            </div>
            <div className="mb-4">
              <Percentage
                label="High"
                name="real_post_retirement_capital_growth_high"
                id="real_post_retirement_capital_growth_high"
                value={formData.real_post_retirement_capital_growth_high}
                onChange={handleRealChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Real Post-retirement growth rate under a high scenario over extended periods - typically 6-9% p.a. Post-retirement rates are usually lower than pre-retirement due to lower capacity to assume risk"
              />
              <Error error={errors && errors.real_post_retirement_capital_growth_high} />
            </div>
          </>
        ) : (
          <>
            <div className="mb-4">
              <Percentage
                label="Low"
                name="nominal_post_retirement_capital_growth_low"
                id="nominal_post_retirement_capital_growth_low"
                value={formData.nominal_post_retirement_capital_growth_low}
                onChange={handleNominalChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Nominal Post-retirement growth rate under a low scenario over extended periods - typically inflation + 1-3% p.a. Post-retirement rates are usually lower than pre-retirement due to lower capacity to assume risk"
              />
              <Error error={errors && errors.nominal_post_retirement_capital_growth_low} />
            </div>
            <div className="mb-4">
              <Percentage
                label="Medium"
                name="nominal_post_retirement_capital_growth_medium"
                id="nominal_post_retirement_capital_growth_medium"
                value={formData.nominal_post_retirement_capital_growth_medium}
                onChange={handleNominalChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Nominal Post-retirement growth rate under a medium scenario over extended periods - typically inflation + 3-6% p.a. Post-retirement rates are usually lower than pre-retirement due to lower capacity to assume risk"
              />
              <Error error={errors && errors.nominal_post_retirement_capital_growth_medium} />
            </div>
            <div className="mb-4">
              <Percentage
                label="High"
                name="nominal_post_retirement_capital_growth_high"
                id="nominal_post_retirement_capital_growth_high"
                value={formData.nominal_post_retirement_capital_growth_high}
                onChange={handleNominalChange}
                disabled={formData.default_financial_settings ? true : false}
                variant="white"
                inLine={true}
                tooltipText="Nominal Post-retirement growth rate under a high scenario over extended periods - typically inflation + 6-9% p.a. Post-retirement rates are usually lower than pre-retirement due to lower capacity to assume risk"
              />
              <Error error={errors && errors.nominal_post_retirement_capital_growth_high} />
            </div>
          </>
        )}
        <div className="mb-4">
          <Percentage
            label="Annual costs"
            name="post_retirement_costs"
            id="post_retirement_costs"
            value={formData.post_retirement_costs}
            onChange={handleCurrencyChange}
            disabled={formData.default_financial_settings ? true : false}
            variant="white"
            inLine={true}
            tooltipText="Costs associated with investments including management, platform and trading fees (if unsure, use defaults). Post-retirement costs are usually higher than pre-retirement due to greater service levels"
          />
          <Error error={errors && errors.post_retirement_costs} />
        </div>
        <div className="mb-4 mt-6 grid grid-cols-2 items-center gap-2 text-sm font-bold">
          <div className="col-start-2">
            <p className="text-center">Estimated growth rates to retirement</p>
          </div>
        </div>
        {formData.primary_representation_id === 1 ? (
          <>
            <div className="mb-4">
              <Percentage
                label="Annual income"
                name="real_growth_in_income"
                id="real_growth_in_income"
                value={formData.real_growth_in_income}
                onChange={handleRealChange}
                variant="white"
                inLine={true}
                tooltipText="This is your average estimated compound growth rate in your annual income to retirement – it will typically be higher (inflation + 6-8% p.a.)in your early years and lower (inflation +0-2%) in your latter years.”"
              />
              <Error error={errors && errors.real_growth_in_income} />
            </div>
            <div className="mb-4">
              <Percentage
                label="Annual bonus"
                name="real_growth_in_bonus"
                id="real_growth_in_bonus"
                value={formData.real_growth_in_bonus}
                onChange={handleRealChange}
                variant="white"
                inLine={true}
                tooltipText="This is your average estimated compound growth rate in your annual bonus to retirement – it is likely to be similar (but can be different) to your growth in annual income."
              />
              <Error error={errors && errors.real_growth_in_bonus} />
            </div>
          </>
        ) : (
          <>
            <div className="mb-4">
              <Percentage
                label="Annual income"
                name="nominal_growth_in_income"
                id="nominal_growth_in_income"
                value={formData.nominal_growth_in_income}
                onChange={handleNominalChange}
                variant="white"
                inLine={true}
                tooltipText="This is your average estimated compound growth rate in your annual income to retirement – it will typically be higher (inflation + 6-8% p.a.)in your early years and lower (inflation +0-2%) in your latter years.”"
              />
              <Error error={errors && errors.nominal_growth_in_income} />
            </div>
            <div className="mb-4">
              <Percentage
                label="Annual bonus"
                name="nominal_growth_in_bonus"
                id="nominal_growth_in_bonus"
                value={formData.nominal_growth_in_bonus}
                onChange={handleNominalChange}
                variant="white"
                inLine={true}
                tooltipText="This is your average estimated compound growth rate in your annual bonus to retirement – it is likely to be similar (but can be different) to your growth in annual income."
              />
              <Error error={errors && errors.nominal_growth_in_bonus} />
            </div>
          </>
        )}
        <div className="absolute bottom-0 w-full mt-6">
          <Button
            type="submit"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default FinancialSettings;
