import { TableHeader } from "@/components/Element/Table/TableHeader";
import { TableRow } from "@/components/Element/Table/TableRow";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import Button from "@/components/Form/Button";
import Input from "@/components/Form/Input";
import React, { memo, useCallback, useEffect, useState } from "react";
import { HeadingType, wealthTableHeadings } from "./WealthTableConstants";

export interface InputData {
  type: "currency" | "text" | "number" | "date" | "percentage" | "percentageSwitch" | "select";
  name: string;
  variant: "green" | "red";
  disabled?: boolean;
}

export interface RowData {
  id: string;
  description: string;
  inputs: InputData[];
  isNew?: boolean;
}

export interface CategoryData {
  category: string;
  rowSpan: number;
  showTotalWhenCollapsed?: boolean;
  rows: RowData[];
}

interface WealthTableProps {
  fixedRows: CategoryData[];
  formData: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
}

const WealthTable: React.FC<WealthTableProps> = ({ fixedRows, formData, handleCurrencyChange }) => {
  const [tableHeadings, setTableHeadings] = useState<HeadingType[]>([]);
  const [visibleCategories, setVisibleCategories] = useState<{ [key: string]: boolean }>({});
  const [tableData, setTableData] = useState<CategoryData[]>([]);

  useEffect(() => {
    setTableHeadings(wealthTableHeadings(formData));
  }, [formData]);

  useEffect(() => {
    if (fixedRows) {
      setVisibleCategories(initializeVisibleCategories(fixedRows));
      setTableData(fixedRows);
    }
  }, [fixedRows]);

  function initializeVisibleCategories(rows: CategoryData[]): { [key: string]: boolean } {
    return Object.fromEntries(rows.map((row) => [row.category, true]));
  }

  const toggleCategoryVisibility = useCallback((category: string) => {
    setVisibleCategories((prev) => ({ ...prev, [category]: !prev[category] }));
  }, []);

  const addRow = useCallback((categoryIndex: number) => {
    setTableData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)) as CategoryData[];
      const category = newData[categoryIndex];
      const newRowIndex = category.rows.length - 1; // Insert before the sub-total row
      const newRow: RowData = {
        id: `${category.category.toLowerCase()}_new_row_${Date.now()}`,
        description: "",
        inputs: [
          {
            type: "currency",
            name: `${category.category.toLowerCase()}_new_row_current_${Date.now()}`,
            variant: "green",
          },
          { type: "number", name: `${category.category.toLowerCase()}_new_row_review_${Date.now()}`, variant: "green" },
          { type: "date", name: `${category.category.toLowerCase()}_new_row_terminal_${Date.now()}`, variant: "green" },
          {
            type: "percentage",
            name: `${category.category.toLowerCase()}_new_row_increase_${Date.now()}`,
            variant: "green",
          },
        ],
        isNew: true,
      };
      category.rows.splice(newRowIndex, 0, newRow);
      category.rowSpan += 1;
      return newData;
    });
  }, []);

  const deleteRow = useCallback((categoryIndex: number, rowId: string) => {
    setTableData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)) as CategoryData[];
      const category = newData[categoryIndex];
      const rowIndex = category.rows.findIndex((row) => row.id === rowId);
      if (rowIndex !== -1 && category.rows[rowIndex].isNew) {
        category.rows.splice(rowIndex, 1);
        category.rowSpan -= 1;
      }
      return newData;
    });
  }, []);

  const handleRowNameChange = useCallback((categoryIndex: number, rowId: string, newName: string) => {
    setTableData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)) as CategoryData[];
      const category = newData[categoryIndex];
      const row = category.rows.find((row) => row.id === rowId);
      if (row) {
        row.description = newName;
        row.isNew = false;
      }
      return newData;
    });
  }, []);

  const renderCategoryRows = useCallback(
    (categoryData: CategoryData, categoryIndex: number) => {
      const isVisible = visibleCategories[categoryData.category];
      if (!isVisible) {
        return (
          <TableRow
            key={`${categoryData.category}-collapsed`}
            category={categoryData.category}
            description={categoryData.rows[0].description}
            inputs={categoryData.rows[0].inputs}
            isFirstInCategory={true}
            categoryRowSpan={1}
            visibilityToggle={
              <VisibilityToggle
                keyName={categoryData.category}
                isVisible={false}
                toggleVisibility={() => toggleCategoryVisibility(categoryData.category)}
              />
            }
            isCollapsed={true}
            showTotalWhenCollapsed={true}
            formData={formData}
            handleCurrencyChange={handleCurrencyChange}
          />
        );
      }

      return (
        <React.Fragment key={`${categoryData.category}-expanded`}>
          {categoryData.rows.map((rowData, rowIndex) => (
            <TableRow
              key={rowData.id}
              category={rowIndex === 0 ? categoryData.category : undefined}
              description={
                rowData.isNew ? (
                  <Input
                    type="text"
                    value={rowData.description}
                    onChange={(e) => handleRowNameChange(categoryIndex, rowData.id, e.target.value)}
                    placeholder="Enter row name"
                    autoFocus
                  />
                ) : (
                  rowData.description
                )
              }
              inputs={rowData.inputs}
              isFirstInCategory={rowIndex === 0}
              categoryRowSpan={categoryData.rowSpan}
              visibilityToggle={
                rowIndex === 0 ? (
                  <VisibilityToggle
                    keyName={categoryData.category}
                    isVisible={true}
                    toggleVisibility={() => toggleCategoryVisibility(categoryData.category)}
                  />
                ) : undefined
              }
              formData={formData}
              handleCurrencyChange={handleCurrencyChange}
              deleteRow={rowData.isNew ? () => deleteRow(categoryIndex, rowData.id) : undefined}
            />
          ))}
          <tr>
            <td
              colSpan={6}
              className="border border-gray-300 p-2"
            >
              <Button onClick={() => addRow(categoryIndex)}>Add row to {categoryData.category}</Button>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    [
      visibleCategories,
      toggleCategoryVisibility,
      formData,
      handleCurrencyChange,
      addRow,
      deleteRow,
      handleRowNameChange,
    ]
  );

  return (
    <>
      <table className="table-auto border">
        <TableHeader headings={tableHeadings} />
        <tbody>{tableData.map((categoryData, index) => renderCategoryRows(categoryData, index))}</tbody>
      </table>
    </>
  );
};

export default memo(WealthTable);
