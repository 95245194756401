import InputTooltip from "@/components/Element/InputTooltip";
import { cn } from "@utils/cn";
import { cva, VariantProps } from "class-variance-authority";
import { useEffect, useState } from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { FaInfoCircle } from "react-icons/fa";
import { MdOutlineCommentsDisabled } from "react-icons/md";

const inputVariants = cva(
  "focus:ring-primary-500 focus:border-primary-500 p-1 h-[30px] rounded-md sm:text-[12px] block w-full sm:min-w-[70px] font-bold",
  {
    variants: {
      variant: {
        white: "bg-gray-50 border border-gray-300 text-gray-900 px-4",
        red: "border border-gray-300 text-white icon-white px-4",
        green: "border border-gray-300 text-white icon-white px-4",
        orange: "bg-[#f2e1c3] border border-gray-300 text-black",
      },
      alignLeft: {
        true: "text-left",
        false: "text-center",
      },
      disabled: {
        true: "cursor-not-allowed",
        false: "",
      },
    },
    compoundVariants: [
      {
        variant: "red",
        disabled: true,
        className: "bg-[#85322f]",
      },
      {
        variant: "red",
        disabled: false,
        className: "bg-[#a96f6d]",
      },
      {
        variant: "green",
        disabled: true,
        className: "bg-[#008308]",
      },
      {
        variant: "green",
        disabled: false,
        className: "bg-[#4ca852]",
      },
    ],
  }
);

interface PercentageProps extends VariantProps<typeof inputVariants> {
  label?: string;
  name?: string;
  id?: string;
  value?: string | number | null;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string | undefined, name: string | undefined, valueType: "any" | "positive" | "negative") => void;
  inLine?: boolean;
  alignText?: "left" | "right";
  twMergeClassName?: string;
  valueType?: "any" | "positive" | "negative";
  tooltipText?: string;
}

export default function Percentage({
  label,
  name,
  id,
  value,
  placeholder,
  required = false,
  disabled = false,
  onChange,
  variant,
  inLine = true,
  alignLeft = false,
  alignText = "left",
  twMergeClassName,
  valueType = "any",
  tooltipText,
}: PercentageProps) {
  const [displayValue, setDisplayValue] = useState(value?.toString() || "");

  useEffect(() => {
    setDisplayValue(value?.toString() || "");
  }, [value]);

  const handleValueChange: CurrencyInputProps["onValueChange"] = (newValue, name) => {
    setDisplayValue(newValue || "");
    if (onChange) {
      onChange(newValue, name, valueType);
    }
  };

  return (
    <div className={`${inLine ? "flex" : ""} items-center relative`}>
      {label && (
        <label
          htmlFor={id}
          className={`${inLine && !tooltipText ? "block" : tooltipText && alignText === "left" ? "flex items-center gap-2" : tooltipText ? "gap-2" : "flex mb-2"} ${alignText === "right" ? "text-right" : "text-left"} mr-2 text-[12px] font-bold text-gray-900 w-full leading-[15px]`}
        >
          {tooltipText && (
            <InputTooltip
              tooltipText={tooltipText}
              alignText={alignText}
            >
              <FaInfoCircle />
            </InputTooltip>
          )}
          {label} {required ? " *" : ""}
        </label>
      )}
      <CurrencyInput
        name={name}
        id={id}
        className={cn(inputVariants({ variant, alignLeft, disabled }), twMergeClassName)}
        placeholder={placeholder}
        value={displayValue}
        onValueChange={handleValueChange}
        // prefix={valueType === "negative" ? "-" : undefined} // Add minus sign as prefix
        disabled={disabled}
        required={required}
        suffix={"%"}
        decimalsLimit={2}
        decimalScale={2}
        decimalSeparator="."
        groupSeparator=" "
      />
      {disabled && (
        <div
          className={`${inLine ? "" : "h-fit top-8"} absolute inset-y-0 right-0.5  flex items-center pointer-events-none`}
        >
          <MdOutlineCommentsDisabled className={`h-[12px] ${variant === "white" ? "text-red-500" : "text-white"}`} />
        </div>
      )}
    </div>
  );
}
