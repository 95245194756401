import SelectToggle from "@/components/Form/ToggleSwitch";
import words from "@/services/algorithms/words";
import Change from "@/services/utils/change";
import useEducationCostTool from "@api/Standard/Lifestyle/useEducationCostTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import { CommaSeparated } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Currency from "@form/Currency";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import calculations from "@services/algorithms/calculations";
import useVisibility from "@utils/useVisibility";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { IoCloseCircleOutline } from "react-icons/io5";

export interface TabPhase {
  name: string;
  start_age: number;
  start_year: number;
  study_period: number;
  cost_per_year: number;
  include: boolean;
}

export interface TabContent {
  education_plan: string;
  education_inflation: number;
  base_cost_year: number;
  investment_return: number;
  current_savings: number;
  current_monthly_contributions: number;
  years_to_save: number;
  phases: TabPhase[];
}

const EducationCost = () => {
  const { isVisible, toggleVisibility } = useVisibility();
  const { capitalizeFirstLetter } = words();
  const { calculateMaxYearsToSave } = calculations();

  const initialTabContent: TabContent = {
    education_plan: "",
    education_inflation: 9.0,
    base_cost_year: 2020,
    investment_return: 10.0,
    current_savings: 100000,
    current_monthly_contributions: 600,
    years_to_save: 20,
    phases: [
      {
        name: "Pre-primary",
        start_age: 3,
        start_year: 2025,
        study_period: 3,
        cost_per_year: 25000,
        include: false,
      },
      {
        name: "Primary - 1st stage",
        start_age: 6,
        start_year: 2028,
        study_period: 3,
        cost_per_year: 32000,
        include: true,
      },
      {
        name: "Primary - 2nd stage",
        start_age: 9,
        start_year: 2031,
        study_period: 4,
        cost_per_year: 45000,
        include: true,
      },
      {
        name: "Secondary - initial stage",
        start_age: 13,
        start_year: 2035,
        study_period: 2,
        cost_per_year: 62000,
        include: true,
      },
      {
        name: "Secondary - final stage",
        start_age: 15,
        start_year: 2037,
        study_period: 3,
        cost_per_year: 68000,
        include: true,
      },
      {
        name: "Tertiary - bachelor stage",
        start_age: 18,
        start_year: 2040,
        study_period: 3,
        cost_per_year: 87375,
        include: true,
      },
      {
        name: "Tertiary - postgraduate stage",
        start_age: 21,
        start_year: 2043,
        study_period: 2,
        cost_per_year: 100000,
        include: true,
      },
    ],
  };

  const { loading, formData, setFormData, chartOptions, handleSubmit } = useEducationCostTool();

  const { handleChange, handleCurrencyChange, handlePrimaryRepresentationChange } = Change(formData, setFormData);

  const [showHelpPopup, _] = useState();

  const HelpText = () => {
    return (
      <div className="text-[12px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This is a tool designed to show the present value (i.e. todays' money terms) of the cost of educating a
              dependant through multiple stages of schooling (including tertiary study).
            </li>
            <li className="mb-2">
              We provide the option to store multiple dependant plans under a single client ID, and aggregate as
              required
            </li>
            <li className="mb-2">
              Inputs are standardized and assist the client in determining when (i.e. future year) expenditure will take
              place, the amount of expenditure per schooling year (defaults are available) and the number of years of
              expenditure.
            </li>
            <li className="mb-2">
              Cost per year is dependent upon a <strong>"base year"</strong> i.e. the year that determines all reference
              costs. This allows the client to input his selected school costs as they are currently published by grade.
              The tool will automatically adjust these current costs for the dependant's future education year by the
              input education inflation rate.
            </li>
            <li className="mb-2">
              Goal is to indicate how much must be saved monthly per dependant to cover their full costs until the last
              year of study, offset by current savings to date. The client has the ability to remove certain education
              periods as required.
            </li>
            <li className="mb-2">
              Note that education inflation is significantly higher than normal inflation and, consequently, all
              representations assume increasing savings every year i.e. adjusted by prevailing CPI.
            </li>
            <li className="mb-2">
              Note the opportunity to insert a specific return on investment (or can use default as per standard)
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const [tabs, setTabs] = useState<TabContent[]>([initialTabContent]);

  const addDependent = () => {
    // this will take the data from the currently selected tab and add it to the tabs array as a new dependent
    // to satisfy feedback: "Current inputs must be available as defaults but must allow for overwrites"
    const currentTabData = tabs[selectedTab];
    setTabs([...tabs, currentTabData]);
    setSelectedTab(tabs.length);
  };

  const removeDependent = (index: number) => {
    setSelectedTab(index - 1);
    setTabs((prevTabs: any) => prevTabs.filter((_: any, tabIndex: number) => tabIndex !== index));
  };

  const handleTabChange = (e: any, index: number, name: string, phaseIndex: number | null = null) => {
    let value = e.target?.value !== undefined ? e.target.value : e;

    setTabs((prevTabs: any) => {
      const updatedTabs = prevTabs.map((tab: any, tabIndex: number) => {
        if (tabIndex === index) {
          if (phaseIndex === null) {
            return {
              ...tab,
              [name]: name === "include" ? (value === "yes" ? true : false) : value,
            };
          } else {
            const updatedPhases = tab.phases.map((phase: any, phaseIdx: number) => {
              if (phaseIdx === phaseIndex) {
                return {
                  ...phase,
                  [name]: name === "include" ? (value === "yes" ? true : false) : value,
                };
              }
              return phase;
            });
            return {
              ...tab,
              phases: updatedPhases,
            };
          }
        }
        return tab;
      });

      const analysisYear = new Date(formData.effective_date).getFullYear();
      const phases = updatedTabs[index].phases;
      const startYears = phases.map((item: any) => Number(item.start_year));
      const studyPeriods = phases.map((item: any) => Number(item.study_period));
      const includeFlags = phases.map((item: any) => item.include);
      const maxYearsToSave = calculateMaxYearsToSave(analysisYear, startYears, studyPeriods, includeFlags);

      updatedTabs[index].years_to_save = maxYearsToSave;

      return updatedTabs;
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      // @ts-ignore - TS complaining about formData dependents type
      // will have to change useEducationCostTool.js to a .ts file and fix all the problems
      dependents: tabs,
    });
  }, [tabs]);

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Real cost of education"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Education Cost" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-6 gap-4 w-full p-4">
          <div className="col-span-6">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>

          <div className="col-span-6">
            <ul className="hidden text-md text-center rounded-lg shadow sm:flex w-full">
              {tabs.map((item, index) => (
                <li
                  key={index}
                  className="w-full flex relative border-r last:border-r-0"
                >
                  {index > 0 && (
                    <div
                      className="absolute top-0 bottom-0 left-0 w-16 flex items-center justify-center cursor-pointer"
                      onClick={() => removeDependent(index)}
                    >
                      <IoCloseCircleOutline color="red" />
                    </div>
                  )}
                  <span
                    onClick={() => {
                      setSelectedTab(index);
                    }}
                    className={`${index === selectedTab ? "bg-gray-200 font-bold" : "bg-white"} hover:bg-gray-200 hover:font-bold inline-block w-full p-4 text-gray-900 cursor-pointer`}
                  >
                    {item.education_plan} {item.education_plan === "Name" && `#${index + 1}`}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-span-3">
            <div className="max-h-[calc(100vh-235px)] scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-2 items-center">
                  <div>
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div>
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          alignText="right"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <div className="flex justify-between w-full">
                    <h3 className="text-[16px] font-semibold flex items-center">
                      Smart tool specific inputs{" "}
                      <span className="ml-4 cursor-pointer">
                        <VisibilityToggle
                          keyName="SpecificInputs"
                          isVisible={isVisible("SpecificInputs")}
                          toggleVisibility={toggleVisibility}
                        />
                      </span>
                    </h3>
                    <div>
                      <p className="font-bold text-[12px] shadow-[0px_0px_5px_2px_#bbb] py-[5px] px-[10px] rounded-md">
                        All inputs must be nominal
                      </p>
                    </div>
                  </div>
                </div>

                {!isVisible("SpecificInputs") && (
                  <div className="grid grid-cols-2 gap-2 items-center mt-4">
                    <div>
                      <Input
                        label="Education plan"
                        name="education_plan"
                        id="education_plan"
                        type="text"
                        value={tabs[selectedTab].education_plan}
                        onChange={(e: any) => handleTabChange(e, selectedTab, "education_plan")}
                        variant="green"
                        tooltipText="Insert name of dependent"
                        autoFocus={true}
                      />
                    </div>
                    <div>
                      <Percentage
                        label="Education inflation"
                        name="education_inflation"
                        id="education_inflation"
                        value={tabs[selectedTab].education_inflation}
                        onChange={(e: any) => handleTabChange(e, selectedTab, "education_inflation")}
                        variant="green"
                        alignText="right"
                      />
                    </div>
                    <div>
                      <Input
                        label="Base cost year"
                        name="base_cost_year"
                        id="base_cost_year"
                        type="number"
                        value={tabs[selectedTab].base_cost_year}
                        onChange={(e: any) => handleTabChange(e, selectedTab, "base_cost_year")}
                        variant="green"
                        tooltipText="Insert year on which costs are based"
                      />
                    </div>
                    <div>
                      <Percentage
                        label="Projected net return on investment"
                        name="investment_return"
                        id="investment_return"
                        value={tabs[selectedTab].investment_return}
                        onChange={(e: any) => handleTabChange(e, selectedTab, "investment_return")}
                        variant="green"
                        alignText="right"
                      />
                    </div>
                    <div>
                      <Currency
                        label="Current savings"
                        name="current_savings_pm"
                        id="current_savings_pm"
                        value={tabs[selectedTab].current_savings}
                        onChange={(e: any) => handleTabChange(e, selectedTab, "current_savings")}
                        variant="green"
                      />
                    </div>
                    <div>
                      <Currency
                        label="Current monthly contributions"
                        name="current_monthly_contributions"
                        id="current_monthly_contributions"
                        value={tabs[selectedTab].current_monthly_contributions}
                        onChange={(e: any) => handleTabChange(e, selectedTab, "current_monthly_contributions")}
                        variant="green"
                        alignText="right"
                      />
                    </div>
                  </div>
                )}
              </Card>

              <Card className="col-span-4 mb-4">
                <div className="bg-transparent rounded-md">
                  <div className="bg-[#fff] rounded-t-md mb-4">
                    <h3 className="text-[16px] font-semibold">Description</h3>
                  </div>
                  <div className="bg-white">
                    <table className="table-auto w-full border border-[#999]">
                      <thead className="bg-[#ffffff]">
                        <tr className="whitespace-nowrap bg-[#f1f1f1]">
                          <th className="align-middle text-[12px] text-left font-bold p-2 border-r border-t border-b border-solid border-[#999]">
                            Phase
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-r border-t border-b border-solid border-[#999]">
                            Start age
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-r border-t border-b border-solid border-[#999]">
                            Start year
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-r border-t border-b border-solid border-[#999]">
                            Study period
                            <br />
                            (years)
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-r border-t border-b border-solid border-[#999]">
                            Cost per year
                            <br />
                            (base year)
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-t border-b border-solid border-[#999]">
                            Include?
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tabs[selectedTab].phases.map((phase, phaseIndex) => (
                          <tr
                            key={phaseIndex}
                            className="bg-white"
                          >
                            <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999]">
                              {phase.name}
                            </td>
                            <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                              <Input
                                name="start_age"
                                id="start_age"
                                value={phase.start_age}
                                type="number"
                                onChange={(e: any) => handleTabChange(e, selectedTab, "start_age", phaseIndex)}
                                variant="green"
                              />
                            </td>
                            <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                              <Input
                                name="start_year"
                                id="start_year"
                                value={phase.start_year}
                                type="number"
                                showYearsOnly
                                onChange={(e: any) => handleTabChange(e, selectedTab, "start_year", phaseIndex)}
                                variant="green"
                              />
                            </td>
                            <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                              <Input
                                name="study_period"
                                id="study_period"
                                value={phase.study_period}
                                type="number"
                                onChange={(e: any) => handleTabChange(e, selectedTab, "study_period", phaseIndex)}
                                variant="green"
                              />
                            </td>
                            <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                              <Currency
                                name="cost_per_year"
                                id="cost_per_year"
                                value={phase.cost_per_year}
                                onChange={(e: any) => handleTabChange(e, selectedTab, "cost_per_year", phaseIndex)}
                                variant="green"
                              />
                            </td>
                            <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                              <select
                                name="include"
                                id="include"
                                value={phase.include ? "yes" : "no"}
                                onChange={(e: any) => handleTabChange(e, selectedTab, "include", phaseIndex)}
                              >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-1"
              >
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output:</h3>
                </div>

                <table className="table-auto">
                  <thead>
                    <tr>
                      <th className="align-middle text-[12px] text-left font-semibold p-2 border border-solid border-[#999] w-[25%]">
                        Maximum years to save
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 text-white border border-solid border-[#999] bg-[#85322f] w-[25%]">
                        {tabs[selectedTab].years_to_save}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999]">
                        Present value of future expenditure
                      </td>

                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.outputData[selectedTab]?.present_value_of_future_expenditure}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Additional savings required*
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.outputData[selectedTab]?.additional_savings_required_per_month}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-left font-semibold p-2 ">
                        *this is additional monthly savings required increasing annually by inflation
                      </td>
                    </tr>
                  </tbody>
                </table>

                {tabs.length < 8 && (
                  <Button
                    className="mt-4"
                    onClick={() => {
                      addDependent();
                    }}
                  >
                    Add new dependent
                  </Button>
                )}
              </Card>
            </div>
          </div>

          <div className="col-span-3 mb-1 min-h-[calc(100vh-238px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {capitalizeFirstLetter(formData.primary_representation)} projected education savings & expenditure
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-[calc(100%-40px)]">
                  {formData.chartSeries[selectedTab] && (
                    <Chart
                      // @ts-ignore
                      options={chartOptions}
                      series={formData.chartSeries[selectedTab]}
                      type="line"
                      width="100%"
                      height="100%"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default EducationCost;
