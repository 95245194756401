export interface HeadingType {
  text: string;
  id: string;
  removable?: boolean;
}

export const retirementTableHeadings = (): HeadingType[] => [
  { text: "Category", id: "category" },
  { text: "Description", id: "description" },
  { text: "Pension fund", id: "pension_fund" },
  { text: "Provident Fund", id: "provident_fund" },
  { text: "Preservation fund", id: "preservation_fund" },
  { text: "Retirement Annuity", id: "retirement_annuity" },
  { text: "Retirement pot", id: "retirement_pot" },
  { text: "Savings pot", id: "savings_pot" },
];

export const getTotalColumns = () => retirementTableHeadings().length;
