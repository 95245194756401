import useGeneralInformation from "@api/User/useGeneralInformation";
import { rawDecimal } from "@elements/Output";
import Button from "@form/Button";
import Currency from "@form/Currency";
import Error from "@form/Error";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import Select from "@form/Select";
import Change from "@utils/change";
import React, { useEffect } from "react";

interface GeneralInformationProps {
  includeIdNumber?: boolean;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({ includeIdNumber = false }) => {
  const { formData, setFormData, handleSubmit, errors, employmentStatuses, loading, calculateAndSetValues } =
    useGeneralInformation();

  const { handleChange, handleNumberChange, handleCurrencyChange, handleSelectChange } = Change(formData, setFormData);

  useEffect(() => {
    calculateAndSetValues();
  }, [formData.date_of_birth, formData.retirement_age, formData.effective_date, calculateAndSetValues]);

  const handleEffectiveDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChange(e);
    calculateAndSetValues();
  };

  return (
    <>
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e, includeIdNumber)}
        className="h-full relative pb-14"
      >
        <div className="mb-4">
          <h1 className="font-bold">General information</h1>
        </div>
        <div className="mb-4">
          <Input
            label="Effective Date of analysis"
            type="date"
            name="effective_date"
            id="effective_date"
            value={formData.effective_date || ""}
            onChange={handleEffectiveDateChange}
            variant="white"
            inLine={true}
            tooltipText="Date of analysis is critical to ensuring that values are consistent"
          />
        </div>
        {includeIdNumber && (
          <div className="mb-4">
            <Input
              label="Identification Number"
              name="id_number"
              id="id_number"
              required
              value={formData.id_number}
              onChange={handleChange}
              variant="white"
              inLine={false}
              alignLeft={true}
            />
            <Error error={errors && errors.id_number} />
          </div>
        )}
        <div className="mb-4">
          <Select
            label="Employment Status"
            defaultItem="Select Employment Status"
            id="employment_status_id"
            name="employment_status_id"
            value={formData.employment_status_id}
            onChange={handleSelectChange}
            items={employmentStatuses}
            variant="white"
            inLine={true}
            tooltipText="We use this as a validation mechanism to ensure that all the appropriate fields are completed"
          />
          <Error error={errors && errors.employment_status_id} />
        </div>

        <div className="mb-4">
          <Currency
            label="Annual pre-tax income"
            name="annual_pre_tax_income"
            id="annual_pre_tax_income"
            value={formData.annual_pre_tax_income}
            onChange={handleCurrencyChange}
            variant="white"
            inLine={true}
            tooltipText="Current income (at date of analysis) "
          />
          <Error error={errors && errors.annual_pre_tax_income} />
        </div>
        <div className="mb-4">
          <Currency
            label="Annual Bonus"
            name="annual_bonus"
            id="annual_bonus"
            value={formData.annual_bonus}
            onChange={handleCurrencyChange}
            variant="white"
            inLine={true}
            tooltipText="Current annual bonus"
          />
          <Error error={errors && errors.annual_bonus} />
        </div>
        <div className="mb-4">
          <Input
            label="Formal Retirement age"
            type="number"
            name="retirement_age"
            id="retirement_age"
            value={formData.retirement_age}
            onChange={handleNumberChange}
            variant="white"
            inLine={true}
            tooltipText="Retirement age is the age at which you expect to formally (contractually) retire"
          />
        </div>
        <div className="mb-4">
          <h1 className="font-bold">Calculated values</h1>
        </div>
        <div className="mb-4">
          <Input
            label="Age at analysis date"
            type="text"
            name="current_age"
            id="current_age"
            disabled
            value={rawDecimal(Number(formData.current_age || 0))}
            variant="red"
            inLine={true}
            tooltipText="Calculated age based on DOB and effective date of analysis"
          />
        </div>

        <div className="mb-4">
          <Input
            label="Projected Retirement Date"
            type="date"
            name="projected_retirement_date"
            id="projected_retirement_date"
            disabled
            value={formData.projected_retirement_date || ""}
            onChange={handleChange}
            variant="red"
            inLine={true}
            tooltipText="End of the month in which you turn your retirement age"
          />
          <Error error={errors && errors.projected_retirement_date} />
        </div>
        <div className="mb-4">
          <Input
            label="Years to retirement"
            name="years_to_retirement"
            id="years_to_retirement"
            disabled
            value={rawDecimal(Number(formData.years_to_retirement || 0))}
            variant="red"
            inLine={true}
            tooltipText="Years from effective date of analysis to retirement date"
          />
        </div>
        {!includeIdNumber && (
          <div className="mb-4">
            <Input
              label="Life expectancy"
              type="number"
              name="life_expectancy"
              id="life_expectancy"
              disabled
              value={formData.life_expectancy}
              variant="red"
              inLine={true}
              tooltipText="Life expectancy is an actuarial calculation depending upon your biological gender, current age and location "
            />
          </div>
        )}
        <div className="mb-4">
          <Input
            label="Current tax year"
            type="text"
            name="current_tax_year"
            id="current_tax_year"
            disabled
            value={formData.current_tax_year || ""}
            variant="red"
            inLine={true}
            tooltipText="Current tax year based on your effective date of analysis"
          />
        </div>
        <div className="mb-4">
          <Percentage
            label="Average Tax Rate"
            name="average_tax_rate"
            id="average_tax_rate"
            disabled
            value={formData.average_tax_rate}
            onChange={handleCurrencyChange}
            variant="red"
            inLine={true}
            tooltipText="Calculated from latest tax tables"
          />
          <Error error={errors && errors.average_tax_rate} />
        </div>
        <div className="mb-4">
          <Percentage
            label="Marginal Tax Rate"
            name="marginal_tax_rate"
            id="marginal_tax_rate"
            disabled
            value={formData.marginal_tax_rate}
            onChange={handleCurrencyChange}
            variant="red"
            inLine={true}
            tooltipText="Calculated from latest tax tables"
          />
          <Error error={errors && errors.marginal_tax_rate} />
        </div>
        <div className="mb-4">
          <Input
            label="Retirement longevity"
            name="retirement_longevity"
            id="retirement_longevity"
            type="number"
            value={formData.retirement_longevity}
            variant="white"
            inLine={true}
            onChange={handleNumberChange}
            tooltipText="Retirement longevity is the difference between your retirement age and your life expectancy; this period can be overwritten for your own target"
          />
        </div>
        <div className="mb-4">
          <Percentage
            label="Target income at retirement"
            name="post_retirement_income_percentage"
            id="post_retirement_income_percentage"
            value={formData.post_retirement_income_percentage}
            onChange={handleCurrencyChange}
            variant="white"
            inLine={true}
            tooltipText="Target income on retirement is an estimate of the percentage of your final salary that you need to maintain your standard of living in retirement - it is less than 100% since you will make no further contributions to wealth creation, and your tax rate is lower."
          />
          <Error error={errors && errors.post_retirement_income_percentage} />
        </div>
        <div className="absolute bottom-0 w-full">
          <Button
            type="submit"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default GeneralInformation;
