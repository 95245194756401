import { FooterTabs, TabContent } from "@/components/Element/FooterTabs";
import CommentaryLayout from "@/layouts/CommentaryLayout";
import React from "react";
import { useSearchParams } from "react-router-dom";
import InvestmentApproach from "./InvestmentApproach";
import RecommendedReadings from "./RecommendedReadings";
import RulesOfLife from "./RulesOfLife";

const LifestyleTipsIndex: React.FC = () => {
  const [searchParams] = useSearchParams();
  const getTabIndex = searchParams.get("tab");

  return (
    <CommentaryLayout
      showBackButton
      backButtonNavigateTo="/commentary"
      pageTitle={
        getTabIndex === "1" ? "Investment approach" : getTabIndex === "2" ? "19 rules for life" : "Recommended readings"
      }
    >
      <FooterTabs tabIndex={getTabIndex}>
        <TabContent
          tabtitle="Investment approach"
          className="h-full"
        >
          <InvestmentApproach />
        </TabContent>
        <TabContent
          tabtitle="19 rules for life"
          className="h-full"
        >
          <RulesOfLife />
        </TabContent>
        <TabContent
          tabtitle="Recommended readings"
          className="h-full"
        >
          <RecommendedReadings />
        </TabContent>
      </FooterTabs>
    </CommentaryLayout>
  );
};

export default LifestyleTipsIndex;
