import Card from "@/components/Element/Card";
import ChartLoading from "@/components/Element/ChartLoading";
import RadioFull from "@/components/Form/RadioFull";
import words from "@/services/algorithms/words";
import React, { ChangeEvent, useState } from "react";
import Chart from "react-apexcharts";

const selectChart = [
  {
    id: 1,
    name: "Primary (home) property projections",
    slug: "primary-property-projections",
  },
  {
    id: 2,
    name: "Primary: home utility vs costs",
    slug: "primary-home-utility-vs-costs",
  },
  {
    id: 3,
    name: "Secondary (investment) property projections",
    slug: "secondary-property-projections",
  },
  {
    id: 4,
    name: "Secoondary: cumulative income vs ownership costs",
    slug: "secondary-cumulative-income-vs-ownership-costs",
  },
];

interface PropertyTabTwoProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  formData: any;
  loading: boolean;
  chartSeries: any;
  chartOptions: any;
}

const PropertyTabTwo: React.FC<PropertyTabTwoProps> = ({
  handleSubmit,
  preventEnterPress,
  formData,
  loading,
  chartSeries,
  chartOptions,
}) => {
  const { capitalizeFirstLetter } = words();
  const [selectedChart, setSelectedChart] = useState<string>("");
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedChart(event.target.value);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="">
          <div className="grid grid-cols-7 gap-4 w-full">
            <div className="col-span-3 max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
              <Card
                variant="white"
                className="mb-4"
              >
                <h3 className="text-[16px] font-semibold mb-3">Charts available</h3>
                <form
                  onSubmit={handleSubmit}
                  className="border-b"
                >
                  <div className="divide-y divide-gray-200">
                    {selectChart.map((chart) => (
                      <div
                        className="flex items-center justify-between py-4"
                        key={chart.id}
                      >
                        <RadioFull
                          id={chart.id.toString()}
                          name={chart.name}
                          slug={chart.slug}
                          // I just put a random string in classTarget for now.
                          classTarget="select-chart"
                          handleRadioChange={handleRadioChange}
                          checked={selectedChart === chart.slug}
                        />
                      </div>
                    ))}
                  </div>
                </form>

                <p className="mt-4 text-[12px] font-bold">
                  The Chart shows the projected cumulative net liquidation value i.e. including capital growth but after
                  all associated costs (bond cancellation / agent fees etc). It compares this number to the cumulative
                  income received (if any) on the property less cumulative costs (mortgage / rates / maintenance /
                  insurance etc)
                </p>
              </Card>

              <Card
                variant="white"
                className="mb-2"
              >
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[60%]">
                        {capitalizeFirstLetter(formData.primary_representation)} projections
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                        Formal retirement
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                        Full retirement
                      </th>
                    </tr>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[60%]">
                        Select dates to display results
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                        30 Apr 2048
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                        31 Mar 2053
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">primary</td>
                      <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2"></td>
                      <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Liquidation value
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2"></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-red-500">
                        Total future ownership costs to selected dates*
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 text-red-500"></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 text-red-500"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">Secondary</td>
                      <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2"></td>
                      <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Liquidation value
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Cumulative future net income to selected dates
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">
                        Total property wealth
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-4 text-xs font-bold">
                  * This can be compared to the cost of rent on an equivalent property
                </p>
              </Card>
            </div>
            <div className="col-span-4 mb-1 h-[calc(100vh-240px)]">
              <Card
                variant="white"
                className="h-full"
              >
                <p className="mr-8 font-bold text-[16px] text-center mb-4">
                  {selectChart.find((chart) => chart.slug === selectedChart)?.name}
                  {formData.primary_representation && " - " + formData.primary_representation}
                </p>
                {loading ? (
                  <ChartLoading />
                ) : (
                  <div className="h-full relative">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                        fontFamily="Helvetica, Arial, sans-serif"
                      />
                    )}
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PropertyTabTwo;
