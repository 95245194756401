import { CategoryData } from "./RetirementTable";

type InputType = "currency" | "text" | "number" | "date" | "percentage" | "select";
type Variant = "green" | "red";

interface InputData {
  type: InputType;
  name: string;
  variant: Variant;
  disabled?: boolean;
}

const createInput = (
  type: InputType,
  name: string,
  variant: Variant = "green",
  disabled: boolean = false
): InputData => ({
  type,
  name,
  variant,
  disabled,
});

const createRow = (description: string, ...inputs: InputData[]) => ({
  description,
  inputs,
});

const initialisationRows = [
  createRow(
    "Value at effective date",
    createInput("currency", "pension_fund_value", "green"),
    createInput("currency", "provident_fund_value", "green"),
    createInput("currency", "preservation_fund_value", "green"),
    createInput("currency", "retirement_annuity_value", "green"),
    createInput("currency", "retirement_pot_value", "green"),
    createInput("currency", "savings_pot_value", "green")
  ),
  createRow(
    "Salary-linked",
    createInput("select", "pension_fund_salary_linked", "green"),
    createInput("select", "provident_fund_salary_linked", "green"),
    createInput("select", "preservation_fund_salary_linked", "green"),
    createInput("select", "retirement_annuity_salary_linked", "green"),
    createInput("select", "retirement_pot_salary_linked", "green"),
    createInput("select", "savings_pot_salary_linked", "green")
  ),
  createRow(
    "Current pre-tax monthly salary",
    createInput("currency", "pension_fund_current_salary", "red"),
    createInput("currency", "provident_fund_current_salary", "red"),
    createInput("currency", "preservation_fund_current_salary"),
    createInput("currency", "retirement_annuity_current_salary"),
    createInput("currency", "retirement_pot_current_salary", "red"),
    createInput("currency", "savings_pot_current_salary", "red")
  ),
  createRow(
    "Real growth in salary",
    createInput("percentage", "pension_fund_salary_growth", "red"),
    createInput("percentage", "provident_fund_salary_growth", "red"),
    createInput("percentage", "preservation_fund_salary_growth"),
    createInput("percentage", "retirement_annuity_salary_growth"),
    createInput("percentage", "retirement_pot_salary_growth", "red"),
    createInput("percentage", "savings_pot_salary_growth", "red")
  ),
  createRow(
    "Next contribution",
    createInput("currency", "pension_fund_next_contribution", "green"),
    createInput("currency", "provident_fund_next_contribution", "green"),
    createInput("currency", "preservation_fund_next_contribution"),
    createInput("currency", "retirement_annuity_next_contribution", "green"),
    createInput("currency", "retirement_pot_next_contribution", "green"),
    createInput("currency", "savings_pot_next_contribution", "green")
  ),
  createRow(
    "Contribution % of salary",
    createInput("percentage", "pension_fund_contribution_percentage", "red"),
    createInput("percentage", "provident_fund_contribution_percentage", "red"),
    createInput("percentage", "preservation_fund_contribution_percentage"),
    createInput("percentage", "retirement_annuity_contribution_percentage"),
    createInput("percentage", "retirement_pot_contribution_percentage", "red"),
    createInput("percentage", "savings_pot_contribution_percentage", "red")
  ),
  createRow(
    "Next contribution date",
    createInput("date", "pension_fund_next_contribution_date", "red"),
    createInput("date", "provident_fund_next_contribution_date", "red"),
    createInput("date", "preservation_fund_next_contribution_date"),
    createInput("date", "retirement_annuity_next_contribution_date", "red"),
    createInput("date", "retirement_pot_next_contribution_date", "red"),
    createInput("date", "savings_pot_next_contribution_date", "red")
  ),
  createRow(
    "Final contribution date",
    createInput("date", "pension_fund_final_contribution_date", "red"),
    createInput("date", "provident_fund_final_contribution_date", "red"),
    createInput("date", "preservation_fund_final_contribution_date"),
    createInput("date", "retirement_annuity_final_contribution_date", "green"),
    createInput("date", "retirement_pot_final_contribution_date", "red"),
    createInput("date", "savings_pot_final_contribution_date", "red")
  ),
  createRow(
    "Annual real increase in contributions",
    createInput("percentage", "pension_fund_annual_increase", "green"),
    createInput("percentage", "provident_fund_annual_increase", "green"),
    createInput("percentage", "preservation_fund_annual_increase"),
    createInput("percentage", "retirement_annuity_annual_increase", "green"),
    createInput("percentage", "retirement_pot_annual_increase", "red"),
    createInput("percentage", "savings_pot_annual_increase", "red")
  ),
  createRow(
    "Increase month",
    createInput("number", "pension_fund_increase_month", "red"),
    createInput("number", "provident_fund_increase_month", "red"),
    createInput("number", "preservation_fund_increase_month"),
    createInput("number", "retirement_annuity_increase_month", "green"),
    createInput("number", "retirement_pot_increase_month", "red"),
    createInput("number", "savings_pot_increase_month", "red")
  ),
];

const withdrawalRows = [
  createRow(
    "First withdrawal date",
    createInput("date", "pension_fund_first_withdrawal", "red"),
    createInput("date", "provident_fund_first_withdrawal", "red"),
    createInput("date", "preservation_fund_first_withdrawal", "red"),
    createInput("date", "retirement_annuity_first_withdrawal", "red"),
    createInput("date", "retirement_pot_first_withdrawal", "red"),
    createInput("date", "savings_pot_first_withdrawal", "red")
  ),
  createRow(
    "Initial withdrawal (% of capital)",
    createInput("percentage", "pension_fund_initial_withdrawal", "green"),
    createInput("percentage", "provident_fund_initial_withdrawal", "green"),
    createInput("percentage", "preservation_fund_initial_withdrawal", "green"),
    createInput("percentage", "retirement_annuity_initial_withdrawal", "green"),
    createInput("percentage", "retirement_pot_initial_withdrawal", "green"),
    createInput("percentage", "savings_pot_initial_withdrawal", "green")
  ),
  createRow(
    "Annual real increase in withdrawals",
    createInput("percentage", "pension_fund_annual_withdrawal_increase", "green"),
    createInput("percentage", "provident_fund_annual_withdrawal_increase", "green"),
    createInput("percentage", "preservation_fund_annual_withdrawal_increase", "green"),
    createInput("percentage", "retirement_annuity_annual_withdrawal_increase", "green"),
    createInput("percentage", "retirement_pot_annual_withdrawal_increase", "green"),
    createInput("percentage", "savings_pot_annual_withdrawal_increase", "green")
  ),
  createRow(
    "Increase month",
    createInput("number", "pension_fund_withdrawal_increase_month", "green"),
    createInput("number", "provident_fund_withdrawal_increase_month", "green"),
    createInput("number", "preservation_fund_withdrawal_increase_month", "green"),
    createInput("number", "retirement_annuity_withdrawal_increase_month", "green"),
    createInput("number", "retirement_pot_withdrawal_increase_month", "green"),
    createInput("number", "savings_pot_withdrawal_increase_month", "green")
  ),
];

const RetirementTableData = (): CategoryData[] => [
  {
    category: "Initialisation",
    rowSpan: initialisationRows.length,
    rows: initialisationRows,
  },
  {
    category: "Withdrawal",
    rowSpan: withdrawalRows.length,
    rows: withdrawalRows,
  },
];

export default RetirementTableData;
