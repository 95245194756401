import { ReactNode } from "react";
import { IconContext } from "react-icons";
import { MdClose } from "react-icons/md";

interface ModalProps {
  show: boolean;
  closeModal: () => void;
  modalContent?: ReactNode;
  modalContainerPadding?: string;
}

const Modal: React.FC<ModalProps> = ({ show, closeModal, modalContent = null, modalContainerPadding = null }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={`fixed z-20 top-0 bottom-0 left-0 right-0 bg-[#00000059] flex justify-center items-center transition-all ${show ? "block opacity-100 visible" : "opacity-0 invisible"} `}
    >
      <div
        className={`${modalContainerPadding ?? ""} w-[90vw] h-[auto] max-h-[90vh] scrollbar overflow-y-auto overflow-x-hidden bg-[#fff] pt-[1rem] pl-[1rem] pr-[calc(1rem-10px)] pb-[1rem] rounded-[8px] backdrop-blur shadow-[0_0_1rem_0_rgba(0,_0,_0,_.2)] translate-y-0`}
      >
        <div
          className="absolute top-[1rem] right-[0.5rem] cursor-pointer"
          onClick={closeModal}
        >
          <IconContext.Provider
            value={{
              color: "#44476a",
              className: "text-[15px]",
            }}
          >
            <MdClose />
          </IconContext.Provider>
        </div>
        {modalContent ?? ""}
      </div>
    </div>
  );
}

export default Modal;
