import React from "react";

interface AssetData {
  domicile: string;
  assetClass: string;
  description: string;
  annReturn: number;
  avgRoll12m: number;
  avgRoll36m: number;
  avgRoll60m: number;
}

interface InternationalUnitTrustsDataTableProps {
  assetData: AssetData[];
  selectedAssets: string[];
  onAssetToggle: (assetKey: string) => void;
  selectAll: boolean;
  onSelectAll: () => void;
}

const InternationalUnitTrustsDataTable: React.FC<InternationalUnitTrustsDataTableProps> = ({
  assetData,
  selectedAssets,
  onAssetToggle,
  selectAll,
  onSelectAll,
}) => {
  const domesticAssets = assetData.filter((asset) => asset.domicile === "Domestic");

  return (
    <div className="overflow-x-auto">
      <table className="w-full text-sm border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th
              className="p-2 text-left border border-gray-300"
              style={{ width: "80px" }}
            >
              Domicile
            </th>
            <th className="p-2 text-left border border-gray-300">Asset class</th>
            <th className="p-2 text-left border border-gray-300">Description</th>
            <th className="p-2 text-right border border-gray-300">Ann. return</th>
            <th className="p-2 text-right border border-gray-300">Avg roll 12m</th>
            <th className="p-2 text-right border border-gray-300">Avg 36m roll</th>
            <th className="p-2 text-right border border-gray-300">Avg 60m roll</th>
            <th className="p-2 text-center border border-gray-300">
              Select for chart
              <div>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={onSelectAll}
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {domesticAssets.map((row, index) => (
            <tr
              key={`${row.domicile}-${index}`}
              className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
            >
              {index === 0 && (
                <td
                  className="border border-gray-300 p-0"
                  style={{
                    width: "80px",
                    height: `${domesticAssets.length * 40}px`,
                  }}
                  rowSpan={domesticAssets.length}
                >
                  <div className="h-full flex items-center justify-center">
                    <span className="transform -rotate-90 whitespace-nowrap">International</span>
                  </div>
                </td>
              )}
              <td className="p-2 border border-gray-300">{row.assetClass}</td>
              <td className="p-2 border border-gray-300">{row.description}</td>
              <td className="p-2 text-right border border-gray-300">{row.annReturn.toFixed(1)}%</td>
              <td className="p-2 text-right border border-gray-300">{row.avgRoll12m.toFixed(1)}%</td>
              <td className="p-2 text-right border border-gray-300">{row.avgRoll36m.toFixed(1)}%</td>
              <td className="p-2 text-right border border-gray-300">{row.avgRoll60m.toFixed(1)}%</td>
              <td className="p-2 text-center border border-gray-300">
                <input
                  type="checkbox"
                  checked={selectedAssets.includes(`${row.domicile} ${row.assetClass}`)}
                  onChange={() => onAssetToggle(`${row.domicile} ${row.assetClass}`)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InternationalUnitTrustsDataTable;
