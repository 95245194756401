import { InputCellProps } from "@/components/Element/Table/InputCell";
import { TableHeader } from "@/components/Element/Table/TableHeader";
import { TableRow } from "@/components/Element/Table/TableRow";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import Button from "@/components/Form/Button";
import Input from "@/components/Form/Input";
import React, { memo, useCallback, useMemo, useState } from "react";
import { HeadingType, retirementTableHeadings } from "./RetirementTableConstants";
import RetirementTableData from "./RetirementTableData";

export interface CategoryData {
  category: string;
  rowSpan: number;
  rows: {
    description: string;
    inputs: Array<{
      type: "currency" | "text" | "number" | "date" | "percentage" | "percentageSwitch" | "select" | "none";
      name: string;
      variant: "green" | "red";
    }>;
  }[];
}

interface RetirementTableProps {
  fixedRows: CategoryData[];
  formData: any;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
}

const RetirementTable: React.FC<RetirementTableProps> = ({ fixedRows, formData, handleCurrencyChange }) => {
  const [tableHeadings, setTableHeadings] = useState<HeadingType[]>(retirementTableHeadings());
  const [visibleCategories, setVisibleCategories] = useState<{ [key: string]: boolean }>(
    initializeVisibleCategories(fixedRows)
  );
  const [newColumnIds, setNewColumnIds] = useState<string[]>([]);

  const retirementTableData = useMemo(() => RetirementTableData(), []);

  function initializeVisibleCategories(rows: CategoryData[]): { [key: string]: boolean } {
    return Object.fromEntries(rows.map((row) => [row.category, true]));
  }

  const toggleCategoryVisibility = useCallback((category: string) => {
    setVisibleCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  }, []);

  const addSecondaryColumn = useCallback(() => {
    const newColumnId = `column-${Date.now()}`;
    setTableHeadings((prev) => [
      ...prev,
      {
        text: "",
        id: newColumnId,
        removable: true,
      },
    ]);
    setNewColumnIds((prevIds) => [...prevIds, newColumnId]);
  }, []);

  const removeSecondaryColumn = useCallback((columnId: string) => {
    setTableHeadings((prev) => prev.filter((heading) => heading.id !== columnId));
    setNewColumnIds((prevIds) => prevIds.filter((id) => id !== columnId));
  }, []);

  const updateColumnName = useCallback((columnId: string, newName: string) => {
    setTableHeadings((prev) =>
      prev.map((heading) => (heading.id === columnId ? { ...heading, text: newName } : heading))
    );
  }, []);

  const getAdditionalInputs = useCallback(
    (categoryIndex: number, rowIndex: number): (InputCellProps | undefined)[] => {
      return tableHeadings.slice(8).map((heading) => {
        const category = retirementTableData[categoryIndex];
        if (!category) return undefined;

        const row = category.rows[rowIndex];
        if (!row) return undefined;

        const isNewColumn = newColumnIds.includes(heading.id);

        if (!isNewColumn) {
          return undefined;
        }

        const input = row.inputs[0];
        if (!input) return undefined;

        return {
          type: input.type,
          name: `${heading.id}_${input.name}`,
          variant: input.variant,
        };
      });
    },
    [tableHeadings, retirementTableData, newColumnIds]
  );

  const renderCategoryRow = useCallback(
    (categoryData: CategoryData, categoryIndex: number) => {
      const isVisible = visibleCategories[categoryData.category];
      return (
        <TableRow
          key={`${categoryData.category}-header`}
          category={categoryData.category}
          description={categoryData.rows[0].description}
          inputs={[...categoryData.rows[0].inputs, ...getAdditionalInputs(categoryIndex, 0)]}
          isFirstInCategory={true}
          categoryRowSpan={isVisible ? categoryData.rowSpan : 1}
          visibilityToggle={
            <VisibilityToggle
              keyName={categoryData.category}
              isVisible={isVisible}
              toggleVisibility={() => toggleCategoryVisibility(categoryData.category)}
            />
          }
          isCollapsed={!isVisible}
          formData={formData}
          handleCurrencyChange={handleCurrencyChange}
        />
      );
    },
    [visibleCategories, getAdditionalInputs, toggleCategoryVisibility, formData, handleCurrencyChange]
  );

  const renderDataRows = useCallback(
    (categoryData: CategoryData, categoryIndex: number) => {
      if (!visibleCategories[categoryData.category]) return null;
      return categoryData.rows.slice(1).map((rowData, rowIndex) => (
        <TableRow
          key={`${categoryIndex}-${rowIndex + 1}`}
          description={rowData.description}
          inputs={[...rowData.inputs, ...getAdditionalInputs(categoryIndex, rowIndex + 1)]}
          formData={formData}
          handleCurrencyChange={handleCurrencyChange}
        />
      ));
    },
    [visibleCategories, getAdditionalInputs, formData, handleCurrencyChange]
  );

  const tableRows = useMemo(
    () =>
      fixedRows.flatMap((categoryData, categoryIndex) => [
        renderCategoryRow(categoryData, categoryIndex),
        renderDataRows(categoryData, categoryIndex),
      ]),
    [fixedRows, renderCategoryRow, renderDataRows]
  );

  return (
    <>
      <table className="table-auto border">
        <TableHeader
          headings={tableHeadings.map((heading) =>
            heading.removable
              ? {
                  ...heading,
                  text: (
                    <Input
                      type="text"
                      autoFocus
                      onChange={(e) => updateColumnName(heading.id, e.target.value)}
                    />
                  ),
                }
              : heading
          )}
          removeColumn={removeSecondaryColumn}
        />
        <tbody>{tableRows}</tbody>
      </table>
      <div className="mt-4">
        <Button onClick={addSecondaryColumn}>Add</Button>
      </div>
    </>
  );
};

export default memo(RetirementTable);
