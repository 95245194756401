import { useState } from "react";

interface PasswordVisibility {
  currentPassword: boolean;
  password: boolean;
  confirmPassword: boolean;
}

type PasswordField = keyof PasswordVisibility;

const usePasswords = () => {
  const [showPasswords, setShowPasswords] = useState<PasswordVisibility>({
    currentPassword: false,
    password: false,
    confirmPassword: false,
  });

  const togglePasswordVisibility = (field: PasswordField) => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return { showPasswords, togglePasswordVisibility };
};

export default usePasswords;
