import createAxiosInstance from "@api/Api";
import calculations from "@services/algorithms/calculations";
import helpers from "@utils/helpers";
import { useEffect, useState, useCallback, FormEvent } from "react";
import numbers from "@services/algorithms/numbers";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";
import { ApexOptions } from "apexcharts";

// Type Aliases
type NumberOrString = number | string;

// Interface Segmentation
interface Miscellaneous {
  growth_rate: GrowthRate;
  primary_representation: string;
}

interface GenericInputs {
  effective_date: string;
  projected_inflation_rate: number;
  date_of_birth: string;
  retirement_date: string;
  retirement_age: number;
  current_age: NumberOrString;
  years_to_retirement: NumberOrString;
  life_expectancy: number;
  retirement_longevity_custom: boolean;
  retirement_longevity: NumberOrString;
}

interface GrowthCostAssumptions {
  real_pre_retirement_capital_growth_low: number;
  real_pre_retirement_capital_growth_medium: number;
  real_pre_retirement_capital_growth_high: number;
  nominal_pre_retirement_capital_growth_low: number;
  nominal_pre_retirement_capital_growth_medium: number;
  nominal_pre_retirement_capital_growth_high: number;
  pre_retirement_costs: number;
  real_pre_net_retirement_growth_low: number;
  real_pre_net_retirement_growth_medium: number;
  real_pre_net_retirement_growth_high: number;
  nominal_pre_net_retirement_growth_low: number;
  nominal_pre_net_retirement_growth_medium: number;
  nominal_pre_net_retirement_growth_high: number;
  post_retirement_costs: NumberOrString;
}

interface SmartToolSpecificInputs {
  horizon_months: number;
  horizon_years: number;
  target_date: string;
  initial_capital: number;
  real_target_capital: number;
  nominal_target_capital: number;
}

interface Output {
  savings_no_increase_low: number;
  savings_no_increase_medium: number;
  savings_no_increase_high: number;
  savings_increase_low: number;
  savings_increase_medium: number;
  savings_increase_high: number;
}

// Main Interface
interface GBIFormData extends Miscellaneous, GenericInputs, GrowthCostAssumptions, SmartToolSpecificInputs, Output {}

enum GrowthRate {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

const useGoalInvestingTools = () => {
  const api = createAxiosInstance("standard/wealth-creation/goal-investing");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementLongevity, calculateTargetDate } =
    numbers();
  const { calculateMonthlySavingsRequired, calculateMonthlySavingsRequiredNoIncrease } = calculations();

  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState<ApexOptions>(chartOptionsDefault);

  const [formData, setFormData] = useState<GBIFormData>({
    // Miscellaneous
    growth_rate: GrowthRate.Low,
    primary_representation: "",
    // Generic inputs
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_date: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity_custom: false,
    retirement_longevity: 0,
    // Growth & cost assumptions
    real_pre_retirement_capital_growth_low: 0,
    real_pre_retirement_capital_growth_medium: 0,
    real_pre_retirement_capital_growth_high: 0,
    nominal_pre_retirement_capital_growth_low: 0,
    nominal_pre_retirement_capital_growth_medium: 0,
    nominal_pre_retirement_capital_growth_high: 0,
    pre_retirement_costs: 0,
    real_pre_net_retirement_growth_low: 0,
    real_pre_net_retirement_growth_medium: 0,
    real_pre_net_retirement_growth_high: 0,
    nominal_pre_net_retirement_growth_low: 0,
    nominal_pre_net_retirement_growth_medium: 0,
    nominal_pre_net_retirement_growth_high: 0,
    post_retirement_costs: 0,
    // Smart tool specific inputs
    horizon_months: 110,
    horizon_years: 110 / 12,
    target_date: "",
    initial_capital: 120000,
    real_target_capital: 1000000,
    nominal_target_capital: 0,
    // Output
    savings_no_increase_low: 0,
    savings_no_increase_medium: 0,
    savings_no_increase_high: 0,
    savings_increase_low: 0,
    savings_increase_medium: 0,
    savings_increase_high: 0,
  });

  const {
    handleNominalChange,
    handleRealChange,
    handleTargetCapitalChange,
    handlePresentCapitalChange,
    genericInputsCalculations,
    growthPreRetirementCalculations,
    calculateFutureValue,
  } = growthRateHandler(formData, setFormData);

  const calculateAndSetValues = useCallback(() => {
    const HorizonYears = formData.horizon_months / 12;

    const savingsNoIncreaseLow = calculateMonthlySavingsRequiredNoIncrease(
      formData.nominal_target_capital,
      formData.nominal_pre_net_retirement_growth_low,
      formData.horizon_months,
      formData.initial_capital
    );
    const savingsNoIncreaseMedium = calculateMonthlySavingsRequiredNoIncrease(
      formData.nominal_target_capital,
      formData.nominal_pre_net_retirement_growth_medium,
      formData.horizon_months,
      formData.initial_capital
    );
    const savingsNoIncreaseHigh = calculateMonthlySavingsRequiredNoIncrease(
      formData.nominal_target_capital,
      formData.nominal_pre_net_retirement_growth_high,
      formData.horizon_months,
      formData.initial_capital
    );

    const savingsIncreaseLow = calculateMonthlySavingsRequired(
      formData.nominal_target_capital,
      formData.nominal_pre_net_retirement_growth_low,
      formData.horizon_months,
      formData.initial_capital,
      formData.real_pre_net_retirement_growth_low
    );
    const savingsIncreaseMedium = calculateMonthlySavingsRequired(
      formData.nominal_target_capital,
      formData.nominal_pre_net_retirement_growth_medium,
      formData.horizon_months,
      formData.initial_capital,
      formData.real_pre_net_retirement_growth_medium
    );
    const savingsIncreaseHigh = calculateMonthlySavingsRequired(
      formData.nominal_target_capital,
      formData.nominal_pre_net_retirement_growth_high,
      formData.horizon_months,
      formData.initial_capital,
      formData.real_pre_net_retirement_growth_high
    );

    const nominalTargetCapital = calculateFutureValue(
      formData.real_target_capital,
      formData.projected_inflation_rate,
      formData.horizon_years
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      target_date: calculateTargetDate(formData.effective_date, formData.horizon_months),
      horizon_years: HorizonYears,
      savings_no_increase_low: savingsNoIncreaseLow,
      savings_no_increase_medium: savingsNoIncreaseMedium,
      savings_no_increase_high: savingsNoIncreaseHigh,
      savings_increase_low: savingsIncreaseLow,
      savings_increase_medium: savingsIncreaseMedium,
      savings_increase_high: savingsIncreaseHigh,
      nominal_target_capital: nominalTargetCapital,
    }));
  }, [
    formData.nominal_pre_net_retirement_growth_low,
    formData.nominal_pre_net_retirement_growth_medium,
    formData.nominal_pre_net_retirement_growth_high,
    formData.initial_capital,
    formData.real_pre_net_retirement_growth_low,
    formData.real_pre_net_retirement_growth_medium,
    formData.real_pre_net_retirement_growth_high,
    formData.effective_date,
    formData.horizon_months,
    formData.real_target_capital,
    formData.nominal_target_capital,
  ]);

  useEffect(() => {
    setTimeout(() => {
      calculateAndSetValues();
      growthPreRetirementCalculations();
    }, 100);
  }, [calculateAndSetValues, growthPreRetirementCalculations]);

  useEffect(() => {
    genericInputsCalculations();
  }, [genericInputsCalculations]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    // @ts-ignore
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: GBIFormData): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      const { series, labels } = convertChartSeries(data.data);

      // @ts-ignore
      setChartSeries(series);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
      }));
      return data;
    } catch (error) {
      setError(error as any);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        primary_representation: data.ufs.primary_representation || "",
        growth_rate: data.ufs.growth_rate || "",
        effective_date: data.ufs.effective_date || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || "",
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || "",
        retirement_date: data.ufs.retirement_date || "",
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || "",
        life_expectancy: data.ufs.life_expectancy || "",
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        retirement_longevity: data.ufs.retirement_longevity
          ? data.ufs.retirement_longevity
          : calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,
        post_retirement_costs: data.ufs.post_retirement_costs || "",
        real_pre_retirement_capital_growth_low: data.ufs.real_pre_retirement_capital_growth_low || "",
        real_pre_retirement_capital_growth_medium: data.ufs.real_pre_retirement_capital_growth_medium || "",
        real_pre_retirement_capital_growth_high: data.ufs.real_pre_retirement_capital_growth_high || "",
        nominal_pre_retirement_capital_growth_low: data.ufs.nominal_pre_retirement_capital_growth_low || "",
        nominal_pre_retirement_capital_growth_medium: data.ufs.nominal_pre_retirement_capital_growth_medium || "",
        nominal_pre_retirement_capital_growth_high: data.ufs.nominal_pre_retirement_capital_growth_high || "",
        pre_retirement_costs: data.ufs.pre_retirement_costs || "",
        real_target_capital: 1000000,
        nominal_target_capital: calculateFutureValue(1000000, data.ufs.projected_inflation_rate, 110 / 12),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData: any) => {
    const months = Object.values(chartData.months);

    const cumulativeContributions = Object.values(chartData.cumulative_contributions).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const capitalValue = Object.values(chartData.capital_value).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const series = [
      {
        name: "Cumulative Contributions",
        type: "column",
        color: "#b4c7e7",
        data: cumulativeContributions,
      },
      {
        name: "Capital Value",
        type: "line",
        color: "#a5a5a5",
        data: capitalValue,
      },
    ];

    const labels = months.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Month " + year;
    });

    return { series, labels };
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    setFormData,
    handleSubmit,
    chartOptions,
    chartSeries,
    handleNominalChange,
    handleRealChange,
    handleTargetCapitalChange,
    handlePresentCapitalChange,
  };
};

export default useGoalInvestingTools;
