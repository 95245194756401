import calculations from "@services/algorithms/calculations";

type Props = {
  value: number;
  prefix?: string | null;
  suffix?: string | null;
  textColor?: string;
};

const useFormattedValue = (value: number) => {
  if (value === Infinity || Number.isNaN(value)) {
    return "∞";
  } else if (value === -Infinity) {
    return "-∞";
  } else {
    return value;
  }
};

const isSpecialValue = (value: number) => {
  return value === Infinity || value === -Infinity || Number.isNaN(value) ? true : false;
};

function CommaSeparated({ value, prefix, suffix, textColor }: Props) {
  const formattedValue = useFormattedValue(value);
  const { formatNumberBreaks } = calculations();

  return (
    <span className={`${textColor ? `text-[${textColor}]` : value < 0 ? "text-[red]" : ""}`}>
      {!isSpecialValue(value) && prefix}
      {isSpecialValue(value) ? formattedValue : formatNumberBreaks(value)}
      {!isSpecialValue(value) && suffix}
    </span>
  );
}

const rawCommaSeparated = (value: number): string => {
  const formattedValue = useFormattedValue(value);
  const { formatNumberBreaks } = calculations();
  return isSpecialValue(value) ? formattedValue.toString() : formatNumberBreaks(value).toString();
};

function Decimal({ value, prefix, suffix, textColor }: Props) {
  const formattedValue = useFormattedValue(value);
  const { formatNumber } = calculations();

  return (
    <span className={`${textColor ? `text-[${textColor}]` : value < 0 ? "text-[red]" : ""}`}>
      {!isSpecialValue(value) && prefix}
      {isSpecialValue(value) ? formattedValue : formatNumber(value)}
      {!isSpecialValue(value) && suffix}
    </span>
  );
}

const rawDecimal = (value: number): string => {
  const formattedValue = useFormattedValue(value);
  const { formatNumber } = calculations();
  return isSpecialValue(value) ? formattedValue.toString() : formatNumber(value).toString();
};

export { CommaSeparated, Decimal, rawCommaSeparated, rawDecimal };
