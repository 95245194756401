import { InputCellProps } from "@/components/Element/Table/InputCell";
import { TableHeader } from "@/components/Element/Table/TableHeader";
import { TableRow } from "@/components/Element/Table/TableRow";
import Button from "@/components/Form/Button";
import Input from "@/components/Form/Input";
import Percentage from "@/components/Form/Percentage";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { ActiveIncomeRowData, ActiveIncomeTableData, ColumnData, initialActiveIncome } from "./ActiveIncomeTableData";

const frequencySelect = [
  {
    id: 1,
    name: "Monthly",
  },
  {
    id: 2,
    name: "Annual",
  },
];

interface Column {
  id: string;
  title: string;
  isEditing: boolean;
  isRemovable: boolean;
  width?: string;
}

interface ActiveIncomeTableProps {
  formData: any;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
}

const ActiveIncomeTable: React.FC<ActiveIncomeTableProps> = ({ formData, handleCurrencyChange }) => {
  const [columns, setColumns] = useState<Column[]>([
    {
      id: "formal_salary",
      title: "Formal salary / wages",
      isEditing: false,
      isRemovable: false,
      width: "min-w-[200px]",
    },
    { id: "bonus", title: "Bonus", isEditing: false, isRemovable: false, width: "min-w-[200px]" },
  ]);

  const [rows, setRows] = useState<ActiveIncomeRowData[]>(ActiveIncomeTableData(formData));
  const newColumnInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (newColumnInputRef.current) {
      newColumnInputRef.current.focus();
    }
  }, [columns]);

  const tableHeadings = [
    { text: "Description", id: "description", removable: false, width: "min-w-[200px]" },
    ...columns.map((col) => ({
      text: (
        <div className="flex items-center justify-between">
          {col.isEditing ? (
            <Input
              type="text"
              value={col.title}
              onChange={(e) => handleColumnTitleChange(col.id, e.target.value)}
              autoFocus={col.isEditing}
            />
          ) : (
            <span>{col.title}</span>
          )}
        </div>
      ),
      id: col.id,
      removable: col.isRemovable,
      width: col.width,
    })),
  ];

  const mapInputType = (type: ColumnData["type"]): InputCellProps["type"] => {
    switch (type) {
      case "currency":
      case "select":
      case "date":
      case "percentage":
        return type;
      case "input":
        return "text";
      default:
        return "text";
    }
  };

  const renderTableRow = useCallback(
    (rowData: ActiveIncomeRowData) => (
      <TableRow
        key={rowData.description}
        description={rowData.description}
        inputs={columns.map((col) => {
          const cellData = rowData[col.id] as ColumnData | undefined;
          return {
            type: cellData ? mapInputType(cellData.type) : "currency",
            name: `${col.id}_${rowData.description.toLowerCase().replace(/ /g, "_")}`,
            variant: cellData?.variant || "green",
            selectItems: cellData?.type === "select" ? frequencySelect : undefined,
          };
        })}
        formData={formData}
        handleCurrencyChange={handleCurrencyChange}
      />
    ),
    [columns, formData, handleCurrencyChange]
  );

  const addColumn = useCallback(() => {
    const newColumnId = `column_${columns.length + 1}`;
    setColumns((prevColumns) => [
      ...prevColumns,
      {
        id: newColumnId,
        title: "",
        isEditing: true,
        isRemovable: true,
        width: "min-w-[200px]",
      },
    ]);

    setRows((prevRows) =>
      prevRows.map((row, index) => {
        const newColumnData: ColumnData = {
          type: initialActiveIncome[index].inputType,
          variant: initialActiveIncome[index].variant,
        };
        return {
          ...row,
          [newColumnId]: newColumnData,
        };
      })
    );
  }, [columns.length]);

  const removeColumn = useCallback((columnId: string) => {
    setColumns((prevColumns) => prevColumns.filter((col) => col.id !== columnId));
    setRows((prevRows) =>
      prevRows.map((row) => {
        const { [columnId]: removed, ...rest } = row;
        return rest as ActiveIncomeRowData;
      })
    );
  }, []);

  const handleColumnTitleChange = (columnId: string, newTitle: string) => {
    setColumns((prevColumns) => prevColumns.map((col) => (col.id === columnId ? { ...col, title: newTitle } : col)));
  };

  return (
    <>
      <table className="table-auto border">
        <TableHeader
          headings={tableHeadings}
          removeColumn={removeColumn}
        />
        <tbody>{rows.map(renderTableRow)}</tbody>
      </table>
      <div className="mt-4">
        <Button onClick={addColumn}>Add</Button>
      </div>
      <div className="mt-4">
        <div className="flex items-center mb-4">
          <p className="text-[12px] font-bold text-gray-900 leading-[15px] w-[300px]">
            Targeted wealth creation proportion of active income
          </p>
          <Percentage
            name="wealth_creation_proportion_of_active_income"
            id="wealth_creation_proportion_of_active_income"
            value={formData.wealth_creation_proportion_of_active_income}
            onChange={(value, name) => handleCurrencyChange(value, name, "any")}
            valueType="any"
            variant="green"
            alignText="right"
            twMergeClassName="w-[200px]"
          />
        </div>

        <div className="flex items-center">
          <p className="text-[12px] font-bold text-gray-900 leading-[15px] w-[300px] text-right pr-1">Default is</p>
          <Percentage
            id=""
            valueType="any"
            variant="red"
            alignText="right"
            disabled={true}
            twMergeClassName="w-[200px]"
          />
          <p className="text-[12px] font-bold text-gray-900 leading-[15px] ml-1">at current age</p>
        </div>
      </div>
    </>
  );
};

export default memo(ActiveIncomeTable);
