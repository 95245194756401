export const HomeDescription = () => {
  return (
    <div>
      <p>
        FutureYou is designed to give you objective insights into your financial status and provide simple tools to
        promote a healthier financial lifestyle. We focus on primary elements, identifying how close you are to
        achieving your goals and what changes you can make to become more financially secure.{" "}
      </p>
      <p className="mt-4">The system is segmented as follows:</p>
      <ul className="list-decimal pl-4 mb-4">
        <li>
          <p className="font-semibold mt-2">Standard option</p>
          <ul className="list-[lower-alpha] pl-4">
            <li>
              16 standalone smart tools, delivering simple answers to complex questions on retirement security, wealth
              creation, lifestyle and other significant decisions.
            </li>
            <li>
              Access to information including latest commentaries, expert tips on affordable living, tables of financial
              information and representations on the impact of compound interest.
            </li>
            <li>Expectations and default inputs from professionals</li>
            <li>Change estimates and see the impact</li>
          </ul>
        </li>
        <li>
          <p className="font-semibold mt-2">Premium option</p>
          <ul className="list-[lower-alpha] pl-4">
            <li>Projections from your current situation on a monthly basis through retirement and beyond</li>
            <li>Insights and inferences from your projected journey</li>
            <li>
              Designed to provide a sense of how your current situation, retirement savings, investments, spending
              patterns are likely to provide for you into the future{" "}
            </li>
            <li>
              A “financial security score” evaluating your status as regards life event preparedness and lifestyle
              affordability
            </li>
          </ul>
        </li>
        <li>
          <p className="font-semibold mt-2">Advice option</p>
          <ul className="list-[lower-alpha] pl-4">
            <li>Insurance considerations – how much do I need? </li>
            <li>What products should I invest in to achieve my goals?</li>
            <li>Building a plan to achieve financial security</li>
            <li>Use of AI “product selector” to recommend best combination of products for your specific needs.</li>
            <li>Use of AI “action plan adviser” to recommend set of actions to improve expected outcomes and score</li>
            <li>
              NB: for best value, should be used in conjunction with financial projections from the Premium option
            </li>
          </ul>
        </li>
      </ul>

      <p className="mb-2">The following “colour” protocol is used across the system:</p>
      <table
        cellPadding={0}
        border={0}
      >
        <tbody>
          <tr>
            <td className="bg-[#a96f6d] text-white pl-1 border-b-2 border-white">Linked</td>
            <td className="pl-2">Linked to your primary inputs / defaults – can be overwritten</td>
          </tr>
          <tr>
            <td className="bg-[#85322f] text-white pl-1 pr-4 border-b-2 border-white">Calculated / Fixed</td>
            <td className="pl-2">Calculated fields - cannot overwrite</td>
          </tr>
          <tr>
            <td className="bg-[#4ca852] text-white pl-1 border-b-2 border-white">Input</td>
            <td className="pl-2">Mandatory tool specific inputs – linked where possible</td>
          </tr>
          <tr>
            <td className="bg-[#f2b443] text-white pl-1">Output</td>
            <td className="pl-2">Tool specific outputs calculated by the system </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
