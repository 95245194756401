import image from "@/assets/bg-image.jpg";
import Navbar from "@/components/Layout/Navbar";
import HelpModal from "@/components/Modals/HelpModal";
import Modal from "@/components/Modals/Modal";
import GeneralInformation from "@/components/User/GeneralInformation";
import React, { ReactNode, useState } from "react";

interface PremiumToolLayoutProps {
  children?: ReactNode;
  pageTitle?: string;
  modalContent?: ReactNode;
  showBackButton?: boolean;
  showHelpPopup?: boolean;
  backButtonNavigateTo?: string | number;
}

const PremiumToolLayout: React.FC<PremiumToolLayoutProps> = ({
  children,
  pageTitle,
  modalContent,
  showBackButton,
  showHelpPopup,
  backButtonNavigateTo,
}) => {
  const [helpModal, setHelpModal] = useState<boolean>(showHelpPopup === false);
  const toggleHelpModal = () => setHelpModal(!helpModal);
  const closeHelpModal = () => setHelpModal(false);

  const [modal, setModal] = useState<boolean>(false);
  const closeModal = () => setModal(false);

  return (
    <div>
      <Navbar
        pageTitle={pageTitle}
        modalContent={modalContent}
        toggleHelpModal={toggleHelpModal}
        showBackButton={showBackButton}
        backButtonNavigateTo={backButtonNavigateTo}
        headerBgColor="#85322f"
        headerTextColor="#fff"
      />
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-cover bg-center bg-no-repeat w-full h-screen pt-[70px] relative position-fixed"
      >
        {children}
        <HelpModal
          show={helpModal}
          closeHelpModal={closeHelpModal}
          modalContent={modalContent}
        />
        <Modal
          show={modal}
          closeModal={closeModal}
          modalContent={<GeneralInformation includeIdNumber={true} />}
        />
      </div>
    </div>
  );
};

export default PremiumToolLayout;
