import Card from "@/components/Element/Card";
import Input from "@/components/Form/Input";
import Select from "@/components/Form/Select";
import { ApexOptions } from "apexcharts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { InternationalUnitTrustGlobalssetLabelMapping } from "./InternationalUnitTrustsData";
import InternationalUnitTrustsDataTable from "./InternationalUnitTrustsDataTable";

interface AssetData {
  domicile: string;
  assetClass: string;
  description: string;
  annReturn: number;
  avgRoll12m: number;
  avgRoll36m: number;
  avgRoll60m: number;
}

// Helper functions
const generateDateRange = (start: Date, end: Date, selectedPeriod: string): Date[] => {
  const dates: Date[] = [];
  let currentDate = new Date(start);

  if (selectedPeriod === "Full") {
    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
  } else {
    currentDate = new Date(end);
    currentDate.setMonth(currentDate.getMonth() - parseInt(selectedPeriod, 10) + 1);
    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
  }

  return dates;
};

const generateDataForAsset = (
  asset: AssetData,
  dateRange: Date[],
  selectedPeriod: string,
  chartType: string
): any[] => {
  const annualReturn = asset.annReturn / 100;

  if (chartType === "Scatter") {
    return [
      {
        x: Math.random() * 10, // This should be replaced with actual risk data
        y: asset.annReturn,
      },
    ];
  } else {
    let cumulativeGrowth = 1;
    const monthlyReturn = Math.pow(1 + annualReturn, 1 / 12) - 1;

    return dateRange.map((date, index) => {
      const timeValue = date.getTime();
      let value: number;

      if (chartType === "Growth") {
        if (index === 0) {
          value = 100;
        } else {
          cumulativeGrowth *= 1 + monthlyReturn;
          value = (cumulativeGrowth - 1) * 100;
        }
      } else {
        // Rolling
        const periodValue =
          selectedPeriod === "12m" ? asset.avgRoll12m : selectedPeriod === "36m" ? asset.avgRoll36m : asset.avgRoll60m;
        const volatility = periodValue * 0.1;
        value = periodValue + (Math.random() - 0.5) * volatility;
      }

      return { x: timeValue, y: parseFloat(value.toFixed(2)) };
    });
  }
};

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const InternationalUnitTrusts: React.FC = () => {
  const [startDate, setStartDate] = useState<string>("2009-08-31");
  const [endDate, setEndDate] = useState<string>("2024-07-31");
  const [analysisType, setAnalysisType] = useState<string>("Nominal");
  const [currency, setCurrency] = useState<string>("ZAR");
  const [chartType, setChartType] = useState<string>("Rolling");
  const [period, setPeriod] = useState<string>("60m");
  const [selectAll, setSelectAll] = useState(true);

  const assetData = useMemo<AssetData[]>(
    () => [
      {
        domicile: "Domestic",
        assetClass: "Inflation",
        description: "CPI",
        annReturn: -0.7,
        avgRoll12m: -0.7,
        avgRoll36m: -1.9,
        avgRoll60m: -1.9,
      },
      {
        domicile: "Domestic",
        assetClass: "Equity",
        description: "Global EQ Financial",
        annReturn: 7.2,
        avgRoll12m: 7.6,
        avgRoll36m: 6.9,
        avgRoll60m: 6.8,
      },
      {
        domicile: "Domestic",
        assetClass: "Fixed Income",
        description: "Global IB Short Term",
        annReturn: 0.1,
        avgRoll12m: 0.1,
        avgRoll36m: -0.4,
        avgRoll60m: -0.4,
      },
      {
        domicile: "Domestic",
        assetClass: "Fixed Income",
        description: "Global IB Variable Term",
        annReturn: 1.1,
        avgRoll12m: 1.0,
        avgRoll36m: 0.8,
        avgRoll60m: 0.9,
      },
      {
        domicile: "Domestic",
        assetClass: "Multi-Asset",
        description: "Global MA Flexible",
        annReturn: 4.6,
        avgRoll12m: 4.5,
        avgRoll36m: 4.2,
        avgRoll60m: 4.2,
      },
      {
        domicile: "Domestic",
        assetClass: "Multi-Asset",
        description: "Global MA High Equity",
        annReturn: 4.9,
        avgRoll12m: 5.0,
        avgRoll36m: 4.7,
        avgRoll60m: 4.7,
      },
      {
        domicile: "Domestic",
        assetClass: "Multi-Asset",
        description: "Global MA Low Equity",
        annReturn: 2.0,
        avgRoll12m: 1.8,
        avgRoll36m: 1.5,
        avgRoll60m: 1.5,
      },
    ],
    []
  );

  const [selectedAssets, setSelectedAssets] = useState<string[]>(
    assetData.map((asset) => `${asset.domicile} ${asset.assetClass}`)
  );

  const [chartData, setChartData] = useState<any[]>([]);

  const fetchData = useCallback(() => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const dateRange = generateDateRange(startDateObj, endDateObj, chartType === "Growth" ? "Full" : period);

    const newChartData = selectedAssets
      .map((assetKey) => {
        const asset = assetData.find((a) => `${a.domicile} ${a.assetClass}` === assetKey);
        if (asset) {
          const assetLabel =
            InternationalUnitTrustGlobalssetLabelMapping[`${asset.domicile} ${asset.assetClass}`] || asset.description;
          return {
            name: assetLabel,
            type: chartType === "Scatter" ? "scatter" : "line",
            data: generateDataForAsset(asset, dateRange, chartType === "Growth" ? "Full" : period, chartType),
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    setChartData(newChartData);
  }, [selectedAssets, startDate, endDate, period, chartType, assetData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAssetToggle = (assetKey: string) => {
    setSelectedAssets((prevSelected) =>
      prevSelected.includes(assetKey) ? prevSelected.filter((key) => key !== assetKey) : [...prevSelected, assetKey]
    );
  };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => {
      const newSelectAll = !prevSelectAll;
      setSelectedAssets(newSelectAll ? assetData.map((asset) => `${asset.domicile} ${asset.assetClass}`) : []);
      return newSelectAll;
    });
  };

  useEffect(() => {
    if (chartType === "Growth") {
      setPeriod("Full");
    } else if (chartType === "Rolling" && period === "Full") {
      setPeriod("60m");
    }
  }, [chartType]);

  const periodOptions = useMemo(
    () => [
      { id: "Full", name: "Full period" },
      { id: "12m", name: "12m" },
      { id: "36m", name: "36m" },
      { id: "60m", name: "60m" },
    ],
    []
  );

  const isPeriodDisabled = useCallback(
    (periodId: string) => {
      if (chartType === "Growth") {
        return periodId !== "Full";
      } else if (chartType === "Rolling") {
        return periodId === "Full";
      }
      return false;
    },
    [chartType]
  );

  const chartOptions: ApexOptions = useMemo(
    () => ({
      chart: {
        type: chartType === "Scatter" ? "scatter" : "line",
        height: 400,
        zoom: { enabled: false },
        toolbar: { show: false },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 200,
          animateGradually: {
            enabled: true,
            delay: 50,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 200,
          },
        },
      },
      xaxis: {
        type: chartType === "Scatter" ? "numeric" : "datetime",
        title: { text: chartType === "Scatter" ? "Risk" : undefined },
        labels: {
          rotate: -90,
          rotateAlways: true,
          formatter: (value) => {
            if (chartType === "Scatter") return Number(value).toFixed(2);
            const date = new Date(value);
            return `${date.toLocaleString("default", { month: "short" })}-${date.getFullYear().toString().substr(-2)}`;
          },
        },
        tickAmount: 15,
      },
      yaxis: {
        title: { text: chartType === "Scatter" ? "Return" : undefined },
        labels: {
          formatter: (value) => `${value.toFixed(1)}%`,
        },
        min: chartType === "Growth" ? 0 : -20,
        max: chartType === "Growth" ? undefined : 30,
        tickAmount: 10,
      },
      stroke: {
        width: chartType === "Scatter" ? 0 : 2,
        curve: "smooth",
      },
      markers: {
        size: chartType === "Scatter" ? 6 : 0,
        colors: ["#008FFB"],
      },
      title: {
        text:
          chartType === "Scatter"
            ? `Average ${period} ${analysisType.toLowerCase()} return scatter plot - ${currency}`
            : chartType === "Growth"
              ? `${analysisType} growth in asset class returns - ${currency}`
              : `${period} ${analysisType.toLowerCase()} % change in asset class returns by selected currency - ${currency}`,
        align: "center",
        style: {
          fontSize: "12px",
          whiteSpace: "normal",
          wordWrap: "break-word",
          overflowWrap: "break-word",
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetY: 15,
        itemMargin: { horizontal: 5, vertical: 10 },
        fontSize: "12px",
        markers: { width: 12, height: 12, strokeWidth: 0, strokeColor: "#fff", radius: 12 },
      },
      tooltip: {
        shared: false,
        intersect: true,
        custom: function ({ seriesIndex, dataPointIndex, w }) {
          const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          if (chartType === "Scatter") {
            return `
            <div class="arrow_box">
              <span>${w.globals.seriesNames[seriesIndex]}</span><br/>
              <span>Risk: ${data.x.toFixed(2)}</span><br/>
              <span>Return: ${data.y.toFixed(2)}%</span>
            </div>
          `;
          } else {
            const date = new Date(data.x);
            return `
            <div class="arrow_box">
              <span>${w.globals.seriesNames[seriesIndex]}</span><br/>
              <span>Date: ${date.toLocaleString("default", { month: "short" })} ${date.getFullYear()}</span><br/>
              <span>Value: ${data.y.toFixed(2)}%</span>
            </div>
          `;
          }
        },
      },
    }),
    [chartType, period, analysisType]
  );

  return (
    <div className="max-h-[calc(100vh-70px)] h-full pb-2 lg:w-[90%] mx-auto">
      <div className="grid grid-cols-5 gap-4 pt-6">
        <div className="col-span-5">
          <Card variant="white">
            <p className="text-sm">
              <strong>
                We represent the category average returns of international unit trusts available in South Africa -{" "}
              </strong>{" "}
              this allows you to contextualise the total growth and rolling rates of return of the major unit trust
              asset categories over time, translated into the currency selected, and commencing and ending at the
              selected dates
            </p>
          </Card>
        </div>
        <div className="col-span-3 max-h-[calc(100vh-270px)] overflow-y-auto scrollbar">
          <Card variant="white">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-bold mb-4">Parameters</h2>
              <div className="flex items-center mb-2">
                <label className="block text-sm font-bold mr-2">Period:</label>
                <div className="flex space-x-2 ml-auto">
                  {periodOptions.map((option) => (
                    <label
                      key={option.id}
                      className="inline-flex items-center"
                    >
                      <input
                        type="radio"
                        className="text-xs"
                        name="period"
                        value={option.id}
                        checked={period === option.id}
                        onChange={(e) => setPeriod(e.target.value)}
                        disabled={isPeriodDisabled(option.id)}
                      />
                      <span
                        className={`ml-2 text-xs ${isPeriodDisabled(option.id) ? "text-gray-400" : "text-gray-700"}`}
                      >
                        {option.name}
                      </span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-5 gap-4">
              <Input
                type="date"
                label="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min="1999-12-31"
                max={endDate}
                inLine={false}
              />
              <Input
                type="date"
                label="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate}
                max={getCurrentDate()}
                inLine={false}
              />
              <Select
                label="Analysis Type"
                value={analysisType}
                onChange={(e) => setAnalysisType(e.target.value)}
                items={[
                  { id: "Nominal", name: "Nominal" },
                  { id: "Real", name: "Real" },
                ]}
                variant="white"
                inLine={false}
              />
              <Select
                label="Currency"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                items={[
                  { id: "ZAR", name: "ZAR" },
                  { id: "USD", name: "USD" },
                  { id: "GBP", name: "GBP" },
                  { id: "EUR", name: "EUR" },
                ]}
                variant="white"
                inLine={false}
              />
              <Select
                label="Chart Type"
                value={chartType}
                onChange={(e) => setChartType(e.target.value)}
                items={[
                  { id: "Scatter", name: "Scatter" },
                  { id: "Growth", name: "Growth" },
                  { id: "Rolling", name: "Rolling" },
                ]}
                variant="white"
                inLine={false}
              />
            </div>
          </Card>

          <Card
            variant="white"
            className="mt-4"
          >
            <h2 className="text-lg font-bold mb-4">
              Asset class selection & returns : period & currency indicated above
            </h2>
            <InternationalUnitTrustsDataTable
              assetData={assetData}
              selectedAssets={selectedAssets}
              onAssetToggle={handleAssetToggle}
              selectAll={selectAll}
              onSelectAll={handleSelectAll}
            />
          </Card>
        </div>

        <div className="col-span-2 max-h-[calc(100vh-270px)]">
          <Card
            variant="white"
            className="h-full p-0"
          >
            <div className="h-full ">
              {chartData.length > 0 ? (
                <Chart
                  options={chartOptions}
                  series={chartData}
                  type={chartType === "Scatter" ? "scatter" : "line"}
                  height="100%"
                  width="100%"
                  className="max-h-full h-full min-h-[unset_!important]"
                />
              ) : (
                <div className="h-full flex items-center justify-center">
                  <p>No data selected.</p>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default InternationalUnitTrusts;
