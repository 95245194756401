import React, { ChangeEvent } from "react";

interface GrowthRateProps {
  riskLevel: string;
  loading?: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const GrowthRate: React.FC<GrowthRateProps> = ({ riskLevel, loading = false, handleChange }) => {
  const createSyntheticEvent = (name: string, value: string) => {
    return {
      target: { name, value },
    } as ChangeEvent<HTMLInputElement>;
  };

  return (
    <>
      <p className="mr-4 font-bold text-[16px]">Select growth rate from:</p>
      <div className="bg-white h-[44px] rounded-md p-1 flex items-center">
        <button
          className={`px-[18px] h-[36px] rounded mr-1 text-[12px] min-w-[100px] hover:bg-green-600 hover:text-white transition-all ${riskLevel === "Low" ? "bg-green-600 text-white" : "bg-[#f9f9f9]"} ${loading ? "cursor-not-allowed" : ""} `}
          onClick={() => !loading && handleChange(createSyntheticEvent("growth_rate", "Low"))}
          disabled={loading}
        >
          Low
        </button>
        <button
          className={`px-[18px] h-[36px] rounded mr-1 text-[12px] min-w-[100px] hover:bg-orange-600 hover:text-white transition-all ${riskLevel === "Medium" ? "bg-orange-600 text-white" : "bg-[#f9f9f9]"} ${loading ? "cursor-not-allowed" : ""} `}
          onClick={() => !loading && handleChange(createSyntheticEvent("growth_rate", "Medium"))}
          disabled={loading}
        >
          Medium
        </button>
        <button
          className={`px-[18px] h-[36px] rounded text-[12px] min-w-[100px] hover:bg-red-600 hover:text-white transition-all ${riskLevel === "High" ? "bg-red-600 text-white" : "bg-[#f9f9f9]"} ${loading ? "cursor-not-allowed" : ""} `}
          onClick={() => !loading && handleChange(createSyntheticEvent("growth_rate", "High"))}
          disabled={loading}
        >
          High
        </button>
      </div>
    </>
  );
};

export default GrowthRate;
