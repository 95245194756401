import { cn } from "@utils/cn";
import { cva } from "class-variance-authority";
import CurrencyInput from "react-currency-input-field";
import { MdOutlineCommentsDisabled } from "react-icons/md";

export default function PercentageSwitch({
  label = "",
  disabled = false,
  primaryRepresentation,
  realField,
  nominalField,
  formData,
  onChange,
  twMergeClassName = "",
  variant,
  required,
  inLine = true,
  alignLeft = false,
  alignText = "left",
}) {
  const inputVariants = cva(
    `${alignLeft ? "text-left" : "text-center"} ${twMergeClassName ?? ""} text-center focus:ring-primary-500 focus:border-primary-500 p-1 h-[30px] rounded-md sm:text-[12px] block w-full sm:min-w-[70px] font-bold `,
    {
      variants: {
        variant: {
          white: `bg-gray-50 border border-gray-300 text-gray-900 px-4 ${disabled ? "cursor-not-allowed" : ""}`,
          red: `border border-gray-300 text-white icon-white px-4 ${disabled ? "bg-[#85322f] cursor-not-allowed" : "bg-[#a96f6d]"}`,
          green: `border border-gray-300 text-white icon-white px-4 ${disabled ? "bg-[#008308] cursor-not-allowed" : "bg-[#4ca852]"}`,
          orange: "bg-[#f2e1c3] border border-gray-300 text-black",
        },
      },
    }
  );

  return (
    <div className={`${inLine ? "flex" : ""} items-center relative`}>
      {label && (
        <label
          htmlFor={primaryRepresentation === "real" ? realField : nominalField}
          className={`${inLine ? "block " : "flex mb-2"} ${alignText === "right" ? "text-right" : "text-left"} mr-2 text-[12px] font-bold text-gray-900 w-full leading-[15px]`}
        >
          {label} {required ? " *" : ""}
        </label>
      )}
      <CurrencyInput
        name={primaryRepresentation === "real" ? realField : nominalField}
        id={primaryRepresentation === "real" ? realField : nominalField}
        value={primaryRepresentation === "real" ? formData[realField] : formData[nominalField]}
        className={cn(inputVariants({ variant }))}
        disabled={disabled}
        decimalsLimit={2}
        decimalScale={2}
        decimalSeparator="."
        groupSeparator=" "
        onValueChange={onChange}
        suffix={" %"}
      />
      {disabled && (
        <div
          className={`${inLine ? "" : "h-fit top-8"} absolute inset-y-0 right-0.5  flex items-center pointer-events-none`}
        >
          <MdOutlineCommentsDisabled className={`h-[12px] ${variant === "white" ? "text-red-500" : "text-white"}`} />
        </div>
      )}
    </div>
  );
}
