import React, { useEffect, useState } from "react";

interface ToggleSwitchProps {
  initialChecked?: boolean;
  onToggle?: (checked: boolean) => void;
  leftLabel?: string;
  rightLabel?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  initialChecked = false,
  onToggle,
  leftLabel = "On",
  rightLabel = "Off",
}) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onToggle) {
      onToggle(newChecked);
    }
  };

  useEffect(() => {
    setChecked(initialChecked);
  }, [initialChecked]);

  return (
    <div
      onClick={handleToggle}
      className={`relative w-28 h-10 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ease-in-out bg-[#243665]`}
    >
      <div
        className={`absolute left-1 top-1 w-8 h-8 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
          checked ? "translate-x-[4.5rem]" : ""
        }`}
      />
      <span
        className={`absolute left-3 text-white font-medium text-sm transition-opacity duration-300 ease-in-out ${
          checked ? "opacity-100" : "opacity-0"
        }`}
      >
        {leftLabel}
      </span>
      <span
        className={`absolute right-3 text-white font-medium text-sm transition-opacity duration-300 ease-in-out ${
          checked ? "opacity-0" : "opacity-100"
        }`}
      >
        {rightLabel}
      </span>
    </div>
  );
};

export default ToggleSwitch;
