import { FooterTabs, TabContent } from "@/components/Element/FooterTabs";
import CommentaryLayout from "@/layouts/CommentaryLayout";
import { useSearchParams } from "react-router-dom";
import DomesticUnitTrusts from "./DomesticUnitTrusts/DomesticUnitTrusts";
import InternationalUnitTrusts from "./InternationalUnitTrusts/InternationalUnitTrusts";
import MarketMetrics from "./MarketMetrics/MarketMetrics";

const PerformanceTablesAndLinksIndex = () => {
  const [searchParams] = useSearchParams();
  const getTabIndex = searchParams.get("tab");

  return (
    <CommentaryLayout
      showBackButton
      backButtonNavigateTo={"/commentary"}
      pageTitle={
        getTabIndex === "1"
          ? "Market metrics"
          : getTabIndex === "2"
            ? "Select domestic unit trusts"
            : getTabIndex === "3" && "Select international unit trusts"
      }
    >
      <>
        <FooterTabs tabIndex={getTabIndex}>
          <TabContent
            tabtitle="Market metrics"
            className="h-full"
          >
            <MarketMetrics />
          </TabContent>
          <TabContent
            tabtitle="Select domestic unit trusts"
            className="h-full"
          >
            <DomesticUnitTrusts />
          </TabContent>
          <TabContent
            tabtitle="Select international unit trusts"
            className="h-full"
          >
            <InternationalUnitTrusts />
          </TabContent>
        </FooterTabs>
      </>
    </CommentaryLayout>
  );
};

export default PerformanceTablesAndLinksIndex;
