import image from "@assets/bg-image.jpg";
import PageTitle from "@elements/PageTitle";
import StandardToolLayout from "@layouts/StandardToolLayout";

import { IconContext } from "react-icons";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaCalculator, FaCar, FaGraduationCap, FaMoneyCheckAlt, FaStarOfLife, FaTools } from "react-icons/fa";
import { FaHouseChimney, FaPersonArrowUpFromLine, FaSackDollar } from "react-icons/fa6";
import { GiStairsGoal, GiThink } from "react-icons/gi";
import { HiReceiptTax } from "react-icons/hi";
import { IoIosPaper } from "react-icons/io";
import { MdSolarPower } from "react-icons/md";
import { TbChartInfographic } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const StandardIndex = () => {
  const navigate = useNavigate();

  return (
    <StandardToolLayout
      showBackButton={true}
      pageTitle={"Standard Smart Tools"}
    >
      <PageTitle title="Standard Smart Tools" />
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-cover bg-center bg-no-repeat w-full h-full relative"
      >
        <div className="w-full min-h-[calc(100vh-70px)] relative">
          <div className="mx-auto lg:px-[6%] md:px-10 xs:px-4 pb-4 pt-[20px] min-h-[calc(100vh-70px)] items-center relative grid">
            <div>
              <div className="grid grid-cols-2 gap-10 text-center mb-4">
                <div>
                  <p className="text-[24px] text-center">RETIREMENT</p>
                </div>
                <div>
                  <p className="text-[24px] text-center">LIFESTYLE</p>
                </div>
              </div>
              <div className="col-span-8">
                <div className="grid grid-cols-4 gap-4 text-center">
                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/retirement/retirement-need")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaSackDollar />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            How much money do I need in retirement?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/retirement/money-growth")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <BsGraphUpArrow />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            How much will my money grow to by retirement?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/lifestyle/vehicle-cost")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaCar />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Opportunity costs of ownership of different vehicles
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/lifestyle/buy-vs-rent")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaHouseChimney />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Home purchase vs renting - what's my breakeven point and what can I afford?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/retirement/money-last")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <TbChartInfographic />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            How long will my money last in retirement?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/retirement/gap-analysis")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <GiThink />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Retirement funding gap analysis - what can I do?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/lifestyle/education-cost")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaGraduationCap />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">Real cost of education</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/lifestyle/loan-affordability")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaCalculator />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Loan affordability calculator and the impact on your standard of living
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-10 text-center mt-10 mb-4">
                <div>
                  <p className="text-[24px] text-center">WEALTH CREATION</p>
                </div>
                <div>
                  <p className="text-[24px] text-center">ADDITIONAL</p>
                </div>
              </div>

              <div className="col-span-8">
                <div className="grid grid-cols-4 gap-4 text-center">
                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/wealth-creation/goal-investing")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <GiStairsGoal />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Goal-based investing - how much do I need to invest each month to reach my goal?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/wealth-creation/bonus-impact")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaMoneyCheckAlt />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Impact of investing part of your annual bonus on your retirement pot
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/additional/budgeting")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaTools />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">Simple budgeting tool</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/additional/solar-energy")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <MdSolarPower />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">Solar energy - is it worth it?</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/wealth-creation/tax-benefits")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <HiReceiptTax />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Tax benefits of investing in a retirement fund vs a standard investment fund
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/wealth-creation/targeted-investments")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaPersonArrowUpFromLine />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Targeted financial investments by age relative to income
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/additional/two-pot-retirement")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <IoIosPaper />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Two-pot retirement solution - what is the impact on me?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#fff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/standard/additional/life-insurance")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaStarOfLife />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">How much life insurance do I need?</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StandardToolLayout>
  );
};

export default StandardIndex;
