import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";
import createAxiosInstance from "@api/Api";
import useDebounce from "@hooks/useDebounce";
import calculations from "@services/algorithms/calculations";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import numbers from "@services/algorithms/numbers";
import helpers from "@utils/helpers";
import { ApexOptions } from "apexcharts";
import { useCallback, useEffect, useState } from "react";

// Enums for fixed values
enum GrowthRate {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

// Type Aliases
type NumberOrString = number | string;

// Interface Segmentation
interface Miscellaneous {
  growth_rate: GrowthRate;
  primary_representation: string;
}

interface GenericInputs {
  effective_date: string;
  projected_inflation_rate: number;
  date_of_birth: string;
  retirement_date: string;
  retirement_age: number;
  current_age: NumberOrString;
  years_to_retirement: number;
  life_expectancy: number;
  retirement_longevity_custom: boolean;
  retirement_longevity: number;
  employment_status_id: string;
  currency_id: string;
}

interface GrowthCostAssumptions {
  real_pre_retirement_capital_growth_low: number;
  real_pre_retirement_capital_growth_medium: number;
  real_pre_retirement_capital_growth_high: number;
  nominal_pre_retirement_capital_growth_low: number;
  nominal_pre_retirement_capital_growth_medium: number;
  nominal_pre_retirement_capital_growth_high: number;
  pre_retirement_costs: number;
  pre_retirement_capital_growth_low: number;
  pre_retirement_capital_growth_medium: number;
  pre_retirement_capital_growth_high: number;
  real_post_retirement_capital_growth_low: number;
  real_post_retirement_capital_growth_medium: number;
  real_post_retirement_capital_growth_high: number;
  nominal_post_retirement_capital_growth_low: number;
  nominal_post_retirement_capital_growth_medium: number;
  nominal_post_retirement_capital_growth_high: number;
  post_retirement_costs: number;
  post_retirement_capital_growth_low: number;
  post_retirement_capital_growth_medium: number;
  post_retirement_capital_growth_high: number;
  real_pre_net_retirement_growth_low: number;
  real_pre_net_retirement_growth_medium: number;
  real_pre_net_retirement_growth_high: number;
  nominal_pre_net_retirement_growth_low: number;
  nominal_pre_net_retirement_growth_medium: number;
  nominal_pre_net_retirement_growth_high: number;
  real_post_net_retirement_growth_low: number;
  real_post_net_retirement_growth_medium: number;
  real_post_net_retirement_growth_high: number;
  nominal_post_net_retirement_growth_low: number;
  nominal_post_net_retirement_growth_medium: number;
  nominal_post_net_retirement_growth_high: number;
  real_target_income_at_retirement: number;
  nominal_target_income_at_retirement: number;
  tax_domicile_id: number;
  primary_representation_id: string;
  pre_retirement_income_percentage: number;
  contribution_as_percentage_of_income: number;
}

interface SmartToolSpecificInputs {
  annual_pre_tax_income: number;
  annual_income: number;
  real_growth_in_income: number;
  nominal_growth_in_income: number;
  annual_bonus: number;
  real_growth_in_bonus: number;
  nominal_growth_in_bonus: number;
  current_accumulated_retirement_fund_value: number;
  current_monthly_contribution: number;
  post_retirement_income_percentage: number;
  post_retirement_bonus_percentage: number;
}

interface Output {
  required_multiple_low: number;
  required_multiple_medium: number;
  required_multiple_high: number;
  real_required_value_low: number;
  real_required_value_medium: number;
  real_required_value_high: number;
  nominal_required_value_low: number;
  nominal_required_value_medium: number;
  nominal_required_value_high: number;
  real_projected_capital_low: number;
  real_projected_capital_medium: number;
  real_projected_capital_high: number;
  nominal_projected_capital_low: number;
  nominal_projected_capital_medium: number;
  nominal_projected_capital_high: number;
  from_initial_capital_low: number;
  from_initial_capital_medium: number;
  from_initial_capital_high: number;
  from_contributions_low: number;
  from_contributions_medium: number;
  from_contributions_high: number;
  from_bonus_allocations_low: number;
  from_bonus_allocations_medium: number;
  from_bonus_allocations_high: number;
}

interface FormatterParams {
  seriesIndex: number;
  dataPointIndex: number;
  w: any;
}

// Main Interface
interface BonusFormData extends Miscellaneous, GenericInputs, GrowthCostAssumptions, SmartToolSpecificInputs, Output {}

const useBonusImpactTool = () => {
  const api = createAxiosInstance("standard/wealth-creation/bonus-impact");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementLongevity, formatCurrency } = numbers();
  const {
    calculateRequiredMultiple,
    calculateRequiredValue,
    calculateTargetProjectedCapital,
    calculateNominalTargetProjectedCapital,
    calculatePercentageFromInitialCapital,
    calculatePercentageFromContribution,
    calculatePercentageFromBonusAllocations,
    calculateTargetIncome,
  } = calculations();

  const [chartSeries, setChartSeries] = useState([]);

  const calculateColumnWidth = useCallback((numberOfYears: number) => {
    const maxYears = 32;
    const maxWidth = 30;
    const minWidth = 10;

    const widthReduction = Math.max(0, maxYears - numberOfYears);

    let columnWidth = Math.max(minWidth, maxWidth - widthReduction);

    return `${columnWidth}px`;
  }, []);

  const [chartOptions, setChartOptions] = useState<ApexOptions>(() => ({
    ...chartOptionsDefault,
    chart: {
      ...chartOptionsDefault.chart,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: [0, 0, 0, 1, 1],
      colors: ["transparent", "transparent", "transparent", "#000000", "#000000"],
    },
    fill: {
      type: ["solid", "solid", "solid", "solid", "solid"],
    },
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      ...chartOptionsDefault.xaxis,
      axisTicks: { show: false },
      axisBorder: { show: false },
    },
    yaxis: {
      ...chartOptionsDefault.yaxis,
      labels: {
        formatter: (value) => (value >= 1000000 ? `R${(value / 1000000).toFixed(1)}M` : `R${value.toFixed(0)}`),
      },
    },
    tooltip: {
      shared: true,
      followCursor: true,
      y: {
        formatter: (value) => `R${value.toFixed(0)}`,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "15%",
        borderRadius: 2,
        dataLabels: {
          position: "top",
        },
      },
    },
  }));

  const updateChartOptions = useCallback(
    (labels: string[]) => {
      const columnWidth = calculateColumnWidth(labels.length);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
        plotOptions: {
          ...prevOptions.plotOptions,
          bar: {
            ...prevOptions.plotOptions?.bar,
            columnWidth: columnWidth,
          },
        },
      }));
    },
    [calculateColumnWidth]
  );

  const convertChartSeries = (chartData: any) => {
    const years = Object.values(chartData.years);

    const initialCapital = Object.values(chartData.initial_capital_at_works).map((value: any) =>
      parseFloat(value.replace(/,/g, ""))
    );
    const monthlyContributions = Object.values(chartData.monthly_contributions_capital_at_works).map((value: any) =>
      parseFloat(value.replace(/,/g, ""))
    );
    const annualBonus = Object.values(chartData.annual_bonus_capital_at_works).map((value: any) =>
      parseFloat(value.replace(/,/g, ""))
    );

    // Calculate cumulative values for stacking
    const cumulativeMonthly = initialCapital.map((ic, index) => ic + monthlyContributions[index]);
    const cumulativeTotal = cumulativeMonthly.map((cm, index) => cm + annualBonus[index]);

    const series = [
      {
        name: "Annual bonus capital at work",
        type: "area",
        data: cumulativeTotal,
        group: "apexchartsaxis1",
        color: "#70ad47",
      },
      {
        name: "Monthly contributions capital at work",
        type: "area",
        data: cumulativeMonthly,
        group: "apexchartsaxis2",
        color: "#5b9bd5",
      },
      {
        name: "Initial capital at work",
        type: "area",
        data: initialCapital,
        group: "apexchartsaxis3",
        color: "#ffc000",
      },
      {
        name: "Cumulative contributions ex salary",
        type: "bar",
        data: Object.values(chartData.cumulative_contributions_ex_salaries).map((value: any) =>
          parseFloat(value.replace(/,/g, ""))
        ),
        group: "apexchartsaxis4",
        color: "#ed7d31",
      },
      {
        name: "Cumulative contributions ex bonus",
        type: "bar",
        data: Object.values(chartData.cumulative_contributions_ex_bonuses).map((value: any) =>
          parseFloat(value.replace(/,/g, ""))
        ),
        group: "apexchartsaxis5",
        color: "#a5a5a5",
      },
    ];

    const labels = years.map((year, index) => (index === 0 ? "Inception" : `Year ${year}`));
    updateChartOptions(labels);

    return { series, labels };
  };

  const [formData, setFormData] = useState<BonusFormData>({
    // Miscellaneous
    growth_rate: GrowthRate.Low,
    primary_representation: "",
    // Generic inputs
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_date: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity_custom: false,
    retirement_longevity: 0,
    employment_status_id: "",
    currency_id: "",
    // Growth & cost assumptions
    real_pre_retirement_capital_growth_low: 0,
    real_pre_retirement_capital_growth_medium: 0,
    real_pre_retirement_capital_growth_high: 0,
    nominal_pre_retirement_capital_growth_low: 0,
    nominal_pre_retirement_capital_growth_medium: 0,
    nominal_pre_retirement_capital_growth_high: 0,
    pre_retirement_costs: 0,
    pre_retirement_capital_growth_low: 0,
    pre_retirement_capital_growth_medium: 0,
    pre_retirement_capital_growth_high: 0,
    real_post_retirement_capital_growth_low: 0,
    real_post_retirement_capital_growth_medium: 0,
    real_post_retirement_capital_growth_high: 0,
    nominal_post_retirement_capital_growth_low: 0,
    nominal_post_retirement_capital_growth_medium: 0,
    nominal_post_retirement_capital_growth_high: 0,
    post_retirement_costs: 0,
    post_retirement_capital_growth_low: 0,
    post_retirement_capital_growth_medium: 0,
    post_retirement_capital_growth_high: 0,
    real_pre_net_retirement_growth_low: 0,
    real_pre_net_retirement_growth_medium: 0,
    real_pre_net_retirement_growth_high: 0,
    nominal_pre_net_retirement_growth_low: 0,
    nominal_pre_net_retirement_growth_medium: 0,
    nominal_pre_net_retirement_growth_high: 0,
    real_post_net_retirement_growth_low: 0,
    real_post_net_retirement_growth_medium: 0,
    real_post_net_retirement_growth_high: 0,
    nominal_post_net_retirement_growth_low: 0,
    nominal_post_net_retirement_growth_medium: 0,
    nominal_post_net_retirement_growth_high: 0,
    real_target_income_at_retirement: 0,
    nominal_target_income_at_retirement: 0,
    tax_domicile_id: 0,
    primary_representation_id: "",
    pre_retirement_income_percentage: 0,
    contribution_as_percentage_of_income: 0,
    // Smart tool specific inputs
    annual_pre_tax_income: 0,
    annual_income: 0,
    real_growth_in_income: 0,
    nominal_growth_in_income: 0,
    annual_bonus: 0,
    real_growth_in_bonus: 0,
    nominal_growth_in_bonus: 0,
    current_accumulated_retirement_fund_value: 0,
    current_monthly_contribution: 0,
    post_retirement_income_percentage: 0,
    post_retirement_bonus_percentage: 25,
    // Output
    required_multiple_low: 0,
    required_multiple_medium: 0,
    required_multiple_high: 0,
    real_required_value_low: 0,
    real_required_value_medium: 0,
    real_required_value_high: 0,
    nominal_required_value_low: 0,
    nominal_required_value_medium: 0,
    nominal_required_value_high: 0,
    real_projected_capital_low: 0,
    real_projected_capital_medium: 0,
    real_projected_capital_high: 0,
    nominal_projected_capital_low: 0,
    nominal_projected_capital_medium: 0,
    nominal_projected_capital_high: 0,
    from_initial_capital_low: 0,
    from_initial_capital_medium: 0,
    from_initial_capital_high: 0,
    from_contributions_low: 0,
    from_contributions_medium: 0,
    from_contributions_high: 0,
    from_bonus_allocations_low: 0,
    from_bonus_allocations_medium: 0,
    from_bonus_allocations_high: 0,
  });

  const debouncedData = useDebounce(formData, 100);

  const {
    handleNominalChange,
    handleRealChange,
    genericInputsCalculations,
    growthPreRetirementCalculations,
    growthPostRetirementCalculations,
  } = growthRateHandler(debouncedData, setFormData);

  const calculateAndSetValues = useCallback(() => {
    const realTargetIncome = calculateTargetIncome(
      formData.annual_pre_tax_income,
      formData.real_growth_in_income,
      formData.years_to_retirement,
      formData.post_retirement_income_percentage
    );
    const nominalTargetIncome = calculateTargetIncome(
      formData.annual_pre_tax_income,
      formData.nominal_growth_in_income,
      formData.years_to_retirement,
      formData.post_retirement_income_percentage
    );

    const realProjectedCapitalLow = calculateTargetProjectedCapital(
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage,
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_low,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value
    );
    const realProjectedCapitalMedium = calculateTargetProjectedCapital(
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage,
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_medium,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value
    );
    const realProjectedCapitalHigh = calculateTargetProjectedCapital(
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage,
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_high,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value
    );
    const nominalProjectedCapitalLow = calculateNominalTargetProjectedCapital(
      realProjectedCapitalLow,
      debouncedData.projected_inflation_rate,
      debouncedData.years_to_retirement
    );
    const nominalProjectedCapitalMedium = calculateNominalTargetProjectedCapital(
      realProjectedCapitalMedium,
      debouncedData.projected_inflation_rate,
      debouncedData.years_to_retirement
    );
    const nominalProjectedCapitalHigh = calculateNominalTargetProjectedCapital(
      realProjectedCapitalHigh,
      debouncedData.projected_inflation_rate,
      debouncedData.years_to_retirement
    );

    const fromInitialCapitalLow = calculatePercentageFromInitialCapital(
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_low,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage
    );
    const fromInitialCapitalMedium = calculatePercentageFromInitialCapital(
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_medium,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage
    );
    const fromInitialCapitalHigh = calculatePercentageFromInitialCapital(
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_high,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage
    );

    const fromContributionsLow = calculatePercentageFromContribution(
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_low,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage
    );

    const fromContributionsMedium = calculatePercentageFromContribution(
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_medium,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage
    );

    const fromContributionsHigh = calculatePercentageFromContribution(
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_high,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage
    );
    const fromBonusAllocationsLow = calculatePercentageFromBonusAllocations(
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_low,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage
    );

    const fromBonusAllocationsMedium = calculatePercentageFromBonusAllocations(
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_medium,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage
    );

    const fromBonusAllocationsHigh = calculatePercentageFromBonusAllocations(
      debouncedData.years_to_retirement,
      debouncedData.real_pre_net_retirement_growth_high,
      debouncedData.annual_bonus,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.real_growth_in_income,
      debouncedData.real_growth_in_bonus,
      debouncedData.current_monthly_contribution,
      debouncedData.post_retirement_bonus_percentage
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      real_target_income_at_retirement: realTargetIncome,
      nominal_target_income_at_retirement: nominalTargetIncome,
      required_multiple_low: calculateRequiredMultiple(
        debouncedData.real_post_net_retirement_growth_low,
        debouncedData.retirement_longevity
      ),
      required_multiple_medium: calculateRequiredMultiple(
        debouncedData.real_post_net_retirement_growth_medium,
        debouncedData.retirement_longevity
      ),
      required_multiple_high: calculateRequiredMultiple(
        debouncedData.real_post_net_retirement_growth_high,
        debouncedData.retirement_longevity
      ),
      real_required_value_low: calculateRequiredValue(debouncedData.required_multiple_low, realTargetIncome),
      real_required_value_medium: calculateRequiredValue(debouncedData.required_multiple_medium, realTargetIncome),
      real_required_value_high: calculateRequiredValue(debouncedData.required_multiple_high, realTargetIncome),
      nominal_required_value_low: calculateRequiredValue(debouncedData.required_multiple_low, nominalTargetIncome),
      nominal_required_value_medium: calculateRequiredValue(
        debouncedData.required_multiple_medium,
        nominalTargetIncome
      ),
      nominal_required_value_high: calculateRequiredValue(debouncedData.required_multiple_high, nominalTargetIncome),

      real_projected_capital_low: realProjectedCapitalLow,
      real_projected_capital_medium: realProjectedCapitalMedium,
      real_projected_capital_high: realProjectedCapitalHigh,
      nominal_projected_capital_low: nominalProjectedCapitalLow,
      nominal_projected_capital_medium: nominalProjectedCapitalMedium,
      nominal_projected_capital_high: nominalProjectedCapitalHigh,
      from_initial_capital_low: fromInitialCapitalLow,
      from_initial_capital_medium: fromInitialCapitalMedium,
      from_initial_capital_high: fromInitialCapitalHigh,
      from_contributions_low: fromContributionsLow,
      from_contributions_medium: fromContributionsMedium,
      from_contributions_high: fromContributionsHigh,
      from_bonus_allocations_low: fromBonusAllocationsLow,
      from_bonus_allocations_medium: fromBonusAllocationsMedium,
      from_bonus_allocations_high: fromBonusAllocationsHigh,
    }));
  }, [
    debouncedData.annual_pre_tax_income,
    debouncedData.real_growth_in_income,
    debouncedData.post_retirement_income_percentage,
    debouncedData.real_post_retirement_capital_growth_low,
    debouncedData.real_post_retirement_capital_growth_medium,
    debouncedData.real_post_retirement_capital_growth_high,
    debouncedData.post_retirement_costs,
    debouncedData.retirement_longevity,
    debouncedData.required_multiple_low,
    debouncedData.required_multiple_medium,
    debouncedData.required_multiple_high,
    debouncedData.current_monthly_contribution,
    debouncedData.annual_bonus,
    debouncedData.current_accumulated_retirement_fund_value,
    debouncedData.real_pre_net_retirement_growth_low,
    debouncedData.real_pre_net_retirement_growth_medium,
    debouncedData.real_pre_net_retirement_growth_high,
    debouncedData.years_to_retirement,
    debouncedData.post_retirement_bonus_percentage,
  ]);

  useEffect(() => {
    calculateAndSetValues();
  }, [calculateAndSetValues]);

  useEffect(() => {
    setTimeout(() => {
      genericInputsCalculations();
    }, 300);
  }, [genericInputsCalculations]);

  useEffect(() => {
    setTimeout(() => {
      growthPreRetirementCalculations();
      growthPostRetirementCalculations();
    }, 300);
  }, [growthPreRetirementCalculations, growthPostRetirementCalculations]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // @ts-ignore
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: BonusFormData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);
      const { series, labels } = convertChartSeries(data.data);
      // @ts-ignore
      setChartSeries(series);

      const monthlyContributions = Object.values(data.data.monthly_contributions_capital_at_works).map((value: any) =>
        parseFloat(value.replace(/,/g, ""))
      );
      const annualBonus = Object.values(data.data.annual_bonus_capital_at_works).map((value: any) =>
        parseFloat(value.replace(/,/g, ""))
      );

      const tooltipFormatter = {
        apexchartsaxis1: {
          // @ts-ignore
          formatter: (value: number, { seriesIndex, dataPointIndex, w }: FormatterParams) => {
            return formatCurrency(monthlyContributions[dataPointIndex]);
          },
        },
        apexchartsaxis2: {
          // @ts-ignore
          formatter: (value: number, { seriesIndex, dataPointIndex, w }: FormatterParams) => {
            return formatCurrency(annualBonus[dataPointIndex]);
          },
        },
      };

      setChartOptions((prevOptions: any) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
        tooltip: {
          y: [
            {
              seriesName: "Monthly contributions capital at work",
              formatter: tooltipFormatter.apexchartsaxis2.formatter,
            },
            {
              seriesName: "Annual bonus capital at work",
              formatter: tooltipFormatter.apexchartsaxis1.formatter,
            },
            {
              name: "Initial capital at work",
              formatter: (value: number) => {
                return formatCurrency(value);
              },
            },
            {
              name: "Cumulative contributions ex salary",
              formatter: (value: number) => {
                return formatCurrency(value);
              },
            },
            {
              name: "Cumulative contributions ex bonus",
              formatter: (value: number) => {
                return formatCurrency(value);
              },
            },
          ],
        },
      }));
      return data;
    } catch (error: any) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        // Miscellaneous
        employment_status_id: data.ufs.employment_status_id || "",
        currency_id: data.ufs.currency_id || "",
        tax_domicile_id: data.ufs.tax_domicile_id || "",
        primary_representation_id: data.ufs.primary_representation_id || "",
        primary_representation: data.ufs.primary_representation || "",
        // Generic inputs
        effective_date: data.ufs.effective_date,
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || "",
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || "",
        retirement_date: data.ufs.retirement_date || "",
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || 0,
        life_expectancy: data.ufs.life_expectancy || "",
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        retirement_longevity: data.ufs.retirement_longevity
          ? data.ufs.retirement_longevity
          : calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,
        // Growth & cost assumptions
        real_pre_retirement_capital_growth_low: data.ufs.real_pre_retirement_capital_growth_low || "",
        real_pre_retirement_capital_growth_medium: data.ufs.real_pre_retirement_capital_growth_medium || "",
        real_pre_retirement_capital_growth_high: data.ufs.real_pre_retirement_capital_growth_high || "",
        nominal_pre_retirement_capital_growth_low: data.ufs.nominal_pre_retirement_capital_growth_low || "",
        nominal_pre_retirement_capital_growth_medium: data.ufs.nominal_pre_retirement_capital_growth_medium || "",
        nominal_pre_retirement_capital_growth_high: data.ufs.nominal_pre_retirement_capital_growth_high || "",
        pre_retirement_costs: data.ufs.pre_retirement_costs || "",
        real_post_retirement_capital_growth_low: data.ufs.real_post_retirement_capital_growth_low || "",
        real_post_retirement_capital_growth_medium: data.ufs.real_post_retirement_capital_growth_medium || "",
        real_post_retirement_capital_growth_high: data.ufs.real_post_retirement_capital_growth_high || "",
        nominal_post_retirement_capital_growth_low: data.ufs.nominal_post_retirement_capital_growth_low || "",
        nominal_post_retirement_capital_growth_medium: data.ufs.nominal_post_retirement_capital_growth_medium || "",
        nominal_post_retirement_capital_growth_high: data.ufs.nominal_post_retirement_capital_growth_high || "",
        post_retirement_costs: data.ufs.post_retirement_costs || "",
        // Smart tool specific inputs
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || "",
        real_growth_in_income: data.ufs.real_growth_in_income || "",
        nominal_growth_in_income: data.ufs.nominal_growth_in_income || "",
        annual_bonus: data.ufs.annual_bonus || "",
        current_accumulated_retirement_fund_value: data.ufs.current_accumulated_retirement_fund_value || "",
        current_monthly_contribution: data.ufs.current_monthly_contribution || "",
        pre_retirement_income_percentage: data.ufs.pre_retirement_income_percentage || "",
        contribution_as_percentage_of_income: data.ufs.contribution_as_percentage_of_income || "",
        post_retirement_income_percentage: data.ufs.post_retirement_income_percentage || "",
        real_growth_in_bonus: data.ufs.real_growth_in_bonus || "",
        nominal_growth_in_bonus: data.ufs.nominal_growth_in_bonus || "",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    setFormData,
    handleSubmit,
    chartSeries,
    chartOptions,
    handleNominalChange,
    handleRealChange,
  };
};

export default useBonusImpactTool;
