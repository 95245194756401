import React, { ReactNode } from "react";

interface HeadingType {
  text: string | ReactNode;
  colSpan?: number;
  id: string;
  removable?: boolean;
  width?: string;
}

interface TableHeaderProps {
  outputTable?: boolean;
  headings: HeadingType[];
  headingTwo?: HeadingType[];
  removeColumn?: (columnId: string) => void;
}

export const TableHeader: React.FC<TableHeaderProps> = ({ outputTable, headings, headingTwo, removeColumn }) => (
  <thead>
    <tr className={`${outputTable ? "bg-[#f2b443]" : ""}`}>
      {headings.map((heading, index) => (
        <th
          key={heading.id || index}
          className={`${outputTable ? "" : "bg-[#f1f1f1] "} text-[12px] font-bold p-2 border border-[#999] relative min-w-[200px] ${heading.width || ""}`}
          colSpan={heading.colSpan}
        >
          <div className="flex items-center justify-center gap-2">
            {heading.text}
            {heading.removable && removeColumn && (
              <button
                onClick={() => removeColumn(heading.id)}
                className="text-red-500 hover:text-red-700 text-xs"
                type="button"
              >
                X
              </button>
            )}
          </div>
        </th>
      ))}
    </tr>
    {headingTwo && (
      <tr className={`${outputTable ? "bg-[#f2b443]" : ""}`}>
        {headingTwo.map((heading, index) => (
          <th
            key={heading.id || index}
            className={`${outputTable ? "" : "bg-[#f1f1f1] "} text-[12px] font-bold p-2 border border-[#999] ${heading.width || ""}`}
            colSpan={heading.colSpan}
          >
            {heading.text}
          </th>
        ))}
      </tr>
    )}
  </thead>
);
