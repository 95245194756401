import image from "@assets/bg-image.jpg";
import AdditionalSettings from "@components/User/AdditionalSettings";
import FinancialSettings from "@components/User/FinancialSettings";
import GeneralInformation from "@components/User/GeneralInformation";
import PasswordReset from "@components/User/PasswordReset";
import PersonalInformation from "@components/User/PersonalInformation";
import PageTitle from "@elements/PageTitle";
import DashboardLayout from "@layouts/DashboardLayout";
import React, { useState } from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";

interface Tab {
  id: string;
  label: string;
  component: React.ComponentType<{ isActive: boolean; includeIdNumber?: boolean }>;
}

const MyProfile: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("personalInformation");

  const tabs: Tab[] = [
    { id: "personalInformation", label: "Personal Information", component: PersonalInformation },
    { id: "generalInformation", label: "General Information", component: GeneralInformation },
    { id: "financialSettings", label: "Financial Settings", component: FinancialSettings },
    { id: "additionalSettings", label: "Additional Settings", component: AdditionalSettings },
    { id: "passwordInformation", label: "Password Information", component: PasswordReset },
  ];

  const handleTabClick = (tabId: string): void => {
    setActiveTab(tabId);
  };

  return (
    <DashboardLayout
      pageTitle="My Profile"
      showBackButton={true}
    >
      <PageTitle title="My Profile" />
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-cover bg-center bg-no-repeat w-full h-full relative"
      >
        <div className="sm:hidden">
          <select
            id="tabs"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-[12px] block w-full p-2.5"
            onChange={(e) => handleTabClick(e.target.value)}
            value={activeTab}
          >
            {tabs.map((tab) => (
              <option
                key={tab.id}
                value={tab.id}
              >
                {tab.label}
              </option>
            ))}
          </select>
        </div>
        <ul className="hidden text-md text-center shadow sm:flex fixed z-auto w-[calc(100%-2rem)] ml-4">
          {tabs.map((tab) => (
            <li
              key={tab.id}
              className="w-full"
              onClick={() => handleTabClick(tab.id)}
            >
              <span
                className={`${
                  activeTab === tab.id ? "bg-gray-200 font-bold" : "bg-white text-gray-500"
                } hover:bg-gray-200 hover:text-gray-700 inline-block w-full p-4 cursor-pointer transition-all duration-200`}
              >
                {tab.label}
              </span>
            </li>
          ))}
        </ul>
        <div className="w-full pt-[55px] pb-4 h-full min-h-[calc(100vh-70px)]">
          <div className="grid grid-cols-1 md:grid-cols-5 px-4 pt-6 pb-1 gap-2 h-full max-h-[calc(100vh-141px)] overflow-y-auto scrollbar">
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={`p-4 bg-white border rounded-lg shadow-sm transition-all duration-200 relative ${
                  activeTab === tab.id ? "border-[#243665] ring-1 ring-[#243665] z-10" : "border-gray-200 opacity-50"
                } 
                  ${activeTab === "personalInformation" && activeTab === tab.id && "w-[calc(150%+5px)]"}
                  ${activeTab === "generalInformation" && activeTab === tab.id && "w-[calc(150%+5px)] left-[calc(-25%-5px)]"}
                  ${activeTab === "financialSettings" && activeTab === tab.id && "w-[calc(150%+5px)] left-[calc(-25%-5px)]"}
                  ${activeTab === "additionalSettings" && activeTab === tab.id && "w-[calc(150%+5px)] left-[calc(-25%-5px)]"}
                  ${activeTab === "passwordInformation" && activeTab === tab.id && "w-[calc(150%+5px)] left-[calc(-50%-5px)]"}
                 `}
                onClick={() => handleTabClick(tab.id)}
              >
                <div className={`${activeTab !== tab.id ? "pointer-events-none" : ""} h-full`}>
                  <tab.component
                    isActive={activeTab === tab.id}
                    {...(tab.id === "generalInformation" ? { includeIdNumber: false } : {})}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MyProfile;
