import React from "react";
import { Link } from "react-router-dom";

interface BlogLink {
  name: string;
  url: string;
}

interface BlogCategory {
  title: string;
  links: BlogLink[];
  color: string;
}

const blogCategories: BlogCategory[] = [
  {
    title: "Latest research",
    links: [
      { name: "The Motley Fool", url: "/commentary" },
      { name: "Neuberger Berman", url: "/commentary" },
      { name: "The Savvy newsletter", url: "/commentary" },
      { name: "Citywire", url: "/commentary" },
      { name: "CFA Institute", url: "/commentary" },
      { name: "Myth-busters", url: "/commentary" },
    ],
    color: "bg-yellow-400",
  },
  {
    title: "Latest news",
    links: [
      { name: "Business Tech", url: "/commentary" },
      { name: "Financial Mail", url: "/commentary" },
      { name: "Biznews", url: "/commentary" },
      { name: "Business Day", url: "/commentary" },
      { name: "Business Maverick", url: "/commentary" },
    ],
    color: "bg-gray-400",
  },
  {
    title: "Latest insights",
    links: [
      { name: "Healthy living", url: "/commentary" },
      { name: "Prof Galloway", url: "/commentary" },
      { name: "Brain Food", url: "/commentary" },
      { name: "James Clear", url: "/commentary" },
      { name: "Clem Sunter", url: "/commentary" },
    ],
    color: "bg-orange-400",
  },
];

const CuratedBlogs: React.FC = () => {
  return (
    <div className="max-h-[calc(100vh-70px)] h-full scrollbar overflow-y-auto overflow-x-hidden py-4 px-4">
      {blogCategories.map((category, index) => (
        <div
          key={index}
          className="mb-8"
        >
          <h2 className="text-2xl font-bold mb-4">{category.title}</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {category.links.map((link, linkIndex) => (
              <Link
                key={linkIndex}
                to={link.url}
                className={`${category.color} text-black font-semibold p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex items-center justify-center text-center`}
              >
                {link.name}
              </Link>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CuratedBlogs;
