import React from "react";

interface RadioFullProps {
  id: string;
  name: string;
  slug: string;
  classTarget: string;
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  centerToggle?: boolean;
}

const RadioFull: React.FC<RadioFullProps> = ({
  id,
  name,
  slug,
  classTarget,
  handleRadioChange,
  checked,
  centerToggle,
}) => {
  return (
    <div className={`${centerToggle ? "justify-center" : "justify-between"} flex items-center w-full`}>
      <div className="text-[14px] font-semibold text-gray-900">{name}</div>
      <label
        className="relative inline-flex items-center cursor-pointer"
        htmlFor={id}
      >
        <input
          type="radio"
          name="chart"
          className={`sr-only peer ${classTarget}`}
          id={id}
          value={slug}
          checked={checked}
          onChange={handleRadioChange}
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#243665]"></div>
      </label>
    </div>
  );
};

export default RadioFull;
