import InputTooltip from "@/components/Element/InputTooltip";
import { cn } from "@utils/cn";
import { cva } from "class-variance-authority";
import React, { ChangeEvent } from "react";
import { FaInfoCircle } from "react-icons/fa";

interface SelectProps {
  label?: string;
  defaultItem?: string | null;
  id?: string;
  name?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  items: { id: string | number; name: string }[];
  required?: boolean;
  placeholder?: string;
  twMergeClassName?: string | null;
  variant: "white" | "red" | "green";
  disabled?: boolean;
  inLine?: boolean;
  alignLeft?: boolean;
  alignText?: "left" | "right";
  [key: string]: any;
  tooltipText?: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  defaultItem = null,
  id,
  name,
  value,
  onChange,
  items,
  required,
  placeholder,
  twMergeClassName = null,
  variant,
  disabled = false,
  inLine = true,
  alignLeft = false,
  alignText = "left",
  tooltipText,
  ...props
}) => {
  const inputVariants = cva(
    `${alignLeft ? "text-left" : "text-center"} ${twMergeClassName ?? ""} focus:ring-primary-500 focus:border-primary-500 p-1 h-[30px] rounded-md sm:text-[12px] block w-full sm:min-w-[70px] font-[600] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none cursor-text`,
    {
      variants: {
        variant: {
          white: `bg-gray-50 border border-gray-300 text-gray-900 px-4 ${disabled ? "cursor-not-allowed" : ""}`,
          red: `border border-gray-300 text-white icon-white px-4 ${disabled ? "bg-[#85322f] cursor-not-allowed" : "bg-[#a96f6d]"}`,
          green: `border border-gray-300 text-white icon-white px-4 ${disabled ? "bg-[#008308] cursor-not-allowed" : "bg-[#4ca852]"}`,
        },
      },
    }
  );

  return (
    <div className={`${inLine ? "flex" : ""} items-center relative`}>
      {label && (
        <label
          htmlFor={id}
          className={`${inLine && !tooltipText ? "block" : tooltipText && alignText === "left" ? "flex items-center gap-2" : tooltipText ? "gap-2" : "flex mb-2"} ${alignText === "right" ? "text-right" : "text-left"} mr-2 text-[12px] font-bold text-gray-900 w-full leading-[15px]`}
        >
          {tooltipText && (
            <InputTooltip
              tooltipText={tooltipText}
              alignText={alignText}
            >
              <FaInfoCircle />
            </InputTooltip>
          )}
          {label} {required ? " *" : ""}
        </label>
      )}
      <select
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        className={cn(inputVariants({ variant }), "cursor-pointer indent-[15px]")}
        {...props}
      >
        {defaultItem === null ? (
          <></>
        ) : (
          <option
            value=""
            className={`${alignLeft ? "text-left" : "text-center"} bg-white text-black p-0`}
          >
            {defaultItem}
          </option>
        )}
        {items?.map((item) => (
          <option
            key={item.id}
            value={item.id}
            className={`${alignLeft ? "text-left" : "text-center"} bg-white text-black p-0`}
          >
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
