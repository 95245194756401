import Card from "@/components/Element/Card";
import ChartLoading from "@/components/Element/ChartLoading";
import RadioFull from "@/components/Form/RadioFull";
import React, { ChangeEvent, useState } from "react";
import Chart from "react-apexcharts";

const selectChart = [
  {
    id: 1,
    name: "Monthly payments",
    slug: "monthly-payments",
    chartName: "Monthly payments",
  },
  {
    id: 2,
    name: "Cumulative payments (incl. loans outstanding)",
    slug: "cumulative-payments",
    chartName: "Cumulative aggregate repayments",
  },
];

interface LoanTabTwoProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  formData: any;
  loading: boolean;
  chartSeries: any;
  chartOptions: any;
}

const LoanTabTwo: React.FC<LoanTabTwoProps> = ({
  handleSubmit,
  preventEnterPress,
  formData,
  loading,
  chartSeries,
  chartOptions,
}) => {
  const [selectedChart, setSelectedChart] = useState<string>("");
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedChart(event.target.value);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
            <Card
              variant="white"
              className="mb-4"
            >
              <form onSubmit={handleSubmit}>
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-[14px] text-left pb-4 w-[50%]">Charts available</th>
                      <th className="text-[14px] pb-4">Individual</th>
                      <th className="text-[14px] pb-4">Aggregate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectChart.map((chart, index) => (
                      <tr key={`selectChart-${index}`}>
                        <td
                          className={`text-[12px] text-left ${index === selectChart.length - 1 ? "font-bold" : "font-semibold"}`}
                        >
                          {chart.name}
                        </td>
                        <td>
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={selectedChart === chart.slug}
                            centerToggle
                          />
                        </td>
                        <td className="text-[12px] font-semibold">
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={false}
                            centerToggle
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>

              <p className="mt-4 text-[12px] font-bold">
                The selected charts show representations of your projected monthly repayments on loans (both monthly &
                cumulatively) and outstanding loans
              </p>
            </Card>

            <Card
              variant="white"
              className="mb-2"
            >
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="bg-[#f2b443]">
                    <th
                      rowSpan={2}
                      className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]"
                    >
                      Description
                    </th>
                    <th
                      rowSpan={2}
                      className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]"
                    >
                      Outstanding loan
                    </th>
                    <th
                      colSpan={3}
                      className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[50%]"
                    >
                      Projected cumulative payments
                    </th>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">Nominal</th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">Real</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Loan #</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">Total loan details</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
          <div className="mb-1 h-[calc(100vh-240px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {selectChart.find((chart) => chart.slug === selectedChart)?.chartName}
                {formData.primary_representation && " - " + formData.primary_representation}
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-full relative">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoanTabTwo;
