import { useState, useEffect, useCallback } from "react";
import createAxiosInstance from "@api/Api";
import calculations from "@services/algorithms/calculations";
import helpers from "@utils/helpers";
import numbers from "@services/algorithms/numbers";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import useDebounce from "@hooks/useDebounce";
import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";

interface FormData {
  growth_rate: string;
  primary_representation: string;
  effective_date: string;
  projected_inflation_rate: number;
  date_of_birth: string;
  retirement_date: string;
  retirement_age: number;
  current_age: number;
  years_to_retirement: number;
  life_expectancy: number;
  retirement_longevity_custom: boolean;
  retirement_longevity: number;
  real_pre_retirement_capital_growth_low: number;
  real_pre_retirement_capital_growth_medium: number;
  real_pre_retirement_capital_growth_high: number;
  nominal_pre_retirement_capital_growth_low: number;
  nominal_pre_retirement_capital_growth_medium: number;
  nominal_pre_retirement_capital_growth_high: number;
  pre_retirement_costs: number;
  real_pre_net_retirement_growth_low: number;
  real_pre_net_retirement_growth_medium: number;
  real_pre_net_retirement_growth_high: number;
  nominal_pre_net_retirement_growth_low: number;
  nominal_pre_net_retirement_growth_medium: number;
  nominal_pre_net_retirement_growth_high: number;
  annual_pre_tax_income: number;
  real_growth_in_income: number;
  nominal_growth_in_income: number;
  current_accumulated_retirement_fund_value: number;
  current_monthly_contribution: number;
  post_retirement_income_percentage: number;
  contribution_as_percentage_of_income: number;
  target_income: number;
  projected_multiple_low: number;
  projected_multiple_medium: number;
  projected_multiple_high: number;
  real_projected_value_low?: number;
  real_projected_value_medium?: number;
  real_projected_value_high?: number;
  nominal_projected_value_low?: number;
  nominal_projected_value_medium?: number;
  nominal_projected_value_high?: number;
}

const useMoneyGrowthTool = () => {
  const api = createAxiosInstance("standard/retirement/money-growth");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const { chartOptionsDefault } = helpers();
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementLongevity } = numbers();
  const { calculateRetirementFundValue, calculateMultiple, calculateContributionOfIncome, calculateTargetIncome } =
    calculations();

  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState<FormData>({
    growth_rate: "Low",
    primary_representation: "",
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_date: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity_custom: false,
    retirement_longevity: 0,
    real_pre_retirement_capital_growth_low: 0,
    real_pre_retirement_capital_growth_medium: 0,
    real_pre_retirement_capital_growth_high: 0,
    nominal_pre_retirement_capital_growth_low: 0,
    nominal_pre_retirement_capital_growth_medium: 0,
    nominal_pre_retirement_capital_growth_high: 0,
    pre_retirement_costs: 0,
    real_pre_net_retirement_growth_low: 0,
    real_pre_net_retirement_growth_medium: 0,
    real_pre_net_retirement_growth_high: 0,
    nominal_pre_net_retirement_growth_low: 0,
    nominal_pre_net_retirement_growth_medium: 0,
    nominal_pre_net_retirement_growth_high: 0,
    annual_pre_tax_income: 0,
    real_growth_in_income: 0,
    nominal_growth_in_income: 0,
    current_accumulated_retirement_fund_value: 0,
    current_monthly_contribution: 0,
    post_retirement_income_percentage: 0,
    contribution_as_percentage_of_income: 0,
    target_income: 0,
    projected_multiple_low: 0,
    projected_multiple_medium: 0,
    projected_multiple_high: 0,
  });

  const debouncedData = useDebounce(formData, 100);

  const { handleNominalChange, handleRealChange, genericInputsCalculations, growthPreRetirementCalculations } =
    growthRateHandler(debouncedData, setFormData);

  const calculateAndSetValues = useCallback(() => {
    const realTargetIncome = calculateTargetIncome(
      debouncedData.annual_pre_tax_income,
      debouncedData.real_growth_in_income,
      debouncedData.years_to_retirement,
      debouncedData.post_retirement_income_percentage
    );
    const nominalTargetIncome = calculateTargetIncome(
      debouncedData.annual_pre_tax_income,
      debouncedData.nominal_growth_in_income,
      debouncedData.years_to_retirement,
      debouncedData.post_retirement_income_percentage
    );
    const targetIncome = debouncedData.primary_representation === "real" ? realTargetIncome : nominalTargetIncome;

    const realProjectedValueLow = calculateRetirementFundValue(
      debouncedData.real_pre_net_retirement_growth_low,
      debouncedData.real_growth_in_income,
      debouncedData.current_monthly_contribution,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.years_to_retirement
    );
    const realProjectedValueMedium = calculateRetirementFundValue(
      debouncedData.real_pre_net_retirement_growth_medium,
      debouncedData.real_growth_in_income,
      debouncedData.current_monthly_contribution,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.years_to_retirement
    );
    const realProjectedValueHigh = calculateRetirementFundValue(
      debouncedData.real_pre_net_retirement_growth_high,
      debouncedData.real_growth_in_income,
      debouncedData.current_monthly_contribution,
      debouncedData.current_accumulated_retirement_fund_value,
      debouncedData.years_to_retirement
    );

    const projectedMultipleLow = calculateMultiple(realProjectedValueLow, realTargetIncome);
    const projectedMultipleMedium = calculateMultiple(realProjectedValueMedium, realTargetIncome);
    const projectedMultipleHigh = calculateMultiple(realProjectedValueHigh, realTargetIncome);

    const nominalProjectedValueLow = projectedMultipleLow * nominalTargetIncome;
    const nominalProjectedValueMedium = projectedMultipleMedium * nominalTargetIncome;
    const nominalProjectedValueHigh = projectedMultipleHigh * nominalTargetIncome;

    const contributionOfIncome = calculateContributionOfIncome(
      debouncedData.current_monthly_contribution,
      debouncedData.annual_pre_tax_income
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      target_income: targetIncome,
      projected_multiple_low: projectedMultipleLow,
      projected_multiple_medium: projectedMultipleMedium,
      projected_multiple_high: projectedMultipleHigh,
      real_projected_value_low: realProjectedValueLow,
      real_projected_value_medium: realProjectedValueMedium,
      real_projected_value_high: realProjectedValueHigh,
      nominal_projected_value_low: nominalProjectedValueLow,
      nominal_projected_value_medium: nominalProjectedValueMedium,
      nominal_projected_value_high: nominalProjectedValueHigh,
      contribution_as_percentage_of_income: contributionOfIncome,
    }));
  }, [
    debouncedData.annual_pre_tax_income,
    debouncedData.real_growth_in_income,
    debouncedData.nominal_growth_in_income,
    debouncedData.years_to_retirement,
    debouncedData.current_monthly_contribution,
    debouncedData.post_retirement_income_percentage,
    debouncedData.primary_representation,
    debouncedData.projected_multiple_low,
    debouncedData.projected_multiple_medium,
    debouncedData.projected_multiple_high,
    debouncedData.real_pre_net_retirement_growth_low,
    debouncedData.real_pre_net_retirement_growth_medium,
    debouncedData.real_pre_net_retirement_growth_high,
    debouncedData.current_accumulated_retirement_fund_value,
    debouncedData.effective_date,
    debouncedData.retirement_date,
  ]);

  useEffect(() => {
    setTimeout(() => {
      calculateAndSetValues();
    }, 100);
  }, [calculateAndSetValues]);
  useEffect(() => {
    setTimeout(() => {
      genericInputsCalculations();
    }, 100);
  }, [genericInputsCalculations]);
  useEffect(() => {
    setTimeout(() => {
      growthPreRetirementCalculations();
    }, 100);
  }, [growthPreRetirementCalculations]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: any) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      const { series, labels } = convertChartSeries(data.data);

      setChartSeries(series);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
      }));
      return data;
    } catch (error) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData: any) => {
    const years = Object.values(chartData.years);

    const annualContributions = Object.values(chartData.annual_contributions).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const cumulativeCapital = Object.values(chartData.cumulative_capital).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const series = [
      {
        name: "Cumulative Contribution",
        type: "column",
        color: "#ed7d31",
        data: annualContributions,
      },
      {
        name: "Cumulative Capital",
        type: "line",
        color: "#a5a5a5",
        data: cumulativeCapital,
      },
    ];

    const labels = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Year " + year;
    });

    return { series, labels };
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        primary_representation: data.ufs.primary_representation || "",
        growth_rate: data.ufs.growth_rate || "",
        effective_date: data.ufs.effective_date || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || 0,
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || 0,
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || 0,
        retirement_date: data.ufs.retirement_date || "",
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || 0,
        life_expectancy: data.ufs.life_expectancy || 0,
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        retirement_longevity: data.ufs.retirement_longevity
          ? data.ufs.retirement_longevity
          : calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,
        pre_retirement_costs: data.ufs.pre_retirement_costs || 0,
        real_pre_retirement_capital_growth_low: data.ufs.real_pre_retirement_capital_growth_low || 0,
        real_pre_retirement_capital_growth_medium: data.ufs.real_pre_retirement_capital_growth_medium || 0,
        real_pre_retirement_capital_growth_high: data.ufs.real_pre_retirement_capital_growth_high || 0,
        nominal_pre_retirement_capital_growth_low: data.ufs.nominal_pre_retirement_capital_growth_low || 0,
        nominal_pre_retirement_capital_growth_medium: data.ufs.nominal_pre_retirement_capital_growth_medium || 0,
        nominal_pre_retirement_capital_growth_high: data.ufs.nominal_pre_retirement_capital_growth_high || 0,
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || 0,
        real_growth_in_income: data.ufs.real_growth_in_income || 0,
        nominal_growth_in_income: data.ufs.nominal_growth_in_income || 0,
        post_retirement_income_percentage: data.ufs.post_retirement_income_percentage || 0,
        current_accumulated_retirement_fund_value: data.ufs.current_accumulated_retirement_fund_value || 0,
        current_monthly_contribution: data.ufs.current_monthly_contribution || 0,
        contribution_as_percentage_of_income: data.ufs.contribution_as_percentage_of_income || 0,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
  }, []);

  return {
    loading,
    error,
    formData,
    setFormData,
    handleSubmit,
    chartSeries,
    chartOptions,
    setChartSeries,
    handleNominalChange,
    handleRealChange,
  };
};

export default useMoneyGrowthTool;
