import image from "@/assets/bg-image.jpg";
import Navbar from "@/components/Layout/Navbar";
import { useState } from "react";

const CommentaryLayout = ({ children, pageTitle, showBackButton, backButtonNavigateTo }) => {
  return (
    <div>
      <Navbar
        pageTitle={pageTitle}
        showBackButton={showBackButton}
        backButtonNavigateTo={backButtonNavigateTo}
        headerBgColor={"#f2e1c3"}
      />
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-cover bg-center bg-no-repeat w-full h-screen pt-[70px] relative position-fixed"
      >
        {children}
      </div>
    </div>
  );
};

export default CommentaryLayout;
