import Card from "@/components/Element/Card";
import SelectToggle from "@/components/Form/ToggleSwitch";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PreRetirement from "@/components/GrowthCostAssumptions/PreRetirement";
import useTaxBenefitsTool from "@api/Standard/WealthCreation/useTaxBenefitsTool";
import ChartLoading from "@components/Element/ChartLoading";
import { CommaSeparated } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Currency from "@form/Currency";
import CurrencySwitch from "@form/CurrencySwitch";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import Change from "@utils/change";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";

const TaxBenefits = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();
  const taxBenefitsTool = useTaxBenefitsTool();
  const { preventEnterPress } = helpers();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    setFormData,
    handleSubmit,
    handleNominalChange,
    handleRealChange,
  } = taxBenefitsTool;

  const { handleChange, handleCurrencyChange, handleDecimalChange, handlePrimaryRepresentationChange } = Change(
    formData,
    setFormData
  );

  const [showHelpPopup, _] = useState();

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              Background
              <ul className="pl-4">
                <li className="my-1">
                  a) Retirement contributions are tax deductible - maximum of 27.5% of taxable income up to a maximum of
                  R350k p.a. (excess contributions can be rolled forward to following tax year)
                </li>
                <li className="my-1">
                  b) Returns on retirement fund returns (including interest, income, dividends & capital gains) are
                  tax-free within the fund.
                </li>
                <li className="my-1">
                  c){" "}
                  <i>
                    We note that up to a third of an RA can be taken as a lump sum (or all if it is worth less than
                    R247,500). First R500k is untaxed, next R200k is taxed at 18%, next R350k at 27%, thereafter at 36%;
                    however, the balance must be converted to an annuity. We ignore this scenario for the purposes of
                    this representation.
                  </i>
                </li>
                <li className="my-1">
                  d) In this tool, we are comparing a WITHDRAWAL scenario to an after-tax investment scenario.
                </li>
                <li className="my-1">
                  e) Retirement withdrawals are taxed at a lower rate than pre-retirement income (lower scales); however
                  Capital Gains Tax (CGT) is a maximum of 20% (inclusion rate of 40%, max marginal rate of 45%)
                </li>
                <li className="my-1">
                  f) All numbers are subject to annual review by the Minister of Finance but are likely to be adjusted
                  for inflation
                </li>
                <li className="my-1">
                  g) Hence, we need to allow for 3 tax rates
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Normal income tax - this allows us to identify what the tax rebate is for retirement fund
                      contributions to calculate what the value of the equivalent investment contribution is
                    </li>
                    <li className="my-1">
                      ii) Retirement fund withdrawal rates - this allows us to identify the after-tax capital value of
                      the retirement fund
                    </li>
                    <li className="my-1">
                      iii) Applicable CGT - we need to calculate the investors equivalent marginal tax rate to calculate
                      the CGT payable on the investment.
                    </li>
                  </ul>
                </li>
                <li className="my-1">
                  h) Note that this comparison is a forward projection; hence it ignores the tax status of prior
                  contributions.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Inputs are as follows:
              <ul className="pl-4">
                <li className="my-1">
                  a) Generic: projected average inflation rate, effective date, age, Retirement age, years to
                  retirement, <strong>projected tax tables (both retirement and capital gains tax)</strong>
                </li>
                <li className="my-1">
                  b) Generic: pre-tax contributions, pre-and post-retirement growth rates and associated costs
                </li>
                <li className="my-1">
                  c) Additional: annual income (determines marginal tax racket), annual real growth in income,
                  contributions, annual real growth in contributions (standard assumption is that contributions increase
                  in line with income)
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output is represented by a table that shows real and nominal values of
              <ul className="pl-4">
                <li className="my-1">
                  a) Post-tax withdrawal cumulative value for retirement contributions at retirement
                </li>
                <li className="my-1">
                  b) Post-tax withdrawal cumulative value for investment contributions at retirement
                </li>
                <li className="my-1">c) Difference</li>
                <li className="my-1">d) 3 different net pre-retirement growth rates</li>
                <li className="my-1">e) Chart is a choice of 2 representations (real and nominal) of output data.</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Tax benefits of investing in a retirement fund vs other?"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Tax Benefits" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-7 gap-4 w-full p-4">
          <div className="col-span-7">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  loading={loading}
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible("GrowthTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="w-[34%]"></th>
                      <th className="text-[12px] w-[22%]">Low</th>
                      <th className="text-[12px] w-[22%]">Medium</th>
                      <th className="text-[12px] w-[22%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PreRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Smart tool specific inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="SpecificInputs"
                      isVisible={isVisible("SpecificInputs")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>

                {!isVisible("SpecificInputs") && (
                  <div className="grid grid-cols-2 gap-2 items-center mt-4">
                    <div>
                      <Currency
                        label="Annual pre-tax income"
                        name="annual_pre_tax_income"
                        id="annual_pre_tax_income"
                        value={formData.annual_pre_tax_income}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>

                    <div>
                      <Percentage
                        label={capitalizeFirstLetter(formData.primary_representation) + " growth in income"}
                        name={`${formData.primary_representation == "real" ? "real_growth_in_income" : "nominal_growth_in_income"}`}
                        id={`${formData.primary_representation == "real" ? "real_growth_in_income" : "nominal_growth_in_income"}`}
                        value={`${formData.primary_representation == "real" ? formData.real_growth_in_income : formData.nominal_growth_in_income}`}
                        onChange={handleCurrencyChange}
                        variant="green"
                        alignText="right"
                        required={false}
                      />
                    </div>

                    <div>
                      <Input
                        label="Current tax year"
                        name="current_tax_year"
                        id="current_tax_year"
                        value={formData.current_tax_year}
                        onChange={handleChange}
                        variant="red"
                        disabled={true}
                      />
                    </div>

                    <div>
                      <Percentage
                        label="Marginal tax rate"
                        name="marginal_tax_rate"
                        id="marginal_tax_rate"
                        value={formData.marginal_tax_rate}
                        onChange={handleCurrencyChange}
                        variant="red"
                        disabled={true}
                        alignText="right"
                      />
                    </div>

                    <div>
                      <Currency
                        label="Current monthly contribution"
                        name="current_monthly_contribution"
                        id="current_monthly_contribution"
                        value={formData.current_monthly_contribution}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>

                    <div>
                      <Currency
                        label="Equivalent after-tax contribution"
                        name="equivalent_after_tax_contribution"
                        id="equivalent_after_tax_contribution"
                        value={formData.equivalent_after_tax_contribution}
                        onChange={handleCurrencyChange}
                        variant="red"
                        disabled={true}
                        alignText="right"
                      />
                    </div>
                    <div>
                      <Input
                        label="Retirement date"
                        name="retirement_date"
                        id="retirement_date"
                        type="date"
                        value={formData.retirement_date}
                        onChange={handleChange}
                        variant="red"
                        disabled={true}
                      />
                    </div>
                    <div>
                      <Input
                        label="Retirement tax year"
                        name="retirement_tax_year"
                        id="retirement_tax_year"
                        value={formData.retirement_tax_year}
                        onChange={handleChange}
                        variant="red"
                        disabled={true}
                        alignText="right"
                      />
                    </div>

                    <div>
                      <CurrencySwitch
                        label="Maximum tax-free annual contribution"
                        primaryRepresentation={formData.primary_representation}
                        realField="real_max_taxfree_retirement_contribution"
                        nominalField="nominal_max_taxfree_retirement_contribution"
                        formData={formData}
                        onChange={handleCurrencyChange}
                        variant="red"
                        disabled={true}
                        required={false}
                      />
                    </div>

                    <div>
                      <Percentage
                        label="Maximum annual tax-free percentage of income"
                        name="max_taxfree_retirement_percentage"
                        id="max_taxfree_retirement_percentage"
                        value={formData.max_taxfree_retirement_percentage}
                        //@ts-ignore
                        onChange={handleChange}
                        variant="red"
                        disabled={true}
                        alignText="right"
                      />
                    </div>
                  </div>
                )}
              </Card>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                  <p className="text-[12px] font-semibold mt-2">
                    The table below shows after-tax difference in the value of your investment through a retirement fund
                    vs a standard investment fund paying capital gains tax (CGT). NB: South African law provides for a
                    Tax-free savings account to avoid CGT up to a current contribution of R36,000 p.a. per individual -
                    we highly recommend its use.
                  </p>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[40%]">
                        Comparison at retirement
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Net retirement fund capital (after withdrawal tax)
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.retirement_after_withdrawal_low}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.retirement_after_withdrawal_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[rgb(242,225,195)] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.retirement_after_withdrawal_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Net investment capital (after capital gains tax)
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.investment_after_cgt_low}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.investment_after_cgt_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.investment_after_cgt_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Difference (after-tax)
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.after_tax_difference_low}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.after_tax_difference_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.after_tax_difference_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4 mb-1 min-h-[calc(100vh-175px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {capitalizeFirstLetter(formData.primary_representation)} equivalent - retirement fund vs investment fund
                after tax
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="h-[calc(100vh-243px)] relative">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default TaxBenefits;
