export interface HeadingType {
  text: string;
  id: string;
  colSpan?: number;
}

export const vehicleTableHeadings = (): HeadingType[] => [
  { text: "Category", id: "category" },
  { text: "Description", id: "description" },
  { text: "Vehicle #1", id: "vehicle" },
];

export const getTotalColumns = () => vehicleTableHeadings().length;

// Keep this for backward compatibility
export const totalColumns = 3; // This should match the number of items in propertyTableHeadings
