import { useCallback, useEffect, useState } from "react";
import words from "@/services/algorithms/words";
import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";
import createAxiosInstance from "@api/Api";
import gapAnalysisCallbacks from "@services/algorithms/gapAnalysisCallbacks";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import numbers from "@services/algorithms/numbers";
import helpers from "@utils/helpers";
import useDebounce from "@hooks/useDebounce";

interface FormData {
  growth_rate: string;
  set: string;
  employment_status_id: string;
  currency_id: string;
  tax_domicile_id: string;
  primary_representation_id: string;
  primary_representation: string;
  effective_date: string;
  projected_inflation_rate: number;
  date_of_birth: string;
  retirement_date: string;
  retirement_age: number;
  current_age: number;
  years_to_retirement: number;
  life_expectancy: number;
  retirement_longevity_custom: boolean;
  retirement_longevity: number;
  real_pre_retirement_capital_growth_low: number;
  real_pre_retirement_capital_growth_medium: number;
  real_pre_retirement_capital_growth_high: number;
  nominal_pre_retirement_capital_growth_low: number;
  nominal_pre_retirement_capital_growth_medium: number;
  nominal_pre_retirement_capital_growth_high: number;
  pre_retirement_costs: number;
  pre_retirement_capital_growth_low: number;
  pre_retirement_capital_growth_medium: number;
  pre_retirement_capital_growth_high: number;
  real_post_retirement_capital_growth_low: number;
  real_post_retirement_capital_growth_medium: number;
  real_post_retirement_capital_growth_high: number;
  nominal_post_retirement_capital_growth_low: number;
  nominal_post_retirement_capital_growth_medium: number;
  nominal_post_retirement_capital_growth_high: number;
  post_retirement_costs: number;
  post_retirement_capital_growth_low: number;
  post_retirement_capital_growth_medium: number;
  post_retirement_capital_growth_high: number;
  real_pre_net_retirement_growth_low: number;
  real_pre_net_retirement_growth_medium: number;
  real_pre_net_retirement_growth_high: number;
  nominal_pre_net_retirement_growth_low: number;
  nominal_pre_net_retirement_growth_medium: number;
  nominal_pre_net_retirement_growth_high: number;
  real_post_net_retirement_growth_low: number;
  real_post_net_retirement_growth_medium: number;
  real_post_net_retirement_growth_high: number;
  nominal_post_net_retirement_growth_low: number;
  nominal_post_net_retirement_growth_medium: number;
  nominal_post_net_retirement_growth_high: number;
  annual_pre_tax_income: number;
  real_growth_in_income: number;
  nominal_growth_in_income: number;
  current_accumulated_retirement_fund_value: number;
  current_monthly_contribution: number;
  post_retirement_income_percentage: number;
  contribution_as_percentage_of_income: number;
  target_income: number;
  real_target_income: number;
  nominal_target_income: number;
  projected_value_at_retirement_low: number;
  projected_value_at_retirement_medium: number;
  projected_value_at_retirement_high: number;
  real_projected_value_low: number;
  real_projected_value_medium: number;
  real_projected_value_high: number;
  nominal_projected_value_low: number;
  nominal_projected_value_medium: number;
  nominal_projected_value_high: number;
  projected_multiple_low: number;
  projected_multiple_medium: number;
  projected_multiple_high: number;
  years_to_full_depletion_at_target_income_low: number;
  years_to_full_depletion_at_target_income_medium: number;
  years_to_full_depletion_at_target_income_high: number;
  required_multiple_low: number;
  required_multiple_medium: number;
  required_multiple_high: number;
  real_required_value_low: number;
  real_required_value_medium: number;
  real_required_value_high: number;
  nominal_required_value_low: number;
  nominal_required_value_medium: number;
  nominal_required_value_high: number;
  real_annual_withdrawal_low: number;
  real_annual_withdrawal_medium: number;
  real_annual_withdrawal_high: number;
  nominal_annual_withdrawal_low: number;
  nominal_annual_withdrawal_medium: number;
  nominal_annual_withdrawal_high: number;
  real_value_difference_low: number;
  real_value_difference_medium: number;
  real_value_difference_high: number;
  nominal_value_difference_low: number;
  nominal_value_difference_medium: number;
  nominal_value_difference_high: number;
  impact_on_living_standard_low: number;
  impact_on_living_standard_medium: number;
  impact_on_living_standard_high: number;
  years_deferred_low: number;
  years_deferred_medium: number;
  years_deferred_high: number;
  more_monthly_contribution_low: number;
  more_monthly_contribution_medium: number;
  more_monthly_contribution_high: number;
  capital_inject_low: number;
  capital_inject_medium: number;
  capital_inject_high: number;
  increase_monthly_contribution: number;
  inject_capital: number;
  defer_retirement_by: number;
  real_adjusted_target_income: number;
  nominal_adjusted_target_income: number;
  adjusted_retirement_longevity: number;
  real_adjusted_capital_at_retirement_low: number;
  real_adjusted_capital_at_retirement_medium: number;
  real_adjusted_capital_at_retirement_high: number;
  nominal_adjusted_capital_at_retirement_low: number;
  nominal_adjusted_capital_at_retirement_medium: number;
  nominal_adjusted_capital_at_retirement_high: number;
  real_adjusted_income_at_retirement_low: number;
  real_adjusted_income_at_retirement_medium: number;
  real_adjusted_income_at_retirement_high: number;
  nominal_adjusted_income_at_retirement_low: number;
  nominal_adjusted_income_at_retirement_medium: number;
  nominal_adjusted_income_at_retirement_high: number;
  real_change_in_sustainable_income_low: number;
  real_change_in_sustainable_income_medium: number;
  real_change_in_sustainable_income_high: number;
  nominal_change_in_sustainable_income_low: number;
  nominal_change_in_sustainable_income_medium: number;
  nominal_change_in_sustainable_income_high: number;
  years_to_depletion_low: number;
  years_to_depletion_medium: number;
  years_to_depletion_high: number;
  change_in_years_to_depletion_low: number;
  change_in_years_to_depletion_medium: number;
  change_in_years_to_depletion_high: number;
}

interface SeriesNames {
  adjustedSustainableIncomes: string;
  currentSustainableIncomes: string;
  adjustedResidualCapitals: string;
  currentResidualCapitals: string;
}

const useGapAnalysisTool = () => {
  const api = createAxiosInstance("standard/retirement/gap-analysis");
  const { capitalizeFirstLetter } = words();
  const { chartOptionsDefault } = helpers();
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementLongevity } = numbers();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);
  const [chartData, setChartData] = useState<any | null>(null);
  const [formData, setFormData] = useState<FormData>({
    // Initial state here
    growth_rate: "Low",
    set: "adjusted_sustainable_income",
    employment_status_id: "",
    currency_id: "",
    tax_domicile_id: "",
    primary_representation_id: "",
    primary_representation: "",
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_date: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity_custom: false,
    retirement_longevity: 0,
    real_pre_retirement_capital_growth_low: 0,
    real_pre_retirement_capital_growth_medium: 0,
    real_pre_retirement_capital_growth_high: 0,
    nominal_pre_retirement_capital_growth_low: 0,
    nominal_pre_retirement_capital_growth_medium: 0,
    nominal_pre_retirement_capital_growth_high: 0,
    pre_retirement_costs: 0,
    pre_retirement_capital_growth_low: 0,
    pre_retirement_capital_growth_medium: 0,
    pre_retirement_capital_growth_high: 0,
    real_post_retirement_capital_growth_low: 0,
    real_post_retirement_capital_growth_medium: 0,
    real_post_retirement_capital_growth_high: 0,
    nominal_post_retirement_capital_growth_low: 0,
    nominal_post_retirement_capital_growth_medium: 0,
    nominal_post_retirement_capital_growth_high: 0,
    post_retirement_costs: 0,
    post_retirement_capital_growth_low: 0,
    post_retirement_capital_growth_medium: 0,
    post_retirement_capital_growth_high: 0,
    real_pre_net_retirement_growth_low: 0,
    real_pre_net_retirement_growth_medium: 0,
    real_pre_net_retirement_growth_high: 0,
    nominal_pre_net_retirement_growth_low: 0,
    nominal_pre_net_retirement_growth_medium: 0,
    nominal_pre_net_retirement_growth_high: 0,
    real_post_net_retirement_growth_low: 0,
    real_post_net_retirement_growth_medium: 0,
    real_post_net_retirement_growth_high: 0,
    nominal_post_net_retirement_growth_low: 0,
    nominal_post_net_retirement_growth_medium: 0,
    nominal_post_net_retirement_growth_high: 0,
    annual_pre_tax_income: 0,
    real_growth_in_income: 0,
    nominal_growth_in_income: 0,
    current_accumulated_retirement_fund_value: 0,
    current_monthly_contribution: 0,
    post_retirement_income_percentage: 0,
    contribution_as_percentage_of_income: 0,
    target_income: 0,
    real_target_income: 0,
    nominal_target_income: 0,
    projected_value_at_retirement_low: 0,
    projected_value_at_retirement_medium: 0,
    projected_value_at_retirement_high: 0,
    real_projected_value_low: 0,
    real_projected_value_medium: 0,
    real_projected_value_high: 0,
    nominal_projected_value_low: 0,
    nominal_projected_value_medium: 0,
    nominal_projected_value_high: 0,
    projected_multiple_low: 0,
    projected_multiple_medium: 0,
    projected_multiple_high: 0,
    years_to_full_depletion_at_target_income_low: 0,
    years_to_full_depletion_at_target_income_medium: 0,
    years_to_full_depletion_at_target_income_high: 0,
    required_multiple_low: 0,
    required_multiple_medium: 0,
    required_multiple_high: 0,
    real_required_value_low: 0,
    real_required_value_medium: 0,
    real_required_value_high: 0,
    nominal_required_value_low: 0,
    nominal_required_value_medium: 0,
    nominal_required_value_high: 0,
    real_annual_withdrawal_low: 0,
    real_annual_withdrawal_medium: 0,
    real_annual_withdrawal_high: 0,
    nominal_annual_withdrawal_low: 0,
    nominal_annual_withdrawal_medium: 0,
    nominal_annual_withdrawal_high: 0,
    real_value_difference_low: 0,
    real_value_difference_medium: 0,
    real_value_difference_high: 0,
    nominal_value_difference_low: 0,
    nominal_value_difference_medium: 0,
    nominal_value_difference_high: 0,
    impact_on_living_standard_low: 0,
    impact_on_living_standard_medium: 0,
    impact_on_living_standard_high: 0,
    years_deferred_low: 0,
    years_deferred_medium: 0,
    years_deferred_high: 0,
    more_monthly_contribution_low: 0,
    more_monthly_contribution_medium: 0,
    more_monthly_contribution_high: 0,
    capital_inject_low: 0,
    capital_inject_medium: 0,
    capital_inject_high: 0,
    increase_monthly_contribution: 1500,
    inject_capital: 150000,
    defer_retirement_by: 4,
    real_adjusted_target_income: 0,
    nominal_adjusted_target_income: 0,
    adjusted_retirement_longevity: 0,
    real_adjusted_capital_at_retirement_low: 0,
    real_adjusted_capital_at_retirement_medium: 0,
    real_adjusted_capital_at_retirement_high: 0,
    nominal_adjusted_capital_at_retirement_low: 0,
    nominal_adjusted_capital_at_retirement_medium: 0,
    nominal_adjusted_capital_at_retirement_high: 0,
    real_adjusted_income_at_retirement_low: 0,
    real_adjusted_income_at_retirement_medium: 0,
    real_adjusted_income_at_retirement_high: 0,
    nominal_adjusted_income_at_retirement_low: 0,
    nominal_adjusted_income_at_retirement_medium: 0,
    nominal_adjusted_income_at_retirement_high: 0,
    real_change_in_sustainable_income_low: 0,
    real_change_in_sustainable_income_medium: 0,
    real_change_in_sustainable_income_high: 0,
    nominal_change_in_sustainable_income_low: 0,
    nominal_change_in_sustainable_income_medium: 0,
    nominal_change_in_sustainable_income_high: 0,
    years_to_depletion_low: 0,
    years_to_depletion_medium: 0,
    years_to_depletion_high: 0,
    change_in_years_to_depletion_low: 0,
    change_in_years_to_depletion_medium: 0,
    change_in_years_to_depletion_high: 0,
  });

  const debouncedData = useDebounce(formData, 100);

  const [seriesNames, setSeriesNames] = useState<SeriesNames>({
    adjustedSustainableIncomes: "",
    currentSustainableIncomes: "",
    adjustedResidualCapitals: "",
    currentResidualCapitals: "",
  });

  const {
    handleNominalChange,
    handleRealChange,
    genericInputsCalculations,
    growthPreRetirementCalculations,
    growthPostRetirementCalculations,
  } = growthRateHandler(debouncedData, setFormData);

  const {
    calculateRetirementIncomeValues,
    calculateProjectedValues,
    calculateAdditionalValues,
    calculateProjectedMultiples,
    calculateDepletionRates,
    calculateRequiredValues,
    calculateAnnualWithdrawals,
    calculateValueDifferences,
    calculateImpactOnLivingStandards,
    calculateRetirementDeferments,
    calculateAdditionalContributions,
    calculateCapitalInjections,
    calculateAdjustedTargetCapital,
    calculateAdjustedRetirementLongevity,
    calculateAdjustedRetirementValues,
    calculateAdjustedIncomes,
    calculateChangesInSustainableIncome,
    calculateYearsToDepletion,
    calculateChangesInYearsToDepletion,
  } = gapAnalysisCallbacks(debouncedData, setFormData);

  const generateSeriesNames = useCallback(() => {
    const adjustedSustainableIncomes =
      debouncedData.set === "adjusted_sustainable_income"
        ? `${capitalizeFirstLetter(debouncedData.primary_representation)} adjusted sustainable income`
        : `${capitalizeFirstLetter(debouncedData.primary_representation)} adjusted target withdrawal`;

    const currentSustainableIncomes =
      debouncedData.set === "adjusted_sustainable_income"
        ? `${capitalizeFirstLetter(debouncedData.primary_representation)} current sustainable income`
        : `${capitalizeFirstLetter(debouncedData.primary_representation)} current target withdrawal`;

    const adjustedResidualCapitals = `${capitalizeFirstLetter(debouncedData.primary_representation)} adjusted residual capital`;
    const currentResidualCapitals = `${capitalizeFirstLetter(debouncedData.primary_representation)} current residual capital`;

    return {
      adjustedSustainableIncomes,
      currentSustainableIncomes,
      adjustedResidualCapitals,
      currentResidualCapitals,
    };
  }, [debouncedData.set, debouncedData.primary_representation]);

  const convertChartSeries = useCallback(
    (chartData: any) => {
      if (!chartData || typeof chartData !== "object") {
        console.warn("Invalid chartData:", chartData);
        return { series: [], labels: [] };
      }

      const years = Array.isArray(chartData.years) ? chartData.years : Object.values(chartData.years || {});

      const getAdjustedIncomes = () =>
        formData.set === "adjusted_sustainable_income"
          ? chartData.adjusted_sustainable_incomes || {}
          : chartData.adjusted_target_withdrawals || {};

      const getCurrentIncomes = () =>
        formData.set === "adjusted_sustainable_income"
          ? chartData.current_sustainable_incomes || {}
          : chartData.current_target_withdrawals || {};

      const parseValues = (obj: any) =>
        Object.values(obj).map((value: any) => {
          if (value === null) return null;
          return parseFloat(value.toString().replace(/,/g, "")).toFixed(0);
        });

      const adjustedIncomes = parseValues(getAdjustedIncomes());
      const currentIncomes = parseValues(getCurrentIncomes());
      const adjustedResidualCapitals = parseValues(chartData.adjusted_residual_capitals || {});
      const currentResidualCapitals = parseValues(chartData.current_residual_capitals || {});

      const series = [
        {
          name: seriesNames.adjustedSustainableIncomes,
          type: "column",
          color: "#4472c4",
          data: adjustedIncomes,
        },
        {
          name: seriesNames.currentSustainableIncomes,
          type: "column",
          color: "#a5a5a5",
          data: currentIncomes,
        },
        {
          name: seriesNames.adjustedResidualCapitals,
          type: "line",
          color: "#ed7d31",
          data: adjustedResidualCapitals,
        },
        {
          name: seriesNames.currentResidualCapitals,
          type: "line",
          color: "#ffc000",
          data: currentResidualCapitals,
        },
      ];

      const labels = years.map((year: any, index: any) => (index === 0 ? "Inception" : "Year " + year));

      return { series, labels };
    },
    [seriesNames, debouncedData.set]
  );

  const calculateAndSetValues = useCallback(() => {
    calculateRetirementIncomeValues();
    calculateProjectedValues();
    calculateAdditionalValues();
    calculateProjectedMultiples();
    calculateDepletionRates();
    calculateRequiredValues();
    calculateAnnualWithdrawals();
    calculateValueDifferences();
    calculateImpactOnLivingStandards();
    calculateRetirementDeferments();
    calculateAdditionalContributions();
    calculateCapitalInjections();
    calculateAdjustedTargetCapital();
    calculateAdjustedRetirementLongevity();
    calculateAdjustedRetirementValues();
    calculateAdjustedIncomes();
    calculateChangesInSustainableIncome();
    calculateYearsToDepletion();
    calculateChangesInYearsToDepletion();
  }, [
    calculateRetirementIncomeValues,
    calculateProjectedValues,
    calculateAdditionalValues,
    calculateProjectedMultiples,
    calculateDepletionRates,
    calculateRequiredValues,
    calculateAnnualWithdrawals,
    calculateValueDifferences,
    calculateImpactOnLivingStandards,
    calculateRetirementDeferments,
    calculateAdditionalContributions,
    calculateCapitalInjections,
    calculateAdjustedTargetCapital,
    calculateAdjustedRetirementLongevity,
    calculateAdjustedRetirementValues,
    calculateAdjustedIncomes,
    calculateChangesInSustainableIncome,
    calculateYearsToDepletion,
    calculateChangesInYearsToDepletion,
  ]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, updatedFormData: FormData | null = null) => {
    e.preventDefault();
    const dataToSubmit = updatedFormData || formData;
    handleSubmitWithToast(e, dataToSubmit, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: any) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);
      if (data && data.data) {
        setChartData(data.data);
        const { series, labels } = convertChartSeries(data.data);
        setChartSeries(series);
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: labels,
          },
        }));
      } else {
        console.warn("Unexpected API response structure:", data);
        setError(new Error("Unexpected API response structure"));
      }
      return data;
    } catch (error) {
      console.error("Error calculating output:", error);
      setError(error as any);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData((prevFormData) => ({
        ...prevFormData,
        employment_status_id: data.ufs.employment_status_id || "",
        currency_id: data.ufs.currency_id || "",
        tax_domicile_id: data.ufs.tax_domicile_id || "",
        primary_representation_id: data.ufs.primary_representation_id || "",
        primary_representation: data.ufs.primary_representation || "",
        effective_date: data.ufs.effective_date,
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || "",
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || 0,
        retirement_date: data.ufs.retirement_date || "",
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || 0,
        life_expectancy: data.ufs.life_expectancy || "",
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        retirement_longevity: data.ufs.retirement_longevity
          ? data.ufs.retirement_longevity
          : calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,
        real_pre_retirement_capital_growth_low: data.ufs.real_pre_retirement_capital_growth_low || "",
        real_pre_retirement_capital_growth_medium: data.ufs.real_pre_retirement_capital_growth_medium || "",
        real_pre_retirement_capital_growth_high: data.ufs.real_pre_retirement_capital_growth_high || "",
        nominal_pre_retirement_capital_growth_low: data.ufs.nominal_pre_retirement_capital_growth_low || "",
        nominal_pre_retirement_capital_growth_medium: data.ufs.nominal_pre_retirement_capital_growth_medium || "",
        nominal_pre_retirement_capital_growth_high: data.ufs.nominal_pre_retirement_capital_growth_high || "",
        pre_retirement_costs: data.ufs.pre_retirement_costs || "",
        real_post_retirement_capital_growth_low: data.ufs.real_post_retirement_capital_growth_low || "",
        real_post_retirement_capital_growth_medium: data.ufs.real_post_retirement_capital_growth_medium || "",
        real_post_retirement_capital_growth_high: data.ufs.real_post_retirement_capital_growth_high || "",
        nominal_post_retirement_capital_growth_low: data.ufs.nominal_post_retirement_capital_growth_low || "",
        nominal_post_retirement_capital_growth_medium: data.ufs.nominal_post_retirement_capital_growth_medium || "",
        nominal_post_retirement_capital_growth_high: data.ufs.nominal_post_retirement_capital_growth_high || "",
        post_retirement_costs: data.ufs.post_retirement_costs || "",
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || "",
        real_growth_in_income: data.ufs.real_growth_in_income || "",
        nominal_growth_in_income: data.ufs.nominal_growth_in_income || "",
        current_accumulated_retirement_fund_value: data.ufs.current_accumulated_retirement_fund_value || "",
        current_monthly_contribution: data.ufs.current_monthly_contribution || "",
        post_retirement_income_percentage: data.ufs.post_retirement_income_percentage || "",
        contribution_as_percentage_of_income: data.ufs.contribution_as_percentage_of_income || "",
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const newSeriesNames = generateSeriesNames();
    setSeriesNames(newSeriesNames);
  }, [debouncedData.set, debouncedData.primary_representation, generateSeriesNames]);

  useEffect(() => {
    if (chartData) {
      try {
        const { series, labels } = convertChartSeries(chartData);
        setChartSeries(series);
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: labels,
          },
        }));
      } catch (error) {
        console.error("Error converting chart series:", error);
      }
    }
  }, [convertChartSeries, chartData, debouncedData.set]);

  useEffect(() => {
    setTimeout(() => {
      calculateAndSetValues();
    }, 100);
  }, [calculateAndSetValues]);

  useEffect(() => {
    setTimeout(() => {
      genericInputsCalculations();
    }, 100);
  }, [genericInputsCalculations]);
  useEffect(() => {
    setTimeout(() => {
      growthPreRetirementCalculations();
    }, 100);
  }, [growthPreRetirementCalculations]);
  useEffect(() => {
    setTimeout(() => {
      growthPostRetirementCalculations();
    }, 100);
  }, [growthPostRetirementCalculations]);

  useEffect(() => {
    getStoredData();
  }, []);

  return {
    loading,
    error,
    formData,
    setFormData,
    handleSubmit,
    chartSeries,
    chartOptions,
    setChartSeries,
    handleNominalChange,
    handleRealChange,
    chartData,
  };
};

export default useGapAnalysisTool;
