import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
/* Index */
import PremiumIndex from "@/pages/Premium";
import ActiveIncomePremium from "@/pages/Premium/ActiveIncome";
import BusinessPremium from "@/pages/Premium/Business";
import DefaultSettingsPremium from "@/pages/Premium/DefaultSettings";
import EducationPremium from "@/pages/Premium/Education";
import ExpenditurePremium from "@/pages/Premium/Expenditure";
import IncomeVsExpenditurePremium from "@/pages/Premium/IncomeVsExpenditure";
import InsurancePremium from "@/pages/Premium/Insurance";
import InvestmentAndSavingsPremium from "@/pages/Premium/InvestmentAndSavings";
import LoansPremium from "@/pages/Premium/Loans";
import PersonalInputsPremium from "@/pages/Premium/PersonalInputs";
import PropertyPremium from "@/pages/Premium/Property";
import RetirementPremium from "@/pages/Premium/Retirement";
import VehiclePremium from "@/pages/Premium/Vehicle";
import WealthPremium from "@/pages/Premium/Wealth";

const PremiumRoutes: React.FC = () => {
  return <Outlet />;
};

export const premiumRoutes: RouteObject[] = [
  {
    path: "premium",
    element: <PremiumRoutes />,
    children: [
      { path: "", element: <PremiumIndex /> },
      { path: "personal-inputs", element: <PersonalInputsPremium /> },
      { path: "default-settings", element: <DefaultSettingsPremium /> },
      { path: "active-income", element: <ActiveIncomePremium /> },
      { path: "property", element: <PropertyPremium /> },
      { path: "retirement", element: <RetirementPremium /> },
      { path: "investment-and-savings", element: <InvestmentAndSavingsPremium /> },
      { path: "loans", element: <LoansPremium /> },
      { path: "vehicle", element: <VehiclePremium /> },
      { path: "business", element: <BusinessPremium /> },
      { path: "education", element: <EducationPremium /> },
      { path: "insurance", element: <InsurancePremium /> },
      { path: "expenditure", element: <ExpenditurePremium /> },
      { path: "income-vs-expenditure", element: <IncomeVsExpenditurePremium /> },
      { path: "wealth", element: <WealthPremium /> },
    ],
  },
];

export default PremiumRoutes;
