import words from "@/services/algorithms/words";

const { capitalizeFirstLetter } = words();

export const InsuranceCharacteristicsTableHeadings = (formData: any) => [
  { text: "Description", id: "description" },
  { text: "Sum assured", id: "sum_assured" },
  { text: "Current premium", id: "current_premium" },
  { text: "Frequency", id: "description" },
  { text: "Review month", id: "description" },
  { text: "Terminal date", id: "description" },
  { text: capitalizeFirstLetter(formData.primary_representation) + " increase", id: "description" },
];

export const getTotalColumns = (formData: any) => InsuranceCharacteristicsTableHeadings(formData).length;

// Keep this for backward compatibility
export const totalColumns = 7; // This should match the number of items in InsuranceCharacteristicsTableHeadings
