import useAdditionalSettings from "@api/User/useAdditionalSettings";
import useEmailArticles from "@api/User/useEmailArticles";
import Button from "@form/Button";
import CheckboxFull from "@form/CheckboxFull";

const AdditionalSettings = () => {
  const {
    preferences,
    userPreferences,
    handleCheckboxChange: handlePreferenceChange,
    handleSubmit: handlePreferenceSubmit,
    loading: preferenceLoading,
  } = useAdditionalSettings();

  const {
    emailArticles,
    userEmailArticles,
    handleCheckboxChange: handleEmailArticleChange,
    handleSubmit: handleEmailArticleSubmit,
    loading: emailArticleLoading,
  } = useEmailArticles();

  const handleSubmit = (e) => {
    e.preventDefault();
    handlePreferenceSubmit(e);
    handleEmailArticleSubmit(e);
  };

  const loading = preferenceLoading || emailArticleLoading;

  return (
    <form
      onSubmit={handleSubmit}
      className="h-full relative pb-14"
    >
      <h3 className="text-[16px] font-semibold mb-3 text-center">Additional settings</h3>
      <div className="divide-y divide-gray-200">
        {preferences.map((preference) => (
          <div
            className="flex items-center justify-between py-2"
            key={preference.id}
          >
            <CheckboxFull
              id={preference.id}
              name={preference.name}
              array={userPreferences}
              slug={preference.slug}
              classTarget="additional-settings"
              handleCheckboxChange={handlePreferenceChange}
            />
          </div>
        ))}
      </div>

      <h3 className="text-[16px] font-semibold mb-3 mt-6 text-center">Email Articles</h3>
      <div className="divide-y divide-gray-200">
        {emailArticles.map((emailArticle) => (
          <div
            className="flex items-center justify-between py-2"
            key={emailArticle.id}
          >
            <CheckboxFull
              id={emailArticle.id}
              name={emailArticle.name}
              array={userEmailArticles}
              slug={emailArticle.slug}
              classTarget="email-articles"
              handleCheckboxChange={handleEmailArticleChange}
            />
          </div>
        ))}
      </div>

      <div className="absolute bottom-0 w-full">
        <Button
          type="submit"
          loading={loading}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default AdditionalSettings;
