import Card from "@/components/Element/Card";
import Currency from "@/components/Form/Currency";
import Percentage from "@/components/Form/Percentage";
import SelectToggle from "@/components/Form/ToggleSwitch";
import words from "@/services/algorithms/words";
import helpers from "@/services/utils/helpers";
import React, { useState } from "react";
import Chart from "react-apexcharts";
import { twMerge } from "tailwind-merge";
import commentaryTool from "../useCommentaryTool";

interface ChartSeries {
  name: string;
  data: number[];
}

const ImpactOfCosts: React.FC = () => {
  const { capitalizeFirstLetter } = words();
  const [chartSeries] = useState<ChartSeries[]>([]);
  const { chartOptionsDefault } = helpers();
  const [chartOptions] = useState<any>(chartOptionsDefault);

  const { formData, handleCurrencyChange, handlePrimaryRepresentationChange } = commentaryTool();

  return (
    <div className="max-h-[calc(100vh-145px)] h-full scrollbar overflow-y-auto overflow-x-hidden pb-4 lg:w-[90%] mx-auto">
      <div className="grid grid-cols-5 gap-4 pt-4">
        <div className="col-span-3">
          <div className="max-h-[calc(100vh-170px)] h-full scrollbar overflow-y-auto overflow-x-hidden">
            <Card
              variant="white"
              className="mb-4"
            >
              <div className={`flex justify-between items-center mb-4`}>
                <div>
                  <h3 className="text-[16px] font-semibold">Insert Parameters</h3>
                </div>
                <div className="flex items-center">
                  <SelectToggle
                    title="Analysis type:"
                    selectedValue={formData?.primary_representation}
                    options={[
                      { value: "nominal", label: "Nominal" },
                      { value: "real", label: "Real" },
                    ]}
                    handleChange={handlePrimaryRepresentationChange}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3 items-end">
                <div>
                  <Percentage
                    label="Projected Inflation"
                    name="projected_inflation_rate"
                    id="projected_inflation_rate"
                    value={formData.projected_inflation_rate}
                    onChange={handleCurrencyChange}
                    variant="red"
                  />
                </div>
                <div>
                  <Currency
                    label="Initial Capital"
                    name="initialCapital"
                    id="initialCapital"
                    value={formData.initialCapital}
                    onChange={handleCurrencyChange}
                    variant="green"
                  />
                </div>
                <div>
                  <Percentage
                    label="Increase in annual contributions"
                    name="annual_contributions"
                    id="annual_contributions"
                    value={formData.annual_contributions}
                    onChange={handleCurrencyChange}
                    variant="green"
                  />
                </div>
                <div>
                  <Currency
                    label="Annual contribution"
                    name="annualContribution"
                    id="annualContribution"
                    value={formData.annualContribution}
                    onChange={handleCurrencyChange}
                    variant="green"
                  />
                </div>
                <div>
                  <Percentage
                    label="Gross growth rate (pre-costs)"
                    name="gross_growth_rate_pre_costs"
                    id="gross_growth_rate_pre_costs"
                    value={formData.gross_growth_rate_pre_costs}
                    onChange={handleCurrencyChange}
                    variant="green"
                  />
                </div>
              </div>
            </Card>
            <Card variant="white">
              <div className="mb-4">
                <h3 className="text-[16px] font-semibold mb-2">Output: projected values</h3>
              </div>
              <div className="scrollbar overflow-x-scroll">
                <table className="table-auto w-full">
                  <thead className="bg-[#ffffff]">
                    <tr className="whitespace-nowrap bg-[#f1f1f1]">
                      <th
                        rowSpan={2}
                        colSpan={2}
                        className="text-[14px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]"
                      >
                        {capitalizeFirstLetter(formData?.primary_representation)}
                      </th>
                      <th
                        colSpan={8}
                        className="text-[14px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]"
                      >
                        Time period (years)
                      </th>
                    </tr>
                    <tr className="whitespace-nowrap bg-[#f1f1f1]">
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        5
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        10
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        15
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        20
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        25
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        30
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        35
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        40
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        rowSpan={20}
                        className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]"
                      >
                        <p className="rotate-[-90deg] text-center text-[14px] w-full whitespace-nowrap">Annual Costs</p>
                      </td>
                      <td className="w-0 text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.10%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        229 425
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        464 675
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        880 715
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 602 191
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 835 176
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 918 772
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        8 408 836
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        14 213 580
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.20%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        228 486
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        461 147
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        871 103
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 579 445
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 785 574
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 186 286
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        8 205 090
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        13 820 090
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.30%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        227 551
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        457 654
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        861625
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 557 110
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 737 061
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 716 455
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        8 007 422
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        13 439 879
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.40%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        226 622
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        454 196
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        852 281
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 535 176
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 689 613
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 619 204
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        7 815 631
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        13 072 460
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.50%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        225 699
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        450 772
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        843 067
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 513 636
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 643 202
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 524 458
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        7 629 527
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        12 717 363
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.60%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        224 780
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        447 382
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        833 983
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 492 483
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 597 804
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 432 146
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        7 448 925
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        12 374 136
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.70%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        223 867
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        444 026
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        825 024
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 471 707
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 553 395
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 342 201
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        7 273 646
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        12 042 347
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.80%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        222 958
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        440 703
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        816 191
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 451 302
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 509 950
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 254 555
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        7 103 520
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        11 721 578
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.90%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        222 055
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        437 413
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        807 480
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 431 261
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 467 446
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 169 144
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        6 938 378
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        11 411 428
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.00%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        221 157
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        434 155
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        798 890
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 411 576
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 425 862
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 085 904
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        6 778 061
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        11 111 512
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.10%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        220 264
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        430 929
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        790 418
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 392 240
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 385 174
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 004 775
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        6 622 413
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        10 821 460
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.20%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        219 377
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        427 735
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        782 064
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 373 247
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 345 361
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        3 925 697
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        6 471 285
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        10 540 915
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.30%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        218 494
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        424 572
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        773 826
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 354 589
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 306 403
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        3 848 613
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        6 324 532
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        10 269 534
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.40%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        217 616
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        421 440
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        765 700
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 336 259
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 268 279
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        3 773 468
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        6 182 014
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        10 006 988
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.50%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        216 742
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        418 339
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        757 687
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 318 253
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 230 970
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        3 700 208
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        6 043 597
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        9 752 960
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.60%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        215 874
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        415 268
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        749 783
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 300 562
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 194 455
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        3 628 779
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        5 909 149
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        9 507 145
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.70%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        215 011
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        412 227
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        741 988
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 283 182
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 158 717
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        3 559 132
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        5 778 544
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        9 269 250
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.80%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        214 152
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        409 216
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        734 300
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 266 105
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 123 736
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        3 491 217
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        5 651 662
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        9 038 992
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        1.90%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        213 299
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        406 234
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        726 716
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 249 327
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 089 495
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        3 424 986
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        5 528 384
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        8 816 100
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        2.00%
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        212 450
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        403 281
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        719 236
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 232 840
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 055 976
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        3 360 392
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        5 408 596
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        8 600 313
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={10}
                        className="text-[16px] font-semibold mb-2 py-4"
                      >
                        Select your specific variables to see the cost impacts – difference in projected values
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[14px] text-left font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        Low
                      </td>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        <Percentage
                          variant="green"
                          twMergeClassName={twMerge(`text-center w-fit`)}
                        />
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        227 551
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        457 654
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        861 625
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 557 110
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 737 061
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 716 455
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        8 007 422
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        13 439 879
                      </td>
                    </tr>
                    <tr>
                      <td className="text-[14px] text-left font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        High
                      </td>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        <Percentage
                          variant="green"
                          twMergeClassName={twMerge(`text-center w-fit`)}
                        />
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        221 157
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        434 155
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        798 890
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        1 411 576
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        2 425 862
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        4 085 904
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        6 778 061
                      </td>
                      <td className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center">
                        11 111 512
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        className="text-[14px] text-left font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]"
                      >
                        Difference
                      </td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                    </tr>
                    <tr>
                      <td className="text-[14px] text-left font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        Percentage
                      </td>
                      <td className="text-[12px] text-right font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f1f1f1]">
                        0.70%
                      </td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                      <td className="text-[12px] text-red-500 font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] text-center"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
        </div>

        <div className="col-span-2">
          <Card
            variant="white"
            className="h-full"
          >
            <p className="mr-8 font-bold text-[16px] text-center mb-4">Impact of fees over time</p>
            <div className="h-full max-h-[calc(100%-40px)]">
              {chartSeries && (
                <Chart
                  options={chartOptions}
                  series={chartSeries}
                  type="line"
                  width="100%"
                  height="100%"
                />
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ImpactOfCosts;
