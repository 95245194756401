import { CategoryData } from "./PropertyTable";

type InputType = "date" | "currency" | "select" | "number" | "percentage" | "none";
type Variant = "green" | "red";

interface RowData {
  description: string;
  inputType: InputType;
  name: string;
  variant: Variant;
}

const createRow = (description: string, inputType: InputType, name: string, variant: Variant = "green"): RowData => ({
  description,
  inputType,
  name,
  variant,
});

const createCategory = (category: string, rows: RowData[]): CategoryData => ({
  category,
  rowSpan: rows.length,
  rows: rows.map((row) => ({
    description: row.description,
    inputs: row.inputType === "none" ? [] : [{ type: row.inputType, name: row.name, variant: row.variant }],
  })),
});

export const PropertyTableData = (formData: any): CategoryData[] => {
  const assetCostRows: RowData[] = [
    createRow("Purchase date", "date", "primary_purchase_date"),
    createRow("Initial purchase price", "currency", "primary_initial_purchase_price"),
    createRow("Initial bond", "currency", "primary_initial_bond"),
    createRow("All property purchase costs", "currency", "primary_property_purchase_costs"),
    createRow("Capital improvements", "currency", "primary_capital_inprovements"),
  ];

  const assetLiabilitiesRows: RowData[] = [
    createRow("Outstanding bond", "currency", "primary_outstanding_bond"),
    createRow("Bond payment frequency", "select", "primary_bond_payment_frequency"),
    createRow("Bond outstanding term (months)", "number", "primary_bond_outstanding_term"),
    createRow("Bond completion date", "date", "primary_bond_completion_date", "red"),
    createRow("Bond interest rate", "currency", "primary_bond_interest_rate"),
    createRow("Bond monthly payments", "currency", "primary_bond_monthly_payments", "red"),
    createRow("Property sum insured", "currency", "primary_property_sum_insured"),
    createRow("Property monthly insurance", "currency", "primary_property_monthly_insurance"),
    createRow("Insurance review month", "number", "primary_insurance_review_month"),
    createRow("Annual increase in insurance", "percentage", "primary_annual_increase_in_insurance"),
    createRow("Monthly property rates", "currency", "primary_monthly_property_rates"),
    createRow("Rates review month", "number", "primary_rates_review_month"),
    createRow("Annual increase in rates", "percentage", "primary_annual_increase_in_months"),
    createRow("Annual maintenance costs", "percentage", "primary_annual_maintenance_costs"),
  ];

  const assetRevenuesRows: RowData[] = [
    createRow("Property rental income", "currency", "property_rental_income"),
    createRow("Rent payment frequency", "select", "rent_payment_frequency"),
    createRow(
      `Rental annual ${formData.primary_representation} increase`,
      "percentage",
      `rental_annual_${formData.primary_representation}_increase`
    ),
    createRow("Bond completion date", "date", "bond_completion_date", "green"),
  ];

  const assetValuesRows: RowData[] = [
    createRow("Property market value", "currency", "property_market_value", "green"),
    createRow(
      `Property ${formData.primary_representation} capital growth`,
      "percentage",
      `property_${formData.primary_representation}_capital_growth`
    ),
  ];

  const assetLiquidationRows: RowData[] = [
    createRow("Liquidation fees", "percentage", "liquidation_fees", "green"),
    createRow("Capital gains rebate", "currency", "capital_gains_rebate", "red"),
    createRow("CGT inclusion rate", "percentage", "cgt_inclusion_rate", "red"),
    createRow("Marginal tax rate", "percentage", "marginal_tax_rate", "red"),
  ];

  return [
    createCategory("Asset cost", assetCostRows),
    createCategory("Asset liabilities", assetLiabilitiesRows),
    createCategory("Asset revenues", assetRevenuesRows),
    createCategory("Asset values", assetValuesRows),
    createCategory("Asset liquidation", assetLiquidationRows),
  ];
};

export default PropertyTableData;
