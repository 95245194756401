import { TableHeader } from "@/components/Element/Table/TableHeader";
import { TableRow } from "@/components/Element/Table/TableRow";
import Button from "@/components/Form/Button";
import Currency from "@/components/Form/Currency";
import Input from "@/components/Form/Input";
import React, { memo } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { getTotalColumns, InsuranceCharacteristicsTableHeadings } from "./InsuranceCharacteristicsTableContants";

export interface CategoryData {
  category: string;
  rowSpan: number;
  rows: {
    description: string;
    inputs: Array<{
      type: "currency" | "text" | "number" | "date" | "percentage" | "percentageSwitch" | "select";
      name: string;
      variant: "green" | "red";
      primaryRepresentation?: string;
      realField?: string;
      nominalField?: string;
    }>;
  }[];
}

interface InsuranceCharacteristicsTableProps {
  fixedRows: CategoryData[];
  additionalRows: Array<{ id: number }>;
  removeRow: (id: number) => void;
  addRow: () => void;
  formData: any;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
}

const InsuranceCharacteristicsTable: React.FC<InsuranceCharacteristicsTableProps> = ({
  fixedRows,
  additionalRows,
  removeRow,
  addRow,
  formData,
  handleCurrencyChange,
}) => {
  const totalColumns = getTotalColumns(formData);
  const headings = InsuranceCharacteristicsTableHeadings(formData);

  return (
    <table className="table-auto border">
      <TableHeader headings={headings} />
      <tbody>
        {fixedRows.flatMap((category) =>
          category.rows.map((row, rowIndex) => (
            <TableRow
              key={`${category.category}-${rowIndex}`}
              description={row.description}
              inputs={row.inputs}
              formData={formData}
              handleCurrencyChange={handleCurrencyChange}
            />
          ))
        )}
        {additionalRows.map((row) => (
          <TableRow
            key={row.id}
            description={
              <div className="flex items-center justify-between">
                <Input
                  variant="bottomborder"
                  autoFocus
                  required
                  placeholder="Enter description"
                />
                <IoCloseCircleOutline
                  onClick={() => removeRow(row.id)}
                  color="red"
                  className="cursor-pointer min-w-[20px]"
                />
              </div>
            }
            inputs={[
              { type: "currency", name: `additional_row_${row.id}_sum_assured`, variant: "green" },
              { type: "currency", name: `additional_row_${row.id}_premium`, variant: "green" },
              { type: "select", name: `additional_row_${row.id}_frequency`, variant: "green" },
              { type: "number", name: `additional_row_${row.id}_review_month`, variant: "green" },
              { type: "date", name: `additional_row_${row.id}_terminal_date`, variant: "green" },
              { type: "percentage", name: `${row.id}_${formData.primary_representation}_increase`, variant: "green" },
            ]}
            formData={formData}
            handleCurrencyChange={handleCurrencyChange}
            deleteRow={() => removeRow(row.id)}
          />
        ))}

        <tr>
          <td className="border border-gray-300 p-2 text-xs font-bold">TOTAL</td>
          <td className="border border-gray-300 p-2 text-xs">
            <Currency
              disabled
              variant="red"
            />
          </td>
          <td className="border border-gray-300 p-2 text-xs">
            <Currency
              disabled
              variant="red"
            />
          </td>
        </tr>
        <tr className="border-b-2 border-t-2 border-gray-400">
          <td
            colSpan={totalColumns}
            className="p-2 border border-gray-300"
          >
            <Button onClick={addRow}>Add</Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default memo(InsuranceCharacteristicsTable);
