import React from "react";

const RecommendedReadings: React.FC = () => {
  return (
    <div className="max-h-[calc(100vh-70px)] h-full scrollbar overflow-y-auto overflow-x-hidden p-4 lg:w-[90%] mx-auto">
      <div className="grid grid-cols-2 gap-10 pt-4 text-sm border border-gray-400 p-4">
        <div>
          <p className="font-bold mb-4">Layman</p>
          <ol className="ml-6 space-y-4 mb-4 my-2 font-medium list-[upper-alpha]">
            <li>
              <p>"The Richest man in Babylon" – George Clason</p>
            </li>
            <li>
              <p>"Atomic habits" – James Clear</p>
            </li>
            <li>
              <p>"The only investment guide you'll ever need" – Andrew Tobias</p>
            </li>
            <li>
              <p>"The little book of common sense investing" – John Bogle</p>
            </li>
            <li>
              <p>
                "The opposite of spoiled : raising kids who are grounded, generous and smart about money" – Ron Lieber
              </p>
            </li>
            <li>
              <p>"The behaviour gap : simple ways to stop doing dumb things with money" – Carl Richards</p>
            </li>
            <li>
              <p>"Rich Dad, Poor Dad" – Robert Kiyosaki</p>
            </li>
            <li>
              <p>"Sapiens" – Yuvral Noah Harari</p>
            </li>
            <li>
              <p>"Happy money : the science of happier spending" – Elizabeth Dunn & Michael Norton</p>
            </li>
            <li>
              <p>
                "Fierce conversations : achieving success at work and in life one conversation at a time" – Susan Scott
              </p>
            </li>
            <li>
              <p>"The 4-hour workweek" – Tim Ferris</p>
            </li>
          </ol>
        </div>
        <div>
          <p className="font-bold mb-4">Professional</p>
          <ol className="ml-6 space-y-4 mb-4 my-2 font-medium list-[upper-alpha]">
            <li>
              <p>"The Intelligent Investor" – Benjamin Graham</p>
            </li>
            <li>
              <p>"The Clash of Cultures" – John Bogle</p>
            </li>
            <li>
              <p>"Lying for money" – Dan Davies</p>
            </li>
            <li>
              <p>"The little book of common sense investing" – John Bogle</p>
            </li>
            <li>
              <p>
                "Triumph of the Optimists : 101 years of global investment returns" – Elroy Dimson, Paul Marsh & Mike
                Staunton
              </p>
            </li>
            <li>
              <p>"Investing amid low expectations" – Antti Ilmanen</p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default RecommendedReadings;
