import { cn } from "@/services/utils/cn";
import { cva } from "class-variance-authority";
import type { ReactNode } from "react";

type CardVariants = "white" | "red" | "green" | "orange";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: CardVariants;
  children?: ReactNode;
}

export const cardVariants = cva("rounded-md shadow-sm bg-[#fff] border h-full", {
  variants: {
    variant: {
      white: "bg-[#fff] border-gray-200 shadow-sm",
      red: "bg-[#ff000020] border-[#ff000090]",
      green: "bg-[#00800040] border-[#00800090]",
      orange: "bg-[#F2B443] border-[#ffa700]",
    },
  },
});

function Card({ variant, children, className, ...rest }: CardProps) {
  return (
    <div
      className={cn("bg-white rounded-md shadow-sm", className)}
      {...rest}
    >
      <div className={cn(cardVariants({ variant }), "border-gray-200 shadow-sm")}>
        <div className="p-4 rounded-md h-full shadow-sm">{children}</div>
      </div>
    </div>
  );
}

export default Card;
