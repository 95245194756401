import Card from "@/components/Element/Card";
import ChartLoading from "@/components/Element/ChartLoading";
import RadioFull from "@/components/Form/RadioFull";
import words from "@/services/algorithms/words";
import React, { ChangeEvent, useState } from "react";
import Chart from "react-apexcharts";

const selectChart = [
  {
    id: 1,
    name: "Pre-tax active income - monthly",
    slug: "pre-tax-active-income-monthly",
  },
  {
    id: 2,
    name: "Pre-tax annual bonus",
    slug: "pre-tax-annual-bonus",
  },
  {
    id: 3,
    name: "Total income - monthly",
    slug: "total-income-monthly",
  },
  {
    id: 4,
    name: "Cumulative income (pre- and post-tax)",
    slug: "cumulative-income-pre-and-post-tax",
  },
  {
    id: 5,
    name: "Human Capital",
    slug: "human-capital",
  },
];

interface ActiveIncomeTabTwoProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  formData: any;
  loading: boolean;
  chartSeries: any;
  chartOptions: any;
}

const ActiveIncomeTabTwo: React.FC<ActiveIncomeTabTwoProps> = ({
  handleSubmit,
  preventEnterPress,
  formData,
  loading,
  chartSeries,
  chartOptions,
}) => {
  const { capitalizeFirstLetter } = words();
  const [selectedChart, setSelectedChart] = useState<string>("");
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedChart(event.target.value);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="">
          <div className="grid grid-cols-7 gap-4 w-full">
            <div className="col-span-3 max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
              <Card
                variant="white"
                className="mb-4"
              >
                <h3 className="text-[16px] font-semibold mb-3">Charts available</h3>
                <form
                  onSubmit={handleSubmit}
                  className="border-b"
                >
                  <div className="divide-y divide-gray-200">
                    {selectChart.map((chart) => (
                      <div
                        className="flex items-center justify-between py-4"
                        key={chart.id}
                      >
                        <RadioFull
                          id={chart.id.toString()}
                          name={chart.name}
                          slug={chart.slug}
                          // I just put a random string in classTarget for now.
                          classTarget="select-chart"
                          handleRadioChange={handleRadioChange}
                          checked={selectedChart === chart.slug}
                        />
                      </div>
                    ))}
                  </div>
                </form>

                <p className="mt-4 text-[12px] font-bold">
                  Chart #3 shows the elements of your active income in a cumulative representation – this allows us to
                  estimate total earnings until full retirement and hence calculate the value of your implicit human
                  capital
                </p>
              </Card>
              <Card
                variant="white"
                className="mb-4"
              >
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[60%]">
                        {capitalizeFirstLetter(formData.primary_representation)} projections @ target dates
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                        Formal retirement Apr-2048
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                        Full retirement Mar-2053
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Age at event</td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2"></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Cumulative active income (from today)
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-red-500">
                        Cumulative Tax
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 text-red-500"></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 text-red-500"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Cumulative Net active income
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Investable potential capital (wealth creation proportion from effective date until target dates
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2"></td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-1"
              >
                <div className="text-[12px] leading-[23px] font-bold w-fit">
                  <p>1. Can I live within my active income means to retirement i.e.</p>
                  <p>2. If I saved all my annual bonuses, what would that mean</p>
                  <ul className="pl-6 list-[lower-alpha]">
                    <li>Additional savings for retirement – what is the multiple at retirement?</li>
                    <li>How does this compare to my monthly savings?</li>
                  </ul>
                  <p>3. What does my human capital look like over time?</p>
                  <ul className="pl-6 list-[lower-alpha]">
                    <li>Present value of future labour crossover to wealth creation</li>
                    <li>Table of wealth comparison</li>
                  </ul>
                </div>
              </Card>
            </div>
            <div className="col-span-4 h-[calc(100vh-235px)]">
              <Card
                variant="white"
                className="mb-1 h-full"
              >
                <p className="mr-8 font-bold text-[16px] text-center mb-4">
                  {selectChart.find((chart) => chart.slug === selectedChart)?.name}
                  {formData.primary_representation && " - " + formData.primary_representation}
                </p>
                {loading ? (
                  <ChartLoading />
                ) : (
                  <div className="h-full relative">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                        fontFamily="Helvetica, Arial, sans-serif"
                      />
                    )}
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ActiveIncomeTabTwo;
