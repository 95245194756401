import React from "react";

interface TermDefinition {
  term: string;
  definition: string;
}

const terms: TermDefinition[] = [
  {
    term: "Active management",
    definition:
      "the process of making decisions on the investments within a portfolio with the goal of delivering excess returns over the designated benchmark.",
  },
  {
    term: "Assets",
    definition:
      "assets are vehicles that support the investment process and will include stocks, bonds, property and cash, both local and international.",
  },
  {
    term: "Asset allocation",
    definition:
      "the process of allocating your investment portfolio to different assets. The rationale for this is risk and return diversification.",
  },
  {
    term: "Alpha",
    definition: "a measure of excess return (usually in percentage terms) relative to target or benchmark.",
  },
  {
    term: "Annualisation",
    definition:
      "the process of converting a series of short- or long-term statistics into a form that is comparable over an annual time frame.",
  },
  {
    term: "Bear market",
    definition:
      "this describes an environment where asset prices typically decline over a period of time. Usually considered to be a sustained fall as distinct from short-term volatility.",
  },
  {
    term: "Benchmark",
    definition:
      "the performance objective and/or neutral structure of a portfolio used to define the success (or failure) of an investment strategy.",
  },
  {
    term: "Beta",
    definition:
      "a measure of how volatile an assets performance is relative to its target or benchmark. A beta of one represents an asset that has exactly the same volatility as its benchmark.",
  },
  {
    term: "Bond",
    definition:
      "a debt security that a business or government sells to investors in return for a fixed (or variable) rate of interest over a set term with repayment of capital (principal) at the end of the term.",
  },
  {
    term: "Bull market",
    definition:
      "this describes an environment where asset prices typically increase over a period of time. Usually considered to be a sustained rise as distinct from short-term volatility.",
  },
  {
    term: "Capital gain (loss)",
    definition:
      "the amount by which an asset appreciates (depreciates) over its purchase price. The gain is considered unrealized until the date of sale.",
  },
  {
    term: "Commodities",
    definition:
      "a type of asset class that are typically raw materials or primary agricultural products that can be purchased or sold including gold, oil and grain.",
  },
  {
    term: "Common stock",
    definition:
      "also stocks or equities; that portion of a company owned by its shareholders, both listed on the stock market and unlisted in private hands. Has associated voting rights.",
  },
  {
    term: "Currency risk",
    definition:
      "the risk that results from changes in the price of one currency to another (also known as exchange rate risk).",
  },
  {
    term: "Default risk",
    definition:
      "also known as counterparty or credit risk – it is the risk that an individual or entity is unable to meet their debt obligations either in terms of interest or capital payments.",
  },
  {
    term: "Dividends",
    definition: "payments made to its shareholders from the earnings or retained capital of a company.",
  },
  {
    term: "Diversification",
    definition:
      "the technique used by investors to reduce the risk of losing money by spreading investments across a variety of assets, instruments and industries.",
  },
  {
    term: "Equities",
    definition: "refer common stock.",
  },
  {
    term: "Fixed income",
    definition: "refers to a debt instrument (bond) that pays a fixed amount of interest over a fixed period of time.",
  },
  {
    term: "Floating income",
    definition:
      "refers to a debt instrument (bond) that pays a variable amount of interest over a fixed period of time. The variation is often a function of prevailing interest rates.",
  },
  {
    term: "Fundamental attribute",
    definition:
      "this describes characteristics of a security that can largely be found under published income statements, balance sheets or cash-flow statements.",
  },
  {
    term: "Index",
    definition:
      "a collection of securities used to track a particular market, asset class or sector. The methodology for the construction of an index follows mechanisms such as fundamental attributes or market capitalization.",
  },
  {
    term: "Interest rate risk",
    definition: "the risk that the value of a security will change because of a change in interest rates.",
  },
  {
    term: "Market capitalization",
    definition:
      "the total value of an instrument calculated by the quotient of the number of shares in issue X the prevailing price of the instrument.",
  },
  {
    term: "Maturity",
    definition:
      "the date on which the bond issuer agrees to pay the last interest and principal amount due on the bond.",
  },
  {
    term: "Passive management",
    definition:
      "the process of making decisions on the investments within a portfolio to mirror the benchmark i.e. a replicating strategy.",
  },
  {
    term: "Portfolio",
    definition: "your investment portfolio represents the aggregation of all your investments.",
  },
  {
    term: "Quantitative management",
    definition:
      "the process of making decisions on the investments within a portfolio on the basis of quantitative models and algorithms.",
  },
  {
    term: "Security",
    definition: "a generic term for an instrument that represents any of the asset classes.",
  },
  {
    term: "Volatility",
    definition:
      "a tendency for quick and unpredictable change, usually measured statistically over an historical period.",
  },
];

const InvestmentTerms: React.FC = () => {
  const renderTerms = (start: number, end: number) => (
    <div>
      {terms.slice(start, end).map((item, index) => (
        <p
          key={index}
          className="font-medium mb-4"
        >
          <span className="font-bold">{`${start + index + 1}. ${item.term}: `}</span>
          {item.definition}
        </p>
      ))}
    </div>
  );

  return (
    <div className="text-sm max-h-[calc(100vh-70px)] h-full scrollbar overflow-y-auto overflow-x-hidden p-4 lg:w-[90%] mx-auto">
      <div className="border border-gray-400 p-4">
        <div className="grid grid-cols-3 gap-6">
          {renderTerms(0, 10)}
          {renderTerms(10, 20)}
          {renderTerms(20, 30)}
        </div>
      </div>
    </div>
  );
};

export default InvestmentTerms;
