import Card from "@/components/Element/Card";
import ChartLoading from "@/components/Element/ChartLoading";
import RadioFull from "@/components/Form/RadioFull";
import words from "@/services/algorithms/words";
import React, { ChangeEvent, useState } from "react";
import Chart from "react-apexcharts";

const selectChart = [
  {
    id: 1,
    name: "Pension fund/s",
    slug: "pension-funds",
  },
  {
    id: 2,
    name: "Provident fund/s",
    slug: "provident-funds",
  },
  {
    id: 3,
    name: "Preservation fund/s",
    slug: "preservation-funds",
  },
  {
    id: 4,
    name: "Retirement Annuity fund/s",
    slug: "retirement-annuity-funds",
  },
  {
    id: 5,
    name: "Total retirement fund assets",
    slug: "total-retirement-fund-assets",
  },
];

interface RetirementTabTwoProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  formData: any;
  loading: boolean;
  chartSeries: any;
  chartOptions: any;
}

const RetirementTabTwo: React.FC<RetirementTabTwoProps> = ({
  handleSubmit,
  preventEnterPress,
  formData,
  loading,
  chartSeries,
  chartOptions,
}) => {
  const { capitalizeFirstLetter } = words();
  const [selectedChart, setSelectedChart] = useState<string>("");
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedChart(event.target.value);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
            <Card
              variant="white"
              className="mb-4"
            >
              <form onSubmit={handleSubmit}>
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-[14px] w-[30%]">Charts available</th>
                      <th className="text-[14px] w-[30%]">Pre-retirement</th>
                      <th
                        colSpan={2}
                        className="text-[14px] w-[40%]"
                      >
                        Post-retirement
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th className="text-[12px]">Aggregate Withdrawals</th>
                      <th className="text-[12px]">Monthly Withdrawals</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectChart.map((chart, index) => (
                      <tr key={`selectChart-${index}`}>
                        <td
                          className={`text-[12px] text-left ${index === selectChart.length - 1 ? "font-bold" : "font-semibold"}`}
                        >
                          {chart.name}
                        </td>
                        <td>
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={selectedChart === chart.slug}
                            centerToggle
                          />
                        </td>
                        <td className="text-[12px] font-semibold">
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={false}
                            centerToggle
                          />
                        </td>
                        <td className="text-[12px] font-semibold">
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={false}
                            centerToggle
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>

              <p className="mt-4 text-[12px] font-bold">
                All charts are driven by the input parameters entered/confirmed on “additional inputs” and “defaults”.
                Pre-retirement charts demonstrate the impact of different growth rates on your retirement fund capital
                value, whilst post-retirement charts show how long your money will last in retirement at your specified
                withdrawal rate. Post-retirement charts show information out to 25 years
              </p>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[60%]">
                      {capitalizeFirstLetter(formData.primary_representation)} fund values at retirement
                    </th>
                    <th
                      colSpan={3}
                      className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]"
                    >
                      Pre-retirement growth rates
                    </th>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[60%]">
                      Date: 30 April 2048(hardcoded for now)
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                      Conservative
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Moderate</th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                      Aggressive
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Pension fund/s</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Provident fund/s</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Preservation fund/s
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Retirement Annuity fund/s
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">
                      Total retirement fund assets
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-1/2">
                      All values effective:
                    </th>
                    <th
                      colSpan={3}
                      className="border-b border-r border-[#999] text-[12px] font-semibold p-2"
                    >
                      Pre-retirement growth rates
                    </th>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      30 April 2048 (hardcoded for now)
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Conservative</th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Moderate</th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Aggressive</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Projected Real monthly income at retirement
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Projected Real capital at retirement
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Projected multiple @85% of income
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Target annual pre-tax real income at retirement
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Target withdrawal rate
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">
                      Years to depletion at post-retirement growth rates
                    </td>
                    <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2">.</td>
                    <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2">.</td>
                    <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2">.</td>
                  </tr>
                </tbody>
              </table>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <p className="text-xs font-bold mb-4">
                Table below shows the impact of varying rates of withdrawal on standard of living and longevity in
                retirement
              </p>
              <h3 className="text-[16px] font-semibold mb-4 text-center">Post-retirement growth</h3>
              <div className="overflow-x-auto">
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th
                        rowSpan={2}
                        className="border-b border-r border-[#999] text-[12px] font-semibold p-2"
                      >
                        Initial withdrawal rate
                      </th>
                      <th className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Conservative
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">.</th>
                      <th className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Moderate
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">.</th>
                      <th className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Aggressive
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">.</th>
                    </tr>
                    <tr className="bg-[#f2b443]">
                      <th className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Years to depletion
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        % SOL pre-retirement
                      </th>
                      <th className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Years to depletion
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        % SOL pre-retirement
                      </th>
                      <th className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Years to depletion
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        % SOL pre-retirement
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="bg-[#f2b443] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2b443] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2b443] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2b443] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-[#f2b443] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2b443] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2b443] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                      <td className="bg-[#f2b443] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center">
                        .
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>

            <Card
              variant="white"
              className="mb-2"
            >
              <p className="text-xs font-bold mb-4">
                What-if tool (change parameters & see impact on capital longevity
              </p>
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-1/2">
                      Your capital will last for X years (before depletion) and maintain the same withdrawal value in
                      real terms with the following parameters:
                    </th>
                    <th className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Your inputs
                    </th>
                    <th className="bg-[#f2b443] border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Fixed inputs
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Net Growth (after costs)
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2b443] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      5.00%
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Initial withdrawal rate
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2b443] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      6.75%
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Capital value multiple of withdrawal at retirement
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2b443] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      14.81
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">
                      Calculation - years to depletion
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2b443] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      25.05
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
          <div className="mb-1 h-[calc(100vh-240px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {selectChart.find((chart) => chart.slug === selectedChart)?.name}
                {formData.primary_representation && " - " + formData.primary_representation}
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-full relative">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </>
  );
};

export default RetirementTabTwo;
