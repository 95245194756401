import React from "react";

interface Option {
  value: string;
  label: string;
}

interface SelectToggleProps {
  title: string;
  selectedValue: string;
  options: Option[];
  loading?: boolean;
  handleChange: (value: string) => void;
}

const SelectToggle: React.FC<SelectToggleProps> = ({
  title,
  selectedValue,
  options,
  loading = false,
  handleChange,
}) => {
  return (
    <>
      <p className="mr-4 font-bold text-[16px]">{title}</p>
      <div className="bg-white h-[44px] rounded-md p-1 flex items-center">
        {options.map((option, index) => (
          <button
            key={option.value}
            className={`px-[18px] h-[36px] rounded text-[12px] min-w-[100px] hover:bg-[#555555] hover:text-white transition-all 
              ${loading ? "cursor-not-allowed" : ""}
              ${
                index !== options.length - 1 ? "mr-1" : ""
              } ${selectedValue === option.value ? "bg-[#555555] text-white" : "font-medium bg-[#f3f3f3]"}`}
            onClick={() => !loading && handleChange(option.value)}
            disabled={loading}
          >
            {option.label}
          </button>
        ))}
      </div>
    </>
  );
};

export default SelectToggle;
