import CommentaryLayout from "@/layouts/CommentaryLayout";
import image from "@assets/bg-image.jpg";
import PageTitle from "@elements/PageTitle";
import { IconContext } from "react-icons";
import { AiOutlineRead } from "react-icons/ai";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { FaArrowsRotate, FaRegChessQueen } from "react-icons/fa6";
import { GiLifeInTheBalance } from "react-icons/gi";
import { GoGraph } from "react-icons/go";
import { LuTable2, LuTableProperties } from "react-icons/lu";
import { PiTableLight } from "react-icons/pi";
import { TbLogs } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const CommentaryIndex = () => {
  const navigate = useNavigate();

  return (
    <CommentaryLayout
      showBackButton
      backButtonNavigateTo={"/home"}
      pageTitle={"Select your access to commetary, blogs, tables & tips"}
    >
      <PageTitle title="Commentary" />
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-cover bg-center bg-no-repeat w-full min-h-[calc(100vh-70px)] relative scrollbar overflow-y-auto"
      >
        <div className="w-full h-full min-h-[calc(100vh-70px)] relative flex items-center">
          <div className="mx-auto lg:px-[6%] md:px-10 xs:px-4 pb-4 pt-[20px]">
            <div className="grid grid-cols-3 gap-10">
              <div>
                <p className="text-[24px] text-center uppercase mb-4">Terminology & Concepts</p>
                <div className="grid gap-y-4">
                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/commentary/terminology-and-concepts?tab=1")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <GoGraph />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">Investment terms</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/commentary/terminology-and-concepts?tab=3")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaArrowsRotate />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            The impact of costs on investment returns
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/commentary/terminology-and-concepts?tab=2")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <BsGraphUpArrow />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">Compound interest & “the rule of 72”</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-[24px] text-center uppercase mb-4">Performance tables</p>
                <div className="grid gap-y-4">
                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/commentary/performance-tables-and-links?tab=1")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <PiTableLight />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">Performance tables - market metrics</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/commentary/performance-tables-and-links?tab=2")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <LuTable2 />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Performance tables - select domestic unit trusts & ETF’s
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/commentary/performance-tables-and-links?tab=3")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <LuTableProperties />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">
                            Performance tables - select international units trusts & ETF’s
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-[24px] text-center uppercase mb-4">Lifestyle tips</p>
                <div className="grid gap-y-4">
                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/commentary/lifestyle-tips?tab=1")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <FaRegChessQueen />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">Investment approach</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/commentary/lifestyle-tips?tab=3")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <AiOutlineRead />
                            </IconContext.Provider>
                          </div>
                        </div>
                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">Recommended readings</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                    onClick={() => navigate("/commentary/lifestyle-tips?tab=2")}
                  >
                    <div className="rounded-[8px] p-3 h-full grid items-center relative">
                      <div className="flex items-center relative">
                        <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                          <div className="flex justify-center items-center h-full">
                            <IconContext.Provider
                              value={{
                                color: "#333",
                                className: "text-[25px]",
                              }}
                            >
                              <GiLifeInTheBalance />
                            </IconContext.Provider>
                          </div>
                        </div>

                        <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                        <div>
                          <p className="text-left pl-3 text-[14px] font-bold">19 rules for life</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-3">
                <div>
                  <p className="text-[24px] text-center uppercase mb-4">Expectations & commentary</p>
                  <div className="grid grid-cols-2 gap-y-4 gap-x-10 ">
                    <div
                      className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                      onClick={() => navigate("/commentary/expectations-and-commentary?tab=1")}
                    >
                      <div className="rounded-[8px] p-3 h-full grid items-center relative">
                        <div className="flex items-center relative">
                          <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                            <div className="flex justify-center items-center h-full">
                              <IconContext.Provider
                                value={{
                                  color: "#333",
                                  className: "text-[25px]",
                                }}
                              >
                                <FaUserTie />
                              </IconContext.Provider>
                            </div>
                          </div>

                          <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                          <div>
                            <p className="text-left pl-3 text-[14px] font-bold">
                              Investment expectations – professional commentary
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card bg-[#ffffff] shadow-[4px_4px_8px_#b8b9be,-4px_-4px_8px_#d1d1d185] border-[.0625rem] border-[#d1d9e6] rounded-[8px] cursor-pointer hover:shadow-[inset_2px_2px_5px_#b8b9be,inset_-2px_-2px_7px_#fff] transition-all"
                      onClick={() => navigate("/commentary/expectations-and-commentary?tab=2")}
                    >
                      <div className="rounded-[8px] p-3 h-full grid items-center relative">
                        <div className="flex items-center relative">
                          <div className="z-10 w-[80px] min-w-[80px] h-[70px] bg-[#f2e1c3] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]">
                            <div className="flex justify-center items-center h-full">
                              <IconContext.Provider
                                value={{
                                  color: "#333",
                                  className: "text-[25px]",
                                }}
                              >
                                <TbLogs />
                              </IconContext.Provider>
                            </div>
                          </div>

                          <div className="absolute left-[-1px] w-[82px] min-w-[82px] h-[72px] bg-[#333] [clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]"></div>
                          <div>
                            <p className="text-left pl-3 text-[14px] font-bold">Curated blogs</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommentaryLayout>
  );
};

export default CommentaryIndex;
