import words from "@/services/algorithms/words";
import { CategoryData } from "./VehicleTable";

type InputType = "date" | "currency" | "select" | "number" | "percentage" | "none";
type Variant = "green" | "red";

interface RowData {
  description: string;
  inputType: InputType;
  name: string;
  variant: Variant;
}

const createRow = (description: string, inputType: InputType, name: string, variant: Variant = "green"): RowData => ({
  description,
  inputType,
  name,
  variant,
});

const createCategory = (category: string, rows: RowData[]): CategoryData => ({
  category,
  rowSpan: rows.length,
  rows: rows.map((row) => ({
    description: row.description,
    inputs: row.inputType === "none" ? [] : [{ type: row.inputType, name: row.name, variant: row.variant }],
  })),
});

export const VehicleTableData = (formData: any): CategoryData[] => {
  const { capitalizeFirstLetter } = words();

  const assetCostRows: RowData[] = [
    createRow("Purchase date", "date", "vehicle_purchase_date"),
    createRow("Initial purchase price", "currency", "vehicle_initial_purchase_price"),
    createRow("Accessories", "currency", "accessories"),
    createRow("Initial deposit & trade in", "currency", "vehicle_deposit_and_trade_in"),
  ];

  const assetLiabilitiesRows: RowData[] = [
    createRow("Outstanding Lease / finance amount", "currency", "vehicle_outstanding_lease_finance_amount"),
    createRow("Payment frequency", "select", "vehicle_payment_frequency"),
    createRow("Outstanding term (months)", "number", "vehicle_outstanding_term"),
    createRow("Completion date", "date", "vehicle_ompletion_date", "red"),
    createRow("Interest rate", "percentage", "vehicle_interest_rate"),
    createRow("Monthly finance payments", "currency", "vehicle_monthly_finance_payments", "red"),
  ];

  // const assetRevenuesRows: RowData[] = [
  //   createRow("Property rental income", "none", "property_rental_income"),
  //   createRow("Rent payment frequency", "none", "rent_payment_frequency"),
  //   createRow("Rental annual real increase", "none", "rental_annual_real_increase"),
  //   createRow("Rental increase month", "none", "rental_increase_month"),
  // ];

  const assetPreservationRows: RowData[] = [
    createRow("Monthly insurance", "currency", "vehicle_monthly_insurance"),
    createRow("Insurance month review date", "number", "vehicle_insurance_month_review_date"),
    createRow(
      `${capitalizeFirstLetter(formData.primary_representation)} annual insurance increase`,
      "percentage",
      `vehicle_${formData.primary_representation}_annual_insurance_increase`,
      "red"
    ),
    createRow("Insurance rate", "percentage", "vehicle_insurance_rate", "red"),
    createRow("Annual maintenance costs", "currency", "vehicle_annual_maintenance_costs"),
    createRow("Maintenance month", "number", "vehicle_maintenance_month"),
    createRow("Monthly running costs", "currency", "vehicle_monthly_running_costs"),
    createRow(
      `${capitalizeFirstLetter(formData.primary_representation)} annual insurance increase in costs`,
      "percentage",
      `vehicle_${formData.primary_representation}_annual_insurance_increase_in_costs`,
      "red"
    ),
  ];

  const assetReplacementRows: RowData[] = [
    createRow(
      `Target value (${formData.primary_representation} annual escalation)`,
      "percentage",
      `vehicle_target_value_${formData.primary_representation}_annual_escalation`
    ),
    createRow(`Replacement cycle (months)`, "number", "vehicle_replacement_cycle"),
    createRow(`Long term (months)`, "number", "vehicle_long_term"),
    createRow(
      `${capitalizeFirstLetter(formData.primary_representation)} annual increase in costs`,
      "percentage",
      `vehicle_${formData.primary_representation}_annual_increase_in_costs`,
      "red"
    ),
  ];

  const valuations: RowData[] = [
    createRow("Current market value", "currency", "vehicle_current_market_value", "red"),
    createRow("Current replacement value", "currency", "vehicle_current_replacement_value", "red"),
  ];

  return [
    createCategory("Asset cost", assetCostRows),
    createCategory("Asset liabilities", assetLiabilitiesRows),
    createCategory("Asset preservation", assetPreservationRows),
    createCategory("Asset replacement", assetReplacementRows),
    createCategory("Valuations", valuations),
  ];
};

export default VehicleTableData;
