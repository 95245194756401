import PasswordEye from "@elements/PasswordEye";

const Password = ({ label, showPassword, name, id, required = false, value, onChange, togglePasswordVisibility }) => {
  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className="block mb-1.5 text-[12px] font-bold text-gray-900 "
        >
          {label} {required ? " *" : ""}
        </label>
      )}
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          name={name}
          id={id}
          className="px-4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-[12px] rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-1 "
          placeholder="••••••••"
          autoComplete="off"
          required={required}
          value={value}
          onChange={onChange}
        />
        <div
          className="absolute inset-y-0 right-0 flex items-center mr-2 cursor-pointer"
          onClick={togglePasswordVisibility}
        >
          <PasswordEye show={showPassword} />
        </div>
      </div>
    </>
  );
};

export default Password;
