import usePersonalInformation from "@api/User/usePersonalInformation";
import Button from "@form/Button";
import Error from "@form/Error";
import Input from "@form/Input";
import Select from "@form/Select";
import React from "react";

const PersonalInformation: React.FC = () => {
  const { formData, handleChange, handleIDChange, handleSubmit, idError, errors, genders, loading } =
    usePersonalInformation();

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="h-full relative pb-14"
      >
        <div className="mb-4">
          <h1 className="font-bold">Client information</h1>
        </div>
        <div className="mb-4">
          <Input
            label="First name"
            name="name"
            id="name"
            required
            value={formData.name}
            onChange={handleChange}
            variant="white"
            inLine={true}
          />
          <Error error={errors && errors.name} />
        </div>
        <div className="mb-4">
          <Input
            label="Last name"
            name="surname"
            id="surname"
            value={formData.surname}
            placeholder=""
            required
            onChange={handleChange}
            variant="white"
            inLine={true}
          />
          <Error error={errors && errors.surname} />
        </div>
        <div className="mb-4">
          <Input
            label="Identification number"
            name="id_number"
            id="id_number"
            required
            value={formData.id_number}
            onChange={handleIDChange}
            variant="white"
            inLine={true}
          />
          <Error error={idError} />
          <Error error={errors && errors.id_number} />
        </div>
        <div className="mb-4">
          <Input
            label="Contact / mobile number"
            type="number"
            name="mobile_number"
            id="mobile_number"
            required
            value={formData.mobile_number}
            onChange={handleChange}
            variant="white"
            inLine={true}
          />
          <Error error={errors && errors.mobile_number} />
        </div>
        <div className="mb-4">
          <Input
            label="Email address"
            type="email"
            name="email"
            id="email"
            required
            value={formData.email}
            onChange={handleChange}
            variant="white"
            inLine={true}
          />
          <Error error={errors && errors.email} />
        </div>
        <div className="mb-4">
          <Input
            label="Physical address"
            name="address"
            id="address"
            type="textarea"
            required
            value={formData.address}
            onChange={handleChange}
            variant="white"
            inLine={true}
          />
          <Error error={errors && errors.address} />
        </div>
        <div className="mb-4">
          <Select
            label="Biological gender (used for life expectancy)"
            defaultItem="Select Gender"
            id="gender_id"
            name="gender_id"
            value={formData.gender_id}
            onChange={handleChange}
            items={genders}
            variant="white"
            inLine={true}
          />
          <Error error={errors && errors.gender_id} />
        </div>
        <div className="mb-4">
          <Input
            label="Date of birth"
            type="date"
            name="date_of_birth"
            id="date_of_birth"
            required
            readOnly={true}
            disabled
            value={formData.date_of_birth || ""}
            onChange={handleChange}
            variant="red"
            inLine={true}
          />
          <Error error={errors && errors.date_of_birth} />
        </div>
        <div className="absolute bottom-0 w-full">
          <Button
            type="submit"
            loading={loading}
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default PersonalInformation;
