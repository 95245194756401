import { ApexOptions } from "apexcharts";
import { KeyboardEvent } from "react";

const helpers = () => {
  const convertChartSeries = (chartSeries: any): ApexAxisChartSeries => {
    return chartSeries.map((obj: any) => ({
      name: obj.name,
      data: Object.entries(obj.data).map(([key, value]: [string, any]) => ({
        x: key,
        y: parseFloat(value),
      })),
    }));
  };

  const chartOptionsDefault: ApexOptions = {
    chart: {
      type: "line",
      height: 500,
      width: undefined,
      toolbar: {
        show: false,
      },

      stacked: false,
      zoom: {
        enabled: true,
      },
      fontFamily: "Nunito Sans",
      foreColor: "#373d3f",
    },

    tooltip: {
      style: {
        fontFamily: "Nunito Sans",
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
      },
    },

    colors: [
      "#008FFB",
      "#00E396",
      "#feb019",
      "#ff455f",
      "#775dd0",
      "#80effe",
      "#0077B5",
      "#ff6384",
      "#c9cbcf",
      "#0057ff",
      "#00a9f4",
      "#2ccdc9",
      "#5e72e4",
    ],

    series: [],

    dataLabels: {
      enabled: false,
    },

    title: {
      text: "",
    },

    yaxis: {
      labels: {
        formatter: function (value) {
          // Check if value is a valid number
          if (typeof value === "number" && !isNaN(value)) {
            return "R" + value?.toLocaleString("en-ZA", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
          }
          // Return a default value or empty string for invalid values
          return "";
        },
      },
    },

    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: [],
    },

    grid: {
      show: true,
    },

    markers: {
      size: 0,
      strokeColors: "#fff",
      strokeWidth: 0,
      hover: {
        size: 4,
      },
    },

    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
  };

  const preventEnterPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      return;
    }
  };

  return {
    convertChartSeries,
    chartOptionsDefault,
    preventEnterPress,
  };
};

export default helpers;
