import Card from "@/components/Element/Card";
import SelectToggle from "@/components/Form/ToggleSwitch";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PreRetirement from "@/components/GrowthCostAssumptions/PreRetirement";
import Change from "@/services/utils/change";
import useLoanAffordabilityTool from "@api/Standard/Lifestyle/useLoanAffordabilityTool";
import ChartLoading from "@components/Element/ChartLoading";
import { CommaSeparated } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Currency from "@form/Currency";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { addMonths, endOfMonth } from "date-fns";
import { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { IoCloseCircleOutline } from "react-icons/io5";

export interface LoanDetail {
  name: string;
  inputType: "input" | "currency" | "percentage" | "date" | "none";
  variant?: "green" | "red";
  disabled?: boolean;
  value?: number | string | null;
}

const initialLoanDetails: LoanDetail[] = [
  {
    name: "outstanding_loan",
    inputType: "currency",
    variant: "green",
    value: 0,
  },
  {
    name: "outstanding_term",
    inputType: "input",
    variant: "green",
    value: 0,
  },
  {
    name: "completion_date",
    inputType: "date",
    variant: "red",
    disabled: true,
    value: "",
  },
  {
    name: "interest_rate",
    inputType: "percentage",
    variant: "green",
    value: 0,
  },
  {
    name: "after_tax_monthly_payments",
    inputType: "currency",
    variant: "red",
    disabled: true,
    value: 0,
  },
  {
    name: "pre_tax_equivalent",
    inputType: "currency",
    variant: "red",
    disabled: true,
    value: 0,
  },
];

const initialTotals: LoanDetail[] = [
  {
    name: "total_outstanding_loan",
    value: 0,
    inputType: "currency",
    variant: "red",
    disabled: true,
  },
  {
    name: "total_outstanding_term",
    value: 0,
    inputType: "input",
    variant: "red",
    disabled: true,
  },
  {
    name: "total_completion_date",
    value: 0,
    inputType: "date",
    variant: "red",
    disabled: true,
  },
  { name: "total_interest_rate", value: null, inputType: "none" },
  { name: "total_after_tax_monthly_payments", value: 0, inputType: "currency", variant: "red", disabled: true },
  { name: "total_pre_tax_equivalent", value: 0, inputType: "currency", variant: "red", disabled: true },
];

function LoanAffordability() {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();
  const { preventEnterPress } = helpers();

  const {
    loading,
    formData,
    handleSubmit,
    chartSeries,
    chartOptions,
    handleNominalChange,
    handleRealChange,
    setFormData,
  } = useLoanAffordabilityTool();

  const {
    handleChange,
    handleCurrencyChange,
    handleDecimalChange,
    handlePrimaryRepresentationChange,
    handleFundSourceChange,
  } = Change(formData, setFormData);

  const rowNames: string[] = [
    "Outstanding loan",
    "Outstanding term",
    "Completion date",
    "Interest rate",
    "After tax monthly payments",
    "Pre tax equivalent",
  ];

  // States
  const [columns, setColumns] = useState<LoanDetail[][]>([initialLoanDetails]);
  const prevColumnsRef = useRef<LoanDetail[][]>(columns);
  const [totals, setTotals] = useState<LoanDetail[]>(initialTotals);
  const [showHelpPopup] = useState();

  // Calculation functions
  const calculateAfterTaxMonthlyPayments = (
    annualRate: number,
    numberOfPayments: number,
    loanAmount: number
  ): number => {
    const monthlyRate = annualRate / 100 / 12;
    const monthlyPayment = (monthlyRate * loanAmount) / (1 - Math.pow(1 + monthlyRate, -numberOfPayments));
    return isNaN(monthlyPayment) ? 0 : monthlyPayment;
  };

  const calculatePreTaxEquivalent = (afterTaxMonthlyPayments: number): number => {
    const preTaxEquivalent = afterTaxMonthlyPayments / (1 - formData.tax_rate / 100);
    return isNaN(preTaxEquivalent) ? 0 : preTaxEquivalent;
  };

  const calculateTotal = (columns: LoanDetail[][], name: string) =>
    columns.reduce((acc, col) => {
      const item = col.find((item) => item.name === name);
      const value = item ? Number(item.value) : 0;
      return acc + (isNaN(value) ? 0 : value);
    }, 0);

  const calculateMax = (columns: LoanDetail[][], name: string) =>
    columns.reduce((max, col) => {
      const item = col.find((item) => item.name === name);
      const value = item ? Number(item.value) : 0;
      return value > max ? value : max;
    }, 0);

  const calculateMaxDate = (columns: LoanDetail[][], name: string) =>
    columns.reduce((max, col) => {
      const item = col.find((item) => item.name === name);
      const value = item && item.value ? new Date(item.value).getTime() : 0;
      return value > max ? value : max;
    }, 0);

  // Change functions
  const handleColChange = (event: any, colIndex: number, name: string) => {
    let value = event.target?.value !== undefined ? event.target.value : event;

    setColumns((prevColumns) => {
      const newColumns = prevColumns.map((col, index) => {
        if (index === colIndex) {
          return col.map((item) => {
            if (item.name === name) {
              return {
                ...item,
                value: value,
              };
            }
            return item;
          });
        }
        return col;
      });

      return updateAllCols(newColumns);
    });
  };

  const updateAllCols = (columns: LoanDetail[][]) => {
    return columns.map((col) => {
      return col.map((c) => {
        const months = col.find((item) => item.name === "outstanding_term")?.value;
        const interestRate = col.find((item) => item.name === "interest_rate")?.value;
        const outstandingTerm = col.find((item) => item.name === "outstanding_term")?.value;
        const loanAmount = col.find((item) => item.name === "outstanding_loan")?.value;
        const afterTaxMonthlyPayments = col.find((item) => item.name === "after_tax_monthly_payments")?.value;

        let updatedValue = c.value;

        if (c.name === "completion_date") {
          const effectiveDate = new Date(formData.effective_date);
          if (isNaN(effectiveDate.getTime())) {
            console.error("Invalid date format:", formData.effective_date);
          } else {
            const futureDate = addMonths(effectiveDate, Number(months));
            updatedValue = endOfMonth(futureDate).toISOString().split("T")[0];
          }
        } else if (c.name === "after_tax_monthly_payments") {
          updatedValue = calculateAfterTaxMonthlyPayments(
            Number(interestRate),
            Number(outstandingTerm),
            Number(loanAmount)
          );
        } else if (c.name === "pre_tax_equivalent") {
          updatedValue = calculatePreTaxEquivalent(Number(afterTaxMonthlyPayments));
        }

        if (updatedValue !== c.value) {
          return { ...c, value: updatedValue };
        }

        return c;
      });
    });
  };

  // Add and Remove columns
  const addColumn = () => {
    setColumns((prevColumns) => {
      const newColumns = [...prevColumns, initialLoanDetails];
      return updateAllCols(newColumns);
    });
  };

  const removeColumn = (index: number) => {
    setColumns((prevColumns) => {
      const newColumns = prevColumns.filter((_, i) => i !== index);
      return updateAllCols(newColumns);
    });
  };

  // helper to check if two arrays are equal
  // can probably be moved to a different file if needed elsewhere
  const isEqual = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  // USE_EFFECTS

  useEffect(() => {
    setTimeout(() => {
      if (!loading) {
        // Update columns when column values change
        const prevColumns = prevColumnsRef.current;
        if (!isEqual(prevColumns, columns)) {
          const updatedCols = updateAllCols(columns);
          if (!isEqual(updatedCols, columns)) {
            setColumns(updatedCols);
            prevColumnsRef.current = updatedCols;
          }
        }

        const totalOutstandingLoan = calculateTotal(columns, "outstanding_loan");
        const highestOutstandingTerm = calculateMax(columns, "outstanding_term");
        const highestCompletionDate = calculateMaxDate(columns, "completion_date");
        const formattedCompletionDate = new Date(highestCompletionDate).toISOString().slice(0, 10);
        const totalAfterTaxMonthlyPayments = calculateTotal(columns, "after_tax_monthly_payments");
        const totalPreTaxEquivilent = calculateTotal(columns, "pre_tax_equivalent");

        // Update totals when columns change
        setTotals((prevTotals) =>
          prevTotals.map((total) => {
            switch (total.name) {
              case "total_outstanding_loan":
                return { ...total, value: totalOutstandingLoan };
              case "total_outstanding_term":
                return { ...total, value: highestOutstandingTerm };
              case "total_completion_date":
                return { ...total, value: formattedCompletionDate };
              case "total_after_tax_monthly_payments":
                return { ...total, value: totalAfterTaxMonthlyPayments };
              case "total_pre_tax_equivalent":
                return { ...total, value: totalPreTaxEquivilent };
              default:
                return total;
            }
          })
        );

        // Update formData when columns change
        setFormData((prevFormData) => ({
          ...prevFormData,
          loan_values: columns,
        }));
      }
    }, 100);
  }, [columns]);

  // Update columns when tax rate changes
  useEffect(() => {
    setTimeout(() => {
      if (!loading) {
        const updatedCols = updateAllCols(columns);
        setColumns(updatedCols);
      }
    }, 100);
  }, [formData.tax_rate]);

  // Update formData when totals change
  useEffect(() => {
    setTimeout(() => {
      if (!loading) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          loan_totals: totals,
        }));
      }
    }, 100);
  }, [totals]);

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is designed to demonstrate the impact of taking out a loan / multiple loans on your retirement
              or investments capital over time, as well as the reduction in your current disposable income. Inputs
              required are:
              <ul className="pl-4">
                <li className="my-1">
                  a) Your loan details including outstanding amounts, outstanding terms, interest rates and payments. We
                  use the payments (made with after-tax money) to determine the impact on disposable income.
                </li>
                <li className="my-1">b) Specific inputs include annual income and tax rate (default available)</li>
                <li className="my-1">
                  c) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              The long-term impact of these payments is calculated as follows:
              <ul className="pl-4">
                <li className="my-1">
                  a) If the total of the after-tax loan repayment had been invested, this would have generated
                  investment capital over time. We calculate the projected amounts at different growth rates to
                  retirement. This represents a reduction in final <strong>investment</strong> capital.
                </li>
                <li className="my-1">
                  b) If the total of the equivalent pre-tax loan repayment had been added to retirement contributions,
                  this would have generated additional retirement capital over time. We calculate the projected amounts
                  at different growth rates to retirement. This represents a reduction in final{" "}
                  <strong>retirement</strong> capital.
                </li>
                <li className="my-1">c) Note that the difference in the pot sizes is largely a function of tax.</li>
              </ul>
            </li>
            <li className="mb-2">
              The chart shows the overall reduction in the loans outstanding, the cumulative loan repayments as against
              lines showing the impact of investing the monies at different growth rates – note how small amounts in the
              beginning can become significant amounts over time as growth rate compound.
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Affordability calculator & the impact of loans"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Loan Affordability" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center gap-x-[50px]">
                <div className="flex items-center justify-center">
                  <SelectToggle
                    loading={loading}
                    title="Analysis type:"
                    selectedValue={formData?.primary_representation}
                    options={[
                      { value: "nominal", label: "Nominal" },
                      { value: "real", label: "Real" },
                    ]}
                    handleChange={handlePrimaryRepresentationChange}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <SelectToggle
                    loading={loading}
                    title="Select from fund source:"
                    selectedValue={formData?.fund_source_representation}
                    options={[
                      { value: "retirement", label: "Retirement" },
                      { value: "investment", label: "Investment" },
                    ]}
                    handleChange={handleFundSourceChange}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <Button
                    loading={loading}
                    type="submit"
                  >
                    Calculate
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] h-full scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
              </Card>
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible("GrowthTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="w-[34%]"></th>
                      <th className="text-[12px] w-[22%]">Low</th>
                      <th className="text-[12px] w-[22%]">Medium</th>
                      <th className="text-[12px] w-[22%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PreRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-2 items-end">
                  <div>
                    <Currency
                      label="Annual pre-tax income"
                      name="annual_pre_tax_income"
                      id="annual_pre_tax_income"
                      value={formData.annual_pre_tax_income}
                      onChange={handleCurrencyChange}
                      variant="green"
                    />
                  </div>
                  <div>
                    <Percentage
                      label="Tax rate"
                      name="tax_rate"
                      id="tax_rate"
                      value={formData.tax_rate}
                      onChange={handleCurrencyChange}
                      variant="red"
                      alignText="right"
                    />
                  </div>
                </div>
                <div className="overflow-x-auto mt-4">
                  <table className="table-auto border">
                    <thead className="bg-white border">
                      <tr className="">
                        <th className="sticky left-[-1px] top-0 z-10 bg-[#f1f1f1] text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[170px] w-[170px]">
                          Description
                        </th>
                        {columns.map((_, index) => (
                          <th
                            key={index}
                            className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] bg-[#f9f9f9] w-[130px]"
                          >
                            <div className="flex items-center justify-center gap-2">
                              Loan #{index + 1}
                              {index > 0 && (
                                <IoCloseCircleOutline
                                  onClick={() => removeColumn(index)}
                                  color="red"
                                  className="mr-1 cursor-pointer"
                                />
                              )}
                            </div>
                          </th>
                        ))}

                        <th className="bg-[#f1f1f1] text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[130px] w-[130px]">
                          TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rowNames.map((name, index1) => (
                        <tr key={index1}>
                          <td className="sticky left-[-1px] p-2 border border-r border-gray-300 z-10 text-xs bg-[#f1f1f1]">
                            {capitalizeFirstLetter(name.replace(/_/g, " "))}
                          </td>

                          {columns.map((col, index2) => (
                            <td
                              key={index2}
                              className="p-2 border border-gray-300"
                            >
                              {(() => {
                                switch (col[index1].inputType) {
                                  case "input":
                                    return (
                                      <Input
                                        type="number"
                                        name={col[index1].name}
                                        variant={col[index1].variant}
                                        disabled={col[index1].disabled}
                                        value={col[index1].value!}
                                        onChange={(e) => handleColChange(e, index2, col[index1].name)}
                                      />
                                    );
                                  case "currency":
                                    return (
                                      <Currency
                                        name={col[index1].name}
                                        variant={col[index1].variant}
                                        disabled={col[index1].disabled}
                                        value={col[index1].value}
                                        onChange={(e: string) => handleColChange(e, index2, col[index1].name)}
                                      />
                                    );
                                  case "percentage":
                                    return (
                                      <Percentage
                                        name={col[index1].name}
                                        variant={col[index1].variant}
                                        disabled={col[index1].disabled}
                                        value={col[index1].value}
                                        onChange={(value) => handleColChange(value, index2, col[index1].name)}
                                      />
                                    );
                                  case "date":
                                    return (
                                      <Input
                                        type="date"
                                        name={col[index1].name}
                                        variant={col[index1].variant}
                                        disabled={col[index1].disabled}
                                        value={col[index1].value!}
                                        onChange={(e) => handleColChange(e, index2, col[index1].name)}
                                      />
                                    );
                                  default:
                                    return null;
                                }
                              })()}
                            </td>
                          ))}
                          <td className="bg-[#f1f1f1] p-2 border border-gray-300">
                            {(() => {
                              switch (totals[index1].inputType) {
                                case "input":
                                  return (
                                    <Input
                                      type="number"
                                      name={totals[index1].name}
                                      variant={totals[index1].variant}
                                      disabled={totals[index1].disabled}
                                      value={totals[index1].value!}
                                    />
                                  );
                                case "currency":
                                  return (
                                    <Currency
                                      name={totals[index1].name}
                                      variant={totals[index1].variant}
                                      disabled={totals[index1].disabled}
                                      value={totals[index1].value}
                                    />
                                  );
                                case "percentage":
                                  return (
                                    <Percentage
                                      name={totals[index1].name}
                                      variant={totals[index1].variant}
                                      disabled={totals[index1].disabled}
                                      value={totals[index1].value}
                                      onChange={handleCurrencyChange}
                                    />
                                  );
                                case "date":
                                  return (
                                    <Input
                                      type="date"
                                      name={totals[index1].name}
                                      variant={totals[index1].variant}
                                      disabled={totals[index1].disabled}
                                      value={totals[index1].value!}
                                    />
                                  );
                                case "none":
                                  return null;
                                default:
                                  return null;
                              }
                            })()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <Button
                  onClick={addColumn}
                  name=""
                  className="mt-4 mb-2"
                >
                  Add loan
                </Button>
              </Card>

              <Card
                variant="white"
                className="mb-1"
              >
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="align-middle text-[12px] text-left font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443] w-[40%]">
                        Description of outcomes at different growth rates
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443] w-[20%]">
                        Low
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443] w-[20%]">
                        Medium
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Reduction in final retirement pot ({formData.primary_representation}) if you paid for loans by
                        reducing retirement contributions
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.final_retirement_pot_low}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.final_retirement_pot_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.final_retirement_pot_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Reduction in final investment pot ({formData.primary_representation}) if you paid for loans by
                        reducing investment contributions
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.final_investment_pot_low}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.final_investment_pot_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.final_investment_pot_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-4 text-xs font-medium">
                  * NB : note that retirement contributions are made with pre-tax monies, investments with after-tax
                  monies; both will be taxed on withdrawal but at different rates'
                </p>
              </Card>
            </div>
          </div>

          <div className="col-span-4 mb-1 min-h-[calc(100vh-175px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[18px] text-center mb-2">
                {capitalizeFirstLetter(formData.primary_representation)} opportunity costs of loans - reduction in
                capital
              </p>
              <p className="mr-8 font-medium text-[16px] text-center mb-4">
                repayments sourced from reduced{" "}
                {formData.fund_source_representation === "retirement"
                  ? "retirement contributions"
                  : "investment funding"}
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="h-full max-h-[calc(100vh-280px)] relative">
                    {chartSeries && (
                      <Chart
                        options={chartOptions as any}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
}

export default LoanAffordability;
