import History from "@/pages/Dashboard/History/History";
import Home from "@/pages/Dashboard/Home/Home";
import Logout from "@/pages/Dashboard/Logout/Logout";
import MyProfile from "@/pages/Dashboard/MyProfile/MyProfile";
import React from "react";
import { Outlet, RouteObject } from "react-router-dom";

const DashboardRoutes: React.FC = () => {
  return <Outlet />;
};

export const dashboardRoutes: RouteObject[] = [
  {
    path: "",
    element: <DashboardRoutes />,
    children: [
      { path: "home", element: <Home /> },
      { path: "my-profile", element: <MyProfile /> },
      { path: "history", element: <History /> },
      { path: "logout", element: <Logout /> },
    ],
  },
];

export default DashboardRoutes;
