import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import { ForgotPassword } from "@/pages/Auth/ForgotPassword/ForgotPassword";
import { Login } from "@/pages/Auth/Login/Login";
import { Register } from "@/pages/Auth/Register/Register";
import { ResetPassword } from "@/pages/Auth/ResetPassword/ResetPassword";

const AuthRoutes: React.FC = () => {
  return <Outlet />;
};

export const authRoutes: RouteObject[] = [
  { path: "", element: <Login /> },
  { path: "register", element: <Register /> },
  { path: "forgot-password", element: <ForgotPassword /> },
  { path: "reset-password/:token", element: <ResetPassword /> },
];

export default AuthRoutes;
