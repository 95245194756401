import SelectToggle from "@/components/Form/ToggleSwitch";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PostRetirement from "@/components/GrowthCostAssumptions/PostRetirement";
import useRetirementNeedTool from "@api/Standard/Retirement/useRetirementNeedTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import { CommaSeparated, Decimal } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import Change from "@utils/change";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";

const RetirementNeed = () => {
  const { preventEnterPress } = helpers();
  const { isVisible, toggleVisibility } = useVisibility();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    setFormData,
    handleSubmit,
    handleNominalChange,
    handleRealChange,
  } = useRetirementNeedTool();

  const { type, handleChange, handleCurrencyChange, handleDecimalChange, handlePrimaryRepresentationChange } = Change(
    formData,
    setFormData
  );

  const [showHelpPopup, _] = useState();

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool determines how much money you need to retire with to maintain your standard of living (SOL)
              post-retirement. To calculate this we need the following information:
              <ul className="pl-4">
                <li className="my-1">
                  a) Your projected final income pre-retirement – this is a function of your current income, how much
                  you expect it to increase annually until retirement, and your time to retirement.
                </li>
                <ul className="my-1">
                  b) Your target SOL in retirement – this is a percentage of your projected final income. Typically this
                  number is around 80% due to
                  <ul className="pl-4">
                    <li className="my-1">i) No further retirement or investment contributions</li>
                    <li className="my-1">ii) Lower tax rates post age 65 & 75 (higher rebates)</li>
                    <li className="my-1">iii) This should be offset by elevated medical costs</li>
                  </ul>
                  <li className="my-1">
                    c) Expected period of retirement (defaults to 25 years) – how long you need to support yourself and
                    your beneficiaries in retirement
                  </li>
                  <li className="my-1">
                    d) The 3 standard net growth rates are used to estimate the performance of your capital.
                  </li>
                </ul>
              </ul>
            </li>
            <li className="mb-2">
              The output table shows
              <ul className="pl-4">
                <li className="my-1">a) a multiple of your target annual income at retirement</li>
                <li className="my-1">b) the projected amount in real & nominal terms</li>
                <li className="my-1">
                  c) Note that higher growth rates imply reduced requirements (although higher risk!)
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Chart is a choice of 6 representations (real & nominal at low/medium/high growth rates)
              <ul className="pl-4">
                <li className="my-1">
                  a) Real chart will have a histogram of flat withdrawals on x-axis; nominal will be increasing
                </li>
                <li className="my-1">
                  b) Real residual capital chart will decline to zero at end of Life expectancy period; nominal may
                  initially increase and then decline to zero over the same time period
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="How much money do I need in retirement?"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Retirement Need" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-7 gap-4 w-full p-4">
          <div className="col-span-7">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  loading={loading}
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="max-h-[calc(100vh-170px)] h-full scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-2`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible("GrowthTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="w-[34%]"></th>
                      <th className="text-[12px] w-[22%]">Low</th>
                      <th className="text-[12px] w-[22%]">Medium</th>
                      <th className="text-[12px] w-[22%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PostRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Smart tool specific inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="SpecificInputs"
                      isVisible={isVisible("SpecificInputs")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>

                {!isVisible("SpecificInputs") && (
                  <div className="grid grid-cols-2 gap-2 items-center mt-4">
                    <div className="col-span-1 col-start-1">
                      <Currency
                        label="Annual pre-tax income"
                        name="annual_pre_tax_income"
                        id="annual_pre_tax_income"
                        value={formData.annual_pre_tax_income}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>
                    <div className="col-span-1 col-start-1">
                      <Percentage
                        label={type ? "Real growth in income" : "Nominal growth in income"}
                        name={type ? "real_growth_in_income" : "nominal_growth_in_income"}
                        id={type ? "real_growth_in_income" : "nominal_growth_in_income"}
                        value={type ? formData.real_growth_in_income : formData.nominal_growth_in_income}
                        onChange={(value, name) =>
                          type ? handleRealChange(value, name) : handleNominalChange(value, name)
                        }
                        variant="green"
                      />
                    </div>

                    <div className="col-span-1 col-start-1">
                      <Percentage
                        label="Target income on retirement"
                        name="post_retirement_income_percentage"
                        id="post_retirement_income_percentage"
                        value={formData.post_retirement_income_percentage}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>
                  </div>
                )}
              </Card>

              <Card
                variant="white"
                className="mb-1"
              >
                <div className="mb-2">
                  <div className="grid grid-cols-4">
                    <div>
                      <h3 className="text-[16px] font-semibold">Output</h3>
                    </div>
                    <div className="col-span-2 text-right">
                      <p className="text-[12px] font-bold text-gray-900 mr-2 mt-1.5">Target income at retirement:</p>
                    </div>
                    <div>
                      <Currency
                        value={
                          type
                            ? formData.real_target_income_at_retirement
                            : formData.nominal_target_income_at_retirement
                        }
                        variant="red"
                        disabled
                      />
                    </div>
                  </div>
                  <p className="text-[12px] font-semibold mt-2">
                    The table below shows you what multiple of your adjusted final salary you need to retire on, your
                    projected final salary, and the value in todays' money / future money terms assuming different
                    growth rates in retirement and your Life expectancy
                  </p>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[40%]">
                        Description
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Required multiple
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal value={formData.required_multiple_low} />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal value={formData.required_multiple_medium} />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal value={formData.required_multiple_high} />
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Required value</td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={type ? formData.real_required_value_low : formData.nominal_required_value_low}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={type ? formData.real_required_value_medium : formData.nominal_required_value_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={type ? formData.real_required_value_high : formData.nominal_required_value_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4 mb-1 min-h-[calc(100vh-175px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                Retirement - {formData.primary_representation} withdrawals & residual capital
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-[calc(100vh-250px)] relative">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default RetirementNeed;
