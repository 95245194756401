import Card from "@/components/Element/Card";
import ChartLoading from "@/components/Element/ChartLoading";
import RadioFull from "@/components/Form/RadioFull";
import words from "@/services/algorithms/words";
import React, { ChangeEvent, useState } from "react";
import Chart from "react-apexcharts";

const selectChart = [
  {
    id: 1,
    name: "Investment Fund #1",
    slug: "investment-funds",
  },
  {
    id: 2,
    name: "Savings fund #2",
    slug: "savings-funds",
  },
  {
    id: 3,
    name: "Education fund",
    slug: "education-funds",
  },
  {
    id: 4,
    name: "Travel fund",
    slug: "travel-funds",
  },
  {
    id: 5,
    name: "Special savings",
    slug: "special-savings",
  },
  {
    id: 6,
    name: "Total fund assets",
    slug: "total-fund-assets",
  },
];

interface InvestmentTabTwoProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  formData: any;
  loading: boolean;
  chartSeries: any;
  chartOptions: any;
}

const InvestmentTabTwo: React.FC<InvestmentTabTwoProps> = ({
  handleSubmit,
  preventEnterPress,
  formData,
  loading,
  chartSeries,
  chartOptions,
}) => {
  const { capitalizeFirstLetter } = words();
  const [selectedChart, setSelectedChart] = useState<string>("");
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedChart(event.target.value);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
            <Card
              variant="white"
              className="mb-4"
            >
              <form onSubmit={handleSubmit}>
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-[14px] w-[30%]">Charts available</th>
                      <th className="text-[14px] w-[30%]">Full period</th>
                      <th
                        colSpan={2}
                        className="text-[14px] w-[40%]"
                      >
                        Contributions & withdrawals
                      </th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th className="text-[12px]">Pre-retirement</th>
                      <th className="text-[12px]">Post-retirement</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectChart.map((chart, index) => (
                      <tr key={`selectChart-${index}`}>
                        <td
                          className={`text-[12px] text-left ${index === selectChart.length - 1 ? "font-bold" : "font-semibold"}`}
                        >
                          {chart.name}
                        </td>
                        <td className="text-[12px] font-semibold">
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={selectedChart === chart.slug}
                            centerToggle
                          />
                        </td>
                        <td className="text-[12px] font-semibold">
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={false}
                            centerToggle
                          />
                        </td>
                        <td className="text-[12px] font-semibold">
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={false}
                            centerToggle
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>

              <p className="mt-4 text-[12px] font-bold">
                All charts indicate the value of capital accumulated through contributions and returns and the residual
                amounts remaining after the specified stream of withdrawals. Note that the indicated schedule of
                withdrawals can yield “lumpy” outcomes. Pre- and post-retirement charts are split to conform with
                retirement outcomes – note that financial investments will augment retirement funding.
              </p>
            </Card>

            <Card
              variant="white"
              className="mb-2"
            >
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[60%]">
                      Projections @ date
                    </th>
                    <th
                      colSpan={3}
                      className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]"
                    >
                      {capitalizeFirstLetter(formData.primary_representation)} cumulative values
                    </th>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[60%]">
                      Date: 30 April 2048(hardcoded for now)
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Capital</th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                      Contributions
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                      Withdrawals
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Investment Fund #1
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Savings fund #2</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Education fund</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Travel fund</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Special savings</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">Total fund assets</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
          <div className="mb-1 h-[calc(100vh-240px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {selectChart.find((chart) => chart.slug === selectedChart)?.name}
                {formData.primary_representation && " - " + formData.primary_representation}
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-full relative">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </>
  );
};

export default InvestmentTabTwo;
