import Card from "@/components/Element/Card";
import React from "react";
import WealthTable from "./tables/WealthTable";
import wealthTableData from "./tables/WealthTableData";

interface WealthTabOneProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
  formData: any;
}

const WealthTabOne: React.FC<WealthTabOneProps> = ({
  handleSubmit,
  handleChange,
  handleCurrencyChange,
  handleSelectChange,
  preventEnterPress,
  isVisible,
  toggleVisibility,
  formData,
}) => {
  const fixedRows = React.useMemo(() => wealthTableData(), []);

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
          <div className="w-full">
            <Card
              variant="white"
              className="mb-4"
            >
              <div className="mb-3">
                <h3 className="text-[16px] font-semibold">Wealth characteristics</h3>
              </div>
              <div className="overflow-x-auto space-y-8">
                <WealthTable
                  formData={formData}
                  handleCurrencyChange={handleCurrencyChange}
                  handleChange={handleChange}
                  handleSelectChange={handleSelectChange}
                  isVisible={isVisible}
                  toggleVisibility={toggleVisibility}
                  fixedRows={fixedRows}
                />
              </div>
            </Card>
          </div>
        </div>
      </form>
    </>
  );
};

export default WealthTabOne;
