import Commentary from "@/pages/Commentary";
import ExpectationsAndCommentaryIndex from "@/pages/Commentary/ExpectationsAndCommentary";
import LifestyleTipsIndex from "@/pages/Commentary/LifestyleTips";
import PerformanceTablesAndLinksIndex from "@/pages/Commentary/PerformanceTablesAndLinks";
import TerminologyAndExpectationsIndex from "@/pages/Commentary/TerminologyAndConcepts";
import React from "react";
import { Outlet, RouteObject } from "react-router-dom";

const CommentaryRoutes: React.FC = () => {
  return <Outlet />;
};

export const commentaryRoutes: RouteObject[] = [
  {
    path: "commentary",
    element: <CommentaryRoutes />,
    children: [
      { path: "", element: <Commentary /> },
      { path: "terminology-and-concepts", element: <TerminologyAndExpectationsIndex /> },
      { path: "lifestyle-tips", element: <LifestyleTipsIndex /> },
      { path: "performance-tables-and-links", element: <PerformanceTablesAndLinksIndex /> },
      { path: "expectations-and-commentary", element: <ExpectationsAndCommentaryIndex /> },
    ],
  },
];

export default CommentaryRoutes;
