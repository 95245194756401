import React, { useState } from "react";

type Tab = {
  id: string;
  label: string;
  content: React.ReactNode;
};

type HorizontalTabsProps = {
  tabs: Tab[];
  showArrows?: boolean;
};

const HorizontalTabs: React.FC<HorizontalTabsProps> = ({ tabs, showArrows }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].id);
  const [hoveredTab, setHoveredTab] = useState<string | null>(null);

  const handleMouseEnter = (tabId: string) => {
    setHoveredTab(tabId);
  };

  const handleMouseLeave = () => {
    setHoveredTab(null);
  };

  return (
    <div className="w-full">
      <div className="flex gap-1 text-center text-gray-500 bg-gray-300 rounded-lg p-1">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            onMouseEnter={() => handleMouseEnter(tab.id)}
            onMouseLeave={handleMouseLeave}
            className={`w-full flex-1 justify-center py-3 focus:outline-none text-black hover:bg-[#243665] hover:text-white rounded-lg shadow text-sm ${
              activeTab === tab.id ? "bg-[#243665] text-white" : "bg-white"
            } ${showArrows && (activeTab === tab.id || hoveredTab === tab.id) ? "tabArrow" : ""}`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="pt-4">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={activeTab === tab.id ? "block" : "hidden"}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalTabs;
