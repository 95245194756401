import toast from "react-hot-toast";
import React from "react";

interface FormData {
  [key: string]: any;
}

interface ChartData {
  years: number[];
}

export interface ApiResponse {
  data: ChartData;
}

type CalculateOutputFunction = (formData: FormData) => Promise<ApiResponse["data"]>;

const handleSubmitWithToast = async (
  e: React.FormEvent<HTMLFormElement>,
  formData: FormData,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<any>>,
  calculateOutput: CalculateOutputFunction,
  additionalSuccessHandler?: (data: ApiResponse["data"]) => void
) => {
  e.preventDefault();

  const promise = calculateOutput(formData);

  const loadingToastId = toast.loading("Requesting...");

  promise
    .then((data) => {
      toast.dismiss(loadingToastId);
      if (additionalSuccessHandler) {
        additionalSuccessHandler(data);
      }
    })
    .catch((error) => {
      toast.dismiss(loadingToastId);
      toast.error(error.message);
    })
    .finally(() => {
      setLoading(false);
    });

  setLoading(true);
  setError(null);
};

export default handleSubmitWithToast;
