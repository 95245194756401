import Percentage from "@form/Percentage";
import words from "@services/algorithms/words";
import { FC } from "react";

// types causing issues because inputs dont always send back events
// fix later
interface PreRetirementProps {
  formData: any;
  handleRealChange: (value: any, name: any) => void;
  handleNominalChange: (value: any, name: any) => void;
  handleDecimalChange: (value: any, name: any) => void;
  isVisible: any;
}

const PreRetirement: FC<PreRetirementProps> = ({
  formData,
  handleRealChange,
  handleNominalChange,
  handleDecimalChange,
  isVisible,
}) => {
  const { capitalizeFirstLetter } = words();
  return (
    <>
      {isVisible && (
        <>
          <tr>
            <td className="py-[4px] pr-1 text-[12px] font-bold leading-[15px]">
              {capitalizeFirstLetter(formData.primary_representation)} growth pre-retirement
            </td>
            <td className="py-[4px]">
              <Percentage
                name={`${formData.primary_representation == "real" ? "real_pre_retirement_capital_growth_low" : "nominal_pre_retirement_capital_growth_low"}`}
                id={`${formData.primary_representation == "real" ? "real_pre_retirement_capital_growth_low" : "nominal_pre_retirement_capital_growth_low"}`}
                value={
                  formData.primary_representation == "real"
                    ? formData.real_pre_retirement_capital_growth_low
                    : formData.nominal_pre_retirement_capital_growth_low
                }
                onChange={formData.primary_representation == "real" ? handleRealChange : handleNominalChange}
                variant="red"
              />
            </td>
            <td className="py-[4px]">
              <Percentage
                name={`${formData.primary_representation == "real" ? "real_pre_retirement_capital_growth_medium" : "nominal_pre_retirement_capital_growth_medium"}`}
                id={`${formData.primary_representation == "real" ? "real_pre_retirement_capital_growth_medium" : "nominal_pre_retirement_capital_growth_medium"}`}
                value={
                  formData.primary_representation == "real"
                    ? formData.real_pre_retirement_capital_growth_medium
                    : formData.nominal_pre_retirement_capital_growth_medium
                }
                onChange={formData.primary_representation == "real" ? handleRealChange : handleNominalChange}
                variant="red"
              />
            </td>
            <td className="py-[4px]">
              <Percentage
                name={`${formData.primary_representation == "real" ? "real_pre_retirement_capital_growth_high" : "nominal_pre_retirement_capital_growth_high"}`}
                id={`${formData.primary_representation == "real" ? "real_pre_retirement_capital_growth_high" : "nominal_pre_retirement_capital_growth_high"}`}
                value={
                  formData.primary_representation == "real"
                    ? formData.real_pre_retirement_capital_growth_high
                    : formData.nominal_pre_retirement_capital_growth_high
                }
                onChange={formData.primary_representation == "real" ? handleRealChange : handleNominalChange}
                variant="red"
              />
            </td>
          </tr>
          <tr>
            <td className="py-[4px] text-[12px] font-bold leading-[15px]">Annual costs</td>
            <td className="py-[4px]">
              <Percentage
                name="pre_retirement_costs"
                id="pre_retirement_costs"
                value={formData.pre_retirement_costs}
                onChange={handleDecimalChange}
                variant="red"
              />
            </td>
            <td className="py-[4px]">
              <Percentage
                name="pre_retirement_costs"
                id="pre_retirement_costs"
                value={formData.pre_retirement_costs}
                onChange={handleDecimalChange}
                variant="red"
              />
            </td>
            <td className="py-[4px]">
              <Percentage
                name="pre_retirement_costs"
                id="pre_retirement_costs"
                value={formData.pre_retirement_costs}
                onChange={handleDecimalChange}
                variant="red"
              />
            </td>
          </tr>
        </>
      )}
      <tr>
        <td className="py-[4px] text-[12px] font-bold leading-[15px]">Net growth pre-retirement</td>
        <td className="py-[4px]">
          <Percentage
            name={`${formData.primary_representation == "real" ? "real_pre_net_retirement_growth_low" : "nominal_pre_net_retirement_growth_low"}`}
            id={`${formData.primary_representation == "real" ? "real_pre_net_retirement_growth_low" : "nominal_pre_net_retirement_growth_low"}`}
            value={`${formData.primary_representation == "real" ? formData.real_pre_net_retirement_growth_low : formData.nominal_pre_net_retirement_growth_low}`}
            disabled
            variant="red"
          />
        </td>
        <td className="py-[4px]">
          <Percentage
            name={`${formData.primary_representation == "real" ? "real_pre_net_retirement_growth_medium" : "nominal_pre_net_retirement_growth_medium"}`}
            id={`${formData.primary_representation == "real" ? "real_pre_net_retirement_growth_medium" : "nominal_pre_net_retirement_growth_medium"}`}
            value={`${formData.primary_representation == "real" ? formData.real_pre_net_retirement_growth_medium : formData.nominal_pre_net_retirement_growth_medium}`}
            disabled
            variant="red"
          />
        </td>
        <td className="py-[4px]">
          <Percentage
            name={`${formData.primary_representation == "real" ? "real_pre_net_retirement_growth_high" : "nominal_pre_net_retirement_growth_high"}`}
            id={`${formData.primary_representation == "real" ? "real_pre_net_retirement_growth_high" : "nominal_pre_net_retirement_growth_high"}`}
            value={`${formData.primary_representation == "real" ? formData.real_pre_net_retirement_growth_high : formData.nominal_pre_net_retirement_growth_high}`}
            disabled
            variant="red"
          />
        </td>
      </tr>
    </>
  );
};

export default PreRetirement;
