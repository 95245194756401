import words from "@/services/algorithms/words";

export interface HeadingType {
  text: string | React.ReactNode;
  id: string;
  colSpan?: number;
}
const { capitalizeFirstLetter } = words();

export const expenditureTableHeadings = (formData: any): HeadingType[] => [
  { text: "Category", id: "category" },
  { text: "Description", id: "description" },
  { text: "Current amount", id: "currentAmount" },
  { text: "Review month", id: "reviewMonth" },
  { text: "Terminal month", id: "terminalMonth" },
  { text: `${capitalizeFirstLetter(formData?.primary_representation || "")} increase`, id: "increase" },
];

export const getTotalColumns = (formData: any) => expenditureTableHeadings(formData).length;

// Keep this for backward compatibility
export const totalColumns = 6; // This should match the number of items in expenditureTableHeadings
