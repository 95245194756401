import { addMonths, addYears, differenceInDays, endOfMonth, format, parse } from "date-fns";

const numbers = () => {
  const formatMobileNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, "");

    if (!numericValue) return "";

    return numericValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "+$1 $2 $3 $4");
  };

  const maskMobileNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, "");

    const maskPattern = /^(\d{0,2})(\d{0,3})(\d{0,4})$/;

    const maskedValue = numericValue.replace(maskPattern, (_match, p1: number, p2: number, p3: number) => {
      let maskedNumber = "";
      if (p1) maskedNumber += `+${p1}`;
      if (p2) maskedNumber += ` ${p2}`;
      if (p3) maskedNumber += ` ${p3}`;
      return maskedNumber;
    });

    return maskedValue;
  };

  const isValidSAIDNumber = (idNumber: string) => {
    // Check if the ID number is of correct length and format
    const idRegex = /^[0-9]{13}$/;
    if (!idRegex.test(idNumber)) {
      return false;
    }

    // Extract components from the ID number
    const year = parseInt(idNumber.substring(0, 2), 10);
    const yearPrefix = year > 10 ? "19" : "20";
    const birthYear = parseInt(yearPrefix + year);
    const birthDate = new Date(birthYear, 0, 1);
    const month = parseInt(idNumber.substring(2, 4), 10);
    const day = parseInt(idNumber.substring(4, 6), 10);
    const currentDate = new Date();

    // Calculate a date 150 years ago from the current date
    const thresholdDate = new Date();
    thresholdDate.setFullYear(currentDate.getFullYear() - 150);

    if (birthDate < thresholdDate || birthDate > currentDate) {
      return false;
    }

    // Validate the Date of birth
    if (month < 1 || month > 12 || day < 1 || day > 31) {
      return false;
    }

    if (idNumber.length < 13) {
      return false;
    }

    // Passed all validations
    return true;
  };

  const generateDateOfBirthFromID = (idNumber: string) => {
    const year = parseInt(idNumber.substring(0, 2), 10);
    const month = parseInt(idNumber.substring(2, 4), 10);
    const day = parseInt(idNumber.substring(4, 6), 10);
    const currentYear = new Date().getFullYear();

    const fullYear = year < currentYear % 100 ? 2000 + year : 1900 + year;

    return new Date(fullYear, month - 1, day + 1);
  };

  const calculateCurrentAge = (dateOfBirth: Date, effectiveDate: Date) => {
    const daysDifference = differenceInDays(effectiveDate, dateOfBirth);

    return daysDifference / 365.25;
  };

  function calculateYearsToRetirement(dateOfBirth: string, retirementAge: number, dateOfAnalysis: string): number {
    const birthDate = parse(dateOfBirth, "yyyy-MM-dd", new Date());
    const analysisDate = parse(dateOfAnalysis, "yyyy-MM-dd", new Date());
    const retirementDate = addYears(birthDate, retirementAge);

    const totalDaysToRetirement = differenceInDays(retirementDate, analysisDate);

    return totalDaysToRetirement / 365.25;
  }

  const calculateRetirementIncome = (
    annualIncome: number,
    effectiveDate: Date,
    retirementDate: Date,
    realGrowthInIncome: number,
    targetIncomeOnRetirement: number
  ) => {
    const start = endOfMonth(effectiveDate);
    const end = endOfMonth(retirementDate);

    const daysDifference = differenceInDays(end, start);
    const yearsToRetirement = daysDifference / 365.25;
    const growthRate = realGrowthInIncome / 100;
    const targetIncomePercentage = targetIncomeOnRetirement / 100;
    const growthFactor = Math.pow(1 + growthRate, yearsToRetirement);

    const retirementIncome = annualIncome * growthFactor * targetIncomePercentage;

    return parseInt(retirementIncome.toFixed(0));
  };

  const calculateRetirementDate = (dateOfBirth: number | Date, retirementAge: number | string): string => {
    const dob = new Date(dateOfBirth);
    const retirementAgeNumber = typeof retirementAge === "string" ? parseInt(retirementAge, 10) : retirementAge;

    const retirementDate = new Date(dob.getFullYear() + retirementAgeNumber, dob.getMonth(), dob.getDate(), 23, 59, 59);

    retirementDate.setMonth(retirementDate.getMonth() + 1, 1);
    retirementDate.setDate(retirementDate.getDate() - 1);

    return retirementDate.toISOString().split("T")[0];
  };

  const calculateRetirementLongevity = (retirementAge: any, lifeExpectancy: any) => {
    const retirementAgeParsed = parseInt(retirementAge, 10);
    const lifeExpectancyParsed = parseInt(lifeExpectancy, 10);

    const retirementLongevity = lifeExpectancyParsed - retirementAgeParsed;

    return parseInt(retirementLongevity.toFixed(0));
  };

  function calculateTargetDate(dateOfAnalysis: any, monthsToAdd: any) {
    const dateWithAddedMonths = addMonths(dateOfAnalysis, parseInt(monthsToAdd));

    const endOfMonthDate = endOfMonth(dateWithAddedMonths);

    if (endOfMonthDate != "Invalid Date") {
      return format(endOfMonthDate, "yyyy-MM-dd");
    }
    return "";
  }

  const calculateTaxYear = (val: string): string => {
    const date = new Date(val);
    const month = date.getMonth();
    const year = date.getFullYear();

    // index of march is 2
    if (month < 2) {
      return `${year}`;
    } else {
      return `${year + 1}`;
    }
  };

  const calculateEquivalentAfterTaxContribution = (amount: number, percentage: number): number => {
    const taxAmount = amount * (percentage / 100);
    const afterTaxAmount = amount - taxAmount;
    return afterTaxAmount;
  };

  const formatCurrency = (amount: number): string => {
    return `R${new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount)}`;
  };

  return {
    formatMobileNumber,
    maskMobileNumber,
    isValidSAIDNumber,
    generateDateOfBirthFromID,
    calculateCurrentAge,
    calculateYearsToRetirement,
    calculateRetirementDate,
    calculateRetirementLongevity,
    calculateRetirementIncome,
    calculateTargetDate,
    calculateTaxYear,
    calculateEquivalentAfterTaxContribution,
    formatCurrency,
  };
};

export default numbers;
