import numbers from "@services/algorithms/numbers";
import { useCallback, useState } from "react";
import { CurrencyInputProps } from "react-currency-input-field";

const growthRateHandler = (formData: any, setFormData: any) => {
  const [updating, setUpdating] = useState(false);
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementDate, calculateRetirementLongevity } =
    numbers();

  const calculateNominalGrowthRate = (realGrowth: number, inflation: number) => {
    let nominalGrowthRate = ((1 + realGrowth / 100) * (1 + inflation / 100) - 1) * 100;
    // return nominalGrowthRate;
    return parseFloat(nominalGrowthRate.toFixed(2));
  };

  const calculateRealGrowthRate = (nominalGrowth: number, inflation: number) => {
    let realGrowthRate = ((1 + nominalGrowth / 100) / (1 + inflation / 100) - 1) * 100;
    // return realGrowthRate;
    return parseFloat(realGrowthRate.toFixed(2));
  };

  const handleRealChange: CurrencyInputProps["onValueChange"] = (value, name) => {
    if (!updating && name) {
      const numericValue = value ? parseFloat(value) : 0;
      const nominalKey = name.replace("real", "nominal");
      const inflationRate = parseFloat(formData.projected_inflation_rate);

      setUpdating(true);
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [nominalKey]: calculateNominalGrowthRate(numericValue, inflationRate).toString(),
      }));
      setUpdating(false);
    }
  };

  const handleNominalChange: CurrencyInputProps["onValueChange"] = (value, name) => {
    if (!updating && name) {
      const numericValue = value ? parseFloat(value) : 0;
      const realKey = name.replace("nominal", "real");
      const inflationRate = parseFloat(formData.projected_inflation_rate);

      setUpdating(true);
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [realKey]: calculateRealGrowthRate(numericValue, inflationRate).toString(),
      }));
      setUpdating(false);
    }
  };

  function calculateFutureValue(targetIncome: number, inflationRate: number, yearsToRetirement: number): number {
    const futureValue = targetIncome * Math.pow(1 + inflationRate / 100, yearsToRetirement);

    return parseInt(futureValue.toFixed(0));
  }

  function calculatePresentValue(futureValue: number, inflationRate: number, yearsToRetirement: number): number {
    const presentValue = futureValue / Math.pow(1 + inflationRate / 100, yearsToRetirement);

    return parseInt(presentValue.toFixed(0));
  }

  const handleFutureChange = (value: any, name: any) => {
    if (!updating) {
      const realKey = name.replace("nominal", "real");
      setUpdating(true);
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [realKey]: calculatePresentValue(value, formData.projected_inflation_rate, formData.years_to_retirement),
      }));
      setUpdating(false);
    }
  };

  const handlePresentChange = (value: any, name: any) => {
    if (!updating) {
      const nominalKey = name.replace("real", "nominal");
      setUpdating(true);
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [nominalKey]: calculateFutureValue(value, formData.projected_inflation_rate, formData.years_to_retirement),
      }));
      setUpdating(false);
    }
  };

  const handlePresentCapitalChange = (value: any, name: any) => {
    if (!updating) {
      const key = name.replace("real", "nominal");

      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [key]: calculateFutureValue(value, formData.projected_inflation_rate, formData.horizon_years),
      }));
      setUpdating(false);
    }
  };
  const handleTargetCapitalChange = (value: any, name: any) => {
    if (!updating) {
      const key = name.replace("nominal", "real");

      setUpdating(true);
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [key]: calculatePresentValue(value, formData.projected_inflation_rate, formData.horizon_years),
      }));
      setUpdating(false);
    }
  };

  const genericInputsCalculations = useCallback(() => {
    if (formData.date_of_birth && formData.retirement_age) {
      const birthDate = formData.date_of_birth;
      const retirementAge = formData.retirement_age;
      const effectiveDate = formData.effective_date;
      const retirementDate = calculateRetirementDate(birthDate, retirementAge);

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        current_age: calculateCurrentAge(birthDate, effectiveDate),
        years_to_retirement: calculateYearsToRetirement(birthDate, retirementAge, effectiveDate),
        projected_retirement_date: retirementDate,
      }));

      if (!formData.retirement_longevity_custom) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          retirement_longevity: calculateRetirementLongevity(retirementAge, formData.life_expectancy),
        }));
      }
    }
  }, [formData.date_of_birth, formData.retirement_age, formData.life_expectancy, formData.effective_date]);

  const growthPreRetirementCalculations = useCallback(() => {
    const realGrowthLow = calculateRealGrowthRate(
      formData.real_pre_retirement_capital_growth_low,
      formData.pre_retirement_costs
    );
    const realGrowthMedium = calculateRealGrowthRate(
      formData.real_pre_retirement_capital_growth_medium,
      formData.pre_retirement_costs
    );

    const realGrowthHigh = calculateRealGrowthRate(
      formData.real_pre_retirement_capital_growth_high,
      formData.pre_retirement_costs
    );
    const nominalGrowthInIncome = calculateNominalGrowthRate(
      formData.real_growth_in_income,
      formData.projected_inflation_rate
    );
    const nominalGrowthLow = calculateRealGrowthRate(
      formData.nominal_pre_retirement_capital_growth_low,
      formData.pre_retirement_costs
    );
    const nominalGrowthMedium = calculateRealGrowthRate(
      formData.nominal_pre_retirement_capital_growth_medium,
      formData.pre_retirement_costs
    );
    const nominalGrowthHigh = calculateRealGrowthRate(
      formData.nominal_pre_retirement_capital_growth_high,
      formData.pre_retirement_costs
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_pre_net_retirement_growth_low: realGrowthLow,
      real_pre_net_retirement_growth_medium: realGrowthMedium,
      real_pre_net_retirement_growth_high: realGrowthHigh,
      nominal_pre_net_retirement_growth_low: nominalGrowthLow,
      nominal_pre_net_retirement_growth_medium: nominalGrowthMedium,
      nominal_pre_net_retirement_growth_high: nominalGrowthHigh,
      nominal_growth_in_income: nominalGrowthInIncome,
    }));
  }, [
    formData.primary_representation,
    formData.primary_representation === "real" ? formData.real_growth_in_income : null,
    formData.projected_inflation_rate,
    formData.real_pre_retirement_capital_growth_low,
    formData.real_pre_retirement_capital_growth_medium,
    formData.real_pre_retirement_capital_growth_high,
    formData.nominal_pre_retirement_capital_growth_low,
    formData.nominal_pre_retirement_capital_growth_medium,
    formData.nominal_pre_retirement_capital_growth_high,
    formData.pre_retirement_costs,
  ]);

  const growthPostRetirementCalculations = useCallback(() => {
    const realGrowthLow = calculateRealGrowthRate(
      formData.real_post_retirement_capital_growth_low,
      formData.post_retirement_costs
    );
    const realGrowthMedium = calculateRealGrowthRate(
      formData.real_post_retirement_capital_growth_medium,
      formData.post_retirement_costs
    );
    const realGrowthHigh = calculateRealGrowthRate(
      formData.real_post_retirement_capital_growth_high,
      formData.post_retirement_costs
    );
    const nominalPostRetirementGrowthLow = calculateNominalGrowthRate(
      formData.real_post_retirement_capital_growth_low,
      formData.projected_inflation_rate
    );
    const nominalPostRetirementGrowthMedium = calculateNominalGrowthRate(
      formData.real_post_retirement_capital_growth_medium,
      formData.projected_inflation_rate
    );
    const nominalPostRetirementGrowthHigh = calculateNominalGrowthRate(
      formData.real_post_retirement_capital_growth_high,
      formData.projected_inflation_rate
    );
    const nominalGrowthInIncome = calculateNominalGrowthRate(
      formData.real_growth_in_income,
      formData.projected_inflation_rate
    );
    const nominalGrowthLow = calculateRealGrowthRate(nominalPostRetirementGrowthLow, formData.post_retirement_costs);
    const nominalGrowthMedium = calculateRealGrowthRate(
      nominalPostRetirementGrowthMedium,
      formData.post_retirement_costs
    );
    const nominalGrowthHigh = calculateRealGrowthRate(nominalPostRetirementGrowthHigh, formData.post_retirement_costs);

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_post_net_retirement_growth_low: realGrowthLow,
      real_post_net_retirement_growth_medium: realGrowthMedium,
      real_post_net_retirement_growth_high: realGrowthHigh,
      nominal_post_net_retirement_growth_low: nominalGrowthLow,
      nominal_post_net_retirement_growth_medium: nominalGrowthMedium,
      nominal_post_net_retirement_growth_high: nominalGrowthHigh,
      nominal_growth_in_income: nominalGrowthInIncome,
    }));
  }, [
    formData.primary_representation,
    formData.projected_inflation_rate,
    formData.post_retirement_costs,
    formData.real_post_retirement_capital_growth_low,
    formData.real_post_retirement_capital_growth_medium,
    formData.real_post_retirement_capital_growth_high,
    formData.nominal_post_retirement_capital_growth_low,
    formData.nominal_post_retirement_capital_growth_medium,
    formData.nominal_post_retirement_capital_growth_high,
  ]);

  return {
    calculateNominalGrowthRate,
    calculateRealGrowthRate,
    handleNominalChange,
    handleRealChange,
    updating,
    setUpdating,
    calculateFutureValue,
    calculatePresentValue,
    handleFutureChange,
    handlePresentChange,
    handlePresentCapitalChange,
    handleTargetCapitalChange,
    genericInputsCalculations,
    growthPreRetirementCalculations,
    growthPostRetirementCalculations,
  };
};

export default growthRateHandler;
