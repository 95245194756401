import { useEffect, useState, useCallback } from "react";
import createAxiosInstance from "@api/Api";
import calculations from "@services/algorithms/calculations";
import helpers from "@utils/helpers";
import numbers from "@services/algorithms/numbers";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import useDebounce from "@hooks/useDebounce";
import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";

interface FormData {
  growth_rate: string;
  primary_representation: string;
  effective_date: string;
  projected_inflation_rate: number;
  date_of_birth: string;
  retirement_date: string;
  retirement_age: number;
  current_age: number;
  years_to_retirement: number;
  life_expectancy: number;
  retirement_longevity_custom: boolean;
  retirement_longevity: number;
  real_post_retirement_capital_growth_low: number;
  real_post_retirement_capital_growth_medium: number;
  real_post_retirement_capital_growth_high: number;
  nominal_post_retirement_capital_growth_low: number;
  nominal_post_retirement_capital_growth_medium: number;
  nominal_post_retirement_capital_growth_high: number;
  post_retirement_costs: number;
  real_post_net_retirement_growth_low: number;
  real_post_net_retirement_growth_medium: number;
  real_post_net_retirement_growth_high: number;
  nominal_post_net_retirement_growth_low: number;
  nominal_post_net_retirement_growth_medium: number;
  nominal_post_net_retirement_growth_high: number;
  annual_pre_tax_income: number;
  real_growth_in_income: number;
  nominal_growth_in_income: number;
  post_retirement_income_percentage: number;
  real_target_income_at_retirement: number;
  nominal_target_income_at_retirement: number;
  required_multiple_low: number;
  required_multiple_medium: number;
  required_multiple_high: number;
  real_required_value_low: number;
  real_required_value_medium: number;
  real_required_value_high: number;
  nominal_required_value_low: number;
  nominal_required_value_medium: number;
  nominal_required_value_high: number;
}

const useRetirementNeedTool = () => {
  const api = createAxiosInstance("standard/retirement/retirement-need");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const { chartOptionsDefault } = helpers();
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementLongevity } = numbers();
  const { calculateRequiredMultiple, calculateRequiredValue, calculateTargetIncome } = calculations();

  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState<FormData>({
    growth_rate: "Low",
    primary_representation: "",
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_date: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity_custom: false,
    retirement_longevity: 0,
    real_post_retirement_capital_growth_low: 0,
    real_post_retirement_capital_growth_medium: 0,
    real_post_retirement_capital_growth_high: 0,
    nominal_post_retirement_capital_growth_low: 0,
    nominal_post_retirement_capital_growth_medium: 0,
    nominal_post_retirement_capital_growth_high: 0,
    post_retirement_costs: 0,
    real_post_net_retirement_growth_low: 0,
    real_post_net_retirement_growth_medium: 0,
    real_post_net_retirement_growth_high: 0,
    nominal_post_net_retirement_growth_low: 0,
    nominal_post_net_retirement_growth_medium: 0,
    nominal_post_net_retirement_growth_high: 0,
    annual_pre_tax_income: 0,
    real_growth_in_income: 0,
    nominal_growth_in_income: 0,
    post_retirement_income_percentage: 0,
    real_target_income_at_retirement: 0,
    nominal_target_income_at_retirement: 0,
    required_multiple_low: 0,
    required_multiple_medium: 0,
    required_multiple_high: 0,
    real_required_value_low: 0,
    real_required_value_medium: 0,
    real_required_value_high: 0,
    nominal_required_value_low: 0,
    nominal_required_value_medium: 0,
    nominal_required_value_high: 0,
  });

  const debouncedData = useDebounce(formData, 100);

  const { handleNominalChange, handleRealChange, genericInputsCalculations, growthPostRetirementCalculations } =
    growthRateHandler(debouncedData, setFormData);

  const calculateAndSetValues = useCallback(() => {
    const realTargetIncome = calculateTargetIncome(
      debouncedData.annual_pre_tax_income,
      debouncedData.real_growth_in_income,
      debouncedData.years_to_retirement,
      debouncedData.post_retirement_income_percentage
    );
    const nominalTargetIncome = calculateTargetIncome(
      debouncedData.annual_pre_tax_income,
      debouncedData.nominal_growth_in_income,
      debouncedData.years_to_retirement,
      debouncedData.post_retirement_income_percentage
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      real_target_income_at_retirement: realTargetIncome,
      nominal_target_income_at_retirement: nominalTargetIncome,
      required_multiple_low: calculateRequiredMultiple(
        debouncedData.real_post_net_retirement_growth_low,
        debouncedData.retirement_longevity
      ),
      required_multiple_medium: calculateRequiredMultiple(
        debouncedData.real_post_net_retirement_growth_medium,
        debouncedData.retirement_longevity
      ),
      required_multiple_high: calculateRequiredMultiple(
        debouncedData.real_post_net_retirement_growth_high,
        debouncedData.retirement_longevity
      ),
      real_required_value_low: calculateRequiredValue(debouncedData.required_multiple_low, realTargetIncome),
      real_required_value_medium: calculateRequiredValue(debouncedData.required_multiple_medium, realTargetIncome),
      real_required_value_high: calculateRequiredValue(debouncedData.required_multiple_high, realTargetIncome),
      nominal_required_value_low: calculateRequiredValue(debouncedData.required_multiple_low, nominalTargetIncome),
      nominal_required_value_medium: calculateRequiredValue(
        debouncedData.required_multiple_medium,
        nominalTargetIncome
      ),
      nominal_required_value_high: calculateRequiredValue(debouncedData.required_multiple_high, nominalTargetIncome),
    }));
  }, [
    debouncedData.annual_pre_tax_income,
    debouncedData.real_growth_in_income,
    debouncedData.post_retirement_income_percentage,
    debouncedData.retirement_longevity,
    debouncedData.required_multiple_low,
    debouncedData.required_multiple_medium,
    debouncedData.required_multiple_high,
    debouncedData.nominal_growth_in_income,
    debouncedData.years_to_retirement,
  ]);

  useEffect(() => {
    calculateAndSetValues();
  }, [calculateAndSetValues]);
  useEffect(() => {
    genericInputsCalculations();
  }, [genericInputsCalculations]);
  useEffect(() => {
    growthPostRetirementCalculations();
  }, [growthPostRetirementCalculations]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: any) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      const { series, labels } = convertChartSeries(data.data);

      setChartSeries(series);

      setChartOptions((prevOptions: any) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
      }));
      return data;
    } catch (error) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        primary_representation: data.ufs.primary_representation || "",
        growth_rate: data.ufs.growth_rate || "",
        effective_date: data.ufs.effective_date || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || 0,
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || 0,
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || 0,
        retirement_date: data.ufs.retirement_date || "",
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || 0,
        life_expectancy: data.ufs.life_expectancy || 0,
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        retirement_longevity: data.ufs.retirement_longevity
          ? data.ufs.retirement_longevity
          : calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,
        post_retirement_costs: data.ufs.post_retirement_costs || 0,
        real_post_retirement_capital_growth_low: data.ufs.real_post_retirement_capital_growth_low || 0,
        real_post_retirement_capital_growth_medium: data.ufs.real_post_retirement_capital_growth_medium || 0,
        real_post_retirement_capital_growth_high: data.ufs.real_post_retirement_capital_growth_high || 0,
        nominal_post_retirement_capital_growth_low: data.ufs.nominal_post_retirement_capital_growth_low || 0,
        nominal_post_retirement_capital_growth_medium: data.ufs.nominal_post_retirement_capital_growth_medium || 0,
        nominal_post_retirement_capital_growth_high: data.ufs.nominal_post_retirement_capital_growth_high || 0,
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || 0,
        real_growth_in_income: data.ufs.real_growth_in_income || 0,
        nominal_growth_in_income: data.ufs.nominal_growth_in_income || 0,
        post_retirement_income_percentage: data.ufs.post_retirement_income_percentage || 0,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData: any) => {
    const years = Object.values(chartData.years);

    const annualWithdrawal = Object.values(chartData.annual_withdrawals).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const residualCapital = Object.values(chartData.residual_capitals).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const series = [
      {
        name: "Annual withdrawals",
        type: "column",
        color: "#ed7d31",
        data: annualWithdrawal,
      },
      {
        name: "Residual capital",
        type: "line",
        color: "#a5a5a5",
        data: residualCapital,
      },
    ];

    const labels = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Year " + year;
    });

    return { series, labels };
  };

  useEffect(() => {
    getStoredData();
  }, []);

  return {
    loading,
    error,
    formData,
    setFormData,
    handleSubmit,
    chartSeries,
    setChartSeries,
    chartOptions,
    setChartOptions,
    handleNominalChange,
    handleRealChange,
  };
};

export default useRetirementNeedTool;
