import useLogin from "@api/Auth/useLogin";
import appContext from "@context/app-context";
import Logo from "@elements/Logo";
import PageTitle from "@elements/PageTitle";
import VersionTag from "@elements/VersionTag";
import Button from "@form/Button";
import Input from "@form/Input";
import Password from "@form/Password";
import AuthLayout from "@layouts/AuthLayout";
import usePasswords from "@services/algorithms/usePasswords";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export const Login = () => {
  const { showPasswords, togglePasswordVisibility } = usePasswords();
  const { login, isLoading } = useContext(appContext);
  const { formData, handleChange, handleCheckboxChange } = useLogin();

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/home");
    }
  });

  return (
    <AuthLayout>
      <PageTitle title="Home" />

      <div className="w-full h-screen">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:min-h-screen pt:mt-0">
          <div className="w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow">
            <a
              href="/"
              className="flex items-center justify-center mb-8 lg:mb-10"
            >
              <Logo className="mr-4 h-24" />
            </a>
            <h2 className="text-2xl font-bold text-gray-900 text-center">Sign in</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                login(formData.email, formData.password, formData.remember);
              }}
              className="mt-8 space-y-6"
              action="#"
            >
              <div>
                <Input
                  label="Your email"
                  name="email"
                  id="email"
                  type="email"
                  placeholder="name@email.com"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  variant="white"
                  inLine={false}
                  alignLeft={true}
                />
              </div>
              <div>
                <Password
                  label="Your password"
                  showPassword={showPasswords.password}
                  name="password"
                  id="password"
                  required
                  value={formData.password}
                  onChange={handleChange}
                  togglePasswordVisibility={() => togglePasswordVisibility("password")}
                  variant="white"
                />
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    name="remember"
                    type="checkbox"
                    className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                    value={formData.remember}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="ml-3 text-[12px]">
                  <label
                    htmlFor="remember"
                    className="text-[12px] font-medium text-gray-500 hover:underline cursor-pointer"
                  >
                    Remember me
                  </label>
                </div>
                <div
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                  className="ml-auto text-[12px] font-medium text-gray-500 hover:underline cursor-pointer"
                >
                  Forgot your password?
                </div>
              </div>

              <Button
                type="submit"
                loading={isLoading}
                className={twMerge("min-w-[175px]")}
              >
                Login to your account
              </Button>

              <div className="text-[12px] font-medium text-gray-500">
                Not registered?{" "}
                <div
                  onClick={() => {
                    navigate("/register");
                  }}
                  className="text-primary-700 hover:underline cursor-pointer inline-block"
                >
                  Create account
                </div>
              </div>
            </form>
            <VersionTag />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
