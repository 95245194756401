import { HomeDescription } from "@/components/HomeDescription";
import useHome from "@api/Home/useHome";
import image from "@assets/bg-image.jpg";
import HexShapes from "@components/Layout/HexShapes";
import Links from "@components/Links";
import GeneralInformation from "@components/User/GeneralInformation";
import Costings from "@components/Welcome/Costings";
import Stepper from "@components/Welcome/Stepper";
import PageTitle from "@elements/PageTitle";
import Button from "@form/Button";
import DashboardLayout from "@layouts/DashboardLayout";
import Modal from "@modals/Modal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { twMerge } from "tailwind-merge";

const Home: React.FC = () => {
  const { welcomeModal, closeWelcomeModal, smartToolClick, premiumToolClick, modal, closeModal } = useHome();
  const navigate = useNavigate();
  const [linksModal, setLinksModal] = useState<boolean>(false);
  const [descriptionModal, setDescriptionModal] = useState<boolean>(false);
  const [showCostingsModal, setShowCostingsModal] = useState<boolean>(false);

  const closeLinksModal = (): void => setLinksModal(false);
  const closeCostingsModal = (): void => setShowCostingsModal(false);
  const closeDescriptionModal = (): void => setDescriptionModal(false);

  const showCostings = (): void => {
    setShowCostingsModal(true);
  };

  return (
    <DashboardLayout
      pageTitle="Home"
      modalContent={null}
    >
      <PageTitle title="Home" />
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-cover bg-center bg-no-repeat w-full h-full"
      >
        <div className="container mx-auto min-h-[calc(100vh-70px)] relative">
          <div className="h-full min-h-[calc(100vh-70px)] absolute left-4 grid content-center gap-4">
            <Button
              type="button"
              className={twMerge(
                "min-w-[150px] h-[44px] py-0 border-2 border-[#243665] bg-white text-[#243665_!important] font-[bold_!important] text-[16px]"
              )}
              onClick={() => {
                navigate("/my-profile");
              }}
            >
              My Profile
            </Button>
            <Button
              type="button"
              className={twMerge(
                "min-w-[150px] h-[44px] py-0 border-2 border-[#243665] bg-white text-[#243665_!important] font-[bold_!important] text-[16px]"
              )}
              onClick={() => {
                setDescriptionModal(true);
              }}
            >
              Description
            </Button>
            <Button
              type="button"
              className={twMerge(
                "min-w-[150px] h-[44px] py-0 border-2 border-[#243665] bg-white text-[#243665_!important] font-[bold_!important] text-[16px]"
              )}
              onClick={() => {
                setLinksModal(true);
              }}
            >
              Links
            </Button>
          </div>
          <div className="m-0 absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
            <HexShapes
              parentClassName={twMerge("flex items-center justify-center")}
              showCostings={showCostings}
              smartToolClick={smartToolClick}
              premiumToolClick={premiumToolClick}
            />
          </div>
          <div className="h-full min-h-[calc(100vh-70px)] absolute right-4 grid content-center gap-4">
            <div className="bg-[#f2e1c3] px-4 py-2 border-2 rounded-md border-[orange] min-w-[150px] text-center">
              Standard
            </div>
            <div className="bg-[#85322e] px-4 py-2 border-2 rounded-md border-[#00385a] text-white min-w-[150px] text-center">
              Premium
            </div>
            <div className="bg-[#00385a] px-4 py-2 border-2 rounded-md border-[#000] text-white min-w-[150px] text-center">
              Advice
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={welcomeModal}
        closeModal={closeWelcomeModal}
        modalContent={<Stepper closeModal={closeWelcomeModal} />}
      />
      <Modal
        show={modal}
        closeModal={closeModal}
        modalContainerPadding={twMerge("pr-3")}
        modalContent={<GeneralInformation includeIdNumber={true} />}
      />
      <Modal
        show={linksModal}
        closeModal={closeLinksModal}
        modalContainerPadding={twMerge("py-[50px] w-[60vw_!important] pb-[1rem]")}
        modalContent={<Links />}
      />
      <Modal
        show={descriptionModal}
        closeModal={closeDescriptionModal}
        modalContainerPadding={twMerge("py-[50px] w-[80vw_!important] pb-[1rem]")}
        modalContent={<HomeDescription />}
      />
      <Modal
        show={showCostingsModal}
        closeModal={closeCostingsModal}
        modalContent={<Costings />}
      />
    </DashboardLayout>
  );
};

export default Home;
