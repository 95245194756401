import Button from "@/components/Form/Button";
import GrowthRate from "@/components/Form/GrowthRate";
import PercentageSwitch from "@/components/Form/PercentageSwitch";
import Select from "@/components/Form/Select";
import SelectToggle from "@/components/Form/ToggleSwitch";
import words from "@/services/algorithms/words";
import useLifestyleTools from "@api/Standard/Lifestyle/useBuyVsRentTool";
import Card from "@components/Element/Card";
import VisibilityToggle from "@elements/VisibilityToggle";
import Currency from "@form/Currency";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import Chart from "react-apexcharts";

const homeType = [
  {
    name: "Primary Home",
  },
  {
    name: "Secondary Home",
  },
];

const BuyVsRentToolTwo = () => {
  const { isVisible, toggleVisibility } = useVisibility();
  const { chartOptionsDefault, preventEnterPress } = helpers();
  const { capitalizeFirstLetter } = words();

  const {
    loading,
    chartSeries,
    formData,
    handleSubmit,
    handleChange,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
  } = useLifestyleTools();

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit}
          onKeyDown={preventEnterPress}
          className="lg:w-[90%] mx-auto h-full"
        >
          <div className="grid grid-cols-7 gap-4 w-full min-h-full px-4 pt-4">
            <div className="col-span-7">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center">
                  <SelectToggle
                    loading={loading}
                    title="Analysis type:"
                    selectedValue={formData?.primary_representation}
                    options={[
                      { value: "nominal", label: "Nominal" },
                      { value: "real", label: "Real" },
                    ]}
                    handleChange={handlePrimaryRepresentationChange}
                  />
                </div>
                <div className="flex items-center justify-center ml-10">
                  <GrowthRate
                    loading={loading}
                    riskLevel={formData.growth_rate}
                    handleChange={handleChange}
                  />
                </div>
                <div className="flex items-center justify-center ml-10">
                  <Button
                    loading={loading}
                    type="submit"
                  >
                    Calculate
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <div className="max-h-[calc(100vh-220px)] scrollbar overflow-y-auto overflow-x-hidden">
                <Card
                  variant="white"
                  className="mb-4"
                >
                  <div className={`flex items-center mb-4`}>
                    <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                    <span className="ml-4 cursor-pointer">
                      <VisibilityToggle
                        keyName="GenericTable"
                        isVisible={isVisible("GenericTable")}
                        toggleVisibility={toggleVisibility}
                      />
                    </span>
                  </div>
                  <div className="grid grid-cols-2 gap-2 items-center">
                    <div>
                      <Input
                        label="Date of analysis"
                        name="effective_date"
                        id="effective_date"
                        type="date"
                        value={formData.effective_date}
                        onChange={handleChange}
                        variant="red"
                      />
                    </div>
                    {isVisible("GenericTable") && (
                      <>
                        <div>
                          <Percentage
                            label="CPI inflation"
                            name="projected_inflation_rate"
                            id="projected_inflation_rate"
                            value={formData.projected_inflation_rate}
                            onChange={handleCurrencyChange}
                            variant="red"
                            alignText="right"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Card>

                <div className="col-span-4 mb-4">
                  <div className="bg-white rounded-md">
                    <div className="rounded-t-md p-4">
                      <h3 className="text-[16px] font-semibold">Home purchase description</h3>
                    </div>
                    <div className="pl-4 pb-4 w-1/2">
                      <div className="border border-[#999]">
                        <div className="mb-0.5">
                          <div className={`flex items-center bg-[#C3CBBE] justify-between`}>
                            <p className="text-[12px] font-bold p-2">Home purchase parameters</p>
                            <span className="ml-4 p-2 cursor-pointer">
                              <VisibilityToggle
                                keyName="purchase_params"
                                isVisible={isVisible("purchase_params")}
                                toggleVisibility={toggleVisibility}
                              />
                            </span>
                          </div>
                          {isVisible("purchase_params") && (
                            <table className="table-auto w-full">
                              <tbody>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Monthly income after tax
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      label=""
                                      name=""
                                      id=""
                                      onChange={handleCurrencyChange}
                                      variant="green"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Primary / secondary home
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Select
                                      label=""
                                      defaultItem="Select Primary / secondary home"
                                      id=""
                                      name=""
                                      onChange={handleChange}
                                      variant="green"
                                      items={homeType}
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Annual property appreciation
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Percentage
                                      label=""
                                      name="annual_property_appreciation"
                                      id="annual_property_appreciation"
                                      onChange={handleCurrencyChange}
                                      variant="green"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Location
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Input
                                      label=""
                                      name="location"
                                      id="location"
                                      type="text"
                                      onChange={handleChange}
                                      variant="green"
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>

                        <div className="mb-0.5">
                          <div className={`flex items-center bg-[#C3CBBE] justify-between`}>
                            <p className="text-[12px] font-bold p-2">Purchase elements</p>
                            <span className="ml-4 p-2 cursor-pointer">
                              <VisibilityToggle
                                keyName="purchase_elements"
                                isVisible={isVisible("purchase_elements")}
                                toggleVisibility={toggleVisibility}
                              />
                            </span>
                          </div>
                          {isVisible("purchase_elements") && (
                            <table className="table-auto w-full">
                              <tbody>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Deposit available
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="deposit_available"
                                      id="deposit_available"
                                      onChange={handleCurrencyChange}
                                      variant="green"
                                      allowNegativeValue={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Maximum repayment (35% of income)
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="mortgage_repayment"
                                      id="mortgage_repayment"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      disabled
                                      allowNegativeValue={true}
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Mortgage term (years)
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Input
                                      name="mortgage_term"
                                      id="mortgage_term"
                                      onChange={handleChange}
                                      variant="green"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Mortgage interest rate
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Percentage
                                      name="mortgage_interest_rate"
                                      id="mortgage_interest_rate"
                                      onChange={handleChange}
                                      variant="green"
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Maximum mortgage
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="maximum_mortgage"
                                      id="maximum_mortgage"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      disabled
                                      allowNegativeValue={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Maximum home value
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="maximum_home_value"
                                      id="maximum_home_value"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      disabled
                                      allowNegativeValue={true}
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>

                        <div className="mb-0.5">
                          <div className={`flex items-center bg-[#C3CBBE] justify-between`}>
                            <p className="text-[12px] font-bold p-2">Initial costs</p>
                            <span className="ml-4 p-2 cursor-pointer">
                              <VisibilityToggle
                                keyName="initial_costs"
                                isVisible={isVisible("initial_costs")}
                                toggleVisibility={toggleVisibility}
                              />
                            </span>
                          </div>
                          {isVisible("initial_costs") && (
                            <table className="table-auto w-full">
                              <tbody>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Transfer duties
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="transfer_duties"
                                      id="transfer_duties"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      allowNegativeValue={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Legal fees - transfer costs
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="legal_fees"
                                      id="legal_fees"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      allowNegativeValue={true}
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Legal fees - bond registration
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="legal_fees"
                                      id="legal_fees"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      allowNegativeValue={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Other purchase costs
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="other_purchase_costs"
                                      id="other_purchase_costs"
                                      onChange={handleCurrencyChange}
                                      variant="green"
                                      allowNegativeValue={true}
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Total initial costs
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="total_initial_costs"
                                      id="total_initial_costs"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      disabled
                                      allowNegativeValue={true}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>

                        <div className="mb-0.5">
                          <div className={`flex items-center bg-[#C3CBBE] justify-between`}>
                            <p className="text-[12px] font-bold p-2">Asset preservation & cost of ownership</p>
                            <span className="ml-4 p-2 cursor-pointer">
                              <VisibilityToggle
                                keyName="asset_preservation"
                                isVisible={isVisible("asset_preservation")}
                                toggleVisibility={toggleVisibility}
                              />
                            </span>
                          </div>
                          {isVisible("asset_preservation") && (
                            <table className="table-auto w-full">
                              <tbody>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Monthly insurance
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="monthly_insurance"
                                      id="monthly_insurance"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      allowNegativeValue={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Monthly maintenance
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="monthly_maintenance"
                                      id="monthly_maintenance"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      allowNegativeValue={true}
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Monthly rates
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="monthly_rates"
                                      id="monthly_rates"
                                      onChange={handleCurrencyChange}
                                      variant="green"
                                      allowNegativeValue={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    {capitalizeFirstLetter(formData.primary_representation)} service escalation
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <PercentageSwitch
                                      primaryRepresentation={formData.primary_representation}
                                      // realField="real_service_escalation"
                                      nominalField="nominal_service_escalation"
                                      formData={formData}
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Total initial monthly costs
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="total_initial_monthly_costs"
                                      id="total_initial_monthly_costs"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      disabled
                                      allowNegativeValue={true}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>

                        <div>
                          <div className={`flex items-center bg-[#C3CBBE] justify-between`}>
                            <p className="text-[12px] font-bold p-2">Liquidation costs</p>
                            <span className="ml-4 p-2 cursor-pointer">
                              <VisibilityToggle
                                keyName="liquidation_costs"
                                isVisible={isVisible("liquidation_costs")}
                                toggleVisibility={toggleVisibility}
                              />
                            </span>
                          </div>
                          {isVisible("liquidation_costs") && (
                            <table className="table-auto w-full">
                              <tbody>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Estate agent commission
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Percentage
                                      name="estate_agent_commission"
                                      id="estate_agent_commission"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Legal fees & certifications
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Percentage
                                      name="legal_fees"
                                      id="legal_fees"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    Marginal tax rate
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Percentage
                                      name="marginal_tax_rate"
                                      id="marginal_tax_rate"
                                      onChange={handleCurrencyChange}
                                      variant="green"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999] w-[25%]">
                                    CGT inclusion
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999] w-[25%]">
                                    <Percentage
                                      name="cgt_inclusion"
                                      id="cgt_inclusion"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      disabled
                                      alignText="right"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle text-[12px] font-semibold p-2 border-r border-solid border-[#999] w-[25%]">
                                    Primary home exclusion
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-solid border-[#999] w-[25%]">
                                    <Currency
                                      name="primary_home_exclusion"
                                      id="primary_home_exclusion"
                                      onChange={handleCurrencyChange}
                                      variant="red"
                                      disabled
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Card variant="white">
                  <div className=" mb-4">
                    <h3 className="text-[16px] font-semibold">Output</h3>
                    {/* <p className="text-[12px] font-semibold mt-2">Some text goes here...</p> */}
                  </div>
                  <table className="table-auto w-[50%] border border-[#999]">
                    <thead>
                      <tr className="bg-[#f2b443]">
                        <th className="align-middle text-[12px] text-left font-bold p-2 border-r border-l border-solid border-[#999] w-[50%]">
                          Description
                        </th>
                        <th className="align-middle text-[12px] font-bold p-2 w-[50%]">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-l border-solid border-[#999]">
                          Total downpayments
                        </td>
                        <td className="bg-[#f2e1c3] align-middle text-[12px] text-center font-semibold p-2  border-b border-l border-solid border-[#999]">
                          R{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-l border-solid border-[#999]">
                          Initial monthly cost of ownership
                        </td>
                        <td className="bg-[#f2e1c3] align-middle text-[12px] text-center font-semibold p-2 border-b border-l border-solid border-[#999]">
                          R{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>

            <div className="col-span-4 mb-1 min-h-[calc(100vh-225px)]">
              <Card
                variant="white"
                className="h-full"
              >
                <p className="mr-8 font-bold text-[16px] text-center mb-4">
                  Property - {formData.primary_representation} cost of ownership
                </p>
                <div className="h-[calc(100vh-295px)] relative">
                  {chartSeries && (
                    <Chart
                      series={chartSeries}
                      options={chartOptionsDefault}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default BuyVsRentToolTwo;
