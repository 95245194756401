import ToggleSwitch from "@/components/Element/ToggleSwitch";
import SelectToggle from "@/components/Form/ToggleSwitch";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PostRetirement from "@/components/GrowthCostAssumptions/PostRetirement";
import PreRetirement from "@/components/GrowthCostAssumptions/PreRetirement";
import useGapAnalysisTool from "@api/Standard/Retirement/useGapAnalysisTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import { CommaSeparated, Decimal } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import calculations from "@services/algorithms/calculations";
import words from "@services/algorithms/words";
import Change from "@utils/change";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";

const GapAnalysis = () => {
  const { isVisible, toggleVisibility } = useVisibility();
  const { capitalizeFirstLetter } = words();
  const { preventEnterPress } = helpers();
  const { formatNumberBreaks } = calculations();

  const {
    loading,
    formData,
    setFormData,
    handleSubmit,
    chartSeries,
    chartOptions,
    handleNominalChange,
    handleRealChange,
  } = useGapAnalysisTool();

  const {
    type,
    handleChange,
    handleCurrencyChange,
    handleDecimalChange,
    handlePrimaryRepresentationChange,
    handleToggleChange,
  } = Change(formData, setFormData);

  const [showHelpPopup, setShowHelpPopup] = useState();

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is an extension of “How long will my money last in retirement?” and combines elements of
              pre-retirement contributions, increases and returns & post-retirement withdrawals and returns to determine
              the projected “gap” between pre- and post-retirement standards of living.
            </li>
            <li>
              The tool demonstrates 3 mechanisms that can close the gap in addition to the impact of differential asset
              growth rates.
            </li>
            <li>
              3) Inputs are as per the other retirement tools as follows:
              <ul className="pl-4">
                <li className="my-1">
                  a) Specific inputs: annual income, annual real growth in income, contributions, annual real growth in
                  contributions, current capital value of retirement products (standard assumption is that contributions
                  increase in line with income)
                </li>
                <li className="my-1">
                  b) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li>
              Outputs are delivered in three separate representations:
              <ul className="pl-4">
                <li className="my-1">
                  a) Representation #1
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Projected size of the retirement pot under the 3 pre-retirement net growth environments and
                      associated multiple of the final income.
                    </li>
                    <li className="my-1">
                      ii) Required pot size at retirement to support target standard of living (SOL) in retirement at
                      differential post-retirement growth rates and associated multiples
                      <ul className="pl-4">
                        <li className="my-1">iii) Impact of difference on projected SOL in retirement</li>
                        iv) 3 actions to fully close the gap viz.
                        <li className="my-1">• How much longer will I need to work?</li>
                        <li className="my-1">• How much more must I contribute per month?</li>
                        <li className="my-1">• How much capital must I inject?</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="my-1">
                  b) Representation #2
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Interactive representation of combinations of activities / scenarios as indicated above but
                      with adjusted levels – multiple selections
                    </li>
                    <li className="my-1">
                      ii) Client can input own variables in the 3 categories indicated above, or a combination thereof,
                      to identify trade-offs.
                    </li>
                    <li className="my-1">
                      iii) Table will demonstrate impact on projected SOL in retirement relative to current.
                    </li>
                    <li className="my-1">
                      iv) This option relates to the impact of adjusting annual withdrawals to achieve required life
                      expectancy - chart contrasts the client’s current situation with the impact of changes.
                    </li>
                  </ul>
                </li>
                <li className="my-1">
                  c) Representation #3
                  <ul className="pl-4">
                    <li className="my-1">i) As per above but…</li>
                    <li className="my-1">
                      ii) This option relates to the impact of maintaining target SOL and as much longevity as possible
                      - chart contrasts the client’s current situation with the impact of changes
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const handleToggleSubmitChange = (checked) => {
    const newValue = checked ? "adjusted_sustainable_income" : "years_to_depletion";

    // Update formData
    const updatedFormData = {
      ...formData,
      set: newValue,
    };

    // Update state
    setFormData(updatedFormData);

    // Create a synthetic event object
    const syntheticEvent = {
      preventDefault: () => {},
    };

    // Call handleSubmit immediately with the updated formData
    handleSubmit(syntheticEvent, updatedFormData);
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Retirement funding gap analysis"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Retirement Funding Gap Analysis" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  loading={loading}
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>

          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] h-full scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible("GrowthTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="w-[34%]"></th>
                      <th className="text-[12px] w-[22%]">Low</th>
                      <th className="text-[12px] w-[22%]">Medium</th>
                      <th className="text-[12px] w-[22%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PreRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                    <PostRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Smart tool specific inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="SpecificInputs"
                      isVisible={isVisible("SpecificInputs")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>

                {!isVisible("SpecificInputs") && (
                  <div className="grid grid-cols-2 gap-2 items-end mt-4">
                    <div>
                      <Currency
                        label="Annual pre-tax income"
                        name="annual_pre_tax_income"
                        id="annual_pre_tax_income"
                        value={formData.annual_pre_tax_income}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>

                    <div>
                      {type ? (
                        <>
                          <Percentage
                            label="Real growth in income"
                            name="real_growth_in_income"
                            id="real_growth_in_income"
                            value={formData.real_growth_in_income}
                            onChange={handleRealChange}
                            variant="green"
                            alignText="right"
                          />
                        </>
                      ) : (
                        <>
                          <Percentage
                            label="Nominal growth in income"
                            name="nominal_growth_in_income"
                            id="nominal_growth_in_income"
                            value={formData.nominal_growth_in_income}
                            onChange={handleNominalChange}
                            variant="green"
                            alignText="right"
                          />
                        </>
                      )}
                    </div>

                    <div>
                      <Currency
                        label="Current retirement fund value"
                        name="current_accumulated_retirement_fund_value"
                        id="current_accumulated_retirement_fund_value"
                        value={formData.current_accumulated_retirement_fund_value}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>

                    <div>
                      <Currency
                        label="Current monthly contribution"
                        name="current_monthly_contribution"
                        id="current_monthly_contribution"
                        value={formData.current_monthly_contribution}
                        onChange={handleCurrencyChange}
                        variant="green"
                        alignText="right"
                      />
                    </div>

                    <div>
                      <Percentage
                        label="Target income on retirement"
                        name="post_retirement_income_percentage"
                        id="post_retirement_income_percentage"
                        value={formData.post_retirement_income_percentage}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>

                    <div>
                      <Percentage
                        label="Contribution as a % of income"
                        name="contribution_as_percentage_of_income"
                        id="contribution_as_percentage_of_income"
                        value={formData.contribution_as_percentage_of_income}
                        onChange={handleChange}
                        disabled
                        variant="red"
                        alignText="right"
                      />
                    </div>
                  </div>
                )}
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="grid grid-cols-4">
                  <div className={`flex items-center`}>
                    <h3 className="text-[16px] font-semibold">Output</h3>
                    <span className="ml-4 cursor-pointer">
                      <VisibilityToggle
                        keyName="Output"
                        isVisible={isVisible("Output")}
                        toggleVisibility={toggleVisibility}
                      />
                    </span>
                  </div>
                  <div className="col-span-2 text-right">
                    <p className="text-[12px] font-bold text-gray-900 mr-2 mt-1.5">Target income at retirement:</p>
                  </div>
                  <div>
                    <Currency
                      disabled
                      onChange={handleCurrencyChange}
                      value={formData.target_income}
                      variant="red"
                    />
                  </div>
                </div>
                {!isVisible("Output") && (
                  <>
                    <div className="w-full overflow-x-auto scrollbar mt-4">
                      <table className="table-auto w-full border border-[#999]">
                        <thead>
                          <tr className="bg-[#f2b443]">
                            <th className="border-b border-r border-[#999] text-[12px] text-left font-semibold p-2 w-[40%]">
                              Gap analysis
                            </th>
                            <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                              Low
                            </th>
                            <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                              Medium
                            </th>
                            <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                              High
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Projected value at retirement
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={type ? formData.real_projected_value_low : formData.nominal_projected_value_low}
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type ? formData.real_projected_value_medium : formData.nominal_projected_value_medium
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type ? formData.real_projected_value_high : formData.nominal_projected_value_high
                                }
                                prefix="R"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Required value at retirement
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={type ? formData.real_required_value_low : formData.nominal_required_value_low}
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type ? formData.real_required_value_medium : formData.nominal_required_value_medium
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={type ? formData.real_required_value_high : formData.nominal_required_value_high}
                                prefix="R"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Difference In value
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type ? formData.real_value_difference_low : formData.nominal_value_difference_low
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_value_difference_medium
                                    : formData.nominal_value_difference_medium
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type ? formData.real_value_difference_high : formData.nominal_value_difference_high
                                }
                                prefix="R"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={4}
                              className="p-2"
                            />
                          </tr>
                          <tr>
                            <td className="border-b border-r border-t border-[#999] text-[12px] font-semibold p-2">
                              Projected multiple of target income
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-t border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.projected_multiple_low} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-t border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.projected_multiple_medium} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-t border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.projected_multiple_high} />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Required multiple of target income
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.required_multiple_low} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.required_multiple_medium} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.required_multiple_high} />
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={4}
                              className="p-2"
                            />
                          </tr>
                          <tr>
                            <td className="border-b border-r border-t border-[#999] text-[12px] font-semibold p-2">
                              Years to full depletion at target income
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-t border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.years_to_full_depletion_at_target_income_low} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-t border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.years_to_full_depletion_at_target_income_medium} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-t border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.years_to_full_depletion_at_target_income_high} />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Sustainable annual withdrawals
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type ? formData.real_annual_withdrawal_low : formData.nominal_annual_withdrawal_low
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_annual_withdrawal_medium
                                    : formData.nominal_annual_withdrawal_medium
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type ? formData.real_annual_withdrawal_high : formData.nominal_annual_withdrawal_high
                                }
                                prefix="R"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Impact on standard of living in retirement
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal
                                value={formData.impact_on_living_standard_low}
                                suffix="%"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal
                                value={formData.impact_on_living_standard_medium}
                                suffix="%"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal
                                value={formData.impact_on_living_standard_high}
                                suffix="%"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={4}
                              className="p-2"
                            />
                          </tr>
                        </tbody>
                        <thead>
                          <tr className="bg-[#f2b443]">
                            <th className="border-b border-r border-t border-[#999] text-[12px] text-left font-semibold p-2">
                              Implications **
                            </th>
                            <th className="border-b border-r border-t border-[#999] text-[12px] font-semibold p-2">
                              Low
                            </th>
                            <th className="border-b border-r border-t border-[#999] text-[12px] font-semibold p-2">
                              Medium
                            </th>
                            <th className="border-b border-r border-t border-[#999] text-[12px] font-semibold p-2">
                              High
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              How many years must I defer retirement?
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                              <Decimal value={formData.years_deferred_low} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                              <Decimal value={formData.years_deferred_medium} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                              <Decimal value={formData.years_deferred_high} />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              How much more must I contribute monthly?
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={formData.more_monthly_contribution_low}
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={formData.more_monthly_contribution_medium}
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={formData.more_monthly_contribution_high}
                                prefix="R"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              How much capital do I need to inject?
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={formData.capital_inject_low}
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={formData.capital_inject_medium}
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={formData.capital_inject_high}
                                prefix="R"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <p className="mt-4 text-[12px] font-bold">
                      ** These are the individual adjustments you would need to make to "close the gap"
                    </p>
                  </>
                )}
              </Card>

              <Card variant="white">
                <div className="grid grid-cols-2">
                  <div className={`flex items-center`}>
                    <h3 className="text-[16px] font-semibold">Target adjustments</h3>
                    <span className="ml-4 cursor-pointer">
                      <VisibilityToggle
                        keyName="TargetAdjustments"
                        isVisible={isVisible("TargetAdjustments")}
                        toggleVisibility={toggleVisibility}
                      />
                    </span>
                  </div>
                  <div className="col-span-1 justify-self-end">
                    <ToggleSwitch
                      initialChecked={formData.set === "adjusted_sustainable_income"}
                      leftLabel="Chart A"
                      rightLabel="Chart B"
                      onToggle={handleToggleSubmitChange}
                    />
                  </div>
                </div>
                {!isVisible("TargetAdjustments") && (
                  <>
                    <div className="grid grid-cols-2 gap-2 mt-4">
                      <div>
                        <Currency
                          name="increase_monthly_contribution"
                          id="increase_monthly_contribution"
                          value={formData.increase_monthly_contribution}
                          label="Increase monthly contribution"
                          onChange={handleCurrencyChange}
                          variant="green"
                        />
                      </div>
                      <div>
                        <Currency
                          label="Inject capital"
                          name="inject_capital"
                          id="inject_capital"
                          value={formData.inject_capital}
                          onChange={handleCurrencyChange}
                          variant="green"
                          alignText="right"
                        />
                      </div>
                      <div>
                        <Input
                          name="defer_retirement_by"
                          id="defer_retirement_by"
                          value={formData.defer_retirement_by}
                          label="Defer retirement by"
                          onChange={handleChange}
                          variant="green"
                        />
                      </div>
                      <div>
                        <Currency
                          name={type ? "real_adjusted_target_income" : "nominal_adjusted_target_income"}
                          id={type ? "real_adjusted_target_income" : "nominal_adjusted_target_income"}
                          value={type ? formData.real_adjusted_target_income : formData.nominal_adjusted_target_income}
                          disabled
                          label="Adjusted target income"
                          onChange={handleCurrencyChange}
                          variant="red"
                          alignText="right"
                        />
                      </div>
                      <div>
                        <Input
                          name="adjusted_retirement_longevity"
                          id="adjusted_retirement_longevity"
                          value={formData.adjusted_retirement_longevity}
                          label="Adjusted retirement longevity"
                          onChange={handleChange}
                          variant="red"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="w-full overflow-x-auto scrollbar">
                      <table className="table-auto w-full border border-[#999] mt-4">
                        <thead>
                          <tr className="bg-[#f2b443]">
                            <th className="border-b border-r border-[#999] text-[12px] text-left font-semibold p-2 w-[40%]">
                              Gap analysis
                            </th>
                            <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                              Low
                            </th>
                            <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                              Medium
                            </th>
                            <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">
                              High
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Adjusted projected value at retirement
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_adjusted_capital_at_retirement_low
                                    : formData.nominal_adjusted_capital_at_retirement_low
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_adjusted_capital_at_retirement_medium
                                    : formData.nominal_adjusted_capital_at_retirement_medium
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_adjusted_capital_at_retirement_high
                                    : formData.nominal_adjusted_capital_at_retirement_high
                                }
                                prefix="R"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Adjusted sustainable annual income in retirement
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_adjusted_income_at_retirement_low
                                    : formData.nominal_adjusted_income_at_retirement_low
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_adjusted_income_at_retirement_medium
                                    : formData.nominal_adjusted_income_at_retirement_medium
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_adjusted_income_at_retirement_high
                                    : formData.nominal_adjusted_income_at_retirement_high
                                }
                                prefix="R"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Change in sustainable income
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_change_in_sustainable_income_low
                                    : formData.nominal_change_in_sustainable_income_low
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_change_in_sustainable_income_medium
                                    : formData.nominal_change_in_sustainable_income_medium
                                }
                                prefix="R"
                              />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <CommaSeparated
                                value={
                                  type
                                    ? formData.real_change_in_sustainable_income_high
                                    : formData.nominal_change_in_sustainable_income_high
                                }
                                prefix="R"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Years to depletion at adjusted retirement income
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.years_to_depletion_low} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.years_to_depletion_medium} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.years_to_depletion_high} />
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                              Change in years to depletion
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.change_in_years_to_depletion_low} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.change_in_years_to_depletion_medium} />
                            </td>
                            <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                              <Decimal value={formData.change_in_years_to_depletion_high} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p className="mt-4 text-[12px] font-bold">
                      NB: Deferring the date of retirement implies a higher target income BUT a reduced period in
                      retirement.
                    </p>
                  </>
                )}
              </Card>
            </div>
          </div>

          <div className="col-span-4 mb-1 min-h-[calc(100vh-175px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="font-bold text-[16px] text-center mb-2">
                {formData.set === "adjusted_sustainable_income" ? (
                  <>
                    {capitalizeFirstLetter(formData.primary_representation)} gap analysis: sustainable income in
                    retirement
                  </>
                ) : (
                  <>
                    {capitalizeFirstLetter(formData.primary_representation)} gap analysis: years to depletion of capital
                  </>
                )}
              </p>
              <p className="font-bold text-[12px] text-center mb-4">
                <i>
                  {formData.set === "adjusted_sustainable_income"
                    ? "Current capital @ current sustainable income VS adjusted capital @ adjusted sustainable income"
                    : "Current capital @ current target income VS adjusted capital @ adjusted target income"}
                </i>
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="h-[calc(100vh-270px)] relative">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default GapAnalysis;
