import Spinner from "@/components/Element/Spinner";
import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

const Button = ({ className, loading, disabled, children, ...props }: ButtonProps) => {
  // Combine classes conditionally
  const buttonClasses = classNames(
    "text-white bg-[#243665] min-w-[100px] font-medium rounded-lg px-5 py-2.5 text-center text-[12px] flex items-center justify-center",
    {
      "opacity-50 cursor-not-allowed": disabled,
      "hover:opacity-75": !disabled,
      "flex gap-2": loading,
    },
    className
  );

  return (
    <button
      className={buttonClasses}
      disabled={disabled}
      {...props}
    >
      {loading && <Spinner spinnerSize={"xs"} />}
      {children}
    </button>
  );
};

export { Button };
