import createAxiosInstance from "@api/Api";
import numbers from "@services/algorithms/numbers";
import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

interface FormData {
  name: string;
  surname: string;
  id_number: string;
  mobile_number: string;
  email: string;
  address: string;
  gender_id: string;
  date_of_birth: string;
}

interface Gender {
  id: string;
  name: string;
}

interface ApiResponse {
  user: Partial<FormData>;
  genders: Gender[];
}

const usePersonalInformation = () => {
  const api: AxiosInstance = createAxiosInstance("user/personal-information");
  const { isValidSAIDNumber, generateDateOfBirthFromID } = numbers();

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string[]> | null>(null);
  const [idError, setIdError] = useState<string[] | null>(null);
  const [genders, setGenders] = useState<Gender[]>([]);

  const [formData, setFormData] = useState<FormData>({
    name: "",
    surname: "",
    id_number: "",
    mobile_number: "",
    email: "",
    address: "",
    gender_id: "",
    date_of_birth: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIDChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (isValidSAIDNumber(value)) {
      const dob = generateDateOfBirthFromID(value);
      setIdError(null);
      setFormData((prevData) => ({
        ...prevData,
        date_of_birth: dob.toISOString().split("T")[0],
        [name]: value,
      }));
    } else {
      setIdError(["Invalid ID number"]);
      setFormData((prevData) => ({ ...prevData, date_of_birth: "", [name]: value }));
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    toast
      .promise(savePersonalInformation(formData), {
        loading: "Saving...",
        success: (data: { message: string }) => data.message,
        error: "Could not save.",
      })
      // Not ideal. Need to update formData so that GeneralInformation gets the new values instead of page reload.
      .then(() => {
        window.location.reload();
      });
  };

  const getPersonalInformation = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<ApiResponse>("");

      setFormData({
        name: data.user.name || "",
        surname: data.user.surname || "",
        id_number: data.user.id_number || "",
        mobile_number: data.user.mobile_number || "",
        email: data.user.email || "",
        address: data.user.address || "",
        gender_id: data.user.gender_id || "",
        date_of_birth: data.user.date_of_birth || "",
      });
      setGenders(data.genders);
    } catch (error) {
      toast.error(error as string);
    } finally {
      setLoading(false);
    }
  };

  const savePersonalInformation = async (formData: FormData): Promise<{ message: string }> => {
    setLoading(true);
    setErrors(null);
    try {
      const { data } = await api.put<{ message: string }>("", formData);
      return Promise.resolve(data);
    } catch (error: any) {
      setErrors(error.response.data.errors);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPersonalInformation();
  }, []);

  return {
    formData,
    handleChange,
    handleIDChange,
    handleSubmit,
    idError,
    errors,
    genders,
    loading,
  };
};

export default usePersonalInformation;
