import React from 'react';

const TermsAndConditions: React.FC = () => {
  return (
    <>
      <p className="text-center font-bold text-lg mb-8">Terms and Conditions</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
        elementum a massa ut laoreet. Etiam congue metus felis, id hendrerit
        velit rhoncus ut. Aliquam feugiat ut tellus sit amet porttitor.
        Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere
        cubilia curae; Suspendisse ac dolor nec justo sagittis gravida. Aliquam
        et vehicula metus. Mauris quis lacinia nisi. In id quam id arcu
        consectetur pretium.
      </p>
      <br />
      <p>
        Mauris fermentum magna ipsum, at efficitur ante finibus id. Integer vel
        eros ipsum. Nunc nec urna quis arcu sollicitudin dignissim eu id enim.
        Donec elementum turpis ac tellus accumsan condimentum. Integer enim leo,
        tincidunt sit amet condimentum sed, lobortis porttitor nibh. Aenean
        finibus, diam vitae venenatis porttitor, libero tortor molestie tortor,
        vitae luctus est purus vitae sem. Vestibulum ante ipsum primis in
        faucibus orci luctus et ultrices posuere cubilia curae; Fusce pulvinar
        nunc vel orci ultrices sodales. Sed nibh arcu, elementum ut diam
        sodales, euismod egestas nisi. Duis gravida nec quam ac volutpat. Donec
        odio justo, commodo id laoreet fringilla, ultricies sed augue. Ut tempus
        magna in diam finibus finibus. Ut sapien diam, gravida commodo nisl non,
        gravida ultrices augue. Nunc egestas ultrices velit vel dignissim. Etiam
        lacinia elementum nisi, quis molestie nisl molestie id. Vivamus a
        laoreet orci, nec semper metus.
      </p>
      <br />
      <p>
        Integer dapibus posuere ex eu vulputate. Donec sodales auctor augue eget
        euismod. Nunc elementum dolor et nisi tincidunt facilisis. Etiam libero
        mi, pharetra quis orci in, ultrices condimentum risus. Quisque eget
        tortor mattis, porttitor lorem sagittis, mattis massa. Aliquam a porta
        sapien. Nunc in maximus diam, nec dictum elit. Donec ac enim quis eros
        imperdiet facilisis. Proin porta posuere ultrices. Suspendisse euismod
        massa molestie dolor consectetur mattis.
      </p>
      <br />
      <p>
        Nunc a porttitor massa. Aliquam vulputate facilisis justo, eget sagittis
        ipsum auctor a. Curabitur placerat laoreet ipsum in efficitur. Duis
        egestas neque eget tempor fringilla. Nulla dictum dui vel blandit
        hendrerit. Maecenas sit amet suscipit sapien. Nam interdum iaculis
        ipsum, sit amet bibendum eros suscipit in. Duis nec tortor cursus,
        finibus libero quis, iaculis dolor. Aliquam pretium laoreet urna, ut
        volutpat leo rhoncus eu. Aenean suscipit blandit dolor at commodo. Nam
        mollis mollis lacinia. Proin hendrerit diam vel turpis commodo, et
        malesuada ipsum venenatis. Quisque bibendum volutpat augue non
        consectetur. Sed quis felis magna. Integer eget feugiat eros. Donec
        finibus sapien vitae felis elementum maximus nec sed ex.
      </p>
      <br />
      <p>
        Phasellus dictum nisl mauris, non auctor orci pulvinar eget. Praesent
        varius viverra ipsum non pellentesque. Donec at dolor at sapien aliquam
        tempor vitae sed augue. Sed egestas et nibh vel laoreet. Vivamus
        consequat tellus in accumsan eleifend. Proin eleifend quis dui et
        imperdiet. Suspendisse commodo magna id consectetur condimentum. Mauris
        id nisi arcu. Cras volutpat ipsum quis turpis vulputate congue. Nullam
        libero arcu, pretium in mi ut, luctus eleifend urna. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus.
      </p>
    </>
  );
};

export default TermsAndConditions;