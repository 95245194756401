import { PremiumToolSections } from "@/pages/Premium";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../../../assets/arrow.svg";

type CircularStepperProps = {
  sections: PremiumToolSections[];
  onHover: (popupText: string | JSX.Element) => void;
  onLeave: () => void;
};

const CircularStepper: React.FC<CircularStepperProps> = ({ sections, onHover, onLeave }) => {
  const [hoveredSection, setHoveredSection] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleClick = (navigateTo: string) => {
    navigate(navigateTo); // Navigate to the specified route
  };

  const handleMouseEnter = (section: PremiumToolSections) => {
    setHoveredSection(section.heading);
    onHover(section.popupText);
  };

  const handleMouseLeave = () => {
    setHoveredSection(null);
    onLeave();
  };

  const radius = 400;
  const centerRadius = 180;
  const sectionAngle = 360 / sections.length;

  const viewBoxSize = radius * 2 + 60;
  const viewBox = `-${30} -${30} ${viewBoxSize} ${viewBoxSize}`;

  return (
    <div
      className="flex items-center justify-center h-full bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url('${ArrowIcon}')`, backgroundSize: "contain", backgroundPosition: "center" }}
    >
      <svg
        width="75%"
        height="75%"
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        {sections.map((section, index) => {
          const startAngle = index * sectionAngle - 90;
          const endAngle = (index + 1) * sectionAngle - 90;

          const largeArcFlag = sectionAngle > 180 ? 1 : 0;

          const pathData = `
            M ${radius},${radius}
            L ${radius + radius * Math.cos((startAngle * Math.PI) / 180)}, ${radius + radius * Math.sin((startAngle * Math.PI) / 180)}
            A ${radius},${radius} 0 ${largeArcFlag},1 ${radius + radius * Math.cos((endAngle * Math.PI) / 180)}, ${radius + radius * Math.sin((endAngle * Math.PI) / 180)}
            Z
          `;

          const textAngle = startAngle + sectionAngle / 2;
          const textX = radius + (radius / 1.5) * Math.cos((textAngle * Math.PI) / 180);
          const textY = radius + (radius / 1.5) * Math.sin((textAngle * Math.PI) / 180);

          let displayName: JSX.Element | string = section.heading;
          if (section.heading === "Personal Inputs") {
            displayName = (
              <>
                Personal
                <tspan
                  x={textX}
                  dy="1.2em"
                >
                  Inputs
                </tspan>
              </>
            );
          } else if (section.heading === "Default Settings") {
            displayName = (
              <>
                Default
                <tspan
                  x={textX}
                  dy="1.2em"
                >
                  Settings
                </tspan>
              </>
            );
          } else if (section.heading === "Active Income") {
            displayName = (
              <>
                Active
                <tspan
                  x={textX}
                  dy="1.2em"
                >
                  Income
                </tspan>
              </>
            );
          } else if (section.heading === "Investment & Savings") {
            displayName = (
              <>
                Investment &amp;
                <tspan
                  x={textX}
                  dy="1.2em"
                >
                  Savings
                </tspan>
              </>
            );
          } else if (section.heading === "Income Vs Expenditure") {
            displayName = (
              <>
                Income Vs
                <tspan
                  x={textX}
                  dy="1.2em"
                >
                  Expenditure
                </tspan>
              </>
            );
          }

          return (
            <g
              key={index}
              onMouseEnter={() => handleMouseEnter(section)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(section.navigate)}
              className="cursor-pointer"
            >
              <path
                d={pathData}
                fill={hoveredSection === section.heading ? "#85322f" : "white"}
                stroke="#85322f"
                strokeWidth="5"
              />
              <text
                x={textX}
                y={textY}
                fill={hoveredSection === section.heading ? "white" : "black"}
                fontSize="20"
                fontWeight="bold"
                textAnchor="middle"
                dominantBaseline="middle"
              >
                {displayName}
              </text>
            </g>
          );
        })}

        <polygon
          points={calculateHexagonPoints(radius, centerRadius)}
          fill="#4c00b0"
          stroke="black"
          strokeWidth="5"
        />
        <text
          x={radius}
          y={radius - 50}
          fill="white"
          fontSize="22"
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          Financial projections
        </text>
        <text
          x={radius}
          y={radius - 15}
          fill="white"
          fontSize="22"
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          &amp; inferences:
        </text>
        <text
          x={radius}
          y={radius + 20}
          fill="white"
          fontSize="22"
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          integrated
        </text>
        <text
          x={radius}
          y={radius + 55}
          fill="white"
          fontSize="22"
          fontWeight="bold"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          cashflow modelling
        </text>
      </svg>
    </div>
  );
};

function calculateHexagonPoints(outerRadius: number, innerRadius: number): string {
  const angleStep = (Math.PI * 2) / 6;
  let points = "";

  for (let i = 0; i < 6; i++) {
    const angle = angleStep * i;
    const x = outerRadius + innerRadius * Math.cos(angle);
    const y = outerRadius + innerRadius * Math.sin(angle);
    points += `${x},${y} `;
  }

  return points.trim();
}

export default CircularStepper;
