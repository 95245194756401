import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { FaFileAlt, FaLink, FaMoneyBill, FaQuestionCircle, FaRegCalendarAlt } from "react-icons/fa";
import { IoArrowBackCircleSharp, IoHome, IoPerson } from "react-icons/io5";
import { MdHelp, MdLogout } from "react-icons/md";
import { To, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import Links from "@components/Links";
import TermsAndConditions from "@components/TermsAndConditions";
import Costings from "@components/Welcome/Costings";
import WhichSystemIsForYou from "@components/Welcome/WhichSystemIsForYou";
import Modal from "@modals/Modal";

interface NavbarProps {
  pageTitle?: string;
  modalContent?: React.ReactNode;
  toggleHelpModal?: () => void;
  showBackButton?: boolean;
  backButtonNavigateTo?: string | number;
  headerBgColor?: string;
  headerTextColor?: string;
}

const Navbar: React.FC<NavbarProps> = ({
  pageTitle,
  modalContent,
  toggleHelpModal,
  showBackButton,
  backButtonNavigateTo,
  headerBgColor = "#fff",
  headerTextColor = "#000",
}) => {
  const [modal, setModal] = useState<boolean>(false);
  const [showWhichSystemIsForYou, setShowWhichSystemIsForYou] = useState<boolean>(false);
  const [showCostings, setShowCostings] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showLinks, setShowLinks] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [nav, setNav] = useState<boolean>(false);

  const navigate = useNavigate();

  const closeModal = (): void => setModal(false);

  const logout = (): void => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      logout();
    } else {
      setIsLoggedIn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, isLoggedIn]);

  const handleBackNavigation = () => {
    if (typeof backButtonNavigateTo === "string") {
      navigate(backButtonNavigateTo as To);
    } else if (typeof backButtonNavigateTo === "number") {
      navigate(backButtonNavigateTo);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div
        className={`${headerBgColor ? "bg-[" + headerBgColor + "]" : "#fff"} ${headerTextColor ? "text-[" + headerTextColor + "]" : "text-[#000]"} fixed w-full z-20 mx-auto flex justify-between items-center py-4 px-4 shadow-md h-[70px]`}
      >
        <div className="flex items-center min-w-[147px]">
          <div
            onClick={() => setNav(!nav)}
            className="cursor-pointer"
          >
            <AiOutlineMenu size={20} />
          </div>
        </div>

        <div className="flex items-center px-2 text-center">
          {showBackButton && (
            <button
              className={`text-white bg-[#243665] hover:opacity-75 flex items-center font-medium rounded-lg text-[12px] px-4 py-2 text-center`}
              type="button"
              onClick={handleBackNavigation}
            >
              <IoArrowBackCircleSharp
                size={25}
                className="cursor-pointer mr-2"
              />{" "}
              Return
            </button>
          )}
          {pageTitle || modalContent ? (
            <>
              <div className="mx-auto flex items-center">
                <h1 className="text-[20px] px-10">{pageTitle}</h1>
                {modalContent ? (
                  <div className="flex items-center gap-2">
                    <MdHelp
                      size={25}
                      className="cursor-pointer"
                      onClick={toggleHelpModal}
                    />
                    <p className="text-[12px] mr-10">Help!</p>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="flex items-center">
          <h2 className="text-2xl p-4">
            Future <span className="font-bold">You</span>
          </h2>
        </div>

        {nav ? <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"></div> : ""}

        <div className={`fixed top-0 w-[300px] h-screen bg-white z-30 duration-300 ${nav ? "left-0" : "left-[-100%]"}`}>
          <AiOutlineClose
            onClick={() => setNav(!nav)}
            size={25}
            className="absolute right-4 top-4 cursor-pointer"
          />
          <h2 className="text-2xl p-4">
            Future <span className="font-bold">You</span>
          </h2>
          <div></div>
          <nav className="h-full">
            <ul className="flex flex-col p-4 text-gray-800">
              <div className="p-2">
                <li
                  className="flex cursor-pointer w-full hover:text-black hover:underline"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <IoHome
                    size={20}
                    className="mr-4"
                  />
                  <p>Home</p>
                </li>
              </div>
              <div className="p-2">
                <li
                  className="flex cursor-pointer w-full hover:text-black hover:underline"
                  onClick={() => {
                    navigate("/my-profile");
                  }}
                >
                  <IoPerson
                    size={20}
                    className="mr-4"
                  />
                  <p>My Profile</p>
                </li>
              </div>
              <div className="p-2">
                <li
                  className="flex cursor-pointer w-full hover:text-black hover:underline"
                  onClick={() => {
                    setModal(true);
                    setShowCostings(true);
                    setShowWhichSystemIsForYou(false);
                    setShowTerms(false);
                    setShowLinks(false);
                  }}
                >
                  <FaMoneyBill
                    size={20}
                    className="mr-4"
                  />
                  <p>Costings</p>
                </li>
              </div>
              <div className="p-2">
                <li
                  className="flex cursor-pointer w-full hover:text-black hover:underline"
                  onClick={() => {
                    setModal(true);
                    setShowWhichSystemIsForYou(true);
                    setShowCostings(false);
                    setShowTerms(false);
                    setShowLinks(false);
                  }}
                >
                  <FaQuestionCircle
                    size={20}
                    className="mr-4"
                  />
                  <p>Which system is for you?</p>
                </li>
              </div>
              <div className="p-2">
                <li
                  className="flex cursor-pointer w-full hover:text-black hover:underline"
                  onClick={() => {
                    setModal(true);
                    setShowLinks(true);
                    setShowWhichSystemIsForYou(false);
                    setShowCostings(false);
                    setShowTerms(false);
                  }}
                >
                  <FaLink
                    size={20}
                    className="mr-4"
                  />
                  <p>Links</p>
                </li>
              </div>
              <div className="p-2">
                <li
                  className="flex cursor-pointer w-full hover:text-black hover:underline"
                  onClick={() => {
                    navigate("/history");
                    setModal(false);
                    setNav(!nav);
                  }}
                >
                  <FaRegCalendarAlt
                    size={20}
                    className="mr-4"
                  />
                  <p>Your History</p>
                </li>
              </div>
              <div className="p-2">
                <li
                  className="flex cursor-pointer w-full hover:text-black hover:underline"
                  onClick={() => {
                    setModal(true);
                    setShowTerms(true);
                    setShowWhichSystemIsForYou(false);
                    setShowCostings(false);
                  }}
                >
                  <FaFileAlt
                    size={20}
                    className="mr-4"
                  />
                  <p>Terms & Conditions</p>
                </li>
              </div>
            </ul>
            <div className="p-5 absolute bottom-0 w-full border">
              <li
                className="flex cursor-pointer w-full hover:text-black hover:underline"
                onClick={logout}
              >
                <MdLogout
                  size={20}
                  className="mr-4"
                />
                <p>Logout</p>
              </li>
            </div>
          </nav>
        </div>
      </div>
      <Modal
        show={modal}
        closeModal={closeModal}
        modalContainerPadding={twMerge(`py-[50px] ${showLinks ? "w-[60vw_!important] pb-[1rem]" : ""}`)}
        modalContent={
          showWhichSystemIsForYou ? (
            <WhichSystemIsForYou />
          ) : showCostings ? (
            <Costings />
          ) : showTerms ? (
            <TermsAndConditions />
          ) : (
            showLinks && <Links />
          )
        }
      />
    </>
  );
};

export default Navbar;
