import numbers from "@/services/algorithms/numbers";
import createAxiosInstance from "@api/Api";
import useDebounce from "@hooks/useDebounce";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import helpers from "@utils/helpers";
import { useEffect, useState } from "react";

interface FormData {
  // Generic inputs
  primary_representation: string;
  effective_date: string;
  projected_inflation_rate: number;
  date_of_birth: string;
  retirement_date: string;
  retirement_age: number;
  current_age: number;
  years_to_retirement: number;
  life_expectancy: number;
  retirement_longevity: number;

  // Growth & cost assumptions
  real_pre_retirement_capital_growth_low: number;
  real_pre_retirement_capital_growth_medium: number;
  real_pre_retirement_capital_growth_high: number;
  nominal_pre_retirement_capital_growth_low: number;
  nominal_pre_retirement_capital_growth_medium: number;
  nominal_pre_retirement_capital_growth_high: number;
  pre_retirement_costs: number;
  pre_retirement_capital_growth_low: number;
  pre_retirement_capital_growth_medium: number;
  pre_retirement_capital_growth_high: number;
  real_post_retirement_capital_growth_low: number;
  real_post_retirement_capital_growth_medium: number;
  real_post_retirement_capital_growth_high: number;
  nominal_post_retirement_capital_growth_low: number;
  nominal_post_retirement_capital_growth_medium: number;
  nominal_post_retirement_capital_growth_high: number;
  post_retirement_costs: number;
  post_retirement_capital_growth_low: number;
  post_retirement_capital_growth_medium: number;
  post_retirement_capital_growth_high: number;
  real_pre_net_retirement_growth_low: number;
  real_pre_net_retirement_growth_medium: number;
  real_pre_net_retirement_growth_high: number;
  nominal_pre_net_retirement_growth_low: number;
  nominal_pre_net_retirement_growth_medium: number;
  nominal_pre_net_retirement_growth_high: number;
  real_post_net_retirement_growth_low: number;
  real_post_net_retirement_growth_medium: number;
  real_post_net_retirement_growth_high: number;
  nominal_post_net_retirement_growth_low: number;
  nominal_post_net_retirement_growth_medium: number;
  nominal_post_net_retirement_growth_high: number;
}

const usePremiumTool = () => {
  const api = createAxiosInstance("premium/");
  const getUserData = createAxiosInstance("standard/retirement/retirement-need/");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { chartOptionsDefault } = helpers();
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementLongevity } = numbers();

  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState<FormData>({
    // Generic inputs
    primary_representation: "",
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_date: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity: 0,

    // Growth & cost assumptions
    real_pre_retirement_capital_growth_low: 0,
    real_pre_retirement_capital_growth_medium: 0,
    real_pre_retirement_capital_growth_high: 0,
    nominal_pre_retirement_capital_growth_low: 0,
    nominal_pre_retirement_capital_growth_medium: 0,
    nominal_pre_retirement_capital_growth_high: 0,
    pre_retirement_costs: 0,
    pre_retirement_capital_growth_low: 0,
    pre_retirement_capital_growth_medium: 0,
    pre_retirement_capital_growth_high: 0,
    real_post_retirement_capital_growth_low: 0,
    real_post_retirement_capital_growth_medium: 0,
    real_post_retirement_capital_growth_high: 0,
    nominal_post_retirement_capital_growth_low: 0,
    nominal_post_retirement_capital_growth_medium: 0,
    nominal_post_retirement_capital_growth_high: 0,
    post_retirement_costs: 0,
    post_retirement_capital_growth_low: 0,
    post_retirement_capital_growth_medium: 0,
    post_retirement_capital_growth_high: 0,
    real_pre_net_retirement_growth_low: 0,
    real_pre_net_retirement_growth_medium: 0,
    real_pre_net_retirement_growth_high: 0,
    nominal_pre_net_retirement_growth_low: 0,
    nominal_pre_net_retirement_growth_medium: 0,
    nominal_pre_net_retirement_growth_high: 0,
    real_post_net_retirement_growth_low: 0,
    real_post_net_retirement_growth_medium: 0,
    real_post_net_retirement_growth_high: 0,
    nominal_post_net_retirement_growth_low: 0,
    nominal_post_net_retirement_growth_medium: 0,
    nominal_post_net_retirement_growth_high: 0,
  });

  const debouncedData = useDebounce(formData, 100);

  const {
    handleNominalChange,
    handleRealChange,
    genericInputsCalculations,
    growthPreRetirementCalculations,
    growthPostRetirementCalculations,
  } = growthRateHandler(debouncedData, setFormData);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post("/calculate", formData);
      setChartSeries(response.data.series);
      setChartOptions((prevChartOptions) => ({
        ...prevChartOptions,
        xaxis: {
          ...prevChartOptions.xaxis,
          categories: response.data.categories,
        },
      }));
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await getUserData.get(`get-stored-data`);

      setFormData({
        ...formData,

        // Generic inputs
        primary_representation: data.ufs.primary_representation || "",
        effective_date: data.ufs.effective_date || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || 0,
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || 0,
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || 0,
        retirement_date: data.ufs.retirement_date || "",
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || 0,
        life_expectancy: data.ufs.life_expectancy || 0,
        retirement_longevity: calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,

        // Growth & cost assumptions
        real_pre_retirement_capital_growth_low: data.ufs.real_pre_retirement_capital_growth_low || "",
        real_pre_retirement_capital_growth_medium: data.ufs.real_pre_retirement_capital_growth_medium || "",
        real_pre_retirement_capital_growth_high: data.ufs.real_pre_retirement_capital_growth_high || "",
        nominal_pre_retirement_capital_growth_low: data.ufs.nominal_pre_retirement_capital_growth_low || "",
        nominal_pre_retirement_capital_growth_medium: data.ufs.nominal_pre_retirement_capital_growth_medium || "",
        nominal_pre_retirement_capital_growth_high: data.ufs.nominal_pre_retirement_capital_growth_high || "",
        pre_retirement_costs: data.ufs.pre_retirement_costs || "",
        real_post_retirement_capital_growth_low: data.ufs.real_post_retirement_capital_growth_low || "",
        real_post_retirement_capital_growth_medium: data.ufs.real_post_retirement_capital_growth_medium || "",
        real_post_retirement_capital_growth_high: data.ufs.real_post_retirement_capital_growth_high || "",
        nominal_post_retirement_capital_growth_low: data.ufs.nominal_post_retirement_capital_growth_low || "",
        nominal_post_retirement_capital_growth_medium: data.ufs.nominal_post_retirement_capital_growth_medium || "",
        nominal_post_retirement_capital_growth_high: data.ufs.nominal_post_retirement_capital_growth_high || "",
        post_retirement_costs: data.ufs.post_retirement_costs || "",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      genericInputsCalculations();
    }, 100);
  }, [genericInputsCalculations]);
  useEffect(() => {
    setTimeout(() => {
      growthPreRetirementCalculations();
    }, 100);
  }, [growthPreRetirementCalculations]);
  useEffect(() => {
    setTimeout(() => {
      growthPostRetirementCalculations();
    }, 100);
  }, [growthPostRetirementCalculations]);

  useEffect(() => {
    getStoredData();
  }, []);

  return {
    loading,
    error,
    chartSeries,
    chartOptions,
    formData,
    setFormData,
    handleSubmit,
    handleNominalChange,
    handleRealChange,
  };
};

export default usePremiumTool;
