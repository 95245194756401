import { CategoryData, RowData } from "./ExpenditureTable";

type InputType = "currency" | "text" | "number" | "date" | "percentage" | "percentageSwitch" | "select";
type Variant = "green" | "red";

interface InputData {
  type: InputType;
  name: string;
  variant: Variant;
  disabled?: boolean;
}

const createInput = (
  type: InputType,
  name: string,
  variant: Variant = "green",
  disabled: boolean = false
): InputData => ({
  type,
  name,
  variant,
  disabled,
});

const createRow = (description: string, ...inputs: InputData[]): RowData => ({
  id: `${description.toLowerCase().replace(/\s+/g, "_")}_${Date.now()}`,
  description,
  inputs,
});

const consumptionRows = [
  createRow(
    "Rent",
    createInput("currency", "current_rent"),
    createInput("number", "review_month_rent"),
    createInput("date", "terminal_month_rent"),
    createInput("percentage", "increase_rent")
  ),
  createRow(
    "Transportation",
    createInput("currency", "current_transportation"),
    createInput("number", "review_month_transportation"),
    createInput("date", "terminal_month_transportation"),
    createInput("percentage", "increase_transportation")
  ),
  createRow(
    "Vehicle repayments",
    createInput("currency", "current_vehicle_repayments", "red"),
    createInput("number", "review_month_vehicle_repayments", "green"),
    createInput("date", "terminal_month_vehicle_repayments", "green"),
    createInput("percentage", "increase_vehicle_repayments", "green")
  ),
  createRow(
    "Vehicle running costs",
    createInput("currency", "current_vehicle_running_costs", "red"),
    createInput("number", "review_month_vehicle_running_costs", "green"),
    createInput("date", "terminal_month_vehicle_running_costs", "green"),
    createInput("percentage", "increase_vehicle_running_costs", "green")
  ),
  createRow(
    "Education expenses (incl extra murals)",
    createInput("currency", "current_education_expenses", "red"),
    createInput("number", "review_month_education_expenses", "green"),
    createInput("date", "terminal_month_education_expenses", "green"),
    createInput("percentage", "increase_education_expenses", "green")
  ),
  createRow(
    "Medical Aid",
    createInput("currency", "current_medical_aid"),
    createInput("number", "review_month_medical_aid"),
    createInput("date", "terminal_month_medical_aid"),
    createInput("percentage", "increase_medical_aid")
  ),
  createRow(
    "Loan repayments",
    createInput("currency", "current_loan_repayments", "red"),
    createInput("number", "review_month_loan_repayments", "green"),
    createInput("date", "terminal_month_loan_repayments", "green"),
    createInput("percentage", "increase_loan_repayments", "green")
  ),
  createRow(
    "Security",
    createInput("currency", "current_security"),
    createInput("number", "review_month_security"),
    createInput("date", "terminal_month_security"),
    createInput("percentage", "increase_security")
  ),
  createRow(
    "Communication",
    createInput("currency", "current_communication"),
    createInput("number", "review_month_communication"),
    createInput("date", "terminal_month_communication"),
    createInput("percentage", "increase_communication")
  ),
  createRow(
    "Electricity",
    createInput("currency", "current_electricity"),
    createInput("number", "review_month_electricity"),
    createInput("date", "terminal_month_electricity"),
    createInput("percentage", "increase_electricity")
  ),
  createRow(
    "Water",
    createInput("currency", "current_water"),
    createInput("number", "review_month_water"),
    createInput("date", "terminal_month_water"),
    createInput("percentage", "increase_water")
  ),
  createRow(
    "General living",
    createInput("currency", "current_general_living"),
    createInput("number", "review_month_general_living"),
    createInput("date", "terminal_month_general_living"),
    createInput("percentage", "increase_general_living")
  ),
  createRow(
    "Entertainment",
    createInput("currency", "current_entertainment"),
    createInput("number", "review_month_entertainment"),
    createInput("date", "terminal_month_entertainment"),
    createInput("percentage", "increase_entertainment")
  ),
  createRow(
    "Pets",
    createInput("currency", "current_pets"),
    createInput("number", "review_month_pets"),
    createInput("date", "terminal_month_pets"),
    createInput("percentage", "increase_pets")
  ),
  createRow(
    "Other",
    createInput("currency", "current_other_consumption"),
    createInput("number", "review_month_other_consumption"),
    createInput("date", "terminal_month_other_consumption"),
    createInput("percentage", "increase_other_consumption")
  ),
  createRow("Sub-total", createInput("currency", "subtotal_consumption", "red", true)),
];

const wealthCreationRows = [
  createRow(
    "Pension contributions",
    createInput("currency", "current_pension_contributions", "red", true),
    createInput("number", "review_month_pension_contributions", "green"),
    createInput("date", "terminal_month_pension_contributions", "green"),
    createInput("percentage", "increase_pension_contributions", "green")
  ),
  createRow(
    "Provident contributions",
    createInput("currency", "current_provident_contributions", "red", true),
    createInput("number", "review_month_provident_contributions", "green"),
    createInput("date", "terminal_month_provident_contributions", "green"),
    createInput("percentage", "increase_provident_contributions", "green")
  ),
  createRow(
    "Retirement annuity contributions",
    createInput("currency", "current_retirement_annuity_contributions", "red", true),
    createInput("number", "review_month_retirement_annuity_contributions", "green"),
    createInput("date", "terminal_month_retirement_annuity_contributions", "green"),
    createInput("percentage", "increase_retirement_annuity_contributions", "green")
  ),
  createRow(
    "Retirement pot",
    createInput("currency", "current_retirement_pot", "red", true),
    createInput("number", "review_month_retirement_pot", "green"),
    createInput("date", "terminal_month_retirement_pot", "green"),
    createInput("percentage", "increase_retirement_pot", "green")
  ),
  createRow(
    "Savings pot",
    createInput("currency", "current_savings_pot", "red", true),
    createInput("number", "review_month_savings_pot", "green"),
    createInput("date", "terminal_month_savings_pot", "green"),
    createInput("percentage", "increase_savings_pot", "green")
  ),
  createRow(
    "Primary home mortgage",
    createInput("currency", "current_primary_home_mortgage", "red", true),
    createInput("number", "review_month_primary_home_mortgage", "green"),
    createInput("date", "terminal_month_primary_home_mortgage", "green"),
    createInput("percentage", "increase_primary_home_mortgage", "green")
  ),
  createRow(
    "Other property mortgages",
    createInput("currency", "current_other_property_mortgages", "red", true),
    createInput("number", "review_month_other_property_mortgages", "green"),
    createInput("date", "terminal_month_other_property_mortgages", "green"),
    createInput("percentage", "increase_other_property_mortgages", "green")
  ),
  createRow(
    "Investment #1",
    createInput("currency", "current_investment_1", "red", true),
    createInput("number", "review_month_investment_1", "green"),
    createInput("date", "terminal_month_investment_1", "green"),
    createInput("percentage", "increase_investment_1", "green")
  ),
  createRow(
    "Investment #2",
    createInput("currency", "current_investment_2", "red", true),
    createInput("number", "review_month_investment_2", "green"),
    createInput("date", "terminal_month_investment_2", "green"),
    createInput("percentage", "increase_investment_2", "green")
  ),
  createRow(
    "Savings #1",
    createInput("currency", "current_savings_1", "red", true),
    createInput("number", "review_month_savings_1", "green"),
    createInput("date", "terminal_month_savings_1", "green"),
    createInput("percentage", "increase_savings_1", "green")
  ),
  createRow(
    "Savings #2",
    createInput("currency", "current_savings_2", "red", true),
    createInput("number", "review_month_savings_2", "green"),
    createInput("date", "terminal_month_savings_2", "green"),
    createInput("percentage", "increase_savings_2", "green")
  ),
  createRow(
    "Special fund",
    createInput("currency", "current_special_fund", "red", true),
    createInput("number", "review_month_special_fund", "green"),
    createInput("date", "terminal_month_special_fund", "green"),
    createInput("percentage", "increase_special_fund", "green")
  ),
  createRow(
    "Other",
    createInput("currency", "current_other_wealth_creation"),
    createInput("number", "review_month_other_wealth_creation"),
    createInput("date", "terminal_month_other_wealth_creation"),
    createInput("percentage", "increase_other_wealth_creation")
  ),
  createRow("Sub-total", createInput("currency", "subtotal_wealth_creation", "red", true)),
];

const wealthPreservationRows = [
  createRow(
    "Life insurance",
    createInput("currency", "current_life_insurance", "red", true),
    createInput("number", "review_month_life_insurance", "green"),
    createInput("date", "terminal_month_life_insurance", "green"),
    createInput("percentage", "increase_life_insurance", "green")
  ),
  createRow(
    "Disability insurance",
    createInput("currency", "current_disability_insurance", "red", true),
    createInput("number", "review_month_disability_insurance", "green"),
    createInput("date", "terminal_month_disability_insurance", "green"),
    createInput("percentage", "increase_disability_insurance", "green")
  ),
  createRow(
    "Medical insurance",
    createInput("currency", "current_medical_insurance", "red", true),
    createInput("number", "review_month_medical_insurance", "green"),
    createInput("date", "terminal_month_medical_insurance", "green"),
    createInput("percentage", "increase_medical_insurance", "green")
  ),
  createRow(
    "Non-vehicle short-term insurance",
    createInput("currency", "current_non_vehicle_short_term_insurance", "red", true),
    createInput("number", "review_month_non_vehicle_short_term_insurance", "green"),
    createInput("date", "terminal_month_non_vehicle_short_term_insurance", "green"),
    createInput("percentage", "increase_non_vehicle_short_term_insurance", "green")
  ),
  createRow(
    "Vehicle insurance",
    createInput("currency", "current_vehicle_insurance", "red", true),
    createInput("number", "review_month_vehicle_insurance", "green"),
    createInput("date", "terminal_month_vehicle_insurance", "green"),
    createInput("percentage", "increase_vehicle_insurance", "green")
  ),
  createRow(
    "Other insurance",
    createInput("currency", "current_other_insurance", "red", true),
    createInput("number", "review_month_other_insurance", "green"),
    createInput("date", "terminal_month_other_insurance", "green"),
    createInput("percentage", "increase_other_insurance", "green")
  ),
  createRow(
    "Business continuity insurance",
    createInput("currency", "current_business_continuity_insurance", "red", true),
    createInput("number", "review_month_business_continuity_insurance", "green"),
    createInput("date", "terminal_month_business_continuity_insurance", "green"),
    createInput("percentage", "increase_business_continuity_insurance", "green")
  ),
  createRow(
    "Primary home insurance, rates & maintenance",
    createInput("currency", "current_primary_home_insurance_rates_maintenance", "red", true),
    createInput("number", "review_month_primary_home_insurance_rates_maintenance", "green"),
    createInput("date", "terminal_month_primary_home_insurance_rates_maintenance", "green"),
    createInput("percentage", "increase_primary_home_insurance_rates_maintenance", "green")
  ),
  createRow(
    "Other property insurance, rates & maintenance",
    createInput("currency", "current_other_property_insurance_rates_maintenance", "red", true),
    createInput("number", "review_month_other_property_insurance_rates_maintenance", "green"),
    createInput("date", "terminal_month_other_property_insurance_rates_maintenance", "green"),
    createInput("percentage", "increase_other_property_insurance_rates_maintenance", "green")
  ),
  createRow(
    "Other",
    createInput("currency", "current_other_wealth_preservation"),
    createInput("number", "review_month_other_wealth_preservation"),
    createInput("date", "terminal_month_other_wealth_preservation"),
    createInput("percentage", "increase_other_wealth_preservation")
  ),
  createRow("Sub-total", createInput("currency", "subtotal_wealth_preservation", "red", true)),
];

const createCategory = (category: string, rows: RowData[]): CategoryData => ({
  category,
  rowSpan: rows.length,
  rows,
});

export const expenditureTableData = (): CategoryData[] => [
  createCategory("Consumption", consumptionRows),
  createCategory("Wealth creation", wealthCreationRows),
  createCategory("Wealth preservation", wealthPreservationRows),
];

export default expenditureTableData;
