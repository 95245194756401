import { ReactNode, useState } from "react";

export default function InputTooltip({
  tooltipText,
  alignText = "left",
  children,
}: {
  tooltipText: string;
  alignText: string;
  children: ReactNode;
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      <span
        className={`relative flex flex-col items-center group w-fit ${alignText === "right" ? "float-right top-[2px] ml-2" : ""}`}
      >
        <span
          className="flex justify-center"
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          {children}
        </span>
        <div
          className={`absolute bottom-full flex flex-col items-center group-hover:flex min-w-[250px] ${!show ? "hidden" : ""}`}
        >
          <span className="relative z-10 p-2 text-xs text-white bg-gray-600 shadow-lg rounded-md w-full break-words leading-4">
            {tooltipText}
          </span>
          <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600" />
        </div>
      </span>
    </>
  );
}
