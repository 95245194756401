export interface ColumnData {
  type: "currency" | "select" | "date" | "percentage" | "input";
  variant: "red" | "green";
}

export interface ActiveIncomeRowData {
  description: string;
  [key: string]: string | ColumnData;
}

export interface ActiveIncome {
  name: string;
  inputType: "currency" | "select" | "date" | "percentage" | "input";
  variant: "green" | "red";
  formData?: any;
}

export const initialActiveIncome: ActiveIncome[] = [
  {
    name: "amount",
    inputType: "currency",
    variant: "green",
  },
  {
    name: "frequency",
    inputType: "select",
    variant: "green",
  },
  {
    name: "next_payment",
    inputType: "date",
    variant: "green",
  },
  {
    name: "final_payment",
    inputType: "date",
    variant: "green",
  },
  {
    name: "annual_increase",
    inputType: "percentage",
    variant: "green",
  },
  {
    name: "review_month",
    inputType: "input",
    variant: "green",
  },
];

export const ActiveIncomeTableData = (formData: any): ActiveIncomeRowData[] => [
  {
    description: "Current amount",
    formal_salary: { type: "currency", variant: "red" },
    bonus: { type: "currency", variant: "red" },
  },
  {
    description: "Frequency",
    formal_salary: { type: "select", variant: "red" },
    bonus: { type: "select", variant: "red" },
  },
  {
    description: "Next payment",
    formal_salary: { type: "date", variant: "red" },
    bonus: { type: "date", variant: "red" },
  },
  {
    description: "Final payment",
    formal_salary: { type: "date", variant: "red" },
    bonus: { type: "date", variant: "green" },
  },
  {
    description: `Annual ${formData.primary_representation} % change`,
    formal_salary: { type: "percentage", variant: "green" },
    bonus: { type: "percentage", variant: "green" },
  },
  {
    description: "Review month",
    formal_salary: { type: "select", variant: "green" },
    bonus: { type: "select", variant: "green" },
  },
];

export default ActiveIncomeTableData;
