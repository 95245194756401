import Card from "@/components/Element/Card";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import Button from "@/components/Form/Button";
import Currency from "@/components/Form/Currency";
import Input from "@/components/Form/Input";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import React, { ChangeEvent, useState } from "react";
import { CurrencyInputOnChangeValues } from "react-currency-input-field";
import { IoCloseCircleOutline } from "react-icons/io5";

interface EducationTabOneProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  handleRealChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleNominalChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleDecimalChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  preventEnterPress: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
  formData: any;
}

interface GenericInputsProps {
  formData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
}

interface Column {
  id: number;
  dependentNumber: number;
}

interface EducationLevel {
  name: string;
  grades: string[];
}

const GenericInputsCard: React.FC<GenericInputsProps> = ({
  formData,
  handleChange,
  handleCurrencyChange,
  isVisible,
  toggleVisibility,
}) => (
  <div className="grid grid-cols-2 gap-4 mb-4">
    <div className="col-end-1">
      <Card
        variant="white"
        className="h-full"
      >
        <div className="flex items-center mb-2">
          <h3 className="text-[16px] font-semibold">Generic inputs</h3>
          <span className="ml-4 cursor-pointer">
            <VisibilityToggle
              keyName="GenericTable"
              isVisible={isVisible("GenericTable")}
              toggleVisibility={() => toggleVisibility("GenericTable")}
            />
          </span>
        </div>
        <LayoutOne
          formData={formData}
          handleChange={handleChange}
          handleCurrencyChange={handleCurrencyChange}
          isVisible={isVisible("GenericTable")}
        />
      </Card>
    </div>
  </div>
);

const EducationTabOne: React.FC<EducationTabOneProps> = ({
  handleSubmit,
  handleChange,
  handleCurrencyChange,
  preventEnterPress,
  isVisible,
  toggleVisibility,
  formData,
}) => {
  const [columns, setColumns] = useState<Column[]>([]);

  const addColumn = () => {
    const newColumn: Column = {
      id: Date.now(),
      dependentNumber: columns.length + 2,
    };
    setColumns([...columns, newColumn]);
  };

  const removeColumn = (id: number) => {
    const updatedColumns = columns.filter((column) => column.id !== id);
    setColumns(
      updatedColumns.map((column, index) => ({
        ...column,
        dependentNumber: index + 2,
      }))
    );
  };

  const educationLevels: EducationLevel[] = [
    { name: "Pre-primary", grades: ["Pre-1", "Pre-2", "Pre-3"] },
    { name: "Primary - first stage", grades: ["Grade 1", "Grade 2", "Grade 3"] },
    { name: "Primary - second stage", grades: ["Grade 4", "Grade 5", "Grade 6", "Grade 7"] },
    { name: "Secondary - initial stage", grades: ["Grade 8", "Grade 9"] },
    { name: "Secondary - final stage", grades: ["Grade 10", "Grade 11", "Grade 12"] },
    { name: "Bachelor / Diploma first year", grades: ["NQF5"] },
    { name: "Bachelor second year / Diploma final", grades: ["NQF6"] },
    { name: "Bachelor degree / Advanced Diploma", grades: ["NQF7"] },
    { name: "Honours degree / Post graduate diploma", grades: ["NQF8"] },
    { name: "Masters", grades: ["NQF9"] },
  ];

  return (
    <form
      onSubmit={handleSubmit}
      onKeyDown={preventEnterPress}
      className="mx-auto w-full h-full"
    >
      <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div>
            <GenericInputsCard
              formData={formData}
              handleChange={handleChange}
              handleCurrencyChange={handleCurrencyChange}
              isVisible={isVisible}
              toggleVisibility={toggleVisibility}
            />
          </div>
          <div className="col-span-2">
            <Card variant="white">
              <div className="mb-3">
                <h3 className="text-[16px] font-semibold">Education characteristics</h3>
              </div>
              <div className="overflow-x-auto">
                <table className="table-auto border">
                  <thead>
                    <tr>
                      <th
                        rowSpan={3}
                        className="bg-[#f1f1f1] text-[12px] font-bold p-2 border border-[#999] min-w-[200px]"
                      >
                        Description
                      </th>
                      <th className="bg-[#f1f1f1] text-[12px] font-bold p-2 border border-[#999] min-w-[200px]">
                        Dependant
                      </th>
                      <th
                        colSpan={2}
                        className="text-[12px] font-bold p-2 border border-[#999] min-w-[200px]"
                      >
                        Dependant #1
                      </th>
                      {columns.map((column) => (
                        <th
                          key={column.id}
                          colSpan={2}
                          className="text-[12px] font-bold p-2 border border-[#999] min-w-[200px]"
                        >
                          <div className="flex items-center justify-center">
                            Dependant #{column.dependentNumber}
                            <IoCloseCircleOutline
                              className="inline-block ml-2 cursor-pointer text-red-500"
                              onClick={() => removeColumn(column.id)}
                            />
                          </div>
                        </th>
                      ))}
                    </tr>
                    <tr>
                      <th className="bg-[#f1f1f1] text-[12px] font-bold p-2 border border-[#999]">
                        Base year of inputs
                      </th>
                      <th className="text-[12px] font-bold p-2 border border-[#999] min-w-[200px]">Base year</th>
                      <th className="text-[12px] font-bold p-2 border border-[#999] min-w-[200px]">2023</th>
                      {columns.map((column) => (
                        <React.Fragment key={column.id}>
                          <th className="text-[12px] font-bold p-2 border border-[#999] min-w-[200px]">Base year</th>
                          <th className="text-[12px] font-bold p-2 border border-[#999] min-w-[200px]">2023</th>
                        </React.Fragment>
                      ))}
                    </tr>
                    <tr>
                      <th className="bg-[#f1f1f1] text-[12px] font-bold p-2 border border-[#999]">Level</th>
                      <th className="text-[12px] font-bold p-2 border border-[#999]">Base year cost</th>
                      <th className="text-[12px] font-bold p-2 border border-[#999]">Year of study</th>
                      {columns.map((column) => (
                        <React.Fragment key={column.id}>
                          <th className="text-[12px] font-bold p-2 border border-[#999]">Base year cost</th>
                          <th className="text-[12px] font-bold p-2 border border-[#999]">Year of study</th>
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {educationLevels.map((level, levelIndex) => (
                      <React.Fragment key={levelIndex}>
                        {level.grades.map((grade, gradeIndex) => (
                          <tr
                            key={`${levelIndex}-${gradeIndex}`}
                            className={gradeIndex === level.grades.length - 1 ? "border-b-2 border-gray-400" : ""}
                          >
                            {gradeIndex === 0 && (
                              <td
                                rowSpan={level.grades.length}
                                className="p-2 border border-[#999] text-xs font-bold"
                              >
                                {level.name}
                              </td>
                            )}
                            <td className="p-2 border border-[#999] text-xs">{grade}</td>
                            <td className="p-2 border border-[#999] text-xs">
                              <Currency variant="green" />
                            </td>
                            <td className="p-2 border border-[#999] text-xs">
                              <Input
                                type="date"
                                name={`yearOfStudy_${levelIndex}_${gradeIndex}`}
                                variant="green"
                                showYearsOnly={true}
                              />
                            </td>
                            {columns.map((column) => (
                              <React.Fragment key={column.id}>
                                <td className="p-2 border border-[#999] text-xs">
                                  <Currency variant="green" />
                                </td>
                                <td className="p-2 border border-[#999] text-xs">
                                  <Input
                                    type="date"
                                    name={`yearOfStudy_${levelIndex}_${gradeIndex}_${column.id}`}
                                    variant="green"
                                    showYearsOnly={true}
                                  />
                                </td>
                              </React.Fragment>
                            ))}
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <Button
                onClick={addColumn}
                className="mt-4"
              >
                Add
              </Button>
            </Card>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EducationTabOne;
