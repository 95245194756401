import createAxiosInstance from "@api/Api";
import { FormEvent, useEffect, useState } from "react";
import calculations from "@services/algorithms/calculations";
import helpers from "@utils/helpers";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";

interface GenericInputs {
  primary_representation: string;
  primary_representation_id: string;
  growth_rate: string;
  projected_inflation_rate: string;
  effective_date: string;
}

interface GrowthCostAssumptionsInputs {
  real_growth_low: number;
  real_growth_medium: number;
  real_growth_high: number;
  nominal_growth_low: number;
  nominal_growth_medium: number;
  nominal_growth_high: number;
}

interface AssetCostInputs {
  v1_price: number;
  v2_price: number;
  price_difference: number;
  v1_trv: number;
  v2_trv: number;
  trv_difference: number;
  v1_fin_amount: number;
  v2_fin_amount: number;
  fin_amount_difference: number;
  v1_trade_in: number;
  v2_trade_in: number;
  trade_in_differece: number;
  v1_term: number;
  v2_term: number;
  v1_interest: number;
  v2_interest: number;
  interest_difference: number;
  v1_ann_fin_amount: number;
  v2_ann_fin_amount: number;
  ann_fin_amount_difference: number;
}

interface AssetPreservationCostInputs {
  v1_ann_ins: number;
  v2_ann_ins: number;
  ann_ins_difference: number;
  v1_ann_maintenance: number;
  v2_ann_maintenance: number;
  ann_maintenance_difference: number;
  v1_ann_cost: number;
  v2_ann_cost: number;
  ann_cost_difference: number;
  v1_ann_cost_total: number;
  v2_ann_cost_total: number;
  ann_cost_total_difference: number;
  v1_percentage_value: number;
  v2_percentage_value: number;
}

interface AssetReplacementCostInputs {
  real_v1_tar_value: number;
  real_v2_tar_value: number;
  nominal_v1_tar_value: number;
  nominal_v2_tar_value: number;
  v1_rep_cycle: number;
  v2_rep_cycle: number;
}

interface AssetDepreciationInputs {
  v1_ann_veh_dep: number;
  v2_ann_veh_dep: number;
  future_years_hence: number;
  real_cumulative_low: number;
  real_cumulative_medium: number;
  real_cumulative_high: number;
  nominal_cumulative_low: number;
  nominal_cumulative_medium: number;
  nominal_cumulative_high: number;
}

interface FormData
  extends GenericInputs,
    GrowthCostAssumptionsInputs,
    AssetCostInputs,
    AssetPreservationCostInputs,
    AssetReplacementCostInputs,
    AssetDepreciationInputs {}

const useVehicleCostTool = () => {
  const api = createAxiosInstance("standard/lifestyle/vehicle-cost");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();
  const { calculateDifference, calculateMinusDifference, calculateAnnualFinance, calculateValues, calculateRatio } =
    calculations();
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState<FormData>({
    // Generic inputs
    primary_representation: "",
    primary_representation_id: "",
    growth_rate: "Low",
    projected_inflation_rate: "",
    effective_date: "",
    // Growth & cost assumptions
    real_growth_low: 0,
    real_growth_medium: 0,
    real_growth_high: 0,
    nominal_growth_low: 0,
    nominal_growth_medium: 0,
    nominal_growth_high: 0,
    // Smart tool specific inputs
    // Asset cost
    v1_price: 500000,
    v2_price: 400000,
    price_difference: 0,
    v1_trv: 100000,
    v2_trv: 100000,
    trv_difference: 0,
    v1_fin_amount: 0,
    v2_fin_amount: 0,
    fin_amount_difference: 0,
    v1_trade_in: -100000,
    v2_trade_in: -100000,
    trade_in_differece: 0,
    v1_term: 5,
    v2_term: 5,
    v1_interest: 10.5,
    v2_interest: 10.5,
    interest_difference: 0,
    v1_ann_fin_amount: 0,
    v2_ann_fin_amount: 0,
    ann_fin_amount_difference: 0,
    // Asset preservation & cost of ownership
    v1_ann_ins: -14500,
    v2_ann_ins: -11500,
    ann_ins_difference: 0,
    v1_ann_maintenance: -5000,
    v2_ann_maintenance: -4500,
    ann_maintenance_difference: 0,
    v1_ann_cost: -14500,
    v2_ann_cost: -12500,
    ann_cost_difference: 0,
    v1_ann_cost_total: 0,
    v2_ann_cost_total: 0,
    ann_cost_total_difference: 0,
    v1_percentage_value: 0,
    v2_percentage_value: 0,
    // Asset replacement
    real_v1_tar_value: 1.66,
    real_v2_tar_value: 0.71,
    nominal_v1_tar_value: 7,
    nominal_v2_tar_value: 6,
    v1_rep_cycle: 5,
    v2_rep_cycle: 10,
    // Asset depreciation
    v1_ann_veh_dep: -11,
    v2_ann_veh_dep: -9,
    future_years_hence: 25,
    real_cumulative_low: 0,
    real_cumulative_medium: 0,
    real_cumulative_high: 0,
    nominal_cumulative_low: 0,
    nominal_cumulative_medium: 0,
    nominal_cumulative_high: 0,
  });

  const { calculateNominalGrowthRate, handleNominalChange, handleRealChange } = growthRateHandler(
    formData,
    setFormData
  );

  useEffect(() => {
    const calculateAndSetValues = () => {
      const priceDifference = calculateDifference(formData.v1_price, formData.v2_price);
      const trvDifference = calculateDifference(formData.v1_trv, formData.v2_trv);
      const tradeInDifferece = calculateDifference(formData.v1_trade_in, formData.v2_trade_in);
      const v1FinAmount = calculateMinusDifference(formData.v1_price, formData.v1_trade_in);
      const v2FinAmount = calculateMinusDifference(formData.v2_price, formData.v2_trade_in);
      const finAmountDifference = calculateDifference(v1FinAmount, v2FinAmount);
      const interestDifference = calculateDifference(formData.v1_interest, formData.v2_interest);
      const v1AnnualFinance = calculateAnnualFinance(formData.v1_interest, formData.v1_term, formData.v1_fin_amount);
      const v2AnnualFinance = calculateAnnualFinance(formData.v2_interest, formData.v2_term, formData.v2_fin_amount);
      const annualFinanceDifference = calculateDifference(v1AnnualFinance, v2AnnualFinance);
      const annInsDifference = calculateDifference(formData.v1_ann_ins, formData.v2_ann_ins);
      const annMaintenanceDifference = calculateDifference(formData.v1_ann_maintenance, formData.v2_ann_maintenance);
      const annCostDifference = calculateDifference(formData.v1_ann_cost, formData.v2_ann_cost);
      const v1AnnCostTotal = calculateValues([formData.v1_ann_ins, formData.v1_ann_maintenance, formData.v1_ann_cost]);
      const v2AnnCostTotal = calculateValues([formData.v2_ann_ins, formData.v2_ann_maintenance, formData.v2_ann_cost]);
      const annCostTotalDifference = calculateDifference(v1AnnCostTotal, v2AnnCostTotal);
      const v1PercentageValue = calculateRatio(v1AnnCostTotal, formData.v1_price);
      const v2PercentageValue = calculateRatio(v2AnnCostTotal, formData.v2_price);
      // Set form data with calculated values
      setFormData((prevFormData) => ({
        ...prevFormData,
        price_difference: priceDifference,
        trv_difference: trvDifference,
        trade_in_differece: tradeInDifferece,
        v1_fin_amount: v1FinAmount,
        v2_fin_amount: v2FinAmount,
        fin_amount_difference: finAmountDifference,
        interest_difference: interestDifference,
        v1_ann_fin_amount: v1AnnualFinance,
        v2_ann_fin_amount: v2AnnualFinance,
        ann_fin_amount_difference: annualFinanceDifference,
        ann_ins_difference: annInsDifference,
        ann_maintenance_difference: annMaintenanceDifference,
        ann_cost_difference: annCostDifference,
        v1_ann_cost_total: v1AnnCostTotal,
        v2_ann_cost_total: v2AnnCostTotal,
        ann_cost_total_difference: annCostTotalDifference,
        v1_percentage_value: v1PercentageValue,
        v2_percentage_value: v2PercentageValue,
      }));
    };

    calculateAndSetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.v1_price,
    formData.v2_price,
    formData.price_difference,
    formData.v1_trv,
    formData.v2_trv,
    formData.trv_difference,
    formData.v1_trade_in,
    formData.v2_trade_in,
    formData.v1_fin_amount,
    formData.v2_fin_amount,
    formData.fin_amount_difference,
    formData.v1_term,
    formData.v2_term,
    formData.v1_interest,
    formData.v2_interest,
    formData.interest_difference,
    formData.v1_ann_fin_amount,
    formData.v2_ann_fin_amount,
    formData.ann_fin_amount_difference,
    formData.v1_ann_ins,
    formData.v2_ann_ins,
    formData.ann_ins_difference,
    formData.v1_ann_maintenance,
    formData.v2_ann_maintenance,
    formData.ann_maintenance_difference,
    formData.v1_ann_cost,
    formData.v2_ann_cost,
    formData.ann_cost_difference,
    formData.v1_ann_cost_total,
    formData.v2_ann_cost_total,
    formData.v1_percentage_value,
    formData.v2_percentage_value,
  ]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nominal_growth_low: calculateNominalGrowthRate(
        formData.real_growth_low,
        parseFloat(formData.projected_inflation_rate)
      ),
      nominal_growth_medium: calculateNominalGrowthRate(
        formData.real_growth_medium,
        parseFloat(formData.projected_inflation_rate)
      ),
      nominal_growth_high: calculateNominalGrowthRate(
        formData.real_growth_high,
        parseFloat(formData.projected_inflation_rate)
      ),
    }));
  }, [formData.projected_inflation_rate]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    //@ts-ignore
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: FormData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      setFormData({
        ...formData,
        real_cumulative_low: data.real_cumulative_low || 0,
        real_cumulative_medium: data.real_cumulative_medium || 0,
        real_cumulative_high: data.real_cumulative_high || 0,
        nominal_cumulative_low: data.nominal_cumulative_low || 0,
        nominal_cumulative_medium: data.nominal_cumulative_medium || 0,
        nominal_cumulative_high: data.nominal_cumulative_high || 0,
      });

      const { series, labels } = convertChartSeries(data.data);

      //@ts-ignore
      setChartSeries(series);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
      }));
      return data;
    } catch (error: any) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData: any) => {
    const years = Object.values(chartData.years);

    const cumulativeOpportunityCost = Object.values(chartData.cumulative_opportunity_cost).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const vehicleOne = Object.values(chartData.vehicle_1).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const vehicleTwo = Object.values(chartData.vehicle_2).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const series = [
      {
        name: "Cumulative Opportunity Cost",
        type: "line",
        color: "#ffc000",
        data: cumulativeOpportunityCost,
      },
      {
        name: "Vehicle #1 market value",
        type: "line",
        color: "#4472c4",
        data: vehicleOne,
      },
      {
        name: "Vehicle #2 market value",
        type: "line",
        color: "#ed7d31",
        data: vehicleTwo,
      },
    ];

    const labels = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Year " + year;
    });

    return { series, labels };
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        primary_representation_id: data.ufs.primary_representation_id || "",
        primary_representation: data.ufs.primary_representation || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        effective_date: data.ufs.effective_date,
        real_growth_low: data.ufs.real_pre_retirement_capital_growth_low || "",
        real_growth_medium: data.ufs.real_pre_retirement_capital_growth_medium || "",
        real_growth_high: data.ufs.real_pre_retirement_capital_growth_high || "",
        nominal_growth_low: data.ufs.nominal_pre_retirement_capital_growth_low || "",
        nominal_growth_medium: data.ufs.nominal_pre_retirement_capital_growth_medium || "",
        nominal_growth_high: data.ufs.nominal_pre_retirement_capital_growth_high || "",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    setFormData,
    handleSubmit,
    chartSeries,
    setChartSeries,
    chartOptions,
    setChartOptions,
    handleNominalChange,
    handleRealChange,
  };
};

export default useVehicleCostTool;
