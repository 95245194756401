import { ChangeEvent } from "react";

const Change = (formData: any, setFormData: any) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type, dataset } = e.target;

    if (type === "number") {
      const numValue = parseFloat(value);
      const valueType = dataset.valueType as "positive" | "negative" | undefined;

      if ((valueType === "positive" && numValue < 0) || (valueType === "negative" && numValue > 0)) {
        return;
      }
    }

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleYearChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: parseInt(value),
    }));
  };

  const handleDecimalChange = (value: string | undefined, name?: string) => {
    if (name) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleCurrencyChange = (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative" = "any"
  ) => {
    if (value !== undefined && name) {
      let cleanValue = value.replace("%", "").trim();

      if (cleanValue.endsWith(".")) {
        cleanValue += "0";
      }

      let numericValue = cleanValue === "" ? 0 : Number(cleanValue);

      switch (valueType) {
        case "positive":
          numericValue = Math.abs(numericValue);
          break;
        case "negative":
          numericValue = numericValue === 0 ? 0 : -Math.abs(numericValue);
          break;
      }

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [name]: cleanValue.endsWith(".") ? cleanValue : numericValue,
      }));
    }
  };

  const handlePrimaryRepresentationChange = (value: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      primary_representation: value,
    }));
  };

  const handleFundSourceChange = (value: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      fund_source_representation: value,
    }));
  };

  const handleToggleChange = (value: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      set: value,
    }));
    return value;
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const type = formData.primary_representation === "real";
  const fundSourceType = formData.fund_source_representation === "retirement";

  return {
    type,
    fundSourceType,
    handleToggleChange,
    handleChange,
    handleYearChange,
    handleNumberChange,
    handleCurrencyChange,
    handleDecimalChange,
    handlePrimaryRepresentationChange,
    handleFundSourceChange,
    handleSelectChange,
  };
};

export default Change;
