import Card from "@/components/Element/Card";
import ChartLoading from "@/components/Element/ChartLoading";
import Input from "@/components/Form/Input";
import RadioFull from "@/components/Form/RadioFull";
import words from "@/services/algorithms/words";
import React, { ChangeEvent, useState } from "react";
import Chart from "react-apexcharts";

const selectChart = [
  {
    id: 1,
    name: "Physical asests",
    slug: "physical-assets",
  },
  {
    id: 2,
    name: "Financial asests",
    slug: "financial-assets",
  },
  {
    id: 3,
    name: "Investable human capital",
    slug: "investable-human-capital",
  },
  {
    id: 4,
    name: "Total wealth",
    slug: "total-wealth",
  },
];

interface WealthTabTwoProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  formData: any;
  loading: boolean;
  chartSeries: any;
  chartOptions: any;
}

const WealthTabTwo: React.FC<WealthTabTwoProps> = ({
  handleSubmit,
  preventEnterPress,
  formData,
  loading,
  chartSeries,
  chartOptions,
}) => {
  const [selectedChart, setSelectedChart] = useState<string>("");
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedChart(event.target.value);
  };
  const { capitalizeFirstLetter } = words();

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
            <Card
              variant="white"
              className="mb-4"
            >
              <form onSubmit={handleSubmit}>
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-[12px]">Select chart</th>
                      <th className="text-[12px]">Pre-retirement</th>
                      <th className="text-[12px]">Post-retirement</th>
                      <th className="text-[12px]">Full period</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectChart.map((chart, index) => (
                      <tr key={`selectChart-${index}`}>
                        <td
                          className={`text-[12px] text-left ${index === selectChart.length - 1 ? "font-bold" : "font-semibold"}`}
                        >
                          {chart.name}
                        </td>
                        <td>
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={selectedChart === chart.slug}
                            centerToggle
                          />
                        </td>
                        <td className="text-[12px] font-semibold">
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={false}
                            centerToggle
                          />
                        </td>
                        <td className="text-[12px] font-semibold">
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={false}
                            centerToggle
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>

              <p className="mt-4 text-[12px] font-bold">
                The charts show how your wealth (physical, financial and potential) evolves over time, how this wealth
                is distributed across the underlying components, and projects how long your financial wealth will last
                in retirement (this may precipitate the need to liquidate some physical wealth). Note this is shown
                under "inferences
              </p>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Projection date (select)
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">30 Apr 2048</th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">31 Mar 2053</th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">31 Mar 2063</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-red-500">
                      Age at event
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2 text-red-500"></td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2 text-red-500"></td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2 text-red-500"></td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Net physical assets
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2"></td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2"></td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2"></td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Net financial assets
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2"></td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2"></td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2"></td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Investable human capital
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2"></td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2"></td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-semibold p-2"></td>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">TOTAL</td>
                    <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2">.</td>
                    <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2">.</td>
                    <td className="border-b border-r border-[#999] text-center text-[12px] font-bold p-2">.</td>
                  </tr>
                </tbody>
              </table>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <p className="text-[12px] font-bold">
                Attached chart represents your potential human capital and compares it against your projected physical
                and financial wealth over time. We would like to see whether you have converted potential capital int
                actual wealth, as well as how “efficient” you were.
              </p>
              <ul className="pl-4 list-[disc]">
                <li>
                  <p className="text-[12px] font-bold">
                    {`Total human capital = the present value (after-tax) of all your future labours `}
                  </p>
                </li>
                <li>
                  <p className="text-[12px] font-bold">{`Potential = assumes a set amount (25%) of Total was put aside for wealth creation
`}</p>
                </li>
                <li>
                  <p className="text-[12px] font-bold">{`Cumulative contributions is the sum of all contributions to wealth creation activities
across investments, retirement, property and other formal activities`}</p>
                </li>
                <li>
                  <p className="text-[12px] font-bold">{`Cumulative withdrawals are indicated by your projected expenditures`}</p>
                </li>
              </ul>
              <p className="text-[12px] font-bold mt-2">
                Note that human capital is a depreciating asset – exists for as long as you can “work”
              </p>
            </Card>

            <Card
              variant="white"
              className="mb-2"
            >
              <div className="overflow-x-auto">
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 min-w-[120px]">
                        {capitalizeFirstLetter(formData.primary_representation)} projections
                      </th>
                      <th
                        colSpan={4}
                        className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2"
                      >
                        <Input
                          label="Select dates from"
                          type="date"
                          variant="green"
                          showYearsOnly
                          alignText="right"
                        />
                      </th>
                    </tr>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Description</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Current* May-2020
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        +10 yrs* May-2030
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Retirement* Apr-2048
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        + 10 yrs* Apr-2058
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center text-red-500">
                        Potential capital
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center text-red-500"></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center text-red-500"></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center text-red-500"></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center text-red-500"></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center ">
                        Investable potential capital (@25%)
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center ">
                        Future cumulative contributions
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center ">
                        Projected physical wealth
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center ">
                        Projected financial wealth
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                    </tr>
                    <tr className="border-y-2 border-black font-bold">
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center ">
                        Projected total wealth
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center ">
                        Future cumulative withdrawals
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center "></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>
          </div>
          <div className="mb-1 h-[calc(100vh-240px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {selectChart.find((chart) => chart.slug === selectedChart)?.name}
                {formData.primary_representation && " - " + formData.primary_representation}
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-full relative">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </>
  );
};

export default WealthTabTwo;
