import Card from "@/components/Element/Card";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import Button from "@/components/Form/Button";
import Currency from "@/components/Form/Currency";
import Input from "@/components/Form/Input";
import Percentage from "@/components/Form/Percentage";
import PercentageSwitch from "@/components/Form/PercentageSwitch";
import Select from "@/components/Form/Select";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PostRetirement from "@/components/GrowthCostAssumptions/PostRetirement";
import PreRetirement from "@/components/GrowthCostAssumptions/PreRetirement";
import React, { ChangeEvent, useState } from "react";
import { CurrencyInputOnChangeValues } from "react-currency-input-field";
import { IoCloseCircleOutline } from "react-icons/io5";

interface InvestmentTabOneProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  handleRealChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleNominalChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleDecimalChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
  formData: any;
}

interface InvestmentProps {
  nominalField?: any;
  realField?: any;
  name?: string;
  inputType: "input" | "currency" | "percentage" | "percentageSwitch" | "date" | "select";
  variant?: "green" | "red";
  disabled?: boolean;
}

const select = [
  {
    id: 1,
    name: "Yes",
  },
  {
    id: 2,
    name: "No",
  },
];

const initialInvestmentInputs: InvestmentProps[] = [
  {
    name: "initial_value",
    inputType: "currency",
    variant: "green",
  },
  {
    name: "next_contribution",
    inputType: "currency",
    variant: "green",
  },
  {
    name: "next_contribution_date",
    inputType: "date",
    variant: "green",
  },
  {
    name: "final_contribution_date",
    inputType: "date",
    variant: "green",
  },
  {
    realField: "real_annual_increase_in_contributions",
    nominalField: "nominal_annual_increase_in_contributions",
    inputType: "percentageSwitch",
    variant: "red",
  },

  {
    name: "initialisation_increase_month",
    inputType: "input",
    variant: "green",
  },
  {
    name: "first_withdrawal_date",
    inputType: "date",
    variant: "green",
  },
  {
    name: "initial_withdrawal",
    inputType: "percentage",
    variant: "green",
  },
  {
    realField: "annual_real_increase_in_contributions",
    nominalField: "annual_nominal_increase_in_contributions",
    inputType: "percentageSwitch",
    variant: "red",
  },
  {
    name: "withdrawal_increase_month",
    inputType: "input",
    variant: "green",
  },
  {
    name: "pre_retirement_growth_rate",
    inputType: "percentage",
    variant: "red",
  },
  {
    name: "post_retirement_growth_rate",
    inputType: "percentage",
    variant: "red",
  },
];

const InvestmentTabOne: React.FC<InvestmentTabOneProps> = ({
  handleSubmit,
  handleChange,
  handleCurrencyChange,
  handleSelectChange,
  preventEnterPress,
  isVisible,
  toggleVisibility,
  handleRealChange,
  handleNominalChange,
  handleDecimalChange,
  formData,
}) => {
  const [columns, setColumns] = useState<number>(0);
  const [data, setData] = useState<InvestmentProps[][]>([]);

  const addColumn = () => {
    setColumns(columns + 1);
    setData([...data, initialInvestmentInputs]);
  };

  const removeColumn = (index: number) => {
    setColumns(columns - 1);
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
  };

  const StaticTableColumns = () => {
    return (
      <>
        <tr>
          <td
            rowSpan={6}
            className="p-2 border border-r border-gray-300 text-xs font-bold bg-[#f1f1f1]"
          >
            Initialisation
          </td>
          <td className="p-2 border border-gray-300 text-xs">Initial value</td>
        </tr>
        <tr>
          <td className="p-2 border border-r border-gray-300 text-xs">Next contribution</td>
        </tr>
        <tr>
          <td className="p-2 border border-r border-gray-300 text-xs">Next contribution date </td>
        </tr>
        <tr>
          <td className="p-2 border border-r border-gray-300 text-xs">Final contribution date</td>
        </tr>
        <tr>
          <td className="p-2 border border-r border-gray-300 text-xs max-h-[47px]">
            Annual {formData.primary_representation} increase in withdrawals
          </td>
        </tr>
        <tr className="border-b-2 border-gray-400">
          <td className="p-2 border border-r border-gray-300 text-xs">Increase month</td>
        </tr>
        <tr>
          <td
            rowSpan={4}
            className="p-2 border border-r border-gray-300 text-xs font-bold bg-[#f1f1f1]"
          >
            Withdrawal
          </td>
          <td className="p-2 border border-gray-300 text-xs">First withdrawal date</td>
        </tr>
        <tr>
          <td className="p-2 border border-gray-300 text-xs">Initial withdrawal (% of capital)</td>
        </tr>
        <tr>
          <td className="p-2 border border-gray-300 text-xs">Annual increase in withdrawals</td>
        </tr>
        <tr className="border-b-2 border-gray-400">
          <td className="p-2 border border-gray-300 text-xs">Increase month</td>
        </tr>
        <tr>
          <td
            rowSpan={2}
            className="p-2 border border-r border-gray-300 text-xs font-bold bg-[#f1f1f1]"
          >
            Growth{" "}
          </td>
          <td className="p-2 border border-gray-300 text-xs">Pre-retirement growth rate</td>
        </tr>
        <tr>
          <td className="p-2 border border-gray-300 text-xs">Post-retirement growth rate</td>
        </tr>
      </>
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
          <div className="w-full">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <Card
                  variant="white"
                  className="h-full"
                >
                  <div className="flex items-center mb-2">
                    <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                    <span className="ml-4 cursor-pointer">
                      <VisibilityToggle
                        keyName="GenericTable"
                        isVisible={isVisible("GenericTable")}
                        toggleVisibility={() => toggleVisibility("GenericTable")}
                      />
                    </span>
                  </div>
                  <LayoutOne
                    formData={formData}
                    handleChange={handleChange}
                    handleCurrencyChange={handleCurrencyChange}
                    isVisible={isVisible("GenericTable")}
                  />
                </Card>
              </div>
              <div>
                <Card
                  variant="white"
                  className="h-full"
                >
                  <div className={`flex items-center mb-4`}>
                    <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                    <span className="ml-4 cursor-pointer">
                      <VisibilityToggle
                        keyName="GrowthTable"
                        isVisible={isVisible("GrowthTable")}
                        toggleVisibility={toggleVisibility}
                      />
                    </span>
                  </div>
                  <table className="table-auto w-full">
                    <thead>
                      <tr>
                        <th className="w-[34%]"></th>
                        <th className="text-[12px] w-[22%]">Low</th>
                        <th className="text-[12px] w-[22%]">Medium</th>
                        <th className="text-[12px] w-[22%]">High</th>
                      </tr>
                    </thead>
                    <tbody>
                      <PreRetirement
                        formData={formData}
                        handleRealChange={handleRealChange}
                        handleNominalChange={handleNominalChange}
                        handleDecimalChange={handleDecimalChange}
                        isVisible={isVisible("GrowthTable")}
                      />
                      <PostRetirement
                        formData={formData}
                        handleRealChange={handleRealChange}
                        handleNominalChange={handleNominalChange}
                        handleDecimalChange={handleDecimalChange}
                        isVisible={isVisible("GrowthTable")}
                      />
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
            <div className="col-span-7">
              <Card variant="white">
                <div className="mb-3">
                  <h3 className="text-[16px] font-semibold">Investment & savings fund values and contributions</h3>
                </div>
                <div className="overflow-x-auto flex">
                  <table className={`table-auto border`}>
                    <thead className="bg-white border">
                      <tr>
                        <th className="bg-[#f1f1f1] text-[12px] font-bold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[200px] h-[47px]">
                          Category
                        </th>
                        <th className="bg-[#f1f1f1] text-[12px] font-bold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[200px] h-[47px]">
                          Description
                        </th>
                      </tr>
                    </thead>
                    <tbody>{StaticTableColumns()}</tbody>
                  </table>
                  <table className={`table-auto border`}>
                    <thead className="bg-white border">
                      <tr>
                        {Array.from({ length: columns }).map((_, colIndex) => (
                          <th
                            key={colIndex}
                            className="bg-[#f1f1f1] text-[12px] font-semibold p-2 border-r-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[200px] w-[200px] h-[47px]"
                          >
                            <div className="flex items-center justify-between gap-2">
                              <div className="w-[12px]" />
                              <Input
                                variant="bottomborder"
                                autoFocus
                                required
                              />
                              <IoCloseCircleOutline
                                onClick={() => removeColumn(colIndex)}
                                color="red"
                                className="mr-1 cursor-pointer"
                              />
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {initialInvestmentInputs.map((input, rowIndex) => (
                        <>
                          <tr
                            key={rowIndex}
                            className={`${rowIndex === 9 || rowIndex === 5 ? "border-b-2 border-gray-400" : ""}`}
                          >
                            {data.map((_column, colIndex) => (
                              <td
                                key={colIndex}
                                className={`p-2 border-r border-b border-gray-300`}
                              >
                                {(() => {
                                  switch (input.inputType) {
                                    case "input":
                                      return (
                                        <Input
                                          type="number"
                                          name={input.name}
                                          variant={input.variant}
                                          disabled={input.disabled}
                                        />
                                      );
                                    case "currency":
                                      return (
                                        <Currency
                                          name={input.name}
                                          variant={input.variant}
                                          disabled={input.disabled}
                                        />
                                      );
                                    case "percentage":
                                      return (
                                        <Percentage
                                          name={input.name}
                                          variant={input.variant}
                                          disabled={input.disabled}
                                          onChange={handleCurrencyChange}
                                        />
                                      );
                                    case "percentageSwitch":
                                      return (
                                        <PercentageSwitch
                                          primaryRepresentation={formData.primary_representation}
                                          realField={input.realField}
                                          nominalField={input.nominalField}
                                          formData={formData}
                                          variant={input.variant}
                                          onChange={handleCurrencyChange}
                                          disabled={input.disabled}
                                          required={true}
                                        />
                                      );
                                    case "date":
                                      return (
                                        <Input
                                          type="date"
                                          name={input.name}
                                          variant={input.variant}
                                          disabled={input.disabled}
                                        />
                                      );
                                    case "select":
                                      return (
                                        // TODO
                                        <Select
                                          defaultItem="Select"
                                          name={input.name}
                                          id={input.name}
                                          onChange={handleSelectChange}
                                          variant={input.variant ? input.variant : "green"}
                                          items={select}
                                        />
                                      );
                                    default:
                                      return null;
                                  }
                                })()}
                              </td>
                            ))}
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Button
                  onClick={addColumn}
                  className="mt-4"
                >
                  Add
                </Button>
              </Card>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default InvestmentTabOne;
