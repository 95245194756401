import words from "@/services/algorithms/words";

export interface HeadingType {
  text: string | React.ReactNode;
  id: string;
  colSpan?: number;
}
const { capitalizeFirstLetter } = words();

export const wealthTableHeadings = (formData: any): HeadingType[] => [
  { text: "Category", id: "category" },
  { text: "Description", id: "description" },
  { text: "Value at effective date", id: "effectiveDate" },
  { text: "Current liability", id: "currentLiability" },
  { text: "Net value", id: "netValue" },
  { text: `${capitalizeFirstLetter(formData?.primary_representation)} growth`, id: "growth" },
];

export const getTotalColumns = (formData: any) => wealthTableHeadings(formData).length;

// Keep this for backward compatibility
export const totalColumns = 6; // This should match the number of items in wealthTableHeadings
