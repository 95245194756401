import HorizontalTabs from "@/components/Element/HorizontalTabs";
import PageTitle from "@/components/Element/PageTitle";
import VerticalNav from "@/components/Element/VerticalNav";
import Button from "@/components/Form/Button";
import SelectToggle from "@/components/Form/ToggleSwitch";
import PremiumToolLayout from "@/layouts/PremiumToolLayout";
import usePremiumTool from "@/services/api/Premium/usePremiumTool";
import Change from "@/services/utils/change";
import helpers from "@/services/utils/helpers";
import useVisibility from "@/services/utils/useVisibility";
import { sections } from "..";
import ActiveIncomeTabOne from "./ActiveIncomeTabOne";
import ActiveIncomeTabTwo from "./ActiveIncomeTabTwo";

const ActiveIncomePremium: React.FC = () => {
  const { preventEnterPress } = helpers();
  const { isVisible, toggleVisibility } = useVisibility();
  const { loading, chartSeries, chartOptions, formData, setFormData, handleSubmit } = usePremiumTool();
  const { handleChange, handleCurrencyChange, handlePrimaryRepresentationChange, handleSelectChange } = Change(
    formData,
    setFormData
  );

  const tabs = [
    {
      id: "tab1",
      label: "Inputs",
      content: (
        <ActiveIncomeTabOne
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleCurrencyChange={handleCurrencyChange}
          handleSelectChange={handleSelectChange}
          preventEnterPress={preventEnterPress}
          isVisible={isVisible}
          toggleVisibility={toggleVisibility}
          formData={formData}
        />
      ),
    },
    {
      id: "tab2",
      label: "Financial projections & inferences",
      content: (
        <ActiveIncomeTabTwo
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          handleCurrencyChange={handleCurrencyChange}
          preventEnterPress={preventEnterPress}
          formData={formData}
          loading={loading}
          chartSeries={chartSeries}
          chartOptions={chartOptions}
        />
      ),
    },
  ];

  return (
    <PremiumToolLayout
      showBackButton
      backButtonNavigateTo="/premium"
      pageTitle="Active income"
    >
      <PageTitle title="Premium | Active Income" />
      <div className="fixed top-[215px] left-0 h-full max-h-[calc(100vh-168px)] bg-transparent overflow-y-auto scrollbar">
        <VerticalNav sections={sections} />
      </div>
      <div className="ml-[200px] p-4">
        <div className="flex items-center justify-between pb-4">
          <div className="flex-grow flex items-center justify-center">
            <div className="ml-auto"></div>
            <SelectToggle
              title="Analysis type:"
              selectedValue={formData?.primary_representation}
              options={[
                { value: "nominal", label: "Nominal" },
                { value: "real", label: "Real" },
              ]}
              handleChange={handlePrimaryRepresentationChange}
            />
            <div className="gap-4 flex ml-4">
              <Button>Financial scoreboard</Button>
              <Button className="bg-[#4ca852]">Save inputs</Button>
            </div>
            <div className="ml-auto">
              <Button className="bg-[#85322f]">Demo</Button>
            </div>
          </div>
        </div>
        <HorizontalTabs
          showArrows
          tabs={tabs}
        />
      </div>
    </PremiumToolLayout>
  );
};

export default ActiveIncomePremium;
