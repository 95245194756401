import InputTooltip from "@/components/Element/InputTooltip";
import { cn } from "@utils/cn";
import { cva } from "class-variance-authority";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaInfoCircle } from "react-icons/fa";
import { MdOutlineCommentsDisabled } from "react-icons/md";

type NumberValueType = "any" | "positive" | "negative";

type Props = {
  label?: string;
  type?: string;
  name?: string;
  id?: string;
  value?: string | number;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  inLine?: boolean;
  alignLeft?: boolean;
  alignText?: string;
  min?: number | string;
  max?: number | string;
  step?: number;
  tooltipText?: string;
  variant?: "white" | "red" | "green" | "bottomborder";
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChangeYear?: (date: Date | null) => void;
  twMergeClassName?: string;
  showYearsOnly?: boolean;
  autoFocus?: boolean;
  valueType?: "positive" | "negative";
  rows?: number;
};

export default function Input({
  label,
  type = "text",
  name,
  id,
  value = "",
  placeholder,
  required = false,
  disabled = false,
  readOnly = false,
  inLine = true,
  alignLeft = false,
  alignText = "left",
  min,
  max,
  step,
  tooltipText,
  variant = "white",
  twMergeClassName,
  showYearsOnly = false,
  onChange,
  onChangeYear,
  autoFocus = false,
  valueType,
  rows = 3,
}: Props) {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const [internalValue, setInternalValue] = useState<string | number>(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const formatNumberValue = (value: string, valueType: NumberValueType): string => {
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return "";

    switch (valueType) {
      case "positive":
        return Math.abs(numValue).toString();
      case "negative":
        return (-Math.abs(numValue)).toString();
      default:
        return numValue.toString();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue =
      type === "number" && valueType
        ? formatNumberValue(event.target.value, valueType as NumberValueType)
        : event.target.value;

    // Modify the original event's target value
    event.target.value = newValue;

    setInternalValue(newValue);
    onChange && onChange(event);
  };

  const inputVariants = cva(
    `${alignLeft || type === "textarea" ? "text-left" : "text-center"} ${twMergeClassName ?? ""} focus:ring-primary-500 focus:border-primary-500 h-[30px] p-1 rounded-md sm:text-[12px] block w-full sm:min-w-[70px] font-[600] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none cursor-text`,
    {
      variants: {
        variant: {
          white: `bg-gray-50 border border-gray-300 text-gray-900 px-4 ${disabled ? "cursor-not-allowed" : ""}`,
          red: `border border-gray-300 text-white icon-white px-4 ${disabled ? "bg-[#85322f] cursor-not-allowed" : "bg-[#a96f6d]"}`,
          green: `border border-gray-300 text-white icon-white px-4 ${disabled ? "bg-[#008308] cursor-not-allowed" : "bg-[#4ca852]"}`,
          bottomborder: `border-b rounded-none focus:none border-gray-300 px-1 ${disabled ? "bg-[transparent] cursor-not-allowed" : "bg-[transparent]"}`,
        },
      },
    }
  );

  const renderInput = () => {
    if (showYearsOnly && type === "date") {
      return (
        <DatePicker
          selected={value ? new Date(value) : null}
          onChange={onChangeYear}
          showYearPicker
          dateFormat="yyyy"
          placeholderText={placeholder}
          className={cn(inputVariants({ variant }))}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          value={typeof value === "number" ? value.toString() : value}
          name={name}
        />
      );
    } else if (type === "textarea") {
      return (
        <textarea
          id={id}
          name={name}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          className={cn(inputVariants({ variant }), "h-auto")}
          ref={inputRef as React.RefObject<HTMLTextAreaElement>}
          value={internalValue}
          onChange={handleInputChange}
          rows={rows}
        />
      );
    } else {
      return (
        <input
          id={id}
          min={min}
          max={max}
          step={step}
          type={type}
          name={name}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          className={cn(inputVariants({ variant }))}
          ref={inputRef as React.RefObject<HTMLInputElement>}
          value={internalValue}
          onChange={handleInputChange}
          data-value-type={valueType}
        />
      );
    }
  };

  return (
    <div className={`${inLine ? "flex" : ""} items-center relative`}>
      {label && (
        <label
          htmlFor={id}
          className={`${inLine && !tooltipText ? "block" : tooltipText && alignText === "left" ? "flex items-center gap-2" : tooltipText ? "gap-2" : "flex mb-2"} ${alignText === "right" ? "text-right" : "text-left"} mr-2 text-[12px] font-bold text-gray-900 w-full leading-[15px]`}
        >
          {tooltipText && (
            <InputTooltip
              tooltipText={tooltipText}
              alignText={alignText}
            >
              <FaInfoCircle />
            </InputTooltip>
          )}
          {label} {required ? " *" : ""}
        </label>
      )}
      {renderInput()}
      {disabled && (
        <div
          className={`${inLine ? "" : "h-fit top-8"} absolute inset-y-0 right-0.5 flex items-center pointer-events-none`}
        >
          <MdOutlineCommentsDisabled className={`h-[12px] ${variant === "white" ? "text-red-500" : "text-white"}`} />
        </div>
      )}
    </div>
  );
}
