import SelectToggle from "@/components/Form/ToggleSwitch";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PostRetirement from "@/components/GrowthCostAssumptions/PostRetirement";
import useMoneyLastTool from "@api/Standard/Retirement/useMoneyLastTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import { Decimal, rawDecimal } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import Change from "@utils/change";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";

const MoneyLast = () => {
  const { preventEnterPress } = helpers();
  const { isVisible, toggleVisibility } = useVisibility();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    setFormData,
    handleSubmit,
    handleNominalChange,
    handleRealChange,
    handleFutureChange,
    handlePresentChange,
  } = useMoneyLastTool();

  const { type, handleChange, handleCurrencyChange, handleDecimalChange, handlePrimaryRepresentationChange } = Change(
    formData,
    setFormData
  );

  const [showHelpPopup, _] = useState();

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is designed to show you how long your specific projected retirement pot will last you in
              retirement. Inputs required are:
              <ul className="pl-4">
                <li className="my-1">
                  a) Your projected accumulated value at retirement (use “How much will my money grow to by retirement?”
                  to determine this amount or use other estimate)
                </li>
                <li className="my-1">
                  b) Your projected final income pre-retirement – this is a function of your current income, how much
                  you expect it to increase annually until retirement, and your time to retirement.
                </li>
                <li className="my-1">
                  c) Your target SOL in retirement – this is a percentage of your projected final income.
                </li>
                <li className="my-1">
                  d) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output is represented by
              <ul className="pl-4">
                <li className="my-1">a) A multiple of your annual income at retirement</li>
                <li className="my-1">b) Years to depletion at each identified net growth rates</li>
              </ul>
            </li>
            <li className="mb-2">
              Chart is a choice of 6 representations (real & nominal at low/medium/high growth rates)
              <ul className="pl-4">
                <li className="my-1">
                  a) Real chart will have a histogram of flat withdrawals on x-axis; nominal will be increasing
                </li>
                <li className="my-1">
                  b) Real residual capital chart will decline to zero over depletion period; nominal may initially
                  increase and then decline to zero over the same time period.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="How long will my money last in retirement?"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Money Last" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-7 gap-4 w-full p-4">
          <div className="col-span-7">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  loading={loading}
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="max-h-[calc(100vh-170px)] h-full scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-2`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible("GrowthTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="w-[34%]"></th>
                      <th className="text-[12px] w-[22%]">Low</th>
                      <th className="text-[12px] w-[22%]">Medium</th>
                      <th className="text-[12px] w-[22%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PostRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Smart tool specific inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="SpecificInputs"
                      isVisible={isVisible("SpecificInputs")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                {!isVisible("SpecificInputs") && (
                  <div className="grid grid-cols-2 gap-2 items-end mt-2">
                    <div>
                      <Currency
                        label="Annual pre-tax income"
                        name="annual_pre_tax_income"
                        id="annual_pre_tax_income"
                        value={formData.annual_pre_tax_income}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>
                    <div>
                      <Percentage
                        label={`${type ? "Real growth in income" : "Nominal growth in income"}`}
                        name={`${type ? "real_growth_in_income" : "nominal_growth_in_income"}`}
                        id={`${type ? "real_growth_in_income" : "nominal_growth_in_income"}`}
                        value={`${type ? formData.real_growth_in_income : formData.nominal_growth_in_income}`}
                        onChange={(value, name) =>
                          type ? handleRealChange(value, name) : handleNominalChange(value, name)
                        }
                        variant="green"
                        alignText="right"
                      />
                    </div>
                    <div>
                      <Percentage
                        label="Target income on retirement"
                        name="post_retirement_income_percentage"
                        id="post_retirement_income_percentage"
                        value={formData.post_retirement_income_percentage}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>
                    <div>
                      <Currency
                        label="Projected fund value at retirement"
                        name={`${type ? "real_projected_retirement_fund_value" : "nominal_projected_retirement_fund_value"}`}
                        id={`${type ? "real_projected_retirement_fund_value" : "nominal_projected_retirement_fund_value"}`}
                        value={
                          type
                            ? formData.real_projected_retirement_fund_value
                            : formData.nominal_projected_retirement_fund_value
                        }
                        onChange={type ? handlePresentChange : handleFutureChange}
                        variant="green"
                        alignText="right"
                      />
                    </div>
                  </div>
                )}
              </Card>

              <Card
                variant="white"
                className="mb-1"
              >
                <div className="mb-2">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                  <p className="text-[12px] font-semibold mt-2">
                    The table below shows you how long it will be until your retirement capital is fully depleted at
                    your currrent standard of living assuming varying growth rates in retirement
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-6 mb-2">
                  <div>
                    <Currency
                      label="Target income at retirement:"
                      value={
                        type ? formData.real_target_income_at_retirement : formData.nominal_target_income_at_retirement
                      }
                      variant="red"
                      disabled
                    />
                  </div>
                  <div>
                    <Input
                      label="Projected multiple:"
                      onChange={handleChange}
                      value={rawDecimal(formData.multiple_on_retirement)}
                      variant="red"
                      disabled
                      alignText="right"
                    />
                  </div>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[40%]">
                        Description
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Years to full depletion at projected growth rate
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal value={formData.full_depletion_growth_rate_low} />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal value={formData.full_depletion_growth_rate_medium} />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal value={formData.full_depletion_growth_rate_high} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4 mb-1 min-h-[calc(100vh-175px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                Retirement - {formData.primary_representation} withdrawals & residual capital
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-[calc(100vh-250px)] relative">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default MoneyLast;
