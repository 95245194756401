import Currency from "@/components/Form/Currency";
import GrowthRate from "@/components/Form/GrowthRate";
import SelectToggle from "@/components/Form/ToggleSwitch";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PreRetirement from "@/components/GrowthCostAssumptions/PreRetirement";
import words from "@/services/algorithms/words";
import useGoalInvestingTool from "@api/Standard/WealthCreation/useGoalInvestingTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import { CommaSeparated } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Input from "@form/Input";
import StandardToolLayout from "@layouts/StandardToolLayout";
import Change from "@utils/change";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";

const GoalInvesting = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();
  const { preventEnterPress } = helpers();
  const {
    loading,
    formData,
    setFormData,
    handleSubmit,
    chartOptions,
    chartSeries,
    handleNominalChange,
    handleRealChange,
    handleTargetCapitalChange,
    handlePresentCapitalChange,
  } = useGoalInvestingTool();

  const { type, handleChange, handleCurrencyChange, handleDecimalChange, handlePrimaryRepresentationChange } = Change(
    formData,
    setFormData
  );

  const [showHelpPopup, _] = useState();

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is designed to assist clients in identifying how much they need to put aside on a monthly basis
              over a set horizon to achieve a target outcome. Inputs required are:
              <ul className="pl-4">
                <li className="my-1">a) Specific: horizon, initial capital, target capital (real or nominal)</li>
                <li className="my-1">
                  b) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output is
              <ul className="pl-4">
                <li className="my-1">
                  a) Table of monthly contributions (savings) required over full period on a “flat” or “annually
                  increasing by inflation basis” for the 3 different growth environments
                </li>
                <li className="my-1">
                  b) Chart shows:
                  <ul className="pl-4">
                    <li className="my-1">i) Value of cumulative contributions</li>
                    <li className="my-1">ii) Value of accumulated capital over horizon</li>
                    <li className="my-1">iii) Scale is generally monthly due to monthly nature of contributions.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="How much do I need to invest to reach my goal?"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Goal Investing" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-7 gap-4 w-full p-4">
          <div className="col-span-7">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  loading={loading}
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible("GrowthTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="w-[34%]"></th>
                      <th className="text-[12px] w-[22%]">Low</th>
                      <th className="text-[12px] w-[22%]">Medium</th>
                      <th className="text-[12px] w-[22%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PreRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Smart tool specific inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="SpecificInputs"
                      isVisible={isVisible("SpecificInputs")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>

                {!isVisible("SpecificInputs") && (
                  <div className="grid grid-cols-2 gap-2 items-end mt-4">
                    <div>
                      <Input
                        min={12}
                        label="Horizon (months)"
                        name="horizon_months"
                        id="horizon_months"
                        type="number"
                        value={formData.horizon_months}
                        onChange={handleChange}
                        variant="green"
                      />
                    </div>
                    <div>
                      <Input
                        min={1}
                        max={10}
                        step={1}
                        label="Horizon (years)"
                        name="horizon_years"
                        id="horizon_years"
                        value={formData.horizon_years.toFixed(2)}
                        variant="red"
                        disabled={true}
                        alignText="right"
                      />
                    </div>
                    <div>
                      <Input
                        label="Target date"
                        name="target_date"
                        id="target_date"
                        type="date"
                        value={formData.target_date}
                        variant="red"
                        disabled={true}
                      />
                    </div>
                    <div>
                      <Currency
                        min="1"
                        label="Initial capital"
                        name="initial_capital"
                        id="initial_capital"
                        type="number"
                        value={formData.initial_capital}
                        onChange={handleCurrencyChange}
                        variant="green"
                        alignText="right"
                      />
                    </div>
                    <div>
                      <Currency
                        min="0.01"
                        step="0.01"
                        label={`Target capital -
                      (${formData.primary_representation})`}
                        name={`${type ? "real_target_capital" : "nominal_target_capital"}`}
                        id={`${type ? "real_target_capital" : "nominal_target_capital"}`}
                        value={type ? formData.real_target_capital : formData.nominal_target_capital}
                        onChange={type ? handlePresentCapitalChange : handleTargetCapitalChange}
                        variant={type ? "green" : "red"}
                        disabled={type ? false : true}
                      />
                    </div>
                  </div>
                )}
              </Card>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                  <p className="text-[12px] font-semibold mt-2">
                    The table below shows the amount that needs to be saved per month to achieve your target at
                    different growth rates. The first row assumes a flat payment, the second, an amount that increases
                    every year by prevailing inflation rate.
                  </p>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[40%]">
                        Description
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Monthly savings required - no annual increase
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.savings_no_increase_low}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.savings_no_increase_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.savings_no_increase_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Monthly savings required - CPI annual increase
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.savings_increase_low}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.savings_increase_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={formData.savings_increase_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4 mb-1 min-h-[calc(100vh-175px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {capitalizeFirstLetter(formData.primary_representation)} representation - CPI annual increase
              </p>

              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-[calc(100vh-243px)] relative">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default GoalInvesting;
