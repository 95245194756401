import createAxiosInstance from "@api/Api";
import { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

interface HistoryData {
  selected_system: string;
  last_logged_in: string;
  effective_date: string;
  personal: string;
  financial: string;
  retirement: string;
  lifestyle: string;
  wealth_creation: string;
  other: string;
}

const useHistory = () => {
  const api: AxiosInstance = createAxiosInstance("user/history");
  const [loading, setLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<HistoryData>({
    selected_system: "",
    last_logged_in: "",
    effective_date: "",
    personal: "",
    financial: "",
    retirement: "",
    lifestyle: "",
    wealth_creation: "",
    other: "",
  });

  const getHistory = async (): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await api.get<HistoryData>("");

      setFormData({
        selected_system: data.selected_system || "",
        last_logged_in: data.last_logged_in || "",
        effective_date: data.effective_date || "",
        personal: data.personal || "",
        financial: data.financial || "",
        retirement: data.retirement || "",
        lifestyle: data.lifestyle || "",
        wealth_creation: data.wealth_creation || "",
        other: data.other || "",
      });
    } catch (error) {
      toast.error(error as string);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    formData,
  };
};

export default useHistory;
