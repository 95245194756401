import InputTooltip from "@/components/Element/InputTooltip";
import { cn } from "@utils/cn";
import { cva } from "class-variance-authority";
import { useEffect, useRef } from "react";
import CurrencyInput from "react-currency-input-field";
import { FaInfoCircle } from "react-icons/fa";
import { MdOutlineCommentsDisabled } from "react-icons/md";

export default function Currency(props) {
  const {
    label,
    name,
    id,
    value,
    placeholder,
    required = false,
    disabled = false,
    readOnly = false,
    inLine = true,
    alignLeft = false,
    alignText = "left",
    allowNegativeValue = false,
    onChange,
    variant,
    twMergeClassName,
    valueType,
    tooltipText,
  } = props;

  const inputRef = useRef(null);
  const lastValidValue = useRef(value);

  const inputVariants = cva(
    `${alignLeft ? "text-left" : "text-center"} ${twMergeClassName ?? ""} focus:ring-primary-500 focus:border-primary-500 h-[30px] rounded-md sm:text-[12px] block w-full sm:min-w-[70px] font-bold `,
    {
      variants: {
        variant: {
          white: `bg-gray-50 border border-gray-300 text-gray-900 px-4 ${disabled ? "cursor-not-allowed" : ""}`,
          red: `border border-gray-300 text-white icon-white px-4 ${disabled ? "bg-[#85322f] cursor-not-allowed" : "bg-[#a96f6d]"}`,
          green: `border border-gray-300 text-white icon-white px-4 ${disabled ? "bg-[#008308] cursor-not-allowed" : "bg-[#4ca852]"}`,
          orange: "bg-[#f2e1c3] border border-gray-300 text-black",
        },
      },
    }
  );

  // Determine if negative values are allowed based on valueType
  const allowNegative = valueType === "negative" || (valueType !== "positive" && allowNegativeValue);

  const handleKeyDown = (event) => {
    const input = event.target;

    if (event.key === "Backspace" && input.selectionStart === 0 && input.selectionEnd === input.value.length) {
      // Prevent default behavior
      event.preventDefault();

      // Clear the input
      handleValueChange("", name);
    } else if (event.key === "Backspace" && input.selectionStart === input.selectionEnd) {
      // If backspace is pressed with no selection
      event.preventDefault();
      const cursorPosition = input.selectionStart;
      const valueWithoutFormatting = input.value.replace(/[R\s-]/g, "");
      if (valueWithoutFormatting === "0" || valueWithoutFormatting === "") {
        handleValueChange("", name);
      } else {
        const newValue =
          valueWithoutFormatting.slice(0, cursorPosition - 1) + valueWithoutFormatting.slice(cursorPosition);
        handleValueChange(newValue, name);
        // Set cursor position after React re-renders
        setTimeout(() => {
          input.setSelectionRange(cursorPosition - 1, cursorPosition - 1);
        }, 0);
      }
    }
  };

  // Custom onChange handler to enforce valueType restrictions
  const handleValueChange = (value, name) => {
    if (value === undefined || value === "") {
      onChange("", name);
      lastValidValue.current = "";
      return;
    }

    let numValue = parseFloat(value.replace(/[R\s-]/g, ""));
    if (isNaN(numValue)) {
      numValue = 0;
    }

    let newValue = numValue.toString();

    if (valueType === "positive" && numValue < 0) {
      newValue = "0";
    } else if (valueType === "negative" && numValue > 0) {
      newValue = "-" + newValue;
    } else if (valueType === "negative" && numValue === 0) {
      newValue = "-0";
    }

    lastValidValue.current = newValue;
    onChange(newValue, name);
  };

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      const end = input.value.length;
      input.setSelectionRange(end, end);
    }
  }, [value]);

  return (
    <div className={`${inLine ? "flex" : ""} items-center relative`}>
      {label && (
        <label
          htmlFor={id}
          className={`${inLine && !tooltipText ? "block" : tooltipText && alignText === "left" ? "flex items-center gap-2" : tooltipText ? "gap-2" : "flex mb-2"} ${alignText === "right" ? "text-right" : "text-left"} mr-2 text-[12px] font-bold text-gray-900 w-full leading-[15px]`}
        >
          {tooltipText && (
            <InputTooltip
              tooltipText={tooltipText}
              alignText={alignText}
            >
              <FaInfoCircle />
            </InputTooltip>
          )}
          {label} {required ? " *" : ""}
        </label>
      )}
      <CurrencyInput
        name={name}
        id={id}
        className={cn(inputVariants({ variant }))}
        placeholder={placeholder}
        value={value}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        allowNegativeValue={allowNegative}
        allowDecimals={false}
        onValueChange={(value, name) => handleValueChange(value, name)}
        onKeyDown={handleKeyDown}
        prefix={"R "}
        groupSeparator=" "
        decimalScale={0}
        ref={inputRef}
      />
      {disabled && (
        <div
          className={`${inLine ? "" : "h-fit top-8"} absolute inset-y-0 right-0.5  flex items-center pointer-events-none`}
        >
          <MdOutlineCommentsDisabled className={`h-[12px] ${variant === "white" ? "text-red-500" : "text-white"}`} />
        </div>
      )}
    </div>
  );
}
