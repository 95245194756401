import Button from "@/components/Form/Button";
import RadioButtonGroup from "@/components/Form/RadioButtonGroup";
import samplePDF from "@assets/sample-PDF.pdf";
import React, { useEffect, useState } from "react";

const commentaryOptions = [
  {
    label: "Sanlam multi-manager",
    name: "commentary",
  },
  {
    label: "Coronation",
    name: "commentary",
  },
  {
    label: "Ninety One",
    name: "commentary",
  },
];

const periodOptions = [
  {
    label: "Q1 2024",
    name: "period",
  },
  {
    label: "Q4 2023",
    name: "period",
  },
  {
    label: "Q3 2023",
    name: "period",
  },
];

const InvestmentExpectations = () => {
  const [selectedCommentryValue, setSelectedCommentryValue] = useState<String>("");
  const [selectedPeriodValue, setSelectedPeriodValue] = useState<String>("");

  function commentrySelectHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedCommentryValue(event.target.value);
  }

  function periodSelectHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setSelectedPeriodValue(event.target.value);
  }

  const onButtonClick = () => {
    const pdfUrl = samplePDF;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "sample-pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    console.log("selectedCommentryValue", selectedCommentryValue);
    console.log("selectedPeriodValue", selectedPeriodValue);
  }, [selectedCommentryValue, selectedPeriodValue]);

  return (
    <div className="max-h-[calc(100vh-70px)] h-full scrollbar overflow-y-auto overflow-x-hidden pb-4">
      <div className="grid grid-cols-2 w-full gap-6 pt-4">
        <div>
          <div className="mx-auto text-center">
            <h2 className="text-lg tracking-tight font-bold text-gray-900 text-center mt-2 mb-6">Select commentary</h2>
          </div>
          <ul>
            <RadioButtonGroup
              options={commentaryOptions}
              onChange={commentrySelectHandler}
              selectedOption={selectedCommentryValue}
            />
          </ul>
        </div>
        <div>
          {selectedCommentryValue.length > 0 ? (
            <>
              <div className="mx-auto text-center">
                <h2 className="text-lg tracking-tight font-bold text-gray-900 text-center mt-2 mb-6">Select period</h2>
              </div>
              <ul>
                <RadioButtonGroup
                  options={periodOptions}
                  onChange={periodSelectHandler}
                  selectedOption={selectedPeriodValue}
                />
              </ul>
            </>
          ) : null}
        </div>

        {selectedPeriodValue.length > 0 && (
          <>
            <div className="col-span-2">
              <center>
                <p className="text-md mb-4">Click on the button below to download PDF file</p>
                <Button onClick={onButtonClick}>Download PDF</Button>
              </center>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InvestmentExpectations;
