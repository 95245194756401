import Card from "@/components/Element/Card";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PostRetirement from "@/components/GrowthCostAssumptions/PostRetirement";
import PreRetirement from "@/components/GrowthCostAssumptions/PreRetirement";
import { ChangeEvent } from "react";
import { CurrencyInputOnChangeValues } from "react-currency-input-field";
import RetirementTable from "./tables/RetirementTable";
import retirementTableData from "./tables/RetirementTableData";

interface RetirementTabOneProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  handleRealChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleNominalChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleDecimalChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
  formData: any;
}

interface GenericInputsProps {
  formData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleRealChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleNominalChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleDecimalChange: (value: string | undefined, name?: string, values?: CurrencyInputOnChangeValues) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
}

interface RetirementCharacteristicsProps {
  formData: any;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
}

const GenericInputsCard = ({
  formData,
  handleChange,
  handleRealChange,
  handleNominalChange,
  handleDecimalChange,
  handleCurrencyChange,
  isVisible,
  toggleVisibility,
}: GenericInputsProps) => (
  <div className="grid grid-cols-2 gap-4 mb-4">
    <div>
      <Card
        variant="white"
        className="h-full"
      >
        <div className="flex items-center mb-2">
          <h3 className="text-[16px] font-semibold">Generic inputs</h3>
          <span className="ml-4 cursor-pointer">
            <VisibilityToggle
              keyName="GenericTable"
              isVisible={isVisible("GenericTable")}
              toggleVisibility={() => toggleVisibility("GenericTable")}
            />
          </span>
        </div>
        <LayoutOne
          formData={formData}
          handleChange={handleChange}
          handleCurrencyChange={handleCurrencyChange}
          isVisible={isVisible("GenericTable")}
        />
      </Card>
    </div>
    <div>
      <Card
        variant="white"
        className="h-full"
      >
        <div className={`flex items-center mb-4`}>
          <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
          <span className="ml-4 cursor-pointer">
            <VisibilityToggle
              keyName="GrowthTable"
              isVisible={isVisible("GrowthTable")}
              toggleVisibility={toggleVisibility}
            />
          </span>
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="w-[34%]"></th>
              <th className="text-[12px] w-[22%]">Low</th>
              <th className="text-[12px] w-[22%]">Medium</th>
              <th className="text-[12px] w-[22%]">High</th>
            </tr>
          </thead>
          <tbody>
            <PreRetirement
              formData={formData}
              handleRealChange={handleRealChange}
              handleNominalChange={handleNominalChange}
              handleDecimalChange={handleDecimalChange}
              isVisible={isVisible("GrowthTable")}
            />
            <PostRetirement
              formData={formData}
              handleRealChange={handleRealChange}
              handleNominalChange={handleNominalChange}
              handleDecimalChange={handleDecimalChange}
              isVisible={isVisible("GrowthTable")}
            />
          </tbody>
        </table>
      </Card>
    </div>
  </div>
);

const RetirementCharacteristicsCard = ({ formData, handleCurrencyChange }: RetirementCharacteristicsProps) => (
  <Card
    variant="white"
    className="mb-4"
  >
    <div className="mb-3">
      <h3 className="text-[16px] font-semibold">Monthly expenditure characteristics</h3>
    </div>
    <div className="overflow-x-auto space-y-8">
      <RetirementTable
        formData={formData}
        handleCurrencyChange={handleCurrencyChange}
        fixedRows={retirementTableData()}
      />
    </div>
  </Card>
);

export const RetirementTabOne: React.FC<RetirementTabOneProps> = ({
  handleSubmit,
  handleChange,
  handleRealChange,
  handleNominalChange,
  handleDecimalChange,
  handleCurrencyChange,
  preventEnterPress,
  isVisible,
  toggleVisibility,
  formData,
}) => {
  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
          <div className="grid grid-cols-2 gap-4 w-full">
            <div className="col-span-2">
              <GenericInputsCard
                formData={formData}
                handleChange={handleChange}
                handleRealChange={handleRealChange}
                handleNominalChange={handleNominalChange}
                handleDecimalChange={handleDecimalChange}
                handleCurrencyChange={handleCurrencyChange}
                isVisible={isVisible}
                toggleVisibility={toggleVisibility}
              />
            </div>
            <div className="col-span-2">
              <RetirementCharacteristicsCard
                formData={formData}
                handleCurrencyChange={handleCurrencyChange}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
