interface CategoryData {
  category: string;
  rowSpan: number;
  rows: {
    description: string;
    inputs: Array<{
      type: "currency" | "text" | "number" | "date" | "percentage" | "percentageSwitch" | "select";
      name: string;
      variant: "green" | "red";
    }>;
  }[];
  formData?: any;
}

export const InsuranceTableData = (formData: { primary_representation: any }): CategoryData[] => {
  const rows: CategoryData["rows"] = [
    {
      description: "Life insurance",
      inputs: [
        { type: "currency", name: "life_insurance_sum_assured", variant: "green" },
        { type: "currency", name: "life_insurance_premium", variant: "green" },
        { type: "select", name: "life_insurance_frequency", variant: "green" },
        { type: "number", name: "life_insurance_review_month", variant: "green" },
        { type: "date", name: "life_insurance_terminal_date", variant: "green" },
        { type: "percentage", name: `${formData.primary_representation}_life_insurance`, variant: "green" },
      ],
    },
    {
      description: "Disability insurance",
      inputs: [
        { type: "currency", name: "disability_insurance_sum_assured", variant: "green" },
        { type: "currency", name: "disability_insurance_premium", variant: "green" },
        { type: "select", name: "disability_insurance_frequency", variant: "green" },
        { type: "number", name: "disability_insurance_review_month", variant: "green" },
        { type: "date", name: "disability_insurance_terminal_date", variant: "green" },
        { type: "percentage", name: `${formData.primary_representation}_disability_insurance`, variant: "green" },
      ],
    },
    {
      description: "Medical insurance",
      inputs: [
        { type: "currency", name: "medical_insurance_sum_assured", variant: "green" },
        { type: "currency", name: "medical_insurance_premium", variant: "green" },
        { type: "select", name: "medical_insurance_frequency", variant: "green" },
        { type: "number", name: "medical_insurance_review_month", variant: "green" },
        { type: "date", name: "medical_insurance_terminal_date", variant: "green" },
        { type: "percentage", name: `${formData.primary_representation}_medical_insurance`, variant: "green" },
      ],
    },
    {
      description: "Short-term: Primary structure",
      inputs: [
        { type: "currency", name: "primary_structure_sum_assured", variant: "red" },
        { type: "currency", name: "primary_structure_premium", variant: "red" },
        { type: "select", name: "primary_structure_frequency", variant: "red" },
        { type: "number", name: "primary_structure_review_month", variant: "red" },
        { type: "date", name: "primary_structure_terminal_date", variant: "red" },
        { type: "percentage", name: `${formData.primary_representation}_primary_structure`, variant: "red" },
      ],
    },
    {
      description: "Short-term: Primary home contents",
      inputs: [
        { type: "currency", name: "primary_home_contents_sum_assured", variant: "green" },
        { type: "currency", name: "primary_home_contents_premium", variant: "green" },
        { type: "select", name: "primary_home_contents_frequency", variant: "green" },
        { type: "number", name: "primary_home_contents_review_month", variant: "green" },
        { type: "date", name: "primary_home_contents_terminal_date", variant: "green" },
        { type: "percentage", name: `${formData.primary_representation}_primary_home_contents`, variant: "green" },
      ],
    },
    {
      description: "Short-term: Secondary #1 property structure",
      inputs: [
        { type: "currency", name: "secondary_1_structure_sum_assured", variant: "red" },
        { type: "currency", name: "secondary_1_structure_premium", variant: "red" },
        { type: "select", name: "secondary_1_structure_frequency", variant: "red" },
        { type: "number", name: "secondary_1_structure_review_month", variant: "red" },
        { type: "date", name: "secondary_1_structure_terminal_date", variant: "red" },
        { type: "percentage", name: `${formData.primary_representation}_secondary_1_structure`, variant: "red" },
      ],
    },
    {
      description: "Short-term: Secondary #2 property structure",
      inputs: [
        { type: "currency", name: "secondary_2_structure_sum_assured", variant: "red" },
        { type: "currency", name: "secondary_2_structure_premium", variant: "red" },
        { type: "select", name: "secondary_2_structure_frequency", variant: "red" },
        { type: "number", name: "secondary_2_structure_review_month", variant: "red" },
        { type: "date", name: "secondary_2_structure_terminal_date", variant: "red" },
        { type: "percentage", name: `${formData.primary_representation}_secondary_2_structure`, variant: "red" },
      ],
    },
    {
      description: "Short-term: other (all risks)",
      inputs: [
        { type: "currency", name: "other_all_risks_sum_assured", variant: "green" },
        { type: "currency", name: "other_all_risks_premium", variant: "green" },
        { type: "select", name: "other_all_risks_frequency", variant: "green" },
        { type: "number", name: "other_all_risks_review_month", variant: "green" },
        { type: "date", name: "other_all_risks_terminal_date", variant: "green" },
        { type: "percentage", name: `${formData.primary_representation}_other_all_risks`, variant: "green" },
      ],
    },
    {
      description: "Business continuity insurance",
      inputs: [
        { type: "currency", name: "business_continuity_sum_assured", variant: "green" },
        { type: "currency", name: "business_continuity_premium", variant: "green" },
        { type: "select", name: "business_continuity_frequency", variant: "green" },
        { type: "number", name: "business_continuity_review_month", variant: "green" },
        { type: "date", name: "business_continuity_terminal_date", variant: "green" },
        { type: "percentage", name: `${formData.primary_representation}_business_continuity`, variant: "green" },
      ],
    },
    {
      description: "Vehicle insurance",
      inputs: [
        { type: "currency", name: "vehicle_insurance_sum_assured", variant: "red" },
        { type: "currency", name: "vehicle_insurance_premium", variant: "red" },
        { type: "select", name: "vehicle_insurance_frequency", variant: "red" },
        { type: "number", name: "vehicle_insurance_review_month", variant: "red" },
        { type: "date", name: "vehicle_insurance_terminal_date", variant: "red" },
        { type: "percentage", name: `${formData.primary_representation}_vehicle_insurance`, variant: "red" },
      ],
    },
    {
      description: "Other",
      inputs: [
        { type: "currency", name: "other_insurance_sum_assured", variant: "green" },
        { type: "currency", name: "other_insurance_premium", variant: "green" },
        { type: "select", name: "other_insurance_frequency", variant: "green" },
        { type: "number", name: "other_insurance_review_month", variant: "green" },
        { type: "date", name: "other_insurance_terminal_date", variant: "green" },
        { type: "percentage", name: `${formData.primary_representation}_other`, variant: "green" },
      ],
    },
  ];

  return [
    {
      category: "Insurance characteristics",
      rowSpan: rows.length,
      rows: rows,
    },
  ];
};
