import CircularStepper from "@/components/Layout/CircularStepper";
import PremiumToolLayout from "@/layouts/PremiumToolLayout";
import PageTitle from "@elements/PageTitle";
import { useState } from "react";

export type PremiumToolSections = {
  heading: string;
  navigate: string;
  popupText: string | JSX.Element;
};

export const sections: PremiumToolSections[] = [
  {
    heading: "Default Settings",
    navigate: "/premium/default-settings",
    popupText: (
      <span>
        <strong>Defaults:</strong> the FutureYou team has used their expert knowledge to provide a set of expectations
        that you can use including inflation, interest rates, investment growth rates and costs of ownership. If you
        wish to change these to your own expectations, either individually or collectively, these can be overwritten and
        saved.
      </span>
    ),
  },
  {
    heading: "Active Income",
    navigate: "/premium/active-income",
    popupText: (
      <span>
        <strong>Active Income:</strong> the first element that describes the income that you are currently earning for
        your labours, in the form of salary, wages, commission, bonuses, part-time work, consultancy work etc. Each
        element requires a start and end date, an amount and any expected changes – the system takes care of the rest!
        It also allows you to have an income that is expected to commence well into the future. All values are input in
        today’s terms for ease of reference.
      </span>
    ),
  },
  {
    heading: "Property",
    navigate: "/premium/property",
    popupText: (
      <span>
        <strong>Property:</strong> it is often said the families’ single greatest asset is their home. If applicable,
        this section looks at your current home, its associated (and detailed) costs of purchase, the costs of ownership
        (mortgages, rates, insurance & maintenance) and expected appreciation in value. It also allows you to add
        additional “rental” properties and project their impact on your costs and income into the future
      </span>
    ),
  },
  {
    heading: "Retirement",
    navigate: "/premium/retirement",
    popupText: (
      <span>
        <strong>Retirement:</strong> how much money have you saved to date, how much are you formally contributing to
        retirement products, at what rate is the capital growing, when do you expect to start drawing an income from
        your retirement funds and how long will they last for? Note that this section allows for multiple products
      </span>
    ),
  },
  {
    heading: "Investment & Savings",
    navigate: "/premium/investment-and-savings",
    popupText: (
      <span>
        <strong>Investment & Savings:</strong> akin to Retirement above, these are voluntary mechanisms that allow you
        to invest and save for the future. We suggest using a goal-based name (e.g. education fund). Multiple funds are
        allowed with each providing for current values, contributions, growth rates and a withdrawal rate
      </span>
    ),
  },
  {
    heading: "Loans",
    navigate: "/premium/loans",
    popupText: (
      <span>
        <strong>Loans:</strong> this is the flip-side of investments i.e. money that you have borrowed as capital to
        fund a requirement that you need to repay over a given period at a given interest rate
      </span>
    ),
  },
  {
    heading: "Vehicle",
    navigate: "/premium/vehicle",
    popupText: (
      <span>
        <strong>Vehicle:</strong> we understand that vehicles are both a luxury and a necessity for most families. This
        section assists you in understanding the cost of ownership of what are major assets, how the values will change
        overtime, and the impact of replacement on a set cycle
      </span>
    ),
  },
  {
    heading: "Business",
    navigate: "/premium/business",
    popupText: (
      <span>
        <strong>Business:</strong> entrepreneurship and small business is the backbone of a functioning economy – this
        section allows you to add the concept of a “side-hustle” or even a formal business (elements include income,
        cash-flow and balance sheet) to see how this will impact your financial security
      </span>
    ),
  },
  {
    heading: "Education",
    navigate: "/premium/education",
    popupText: (
      <span>
        <strong>Education:</strong> the cost of formal education has grown significantly over the past decades and we
        know that parents aspire to provide the best possible education for their children. This section explicitly
        allows you to understand the overall costs of educating your dependants well into the future
      </span>
    ),
  },
  {
    heading: "Insurance",
    navigate: "/premium/insurance",
    popupText: "lorem ipsum",
  },
  {
    heading: "Expenditure",
    navigate: "/premium/expenditure",
    popupText: (
      <span>
        <strong>Expenditure:</strong> this section provides all the underlying elements on which one is spending money –
        a substantial amount of these fields will already be filled in as a consequence of all the above components –
        however, this can be used for adding additional granularity on everyday expenditure
      </span>
    ),
  },
  {
    heading: "Income Vs Expenditure",
    navigate: "/premium/income-vs-expenditure",
    popupText: (
      <span>
        <strong>Income vs Expenditure:</strong> the residual of comparing your income to your expenditure is usually
        your “net bank balance” – positive means you are spending less than you are earning and negative, well, vice
        versa. However, this section is designed to demonstrate a comparison of both active income and passive income
        (withdrawals from retirement and savings) and compare it to your planned expenditure through retirement and
        beyond
      </span>
    ),
  },
  {
    heading: "Wealth",
    navigate: "/premium/wealth",
    popupText: "lorem ipsum",
  },
];

const PremiumIndex = () => {
  const [popupText, setPopupText] = useState<string | JSX.Element | null>(null);
  const [popupVisible, setPopupVisible] = useState(false);

  const handleHover = (popupText: string | JSX.Element) => {
    setPopupText(popupText);
    setPopupVisible(true);
  };

  const handleLeave = () => {
    setPopupVisible(false);
  };

  return (
    <PremiumToolLayout
      showBackButton={true}
      pageTitle={"Premium Tools"}
      backButtonNavigateTo={"/home"}
    >
      <PageTitle title="Premium Tools" />

      {popupVisible && (
        <div className="absolute right-5 left-5 mx-auto text-center top-20 bg-white p-4 rounded-md shadow-md w-fit z-30">
          {popupText}
        </div>
      )}

      <div className="h-full w-full relative">
        <CircularStepper
          sections={sections}
          onHover={handleHover}
          onLeave={handleLeave}
        />
      </div>
    </PremiumToolLayout>
  );
};

export default PremiumIndex;
