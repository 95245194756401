import Currency from "@/components/Form/Currency";
import Input from "@/components/Form/Input";
import React, { ReactNode } from "react";
import { FaTrash } from "react-icons/fa";
import { InputCell, InputCellProps } from "./InputCell";

export interface TableRowProps {
  category?: string;
  description: string | ReactNode;
  inputs: (InputCellProps | undefined)[];
  isFirstInCategory?: boolean;
  categoryRowSpan?: number;
  visibilityToggle?: React.ReactNode;
  isCollapsed?: boolean;
  showTotalWhenCollapsed?: boolean;
  formData?: any;
  handleCurrencyChange?: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  deleteRow?: () => void;
  handleChange?: (value: string) => void;
}

export const TableRow: React.FC<TableRowProps> = ({
  category,
  description,
  inputs,
  isFirstInCategory,
  categoryRowSpan,
  visibilityToggle,
  isCollapsed,
  showTotalWhenCollapsed,
  formData,
  handleCurrencyChange,
  deleteRow,
  handleChange,
}) => {
  const renderInputCell = (inputProps: InputCellProps | undefined) => {
    if (!inputProps) {
      return null;
    }
    return (
      <InputCell
        {...inputProps}
        formData={formData}
        onChange={
          inputProps.type === "currency"
            ? (value: string) => handleCurrencyChange && handleCurrencyChange(value, inputProps.name, "any")
            : inputProps.onChange
        }
      />
    );
  };

  if (isCollapsed) {
    return (
      <tr>
        <td
          colSpan={2}
          className="border border-gray-300 p-2 text-xs font-bold"
        >
          <div className="flex items-center">
            <span className="mr-2">{category}</span>
            <div>{visibilityToggle}</div>
          </div>
        </td>
        {showTotalWhenCollapsed && (
          <td className="border border-gray-300 p-2 text-xs">
            <Currency
              type="currency"
              variant="red"
              disabled
            />
          </td>
        )}
        <td
          colSpan={showTotalWhenCollapsed ? 3 : 4}
          className=" p-2 text-xs"
        ></td>
      </tr>
    );
  }

  return (
    <tr>
      {isFirstInCategory && category !== undefined && (
        <td
          className="border border-gray-300 p-2 text-xs font-bold"
          rowSpan={categoryRowSpan}
        >
          <div className="flex items-center gap-2">
            {category}
            <div>{visibilityToggle}</div>
          </div>
        </td>
      )}
      <td className="border border-gray-300 p-2 text-xs">
        <div className="flex items-center justify-between">
          {typeof description === "string" ? (
            description
          ) : (
            <Input
              type="text"
              onChange={(e) => handleChange && handleChange(e.target.value)}
              placeholder="Enter description"
              autoFocus
            />
          )}
          {deleteRow && (
            <button
              onClick={deleteRow}
              className="ml-2 text-red-500"
            >
              <FaTrash />
            </button>
          )}
        </div>
      </td>
      {inputs.map((inputProps, index) => (
        <td
          key={index}
          className="border border-gray-300 p-2 text-xs"
        >
          {renderInputCell(inputProps)}
        </td>
      ))}
    </tr>
  );
};
