import Card from "@/components/Element/Card";
import ChartLoading from "@/components/Element/ChartLoading";
import RadioFull from "@/components/Form/RadioFull";
import words from "@/services/algorithms/words";
import React, { ChangeEvent, useState } from "react";
import Chart from "react-apexcharts";

const selectChart = [
  {
    id: 1,
    name: "Monthly payments",
    slug: "monthly-payments",
    chartName: "Monthly payments",
  },
  {
    id: 2,
    name: "Cumulative payments",
    slug: "cumulative-payments",
    chartName: "Cumulative payments",
  },
];

interface ExpenditureTabTwoProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCurrencyChange: (value: string, name: string) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  formData: any;
  loading: boolean;
  chartSeries: any;
  chartOptions: any;
}

const ExpenditureTabTwo: React.FC<ExpenditureTabTwoProps> = ({
  handleSubmit,
  preventEnterPress,
  formData,
  loading,
  chartSeries,
  chartOptions,
}) => {
  const { capitalizeFirstLetter } = words();
  const [selectedChart, setSelectedChart] = useState<string>("");
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedChart(event.target.value);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
            <Card
              variant="white"
              className="mb-4"
            >
              <form onSubmit={handleSubmit}>
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-[14px] text-left pb-4 w-[50%]">Charts</th>
                      <th className="text-[14px] pb-4">Pre-retirement</th>
                      <th className="text-[14px] pb-4">Full period</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectChart.map((chart, index) => (
                      <tr key={`selectChart-${index}`}>
                        <td
                          className={`text-[12px] text-left ${index === selectChart.length - 1 ? "font-bold" : "font-semibold"}`}
                        >
                          {chart.name}
                        </td>
                        <td>
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={selectedChart === chart.slug}
                            centerToggle
                          />
                        </td>
                        <td className="text-[12px] font-semibold">
                          <RadioFull
                            id={chart.id.toString()}
                            name={""}
                            slug={chart.slug}
                            classTarget="select-chart"
                            handleRadioChange={handleRadioChange}
                            checked={false}
                            centerToggle
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </form>

              <p className="mt-4 text-[12px] font-bold">
                The selected charts show your projected monthly / cumulative expenditure across the 3 main elements viz.
                consumption, wealth creation and wealth preservation. Both monthly expenditure at the selected date and
                cumulative spending to the specified date from the effective date can be shown in the table below.
              </p>
            </Card>

            <Card
              variant="white"
              className="mb-2"
            >
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      Expenditure @
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[50%]">Month</th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[50%]">
                      Cumulative
                    </th>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      30 April 2048{" "}
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      {capitalizeFirstLetter(formData.primary_representation)}
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      {capitalizeFirstLetter(formData.primary_representation)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Consumption</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Wealth creation</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Wealth preservation
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">Total</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
          <div className="mb-1 h-[calc(100vh-240px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {selectChart.find((chart) => chart.slug === selectedChart)?.chartName}
                {formData.primary_representation && " - " + formData.primary_representation}
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-full relative">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </>
  );
};

export default ExpenditureTabTwo;
