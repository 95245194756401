import { FaEye, FaEyeSlash } from "react-icons/fa";

interface VisibilityToggleProps {
  keyName: string;
  isVisible: boolean;
  toggleVisibility: (key: string) => void;
}

const VisibilityToggle: React.FC<VisibilityToggleProps> = ({ keyName, isVisible, toggleVisibility }) => {
  return (
    <>
      {!isVisible ? (
        <FaEye
          onClick={() => {
            toggleVisibility(keyName);
          }}
          className="cursor-pointer"
        />
      ) : (
        <FaEyeSlash
          onClick={() => {
            toggleVisibility(keyName);
          }}
          className="cursor-pointer"
        />
      )}
    </>
  );
};

export default VisibilityToggle;
