import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";
import createAxiosInstance from "@api/Api";
import helpers from "@utils/helpers";
import { useEffect, useState } from "react";

interface FormData {
  primary_representation_id: string;
  primary_representation: string;
  projected_inflation_rate: string;
  effective_date: string;
  dependents: any[];
  chartSeries: any[];
  outputData: any[];
}

const useEducationCostTool = () => {
  const api = createAxiosInstance("standard/lifestyle/education-cost");
  const { chartOptionsDefault } = helpers();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    ...chartOptionsDefault,
    stroke: {
      width: 6,
    },
  });

  const [formData, setFormData] = useState<FormData>({
    primary_representation_id: "",
    primary_representation: "",
    projected_inflation_rate: "",
    effective_date: "",
    dependents: [],
    chartSeries: [],
    outputData: [],
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: any) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      const tempChartSeries: any[] = [];
      const tempOutputData: any[] = [];

      data.data.forEach((tempSeries: any, index: any) => {
        const { series, labels } = convertChartSeries(tempSeries);

        tempChartSeries.push(series);
        tempOutputData.push({
          present_value_of_future_expenditure: tempSeries.present_value_of_future_expenditure,
          additional_savings_required_per_month: tempSeries.additional_savings_required_per_month,
        });

        if (index === 0) {
          setChartSeries(series as any);
          setChartOptions((prevOptions) => ({
            ...prevOptions,
            xaxis: {
              ...prevOptions.xaxis,
              categories: labels,
            },
            stroke: {
              width: 6,
            },
          }));
        }
      });

      setFormData({
        ...formData,
        chartSeries: tempChartSeries,
        outputData: tempOutputData,
      });

      return data;
    } catch (error: any) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData: any) => {
    const years: number[] = chartData.years || [];

    const convertValues = (values: any[]): any[] => {
      return values.map((value) => {
        if (value === null || value === undefined) {
          return 0;
        }
        return parseFloat(value.toString().replace(/,/g, "")).toFixed(0);
      });
    };

    const convertValuesWithNull = (values: any[]): (any | null)[] => {
      return values.map((value) => {
        if (value === null || value === undefined) {
          return null;
        }
        return parseFloat(value.toString().replace(/,/g, "")).toFixed(0);
      });
    };

    const series = [
      {
        name: "Pre Primary",
        type: "bar",
        color: "#5b9bd5",
        data: convertValuesWithNull(chartData.phase_1 || []),
      },
      {
        name: "Primary 1st Stage",
        type: "bar",
        color: "#70ad47",
        data: convertValuesWithNull(chartData.phase_2 || []),
      },
      {
        name: "Primary 2nd Stage",
        type: "bar",
        color: "#264478",
        data: convertValuesWithNull(chartData.phase_3 || []),
      },
      {
        name: "Secondary Initial Stage",
        type: "bar",
        color: "#9e480e",
        data: convertValuesWithNull(chartData.phase_4 || []),
      },
      {
        name: "Secondary Final Stage",
        type: "bar",
        color: "#636363",
        data: convertValuesWithNull(chartData.phase_5 || []),
      },
      {
        name: "Tertiary Bachelor Stage",
        type: "bar",
        color: "#997300",
        data: convertValuesWithNull(chartData.phase_6 || []),
      },
      {
        name: "Tertiary Postgraduate Stage",
        type: "bar",
        color: "#255e91",
        data: convertValuesWithNull(chartData.phase_7 || []),
      },
      {
        name: "Education Fund Value",
        type: "line",
        color: "#698ed0",
        data: convertValues(chartData.education_fund_value || []),
      },
    ];

    const labels: string[] = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return `Year ${year}`;
    });

    return { series, labels };
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("get-stored-data");

      setFormData((prevFormData) => ({
        ...prevFormData,
        primary_representation_id: data.ufs.primary_representation_id || "",
        primary_representation: data.ufs.primary_representation || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        effective_date: data.ufs.effective_date || "",
      }));
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    setFormData,
    chartSeries,
    chartOptions,
    handleSubmit,
  };
};

export default useEducationCostTool;
