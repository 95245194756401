const calculations = () => {
  const formatNumberBreaks = (number: number) => {
    if (!Number.isFinite(number)) {
      return "∞"; // Handle Infinity
    } else if (Number.isNaN(number)) {
      return "NaN"; // Handle NaN
    } else {
      return number.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }
  };

  const formatNumber = (number: number) => {
    if (!Number.isFinite(number)) {
      return "∞"; // Handle Infinity
    } else if (Number.isNaN(number)) {
      return "NaN"; // Handle NaN
    } else {
      return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  const calculateTargetIncome = (
    annualIncome: number,
    realGrowthInIncome: number | any,
    yearsToRetirement: number | any,
    targetIncomeOnRetirement: number | any
  ) => {
    // correct calculation conversion
    // C24*(1+E24)^C12*C27

    realGrowthInIncome = realGrowthInIncome / 100;
    targetIncomeOnRetirement = targetIncomeOnRetirement / 100;
    const result = annualIncome * Math.pow(1 + realGrowthInIncome, yearsToRetirement) * targetIncomeOnRetirement;
    return parseInt(result.toFixed(0));
  };

  const calculateRequiredMultiple = (netAboveInflation: number | any, retirementLongevity: number | any) => {
    const netAboveInflationValue = parseFloat(netAboveInflation) / 100;

    const one = 1 - Math.pow(1 / (1 + netAboveInflationValue), retirementLongevity);
    const two = 1 - 1 / (1 + netAboveInflationValue);

    return one / two;
  };

  const parseNumber = (value: string): number | any => {
    const formatValue = String(value).replaceAll(",", "");
    const parsedValue = parseFloat(formatValue);

    if (!isNaN(parsedValue)) {
      return parsedValue;
    }

    return 0;
  };

  const calculateMultiple = (retirementFundValue: any, preTaxSalary: any) => {
    const fundValue = parseNumber(retirementFundValue);
    const salary = parseNumber(preTaxSalary);
    return fundValue / salary;
  };

  const calculateRequiredValue = (requiredMultiple: any, adjustedPreTaxSalary: any) => {
    requiredMultiple = requiredMultiple?.toString().replace(/,/g, "");
    adjustedPreTaxSalary = adjustedPreTaxSalary?.toString().replace(/,/g, "");

    const requiredMultipleValue = parseFloat(requiredMultiple);
    const adjustedPreTaxSalaryValue = parseFloat(adjustedPreTaxSalary);

    return requiredMultipleValue * adjustedPreTaxSalaryValue;
  };

  const yearsToDepletion = (growth: number, incomeAtRetirement: number) => {
    const rate = growth / 100;
    const income = incomeAtRetirement;

    const numerator = Math.log(1 - income * (1 - 1 / (1 + rate)));
    const denominator = Math.log(1 / (1 + rate));

    return numerator / denominator;
  };

  function calculateAdjustedYearsToDepletion(
    adjustedProjectedValue: number,
    adjustedTargetIncome: number,
    netGrowthPostRetirement: number
  ): number {
    const netGrowthRate = netGrowthPostRetirement / 100;

    const numerator = Math.log(1 - (adjustedProjectedValue / adjustedTargetIncome) * (1 - 1 / (1 + netGrowthRate)));
    const denominator = Math.log(1 / (1 + netGrowthRate));

    return numerator / denominator;
  }

  function calculateRetirementFundValue(
    netAboveInflation: number,
    realGrowthIncome: number,
    currentMonthlyContribution: number,
    currentAccumulatedRetirementFund: number,
    yearsToRetirement: number
  ) {
    const netAboveInflationDecimal = netAboveInflation / 100;
    const realGrowthIncomeDecimal = realGrowthIncome / 100;
    const years = yearsToRetirement;

    const numerator =
      currentMonthlyContribution *
      12 *
      ((Math.pow(1 + netAboveInflationDecimal, years) - Math.pow(1 + realGrowthIncomeDecimal, years)) /
        (netAboveInflationDecimal - realGrowthIncomeDecimal));
    const denominator = currentAccumulatedRetirementFund * Math.pow(1 + netAboveInflationDecimal, years);

    return numerator + denominator;
  }

  function calculateContributionOfIncome(monthlyContribution: number, annualIncome: number) {
    const contribution = parseFloat(monthlyContribution?.toString().replace(/,/g, ""));
    const income = parseFloat(annualIncome?.toString().replace(/,/g, ""));

    return ((contribution * 12) / income) * 100;
  }

  const calculateDifference = (a: number, b: number) => {
    const first = parseFloat(a?.toString().replace(/,/g, ""));
    const second = parseFloat(b?.toString().replace(/,/g, ""));

    return first - second;
  };

  const calculateMinusDifference = (a: number, b: number) => {
    const first = parseFloat(a?.toString().replace(/,/g, ""));
    const second = Math.abs(parseFloat(b.toString().replace(/,/g, "")));

    return first - second;
  };

  const calculateValues = (numbers: (number | string)[]): number => {
    const convertedNumbers = numbers.map((num) => (typeof num === "string" ? parseInt(num, 10) : num));

    if (convertedNumbers.some((num) => isNaN(num))) {
      throw new Error("All elements must be valid numbers or strings that can be converted to numbers.");
    }

    return convertedNumbers.reduce((acc, num) => acc + num, 0);
  };
  function calculateRatio(total: number, price: number): number {
    if (price === 0) {
      return 0;
    }

    return (-total / price) * 100;
  }

  function calculateAnnualFinance(interestRate: number, termYears: number, financeAmount: number) {
    const monthlyInterestRate = interestRate / 100;
    const totalPayments = termYears;

    return (-financeAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -totalPayments));
  }

  function calculateAnnualWithdrawal(projectedValue: number, requiredValue: number, targetIncome: number) {
    const annualWithdrawel = (projectedValue / requiredValue) * targetIncome;
    return annualWithdrawel;
  }

  function calculateImpactOnLivingStandard(projectedValueLow: number, requiredValueLow: number) {
    const result = (projectedValueLow / requiredValueLow - 1) * 100;
    return Math.round(result * 100) / 100;
  }

  function calculateAdditionalContribution(
    differenceInValue: number,
    netGrowthPreRetirement: number,
    yearsToRetirement: number,
    growthInIncome: number
  ): number {
    const netGrowthRate = netGrowthPreRetirement / 100;
    const incomeGrowthRate = growthInIncome / 100;

    if (differenceInValue < 0) {
      const factor =
        (Math.pow(1 + netGrowthRate, yearsToRetirement) - Math.pow(1 + incomeGrowthRate, yearsToRetirement)) /
        (netGrowthRate - incomeGrowthRate);
      return -differenceInValue / factor / 12;
    } else {
      return 0;
    }
  }

  function calculateCapitalInjection(
    differenceInValue: number,
    netGrowthPreRetirement: number,
    yearsToRetirement: number
  ): number {
    const netGrowthRate = netGrowthPreRetirement / 100;

    if (differenceInValue < 0) {
      const capitalInjection = -differenceInValue / Math.pow(1 + netGrowthRate, yearsToRetirement);
      return capitalInjection;
    } else {
      return 0;
    }
  }

  function calculateRealAdjustedRetirementValue(
    realGrowthInIncome: number,
    currentMonthlyContribution: number,
    injectCapital: number,
    yearsToRetirement: number,
    netGrowthPreRetirement: number,
    retirementFundValue: number,
    increaseMonthlyContribution: number,
    deferRetirementBy: number
  ): number {
    const incomeGrowthRate = realGrowthInIncome / 100;
    const netGrowthRate = netGrowthPreRetirement / 100;
    const adjustedYearsToRetirement = yearsToRetirement + deferRetirementBy;
    const contributionFutureValue =
      ((currentMonthlyContribution + increaseMonthlyContribution) *
        12 *
        (Math.pow(1 + netGrowthRate, adjustedYearsToRetirement) -
          Math.pow(1 + incomeGrowthRate, adjustedYearsToRetirement))) /
      (netGrowthRate - incomeGrowthRate);

    const fundFutureValue =
      (retirementFundValue + injectCapital) * Math.pow(1 + netGrowthRate, adjustedYearsToRetirement);

    const adjustedProjectedValue = contributionFutureValue + fundFutureValue;

    return adjustedProjectedValue;
  }

  function calculateNominalAdjustedRetirementValue(
    realProjectedValue: number,
    inflationRate: number,
    yearsToRetirement: number,
    deferRetirementBy: number
  ): number {
    const inflationRateDecimal = inflationRate / 100;
    const totalYearsToRetirement = yearsToRetirement + deferRetirementBy;

    const nominalAdjustedRetirementValue =
      realProjectedValue * Math.pow(1 + inflationRateDecimal, totalYearsToRetirement);

    return nominalAdjustedRetirementValue;
  }

  function calculateAdjustedSustainableAnnualIncome(
    realGrowthInIncome: number,
    currentMonthlyContribution: number,
    targetIncomeAtRetirement: number,
    injectCapital: number,
    yearsToRetirement: number,
    retirementLongevity: number,
    netGrowthPreRetirement: number,
    netGrowthPostRetirement: number,
    retirementFundValue: number,
    increaseMonthlyContribution: number,
    deferRetirementBy: number
  ): number {
    const incomeGrowthRate = realGrowthInIncome / 100;
    const netGrowthPreRate = netGrowthPreRetirement / 100;
    const netGrowthPostRate = netGrowthPostRetirement / 100;

    const adjustedYearsToRetirement = yearsToRetirement + deferRetirementBy;

    const futureValueContributions =
      ((currentMonthlyContribution + increaseMonthlyContribution) *
        12 *
        (Math.pow(1 + netGrowthPreRate, adjustedYearsToRetirement) -
          Math.pow(1 + incomeGrowthRate, adjustedYearsToRetirement))) /
      (netGrowthPreRate - incomeGrowthRate);

    const futureValueFund =
      (retirementFundValue + injectCapital) * Math.pow(1 + netGrowthPreRate, adjustedYearsToRetirement);

    const totalFutureValueAtRetirement = futureValueContributions + futureValueFund;

    const denominatorPart =
      ((1 - Math.pow(1 / (1 + netGrowthPostRate), retirementLongevity - deferRetirementBy)) /
        (1 - 1 / (1 + netGrowthPostRate))) *
      (targetIncomeAtRetirement * Math.pow(1 + incomeGrowthRate, deferRetirementBy));

    const adjustedSustainableAnnualIncome =
      (totalFutureValueAtRetirement / denominatorPart) *
      (targetIncomeAtRetirement * Math.pow(1 + incomeGrowthRate, deferRetirementBy));

    return adjustedSustainableAnnualIncome;
  }

  function calculateAdjustedTargetIncome(
    targetIncomeAtRetirement: number,
    realGrowthInIncome: number,
    deferRetirementBy: number
  ): number {
    const growthRate = realGrowthInIncome / 100;

    const adjustedTargetIncome = targetIncomeAtRetirement * Math.pow(1 + growthRate, deferRetirementBy);

    return adjustedTargetIncome;
  }

  function calculateMonthlySavingsRequiredNoIncrease(
    targetCapital: number,
    growthRate: number,
    months: number,
    initialCapital: number
  ): number {
    growthRate = growthRate / 100;

    const part1 = targetCapital / Math.pow(1 + growthRate, months / 12) - initialCapital;
    const part2 = Math.pow(1 + growthRate, 1 / 12) - 1;
    const part3 = 1 - Math.pow(1 + growthRate, -months / 12);

    const monthlySavingsRequired = (part1 * part2) / part3;

    return monthlySavingsRequired;
  }

  function calculateMonthlySavingsRequired(
    targetCapital: number,
    growthRatePost: number,
    months: number,
    initialCapital: number,
    growthRatePre: number
  ): number {
    growthRatePost = growthRatePost / 100;
    growthRatePre = growthRatePre / 100;

    const part1 =
      ((targetCapital / Math.pow(1 + growthRatePost, months / 12) - initialCapital) * growthRatePre) /
      (1 - Math.pow(1 + growthRatePre, -months / 12)) /
      (1 + growthRatePre);
    const part2 = (Math.pow(1 + growthRatePost, 1 / 12) - 1) / (1 - Math.pow(1 + growthRatePost, -1));

    const monthlySavingsRequired = part1 * part2;

    return monthlySavingsRequired;
  }

  function calculateTargetProjectedCapital(
    realGrowthInIncome: number,
    realGrowthInBonus: number,
    monthlyContribution: number,
    targetPercentBonusContribution: number,
    yearsToRetirement: number,
    netGrowthPreRetirement: number,
    annualBonus: number,
    retirementFundValue: number
  ): number {
    const incomeGrowthRate = realGrowthInIncome / 100;
    const bonusGrowthRate = realGrowthInBonus / 100;
    const netGrowthRate = netGrowthPreRetirement / 100;

    const contributionPart =
      (monthlyContribution *
        12 *
        (Math.pow(1 + netGrowthRate, yearsToRetirement) - Math.pow(1 + incomeGrowthRate, yearsToRetirement))) /
      (netGrowthRate - incomeGrowthRate);

    const bonusContributionPart =
      (((annualBonus * targetPercentBonusContribution) / 100) *
        (Math.pow(1 + netGrowthRate, yearsToRetirement) - Math.pow(1 + bonusGrowthRate, yearsToRetirement))) /
      (netGrowthRate - bonusGrowthRate);

    const retirementFundPart = retirementFundValue * Math.pow(1 + netGrowthRate, yearsToRetirement);

    const targetProjectedCapital = contributionPart + bonusContributionPart + retirementFundPart;

    return targetProjectedCapital;
  }

  function calculateNominalTargetProjectedCapital(
    realValue: number,
    inflationRate: number,
    yearsToRetirement: number
  ): number {
    const inflationRateDecimal = inflationRate / 100;

    const nominalTargetProjectedCapital = realValue * Math.pow(1 + inflationRateDecimal, yearsToRetirement);

    return nominalTargetProjectedCapital;
  }

  function calculatePercentageFromInitialCapital(
    yearsToRetirement: number,
    growthRate: number,
    annualBonus: number,
    retirementFundValue: number,
    realGrowthInIncome: number,
    realGrowthInBonus: number,
    monthlyContribution: number,
    targetPercentageOfBonusContribution: number
  ): number {
    const growthRateDecimal = growthRate / 100;
    const realGrowthInIncomeDecimal = realGrowthInIncome / 100;
    const realGrowthInBonusDecimal = realGrowthInBonus / 100;
    const targetPercentageOfBonusContributionDecimal = targetPercentageOfBonusContribution / 100;

    const futureValueRetirementFund = retirementFundValue * Math.pow(1 + growthRateDecimal, yearsToRetirement);

    const futureValueContributions =
      (monthlyContribution *
        12 *
        (Math.pow(1 + growthRateDecimal, yearsToRetirement) -
          Math.pow(1 + realGrowthInIncomeDecimal, yearsToRetirement))) /
      (growthRateDecimal - realGrowthInIncomeDecimal);

    const futureValueBonusContributions =
      (annualBonus *
        targetPercentageOfBonusContributionDecimal *
        (Math.pow(1 + growthRateDecimal, yearsToRetirement) -
          Math.pow(1 + realGrowthInBonusDecimal, yearsToRetirement))) /
      (growthRateDecimal - realGrowthInBonusDecimal);

    const totalFutureValue = futureValueContributions + futureValueBonusContributions + futureValueRetirementFund;

    const percentageFromInitialCapital = (futureValueRetirementFund / totalFutureValue) * 100;

    return percentageFromInitialCapital;
  }

  function calculatePercentageFromContribution(
    yearsToRetirement: number,
    growthRate: number,
    annualBonus: number,
    retirementFundValue: number,
    realGrowthInIncome: number,
    realGrowthInBonus: number,
    monthlyContribution: number,
    targetPercentageOfBonusContribution: number
  ): number {
    const growthRateDecimal = growthRate / 100;
    const realGrowthInIncomeDecimal = realGrowthInIncome / 100;
    const realGrowthInBonusDecimal = realGrowthInBonus / 100;
    const targetPercentageOfBonusContributionDecimal = targetPercentageOfBonusContribution / 100;

    const futureValueContributions =
      (monthlyContribution *
        12 *
        (Math.pow(1 + growthRateDecimal, yearsToRetirement) -
          Math.pow(1 + realGrowthInIncomeDecimal, yearsToRetirement))) /
      (growthRateDecimal - realGrowthInIncomeDecimal);

    const futureValueBonusContributions =
      (annualBonus *
        targetPercentageOfBonusContributionDecimal *
        (Math.pow(1 + growthRateDecimal, yearsToRetirement) -
          Math.pow(1 + realGrowthInBonusDecimal, yearsToRetirement))) /
      (growthRateDecimal - realGrowthInBonusDecimal);

    const futureValueRetirementFund = retirementFundValue * Math.pow(1 + growthRateDecimal, yearsToRetirement);

    const totalFutureValue = futureValueContributions + futureValueBonusContributions + futureValueRetirementFund;

    const percentageFromContribution = (futureValueContributions / totalFutureValue) * 100;

    return percentageFromContribution;
  }

  function calculatePercentageFromBonusAllocations(
    yearsToRetirement: number,
    growthRate: number,
    annualBonus: number,
    retirementFundValue: number,
    realGrowthInIncome: number,
    realGrowthInBonus: number,
    monthlyContribution: number,
    targetPercentageOfBonusContribution: number
  ): number {
    const growthRateDecimal = growthRate / 100;
    const realGrowthInIncomeDecimal = realGrowthInIncome / 100;
    const realGrowthInBonusDecimal = realGrowthInBonus / 100;
    const targetPercentageOfBonusContributionDecimal = targetPercentageOfBonusContribution / 100;

    const futureValueBonusContributions =
      (annualBonus *
        targetPercentageOfBonusContributionDecimal *
        (Math.pow(1 + growthRateDecimal, yearsToRetirement) -
          Math.pow(1 + realGrowthInBonusDecimal, yearsToRetirement))) /
      (growthRateDecimal - realGrowthInBonusDecimal);

    const futureValueContributions =
      (monthlyContribution *
        12 *
        (Math.pow(1 + growthRateDecimal, yearsToRetirement) -
          Math.pow(1 + realGrowthInIncomeDecimal, yearsToRetirement))) /
      (growthRateDecimal - realGrowthInIncomeDecimal);

    const futureValueRetirementFund = retirementFundValue * Math.pow(1 + growthRateDecimal, yearsToRetirement);

    const totalFutureValue = futureValueContributions + futureValueBonusContributions + futureValueRetirementFund;

    const percentageFromBonusAllocations = (futureValueBonusContributions / totalFutureValue) * 100;

    return percentageFromBonusAllocations;
  }

  function calculateDeferRetirement(
    clientMonthlyContribution: number,
    clientProjSalaryGrowth: number,
    clientQRetValue: number,
    yrsToRet: number,
    netExcessInflation: number,
    projectedValueToday: number
  ): number {
    const growthInIncome = clientProjSalaryGrowth / 100;
    const netGrowth = netExcessInflation / 100;

    function deferFunc(yrsToAdd: number): number {
      const contributionFactor =
        (1 + netGrowth) ** (yrsToRet + yrsToAdd) - (1 + growthInIncome) ** (yrsToRet + yrsToAdd);
      const futureValue =
        (clientMonthlyContribution * 12 * contributionFactor) / (netGrowth - growthInIncome) +
        clientQRetValue * (1 + netGrowth) ** (yrsToRet + yrsToAdd);

      return Math.abs(futureValue - projectedValueToday);
    }

    function minimize(
      func: (x: number) => number,
      guess: number[],
      bounds: [number, number]
    ): { success: boolean; x: number } {
      const [lowerBound, upperBound] = bounds;
      let bestX = guess[0];
      let bestVal = func(bestX);

      for (let i = lowerBound; i <= upperBound; i++) {
        const currentVal = func(i);
        if (currentVal < bestVal) {
          bestVal = currentVal;
          bestX = i;
        }
      }

      return { success: bestVal !== Infinity, x: bestX };
    }

    const guess = [1];
    const bounds: [number, number] = [-20, 100];

    const results = minimize(deferFunc, guess, bounds);
    return results.success ? results.x : NaN;
  }

  function calculateForwardInclusive(
    currentAge: number,
    ageTo: number,
    period: number,
    salaryContributions: number
  ): number {
    if (salaryContributions > 0) {
      const minValue = Math.min(ageTo > currentAge ? ageTo - currentAge : 0, period);
      return minValue;
    }
    return 0;
  }

  function calculateContributionInception(
    currentAge: number,
    annualPreTaxIncome: number,
    phases: any[],
    representation: string
  ): number {
    let denominator = 1;

    for (const phase of phases) {
      const salaryGrowth = (representation === "real" ? phase.real_salary_growth : phase.nominal_salary_growth) / 100;

      if (currentAge >= phase.age_from && currentAge < phase.age_to) {
        denominator *= Math.pow(1 + salaryGrowth, currentAge - phase.age_from);
        break;
      } else if (currentAge >= phase.age_to) {
        denominator *= Math.pow(1 + salaryGrowth, phase.age_to - phase.age_from);
      }
    }
    return annualPreTaxIncome / denominator;
  }

  function calculateOverallAnnualizedGrowth(phases: any, representation: string): number {
    const growthProduct = phases.reduce((product: any, phase: any) => {
      const salaryGrowth = (representation == "real" ? phase.real_salary_growth : phase.nominal_salary_growth) / 100;
      return product * Math.pow(1 + salaryGrowth, phase.period);
    }, 1);

    const totalPeriod = phases.reduce((sum: any, phase: any) => sum + phase.period, 0);
    const annualizedGrowth = Math.pow(growthProduct, 1 / totalPeriod) - 1;

    return annualizedGrowth * 100;
  }

  function calculateGrowthUntilRetirement(phases: any, representation: string): number {
    const growthProduct = phases.reduce((product: any, phase: any) => {
      const salaryGrowth = (representation == "real" ? phase.real_salary_growth : phase.nominal_salary_growth) / 100;
      return product * Math.pow(1 + salaryGrowth, phase.forward_inclusive);
    }, 1);

    const totalForwardInclusive = phases.reduce((sum: any, phase: any) => sum + phase.forward_inclusive, 0);
    const annualizedGrowth = Math.pow(growthProduct, 1 / totalForwardInclusive) - 1;

    return annualizedGrowth * 100;
  }

  function calculateMaxYearsToSave(
    analysisYear: number,
    startYears: number[],
    studyPeriods: number[],
    includeFlags: boolean[]
  ): number {
    for (let i = includeFlags.length - 1; i >= 0; i--) {
      if (includeFlags[i]) {
        return startYears[i] + studyPeriods[i] - 1 - analysisYear;
      }
    }
    return 0;
  }

  return {
    formatNumber,
    formatNumberBreaks,
    calculateTargetIncome,
    calculateRequiredMultiple,
    calculateMultiple,
    calculateRequiredValue,
    yearsToDepletion,
    calculateAdjustedYearsToDepletion,
    calculateRetirementFundValue,
    calculateContributionOfIncome,
    calculateDifference,
    calculateMinusDifference,
    calculateValues,
    calculateRatio,
    calculateAnnualFinance,
    calculateAnnualWithdrawal,
    calculateImpactOnLivingStandard,
    calculateAdditionalContribution,
    calculateCapitalInjection,
    calculateRealAdjustedRetirementValue,
    calculateNominalAdjustedRetirementValue,
    calculateAdjustedSustainableAnnualIncome,
    calculateAdjustedTargetIncome,
    calculateMonthlySavingsRequired,
    calculateMonthlySavingsRequiredNoIncrease,
    calculateTargetProjectedCapital,
    calculateNominalTargetProjectedCapital,
    calculatePercentageFromInitialCapital,
    calculatePercentageFromContribution,
    calculatePercentageFromBonusAllocations,
    calculateDeferRetirement,
    calculateForwardInclusive,
    calculateContributionInception,
    calculateOverallAnnualizedGrowth,
    calculateGrowthUntilRetirement,
    calculateMaxYearsToSave,
  };
};

export default calculations;
