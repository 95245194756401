import Card from "@/components/Element/Card";
import { InputCellProps } from "@/components/Element/Table/InputCell";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import React, { ChangeEvent, ReactNode } from "react";
import IncomeVsExpenditureTable from "./tables/IncomeVsExpenditureTable";

interface IncomeVsExpenditureTabOneProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
  formData: any;
}

interface GenericInputsProps {
  formData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
}

export interface AdditionalRow {
  id: number;
  description: string | ReactNode;
  inputs: Array<InputCellProps>;
}

const GenericInputsCard: React.FC<GenericInputsProps> = ({
  formData,
  handleChange,
  handleCurrencyChange,
  isVisible,
  toggleVisibility,
}) => (
  <div className="grid grid-cols-2 gap-4 mb-4">
    <div className="col-end-1">
      <Card
        variant="white"
        className="h-full"
      >
        <div className="flex items-center mb-2">
          <h3 className="text-[16px] font-semibold">Generic inputs</h3>
          <span className="ml-4 cursor-pointer">
            <VisibilityToggle
              keyName="GenericTable"
              isVisible={isVisible("GenericTable")}
              toggleVisibility={() => toggleVisibility("GenericTable")}
            />
          </span>
        </div>
        <LayoutOne
          formData={formData}
          handleChange={handleChange}
          handleCurrencyChange={handleCurrencyChange}
          isVisible={isVisible("GenericTable")}
        />
      </Card>
    </div>
  </div>
);

const IncomeVsExpenditureTabOne: React.FC<IncomeVsExpenditureTabOneProps> = ({
  handleSubmit,
  handleChange,
  handleCurrencyChange,
  preventEnterPress,
  isVisible,
  toggleVisibility,
  formData,
}) => {
  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
          <div className="grid grid-cols-2 gap-4 w-full">
            <div className="">
              <GenericInputsCard
                formData={formData}
                handleChange={handleChange}
                handleCurrencyChange={handleCurrencyChange}
                isVisible={isVisible}
                toggleVisibility={toggleVisibility}
              />
            </div>
            <div className="col-span-2">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className="overflow-x-auto space-y-8">
                  <IncomeVsExpenditureTable />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default IncomeVsExpenditureTabOne;
