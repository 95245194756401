import { useState, useEffect } from "react";
import createAxiosInstance from "@api/Api";
import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";

const useTwoPotRetirementTool = () => {
  const api = createAxiosInstance("standard/additional/two-pot-retirement");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    amount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);
      return data;
    } catch (error) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    handleChange,
    handleSubmit,
  };
};

export default useTwoPotRetirementTool;
