import { FooterTabs, TabContent } from "@/components/Element/FooterTabs";
import CommentaryLayout from "@/layouts/CommentaryLayout";
import { useSearchParams } from "react-router-dom";
import CuratedBlogs from "./CuratedBlogs/CuratedBlogs";
import InvestmentExpectations from "./InvestmentExpectations/investmentExpectations";

const ExpectationsAndCommentaryIndex = () => {
  const [searchParams] = useSearchParams();
  const getTabIndex = searchParams.get("tab");

  return (
    <CommentaryLayout
      showBackButton
      backButtonNavigateTo={"/commentary"}
      pageTitle={
        getTabIndex === "1"
          ? "Investment expectations – professional commentary"
          : getTabIndex === "2" && "Curated blogs"
      }
    >
      <>
        <FooterTabs tabIndex={getTabIndex}>
          <TabContent
            tabtitle="Investment expectations – professional commentary"
            className="h-full"
          >
            <InvestmentExpectations />
          </TabContent>
          <TabContent
            tabtitle="Curated blogs"
            className="h-full"
          >
            <CuratedBlogs />
          </TabContent>
        </FooterTabs>
      </>
    </CommentaryLayout>
  );
};

export default ExpectationsAndCommentaryIndex;
