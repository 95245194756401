import { useState } from "react";

interface VisibilityState {
  [key: string]: boolean;
}

const useVisibility = () => {
  const [visibilities, setVisibilities] = useState<VisibilityState>({});

  const toggleVisibility = (key: string) => {
    setVisibilities((prevVisibilities) => ({
      ...prevVisibilities,
      [key]: !(prevVisibilities[key] ?? false),
    }));
  };

  const isVisible = (key: string): boolean => {
    return !!visibilities[key];
  };

  return {
    isVisible,
    toggleVisibility,
  };
};

export default useVisibility;
