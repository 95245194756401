import Card from "@/components/Element/Card";
import SelectToggle from "@/components/Form/ToggleSwitch";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PostRetirement from "@/components/GrowthCostAssumptions/PostRetirement";
import PreRetirement from "@/components/GrowthCostAssumptions/PreRetirement";
import useBonusImpactTool from "@api/Standard/WealthCreation/useBonusImpactTool";
import ChartLoading from "@components/Element/ChartLoading";
import { CommaSeparated, Decimal } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import Change from "@utils/change";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";

const BonusImpact = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();
  const { preventEnterPress } = helpers();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    setFormData,
    handleSubmit,
    handleNominalChange,
    handleRealChange,
  } = useBonusImpactTool();

  const { type, handleChange, handleCurrencyChange, handleDecimalChange, handlePrimaryRepresentationChange } = Change(
    formData,
    setFormData
  );

  const [showHelpPopup, _] = useState();

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is designed to assist clients in identifying how much they need to put aside on a monthly basis
              over a set horizon to achieve a target outcome. Inputs required are:
              <ul className="pl-4">
                <li className="my-1">a) Specific: horizon, initial capital, target capital (real or nominal)</li>
                <li className="my-1">
                  b) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output is
              <ul className="pl-4">
                <li className="my-1">
                  a) Table of monthly contributions (savings) required over full period on a “flat” or “annually
                  increasing by inflation” basis for the 3 different growth environments.
                </li>
                <li className="my-1">
                  b) Chart shows:
                  <ul className="pl-4">
                    <li className="my-1">i) Value of cumulative contributions</li>
                    <li className="my-1">ii) Value of accumulated capital over horizon</li>
                    <li className="my-1">iii) Scale is generally monthly due to monthly nature of contributions.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Impact of investing part of your annual bonus"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Bonus Impact" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-7 gap-4 w-full p-4">
          <div className="col-span-7">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  loading={loading}
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible("GrowthTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="w-[34%]"></th>
                      <th className="text-[12px] w-[22%]">Low</th>
                      <th className="text-[12px] w-[22%]">Medium</th>
                      <th className="text-[12px] w-[22%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PreRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                    <PostRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Smart tool specific inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="SpecificInputs"
                      isVisible={isVisible("SpecificInputs")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                {!isVisible("SpecificInputs") && (
                  <div className="grid grid-cols-2 items-center gap-2 mt-4">
                    <div>
                      <Currency
                        label="Annual pre-tax income"
                        name="annual_pre_tax_income"
                        id="annual_pre_tax_income"
                        value={formData.annual_pre_tax_income}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>
                    <div>
                      <Percentage
                        label={
                          formData.primary_representation == "real"
                            ? "Real growth in income"
                            : "Nominal growth in income"
                        }
                        name={
                          formData.primary_representation == "real"
                            ? "real_growth_in_income"
                            : "nominal_growth_in_income"
                        }
                        id={
                          formData.primary_representation == "real"
                            ? "real_growth_in_income"
                            : "nominal_growth_in_income"
                        }
                        value={
                          formData.primary_representation == "real"
                            ? formData.real_growth_in_income
                            : formData.nominal_growth_in_income
                        }
                        //@ts-ignore
                        onChange={formData.primary_representation == "real" ? handleRealChange : handleNominalChange}
                        variant="green"
                        alignText="right"
                      />
                    </div>
                    <div>
                      <Currency
                        label="Annual bonus"
                        name="annual_bonus"
                        id="annual_bonus"
                        value={formData.annual_bonus}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>
                    <div>
                      <Percentage
                        label={
                          formData.primary_representation == "real" ? "Real growth in bonus" : "Nominal growth in bonus"
                        }
                        name={
                          formData.primary_representation == "real" ? "real_growth_in_bonus" : "nominal_growth_in_bonus"
                        }
                        id={
                          formData.primary_representation == "real" ? "real_growth_in_bonus" : "nominal_growth_in_bonus"
                        }
                        value={
                          formData.primary_representation == "real"
                            ? formData.real_growth_in_bonus
                            : formData.nominal_growth_in_bonus
                        }
                        //@ts-ignore
                        onChange={formData.primary_representation == "real" ? handleRealChange : handleNominalChange}
                        variant="green"
                        alignText="right"
                      />
                    </div>
                    <div>
                      <Currency
                        label="Current retirement fund value"
                        name="current_accumulated_retirement_fund_value"
                        id="current_accumulated_retirement_fund_value"
                        value={formData.current_accumulated_retirement_fund_value}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>
                    <div>
                      <Currency
                        label="Current monthly contribution"
                        name="current_monthly_contribution"
                        id="current_monthly_contribution"
                        value={formData.current_monthly_contribution}
                        onChange={handleCurrencyChange}
                        variant="green"
                        alignText="right"
                      />
                    </div>
                    <div>
                      <Percentage
                        label="Target income on retirement"
                        name="post_retirement_income_percentage"
                        id="post_retirement_income_percentage"
                        value={formData.post_retirement_income_percentage}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>
                    <div>
                      <Percentage
                        label="Target % of bonus contribution"
                        name="post_retirement_bonus_percentage"
                        id="post_retirement_bonus_percentage"
                        value={formData.post_retirement_bonus_percentage}
                        onChange={handleCurrencyChange}
                        variant="green"
                        alignText="right"
                      />
                    </div>
                  </div>
                )}
              </Card>

              <Card variant="white">
                <div className="grid grid-cols-4 mb-4 items-center">
                  <div>
                    <h3 className="text-[16px] font-semibold m-0">Output</h3>
                  </div>
                  <div className="col-span-2 text-right">
                    <p className="text-[12px] font-bold text-gray-900 mr-2">Target income at retirement:</p>
                  </div>
                  <div>
                    <Currency
                      value={
                        formData.primary_representation == "real"
                          ? formData.real_target_income_at_retirement
                          : formData.nominal_target_income_at_retirement
                      }
                      variant="red"
                      disabled
                    />
                  </div>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[40%]">
                        Description
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Required multiple
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal value={formData.required_multiple_low} />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal value={formData.required_multiple_medium} />
                      </td>
                      <td className="bg-[rgb(242,225,195)] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal value={formData.required_multiple_high} />
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Required value
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={type ? formData.real_required_value_low : formData.nominal_required_value_low}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={type ? formData.real_required_value_medium : formData.nominal_required_value_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={type ? formData.real_required_value_high : formData.nominal_required_value_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Total projected capital:
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={type ? formData.real_projected_capital_low : formData.nominal_projected_capital_low}
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={
                            type ? formData.real_projected_capital_medium : formData.nominal_projected_capital_medium
                          }
                          prefix="R"
                        />
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2">
                        <CommaSeparated
                          value={type ? formData.real_projected_capital_high : formData.nominal_projected_capital_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        * from initial capital
                      </td>
                      <td className="bg-[#f8eedb] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal
                          value={formData.from_initial_capital_low}
                          suffix="%"
                        />
                      </td>
                      <td className="bg-[#f8eedb] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal
                          value={formData.from_initial_capital_medium}
                          suffix="%"
                        />
                      </td>
                      <td className="bg-[#f8eedb] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal
                          value={formData.from_initial_capital_high}
                          suffix="%"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        * from contributions
                      </td>
                      <td className="bg-[#f8eedb] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal
                          value={formData.from_contributions_low}
                          suffix="%"
                        />
                      </td>
                      <td className="bg-[#f8eedb] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal
                          value={formData.from_contributions_medium}
                          suffix="%"
                        />
                      </td>
                      <td className="bg-[#f8eedb] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal
                          value={formData.from_contributions_high}
                          suffix="%"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        * from bonus allocations
                      </td>
                      <td className="bg-[#f8eedb] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal
                          value={formData.from_bonus_allocations_low}
                          suffix="%"
                        />
                      </td>
                      <td className="bg-[#f8eedb] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal
                          value={formData.from_bonus_allocations_medium}
                          suffix="%"
                        />
                      </td>
                      <td className="bg-[#f8eedb] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                        <Decimal
                          value={formData.from_bonus_allocations_high}
                          suffix="%"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4 mb-1 min-h-[calc(100vh-175px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {capitalizeFirstLetter(formData.primary_representation)} contributions & capital at work -
                pre-retirement
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="h-[calc(100vh-243px)] relative chart-bonus-invest">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="area"
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default BonusImpact;
