import React, { Children, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";

interface TabContentProps {
  tabtitle: string;
  className?: string;
  children: React.ReactNode;
}

const TabContent: React.FC<TabContentProps> = ({ children, className }) => <div className={className}>{children}</div>;

interface FooterTabsProps {
  children: ReactElement<TabContentProps>[];
  tabIndex?: string | null;
}

const FooterTabs: React.FC<FooterTabsProps> = ({ children, tabIndex }) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(tabIndex ? parseInt(tabIndex) - 1 : 0);
  const childrenList = Children.toArray(children) as ReactElement<TabContentProps>[];
  const tabContent = childrenList[tab];

  const tabs = childrenList.map((child, index) => {
    const { tabtitle } = child.props;
    return (
      <li
        key={tabtitle}
        onClick={() => {
          setTab(index);
          navigate(`?tab=${index + 1}`);
        }}
        className="mr-4"
      >
        <div
          className={`${
            tab === index
              ? "bg-[#243665] text-white hover:bg-[#243665] hover:text-white"
              : "bg-white text-gray-900 border-gray-300 hover:bg-[#243665] hover:text-white"
          } border-gray-500 w-max min-w-full p-3 border rounded-lg transition-all duration-100 cursor-pointer`}
        >
          <div className="flex items-center justify-between">
            <p className="font-bold text-xs">{tabtitle}</p>
          </div>
        </div>
      </li>
    );
  });

  return (
    <div className="h-full">
      <div className="w-full h-[calc(100%-74px)] pl-4 pr-[6px]">{tabContent}</div>
      <ol className="w-full pl-4 pr-[6px] py-4 flex items-center fixed bottom-0 bg-white">{tabs}</ol>
    </div>
  );
};

export { FooterTabs, TabContent };
