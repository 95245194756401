import Card from "@/components/Element/Card";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import Button from "@/components/Form/Button";
import Currency from "@/components/Form/Currency";
import GrowthRate from "@/components/Form/GrowthRate";
import Input from "@/components/Form/Input";
import Percentage from "@/components/Form/Percentage";
import PercentageSwitch from "@/components/Form/PercentageSwitch";
import SelectToggle from "@/components/Form/ToggleSwitch";
import words from "@/services/algorithms/words";
import useVisibility from "@/services/utils/useVisibility";
import useBudgetingTool from "@api/Standard/Additional/useBudgetingTool";
import PageTitle from "@elements/PageTitle";
import StandardToolLayout from "@layouts/StandardToolLayout";
import { useState } from "react";

const Budgeting = () => {
  const { isVisible, toggleVisibility } = useVisibility();
  const { capitalizeFirstLetter } = words();

  const {
    loading,
    error,
    formData,
    handleChange,
    handleCurrencyChange,
    handleSubmit,
    budgetingData,
    handlePrimaryRepresentationChange,
  } = useBudgetingTool();

  const [showHelpPopup, setShowHelpPopup] = useState();

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              Tool will assist client to understand his/her income and expenditure within the contexts of
              <ul className="pl-4">
                <li className="my-1">
                  a) Active income (reward for work) vs passive income (due from financial assets including interest and
                  dividends)
                </li>
                <li className="my-1">b) Wealth creation spending (building assets for future consumption)</li>
                <li className="my-1">
                  c) Wealth preservation spending (protection against negative events including insurance spend)
                </li>
                <li className="my-1">d) Consumption spending (maintaining your standard of living)</li>
                <li className="my-1">
                  e) Residual / Net bank balance / opportunity costs (what’s left at the end of the month)
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Note the significant size of table required to cover all aspects. Inputs include
              <ul className="pl-4">
                <li className="my-1">
                  a) Specific budget line items by category
                  <ul className="pl-4">
                    <li className="my-1">i) Current monthly amount (associated calculated annual amount)</li>
                    <li className="my-1">ii) Expected annual change by item.</li>
                    <li className="my-1">
                      iii) Projected terminal year for item (e.g. when will you stop earning an income, when will you
                      stop contributing to investment funds etc)
                    </li>
                  </ul>
                </li>
                <li className="my-1">b) Defaults are available to support projections.</li>
              </ul>
            </li>
            <li className="mb-2">
              Thes inputs are used to represent a comparison of each element (as above) in today’s terms, how these
              elements will change over time and what situation the client will likely find himself in over a 10-year
              period.
            </li>
            <li className="mb-2">
              We provide an estimated tax component based on current and projected official tax tables.
            </li>
            <li className="mb-2">
              We allow you to include a “year specific” element to cover ad hoc income and expenditure in order to
              provide a more comprehensive overview.
            </li>
            <li className="mb-2">
              The goal of this tool is to provide the client a graphic representation of his projected next 10- year
              journey with an associated table of annual expectations.
            </li>
          </ul>
        </div>
        {/* <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("simple-budgeting-tool-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Simple Budgeting Tool"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Budgeting" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  loading={loading}
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>

          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-2 items-center">
                  <div>
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div>
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          alignText="right"
                        />
                      </div>
                      <div>
                        <Input
                          label="Date of birth"
                          name="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          disabled
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          variant="red"
                        />
                      </div>
                      <div>
                        <Input
                          label="Retirement age"
                          name="retirement_age"
                          id="retirement_age"
                          type="number"
                          value={formData.retirement_age}
                          onChange={handleChange}
                          variant="red"
                          alignText="right"
                        />
                      </div>
                      <div>
                        <Input
                          label="Age at analysis date"
                          name="current_age"
                          id="current_age"
                          type="text"
                          disabled
                          value={formData.current_age}
                          onChange={handleChange}
                          variant="red"
                        />
                      </div>
                      <div>
                        <Input
                          label="Years to retirement"
                          name="years_to_retirement"
                          id="years_to_retirement"
                          type="number"
                          disabled
                          value={formData.years_to_retirement}
                          onChange={handleChange}
                          variant="red"
                          alignText="right"
                        />
                      </div>
                      <div>
                        <Input
                          label="Life expectancy"
                          name="life_expectancy"
                          id="life_expectancy"
                          type="number"
                          disabled
                          value={formData.life_expectancy}
                          onChange={handleChange}
                          variant="red"
                        />
                      </div>
                      <div>
                        <Input
                          label="Retirement longevity"
                          name="retirement_longevity"
                          id="retirement_longevity"
                          type="number"
                          value={formData.retirement_longevity}
                          onChange={handleChange}
                          variant="red"
                          alignText="right"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible("GrowthTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  {isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th className="w-[34%]"></th>
                          <th className="text-[12px] w-[22%]">Low</th>
                          <th className="text-[12px] w-[22%]">Medium</th>
                          <th className="text-[12px] w-[22%]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-[4px] pr-1 text-[12px] font-bold leading-[15px]">
                            {capitalizeFirstLetter(formData.primary_representation)} growth pre-retirement
                          </td>
                          <td className="py-[4px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_low"
                              nominalField="nominal_post_retirement_capital_growth_low"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                            />
                          </td>
                          <td className="py-[4px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_medium"
                              nominalField="nominal_post_retirement_capital_growth_medium"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                            />
                          </td>
                          <td className="py-[4px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_high"
                              nominalField="nominal_post_retirement_capital_growth_high"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="py-[4px] pr-1 text-[12px] font-bold leading-[15px]">Annual costs</td>
                          <td className="py-[4px]">
                            <Percentage
                              name="investment_costs"
                              id="investment_costs"
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                            />
                          </td>
                          <td className="py-[4px]">
                            <Percentage
                              name="investment_costs"
                              id="investment_costs"
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                            />
                          </td>
                          <td className="py-[4px]">
                            <Percentage
                              name="investment_costs"
                              id="investment_costs"
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}

                  {!isVisible("GrowthTable") && (
                    <thead>
                      <tr>
                        <th className="w-[34%]"></th>
                        <th className="text-[12px] w-[22%]">Low</th>
                        <th className="text-[12px] w-[22%]">Medium</th>
                        <th className="text-[12px] w-[22%]">High</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td className="py-[4px] pr-1 text-[12px] font-bold leading-[15px]">Net growth pre-retirement</td>
                      <td className="py-[4px]">
                        <Percentage
                          name="netGrowthLow"
                          id="netGrowthLow"
                          disabled
                          value={formData.net_growth_low}
                          variant="red"
                        />
                      </td>
                      <td className="py-[4px]">
                        <Percentage
                          name="netGrowthMedium"
                          id="netGrowthMedium"
                          disabled
                          value={formData.net_growth_medium}
                          variant="red"
                        />
                      </td>
                      <td className="py-[4px]">
                        <Percentage
                          name="netGrowthHigh"
                          id="netGrowthHigh"
                          disabled
                          value={formData.net_growth_high}
                          variant="red"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Smart tool specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div>
                    <Input
                      label="Current tax year"
                      name="current_tax_year"
                      id="current_tax_year"
                      value={formData.current_tax_year}
                      onChange={handleChange}
                      variant="red"
                      disabled={true}
                    />
                  </div>
                  <div>
                    <Input
                      label="Age at current tax year end"
                      name=""
                      id=""
                      onChange={handleChange}
                      variant="red"
                      disabled={true}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default Budgeting;
