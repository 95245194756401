import Card from "@/components/Element/Card";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import Button from "@/components/Form/Button";
import Currency from "@/components/Form/Currency";
import Input from "@/components/Form/Input";
import Percentage from "@/components/Form/Percentage";
import PercentageSwitch from "@/components/Form/PercentageSwitch";
import Select from "@/components/Form/Select";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import React, { ChangeEvent, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

interface LoanTabOneProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
  formData: any;
}

interface LoanCharacteristics {
  nominalField?: any;
  realField?: any;
  name?: string;
  inputType: "input" | "currency" | "percentage" | "percentageSwitch" | "date" | "select";
  variant?: "green" | "red";
  disabled?: boolean;
}

const frequencySelect = [
  {
    id: 1,
    name: "Monthly",
  },
  {
    id: 2,
    name: "Annual",
  },
];

const initialLoanCharacteristics: LoanCharacteristics[] = [
  {
    name: "outstanding_loan",
    inputType: "currency",
    variant: "green",
  },
  {
    name: "outstanding_term_months",
    inputType: "input",
    variant: "green",
  },
  {
    name: "completion_date",
    inputType: "date",
    variant: "green",
  },
  {
    name: "interest_rate",
    inputType: "percentage",
    variant: "green",
  },
  {
    name: "after_tax_monthly_payments",
    inputType: "currency",
    variant: "red",
  },
  {
    name: "pre_tax_equivalent",
    inputType: "currency",
    variant: "red",
  },
];

const LoanTabOne: React.FC<LoanTabOneProps> = ({
  handleSubmit,
  handleChange,
  handleCurrencyChange,
  handleSelectChange,
  preventEnterPress,
  isVisible,
  toggleVisibility,
  formData,
}) => {
  const [columns, setColumns] = useState<number>(0);
  const [data, setData] = useState<LoanCharacteristics[][]>([]);

  const addColumn = () => {
    setColumns(columns + 1);
    setData([...data, initialLoanCharacteristics]);
  };

  const removeColumn = (index: number) => {
    setColumns(columns - 1);
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
  };

  const StaticTableColumns = () => {
    return (
      <>
        <tr>
          <td className="p-2 border border-gray-300 text-xs">Outstanding loan</td>
        </tr>
        <tr>
          <td className="p-2 border border-r border-gray-300 text-xs">Outstanding term (months)</td>
        </tr>
        <tr>
          <td className="p-2 border border-r border-gray-300 text-xs">Completion date</td>
        </tr>
        <tr>
          <td className="p-2 border border-r border-gray-300 text-xs">Interest rate</td>
        </tr>
        <tr>
          <td className="p-2 border border-r border-gray-300 text-xs">After-tax monthy payments</td>
        </tr>
        <tr>
          <td className="p-2 border border-r border-gray-300 text-xs">Pre-tax equivalent</td>
        </tr>
      </>
    );
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
          <div className="grid grid-cols-2 gap-4 w-full">
            <div>
              <Card
                variant="white"
                className="mb-4"
              >
                <div className="flex items-center mb-2">
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={() => toggleVisibility("GenericTable")}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
                <div className="grid grid-cols-2 gap-2 mt-2">
                  <div>
                    <Percentage
                      label="Marginal tax rate"
                      name="marginal_tax_rate"
                      id="marginal_tax_rate"
                      value={formData.marginal_tax_rate}
                      onChange={handleCurrencyChange}
                      variant="red"
                      disabled
                    />
                  </div>
                </div>
              </Card>
            </div>

            <div className="col-span-2">
              <Card variant="white">
                <div className="mb-3">
                  <h3 className="text-[16px] font-semibold">Loan characteristics</h3>
                </div>
                <div>
                  <div className="overflow-x-auto flex">
                    <table className={`table-auto border`}>
                      <thead className="bg-white border">
                        <tr>
                          <th className="bg-[#f1f1f1] text-[12px] font-bold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[180px] w-[180px] h-[47px]">
                            Description
                          </th>
                        </tr>
                      </thead>
                      <tbody>{StaticTableColumns()}</tbody>
                    </table>
                    <table className={`table-auto border`}>
                      <thead className="bg-white border">
                        <tr>
                          {Array.from({ length: columns }).map((_, colIndex) => (
                            <th
                              key={colIndex}
                              className="bg-[#f1f1f1] text-[12px] font-semibold p-2 border-r-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[207px] h-[47px]"
                            >
                              <div className="flex items-center justify-between gap-2 font-bold">
                                <div className="w-[12px]" />
                                Loan #{colIndex + 1}
                                <IoCloseCircleOutline
                                  onClick={() => removeColumn(colIndex)}
                                  color="red"
                                  className="mr-1 cursor-pointer"
                                />
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {initialLoanCharacteristics.map((input, rowIndex) => (
                          <>
                            <tr key={rowIndex}>
                              {data.map((_column, colIndex) => (
                                <td
                                  key={colIndex}
                                  className={`p-2 border-r border-b border-gray-300`}
                                >
                                  {(() => {
                                    switch (input.inputType) {
                                      case "input":
                                        return (
                                          <Input
                                            type="number"
                                            name={input.name}
                                            variant={input.variant}
                                            disabled={input.disabled}
                                          />
                                        );
                                      case "currency":
                                        return (
                                          <Currency
                                            name={input.name}
                                            variant={input.variant}
                                            disabled={input.disabled}
                                          />
                                        );
                                      case "percentage":
                                        return (
                                          <Percentage
                                            name={input.name}
                                            variant={input.variant}
                                            disabled={input.disabled}
                                            onChange={handleCurrencyChange}
                                          />
                                        );
                                      case "percentageSwitch":
                                        return (
                                          <PercentageSwitch
                                            primaryRepresentation={formData.primary_representation}
                                            realField={input.realField}
                                            nominalField={input.nominalField}
                                            formData={formData}
                                            variant={input.variant}
                                            onChange={handleCurrencyChange}
                                            disabled={input.disabled}
                                            required={true}
                                          />
                                        );
                                      case "date":
                                        return (
                                          <Input
                                            type="date"
                                            name={input.name}
                                            variant={input.variant}
                                            disabled={input.disabled}
                                          />
                                        );
                                      case "select":
                                        return (
                                          // TODO
                                          <Select
                                            defaultItem="Select"
                                            name={input.name}
                                            id={input.name}
                                            onChange={handleSelectChange}
                                            variant={input.variant ? input.variant : "green"}
                                            items={frequencySelect}
                                          />
                                        );
                                      default:
                                        return null;
                                    }
                                  })()}
                                </td>
                              ))}
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                    {columns > 1 && (
                      <table className={`table-auto border`}>
                        <thead className="bg-white border">
                          <tr>
                            <th className="bg-[#f1f1f1] text-[12px] font-bold p-2 border-r-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[180px] w-[180px] h-[47px]">
                              TOTAL
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-2 border-r border-gray-300 text-xs"></td>
                          </tr>
                          <tr>
                            <td className="p-2 border border-r border-gray-300 text-xs"></td>
                          </tr>
                          <tr>
                            <td className="p-2 border border-r border-gray-300 text-xs"></td>
                          </tr>
                          <tr>
                            <td className="p-2 border border-r border-gray-300 text-xs"></td>
                          </tr>
                          <tr>
                            <td className="p-2 border border-r border-gray-300 text-xs"></td>
                          </tr>
                          <tr>
                            <td className="p-2 border border-r border-gray-300 text-xs"></td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>

                  <Button
                    onClick={addColumn}
                    className="mt-4"
                  >
                    Add
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoanTabOne;
