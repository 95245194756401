import React from "react";
import { useNavigate } from "react-router-dom";

interface HexShapesProps {
  parentClassName: string;
  showCostings: () => void;
  smartToolClick: () => void;
  premiumToolClick: () => void;
}

const HexShapes: React.FC<HexShapesProps> = ({ parentClassName, showCostings, smartToolClick, premiumToolClick }) => {
  const isActive = true;
  const navigate = useNavigate();

  const showCommentary = () => {
    navigate("/commentary");
  };

  return (
    <>
      <svg
        style={{ visibility: "hidden", position: "absolute" }}
        width="0"
        height="0"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <defs>
          <filter id="hexShape">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="4"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="hexShape"
            />
            <feComposite
              in="SourceGraphic"
              in2="hexShape"
              operator="atop"
            />
          </filter>
        </defs>
      </svg>

      <div className={`${parentClassName}`}>
        <div className="w-[600px] h-[600px] relative">
          <div className="relative w-full h-full left-[16px]">
            <div
              onClick={premiumToolClick}
              className={`${isActive ? "before:bg-[#85322f] cursor-pointer hover:scale-[1.1]" : "before:bg-[#666666] cursor-pointer"}  z-10 transition-all absolute top-[0%] left-[30%] inline-block [filter:url('#hexShape')] text-white w-[35%] before:content-[' '] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            >
              <div className="absolute bottom-0 h-full w-full text-center">
                <p className="grid h-full w-full place-items-center text-[20px]">
                  Financial
                  <br />
                  projections
                  <br />&<br />
                  inferences
                </p>
              </div>
            </div>
            {/*BORDER*/}
            <div
              className={`before:bg-[#000000] absolute top-[-0.5%] left-[29.5%] inline-block [filter:url('#hexShape')] text-white w-[36%] before:content-[' '] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            />
            <div
              className={`${isActive ? "before:bg-[#F2E1C3] cursor-pointer hover:scale-[1.1] text-[#000000]" : "before:bg-[#666666] text-white"} font-bold z-10 transition-all absolute top-[17%] left-[0%] inline-block [filter:url('#hexShape')]  w-[35%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
              onClick={smartToolClick}
            >
              <div className="absolute bottom-0 h-full w-full text-center">
                <p className="grid h-full w-full place-items-center text-[20px]">
                  Smart
                  <br />
                  Tools
                </p>
              </div>
            </div>
            {/*BORDER*/}
            <div
              className={`before:bg-[#000000] absolute top-[16.5%] left-[-0.5%] inline-block [filter:url('#hexShape')] text-white w-[36%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            />
            <div
              onClick={showCostings}
              className={`${isActive ? "before:bg-[#85322f] cursor-pointer hover:scale-[1.1]" : "before:bg-[#666666] cursor-pointer"}  z-10 transition-all absolute top-[17%] left-[60%] inline-block [filter:url('#hexShape')] text-white w-[35%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            >
              <div className="absolute bottom-0 h-full w-full text-center">
                <p className="grid h-full w-full place-items-center text-[20px]">
                  Financial
                  <br />
                  scoreboard
                </p>
              </div>
            </div>
            {/*BORDER*/}
            <div
              className={`before:bg-[#000000] absolute top-[16.5%] left-[59.5%] inline-block [filter:url('#hexShape')] text-white w-[36%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            />
            <div
              onClick={showCommentary}
              className={`cursor-pointer hover:scale-[1.1] before:bg-[#F2E1C3] z-10 transition-all absolute top-[34%] left-[30%] inline-block [filter:url('#hexShape')]  text-[#000000] w-[35%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            >
              <div className="absolute bottom-0 h-full w-full text-center flex items-center justify-center">
                <div>
                  <p className="grid h-full w-full place-items-center font-bold leading-1">
                    <strong className="text-lg">Future YOU</strong>
                    <span className="text-[18px]">Commentary, Blogs, Tables & Tips</span>
                  </p>
                </div>
              </div>
            </div>
            {/*BORDER*/}
            <div
              className={`before:bg-[#000000] absolute top-[33.5%] left-[29.5%] inline-block [filter:url('#hexShape')] text-white w-[36%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            />

            <div
              onClick={showCostings}
              className={`${isActive ? "before:bg-[#02385A] cursor-pointer hover:scale-[1.1]" : "before:bg-[#666666] cursor-pointer"} z-10 transition-all absolute top-[51%] left-[0%] inline-block [filter:url('#hexShape')] text-white w-[35%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            >
              <div className="absolute bottom-0 h-full w-full text-center">
                <p className="grid h-full w-full place-items-center text-[20px]">
                  Action
                  <br />
                  plan
                </p>
              </div>
            </div>
            {/*BORDER*/}
            <div
              className={`before:bg-[#000000] absolute top-[50.5%] left-[-0.5%] inline-block [filter:url('#hexShape')] text-white w-[36%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            />
            <div
              onClick={showCostings}
              className={`${isActive ? "before:bg-[#02385A] cursor-pointer hover:scale-[1.1]" : "before:bg-[#666666] cursor-pointer"} z-10 transition-all absolute top-[68%] left-[30%] inline-block [filter:url('#hexShape')] text-[#fff] w-[35%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            >
              <div className="absolute bottom-0 h-full w-full text-center">
                <p className="grid h-full w-full place-items-center text-[20px]">
                  Investment
                  <br />
                  advice
                </p>
              </div>
            </div>
            {/*BORDER*/}
            <div
              className={`before:bg-[#000000] absolute top-[67.5%] left-[29.5%] inline-block [filter:url('#hexShape')] text-white w-[36%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            />
            <div
              onClick={showCostings}
              className={`${isActive ? "before:bg-[#02385A] cursor-pointer hover:scale-[1.1]" : "before:bg-[#666666] cursor-pointer"} z-10 transition-all absolute top-[51%] left-[60%] inline-block [filter:url('#hexShape')] text-white w-[35%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            >
              <div className="absolute bottom-0 h-full w-full text-center">
                <p className="grid h-full w-full place-items-center text-[20px]">
                  Insurance
                  <br />
                  advice
                </p>
              </div>
            </div>
            {/*BORDER*/}
            <div
              className={`before:bg-[#000000] absolute top-[50.5%] left-[59.5%] inline-block [filter:url('#hexShape')] text-white w-[36%] before:content-[''] before:block before:pt-[86.6%] before:[clip-path:polygon(25%_0%,75%_0%,100%_50%,_75%_100%,_25%_100%,_0%_50%)]`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HexShapes;
