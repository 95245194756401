export const marketMetricsAssetLabelMapping: { [key: string]: string } = {
  "Local Inflation": "South Africa Inflation",
  "Local Equity": "South Africa Equity",
  "Local Property": "South Africa Property",
  "Local Fixed interest": "South Africa Bonds",
  "Local ILB's": "South Africa ILBs",
  "Local Cash": "South Africa Cash",
  "International Equity": "International Equity",
  "International Property": "International Property",
  "International Fixed interest": "International Bonds",
  "International Gold": "Gold",
  "International Cash": "International Cash",
};
