import createAxiosInstance from "@api/Api";
import helpers from "@utils/helpers";
import { useEffect, useState } from "react";
import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";

const useLifeInsuranceTool = () => {
  const api = createAxiosInstance("standard/additional/life-insurance");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();

  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState({
    amount: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePrimaryRepresentationChange = (value) => {
    setFormData({ ...formData, primary_representation: value });
  };

  const handleSubmit = (e) => {
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);
      const { series, labels } = convertChartSeries(data.data);

      setChartSeries(series);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
      }));
      return data;
    } catch (error) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData) => {
    const years = Object.values(chartData.Year);

    const annualContribution = Object.values(chartData["Annual Contribution"]).map((value) =>
      parseFloat(value.toFixed(2))
    );
    const cumulativeContributions = Object.values(chartData["Cumulative Contributions"]).map((value) =>
      parseFloat(value.toFixed(2))
    );

    const series = [
      {
        name: "Annual Contribution",
        type: "column",
        data: annualContribution,
      },
      {
        name: "Cumulative Contributions",
        type: "line",
        data: cumulativeContributions,
      },
    ];

    const labels = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Year " + year;
    });

    return { series, labels };
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    handleChange,
    handleSubmit,
    chartSeries,
    chartOptions,
    handlePrimaryRepresentationChange,
  };
};

export default useLifeInsuranceTool;
