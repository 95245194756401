import { FooterTabs, TabContent } from "@/components/Element/FooterTabs";
import CommentaryLayout from "@/layouts/CommentaryLayout";
import { useSearchParams } from "react-router-dom";
import CompoundInterest from "./compoundInterest";
import ImpactOfCosts from "./impactOfCosts";
import InvestmentTerms from "./investmentTerms";

const TerminologyAndExpectationsIndex = () => {
  const [searchParams] = useSearchParams();
  const getTabIndex = searchParams.get("tab");

  return (
    <CommentaryLayout
      showBackButton
      backButtonNavigateTo={"/commentary"}
      pageTitle={
        getTabIndex === "1"
          ? "Investment terms"
          : getTabIndex === "2"
            ? "Compound interest & “the rule of 72”"
            : "The impact of costs on investment returns"
      }
    >
      <>
        <FooterTabs tabIndex={getTabIndex}>
          <TabContent
            tabtitle="Investment Terms"
            className="h-full"
          >
            <InvestmentTerms />
          </TabContent>
          <TabContent
            tabtitle="Compound interest & “the rule of 72”"
            className="h-full"
          >
            <CompoundInterest />
          </TabContent>
          <TabContent
            tabtitle="The impact of costs on investment returns"
            className="h-full"
          >
            <ImpactOfCosts />
          </TabContent>
        </FooterTabs>
      </>
    </CommentaryLayout>
  );
};

export default TerminologyAndExpectationsIndex;
