import Navbar from "@components/Layout/Navbar";
import GeneralInformation from "@components/User/GeneralInformation";
import HelpModal from "@modals/HelpModal";
import Modal from "@modals/Modal";
import React, { ReactNode, useState } from "react";

interface DashboardLayoutProps {
  children: ReactNode;
  pageTitle: string;
  modalContent?: ReactNode;
  showBackButton?: boolean;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children, pageTitle, modalContent, showBackButton }) => {
  // Help modal
  const [helpModal, setHelpModal] = useState<boolean>(false);
  const toggleHelpModal = (): void => setHelpModal(!helpModal);
  const closeHelpModal = (): void => setHelpModal(false);

  // Modal
  const [modal, setModal] = useState<boolean>(false);
  const closeModal = (): void => setModal(false);

  return (
    <div>
      <Navbar
        pageTitle={pageTitle}
        modalContent={modalContent}
        toggleHelpModal={toggleHelpModal}
        showBackButton={showBackButton}
      />
      <div className="pt-[70px] min-h-[100vh]">
        {children}
        <HelpModal
          show={helpModal}
          closeHelpModal={closeHelpModal}
          modalContent={modalContent}
        />
        <Modal
          show={modal}
          closeModal={closeModal}
          modalContent={<GeneralInformation includeIdNumber={true} />}
        />
      </div>
    </div>
  );
};

export default DashboardLayout;
