import Card from "@/components/Element/Card";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import { ChangeEvent } from "react";
import ExpenditureTable from "./tables/ExpenditureTable";
import expenditureTableData from "./tables/ExpenditureTableData";

interface ExpenditureTabOneProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
  formData: any;
}

interface GenericInputsProps {
  formData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
}

interface ExpenditureCharacteristicsProps {
  formData: any;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
}

const GenericInputsCard = ({
  formData,
  handleChange,
  handleCurrencyChange,
  isVisible,
  toggleVisibility,
}: GenericInputsProps) => (
  <Card
    variant="white"
    className="mb-4"
  >
    <div className="flex items-center mb-2">
      <h3 className="text-[16px] font-semibold">Generic inputs</h3>
      <span className="ml-4 cursor-pointer">
        <VisibilityToggle
          keyName="GenericTable"
          isVisible={isVisible("GenericTable")}
          toggleVisibility={() => toggleVisibility("GenericTable")}
        />
      </span>
    </div>
    <LayoutOne
      formData={formData}
      handleChange={handleChange}
      handleCurrencyChange={handleCurrencyChange}
      isVisible={isVisible("GenericTable")}
    />
  </Card>
);

const ExpenditureCharacteristicsCard = ({ formData, handleCurrencyChange }: ExpenditureCharacteristicsProps) => (
  <Card
    variant="white"
    className="mb-4"
  >
    <div className="mb-3">
      <h3 className="text-[16px] font-semibold">Monthly expenditure characteristics</h3>
    </div>
    <div className="overflow-x-auto space-y-8">
      <ExpenditureTable
        formData={formData}
        handleCurrencyChange={handleCurrencyChange}
        fixedRows={expenditureTableData()}
      />
    </div>
  </Card>
);

const ExpenditureTabOne: React.FC<ExpenditureTabOneProps> = ({
  handleSubmit,
  handleChange,
  handleCurrencyChange,
  preventEnterPress,
  isVisible,
  toggleVisibility,
  formData,
}) => {
  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
          <div className="grid grid-cols-2 gap-4 w-full">
            <div>
              <GenericInputsCard
                formData={formData}
                handleChange={handleChange}
                handleCurrencyChange={handleCurrencyChange}
                isVisible={isVisible}
                toggleVisibility={toggleVisibility}
              />
            </div>
            <div className="col-span-2">
              <ExpenditureCharacteristicsCard
                formData={formData}
                handleCurrencyChange={handleCurrencyChange}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ExpenditureTabOne;
