import Card from "@/components/Element/Card";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

const options: ApexOptions = {
  chart: {
    type: "scatter" as const,
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    tickAmount: 14,
    min: 0,
    max: 28,
    labels: {
      formatter: (value: any): string => `${value}%`,
    },
  },
  yaxis: {
    tickAmount: 8,
    min: 0,
    max: 80,
    labels: {
      formatter: (value: number): string => value.toFixed(2),
    },
  },
  legend: {
    show: true,
    position: "bottom",
    horizontalAlign: "center",
    floating: false,
    fontSize: "14px",
    fontFamily: "Helvetica, Arial",
    fontWeight: 400,
    formatter: function (seriesName: string) {
      return seriesName;
    },
    itemMargin: {
      horizontal: 10,
      vertical: 0,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  colors: ["#1f77b4", "#ff7f0e"],
  markers: {
    size: 6,
  },
  tooltip: {
    enabled: false,
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
  },
};

const series = [
  {
    name: "Actual",
    data: [
      [1, 69.8],
      [2, 35.0],
      [3, 23.3],
      [4, 17.5],
      [5, 13.5],
      [6, 11.5],
      [7, 10.0],
      [8, 8.8],
      [9, 7.7],
      [10, 6.8],
      [11, 6.2],
      [12, 5.6],
      [13, 5.0],
      [14, 4.5],
      [15, 4.3],
      [16, 4.0],
      [17, 3.6],
      [18, 3.2],
      [19, 3.2],
      [20, 3.0],
      [21, 2.7],
      [22, 2.5],
      [23, 2.5],
      [24, 2.5],
      [25, 2.3],
    ],
  },
  {
    name: "Rule of 72",
    data: [
      [1, 72.0],
      [2, 35.8],
      [3, 24.0],
      [4, 18.0],
      [5, 14.0],
      [6, 11.7],
      [7, 10.0],
      [8, 8.8],
      [9, 7.7],
      [10, 6.8],
      [11, 6.0],
      [12, 5.4],
      [13, 4.8],
      [14, 4.3],
      [15, 4.0],
      [16, 3.8],
      [17, 3.5],
      [18, 3.2],
      [19, 2.9],
      [20, 2.7],
      [21, 2.5],
      [22, 2.3],
      [23, 2.2],
      [24, 2.1],
      [25, 1.9],
    ],
  },
];

const CompoundInterest = () => {
  return (
    <div className="max-h-[calc(100vh-70px)] h-full pb-2 lg:w-[90%] mx-auto">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-2">
          <div className="max-h-[calc(100vh-170px)] h-full scrollbar overflow-y-auto overflow-x-hidden">
            <div className="grid grid-cols-5 gap-6">
              <div className="col-span-5">
                <h2 className="text-lg tracking-tight font-bold text-gray-900 text-center mt-6 mb-6">
                  What is the difference between simple and compound interest?
                </h2>
              </div>
              <div className="col-span-3">
                <div className="flex flex-col p-2 mx-auto w-full text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
                  <p className="mb-2 text-sm font-medium">
                    <span className="font-bold">Simple interest</span> is calculated on the original value of the
                    capital <u>only</u> e.g.
                  </p>
                  <table className="table table-auto text-sm font-semibold ml-2">
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Capital amount <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">R 100,000 (“C”)</td>
                    </tr>
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Interest rate <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">10% (“r”)</td>
                    </tr>
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Value after 1 year <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">
                        Capital + growth <br />
                        <u>R100,000 + 10% * (R100,000)</u> = <u>R110,000</u>
                      </td>
                    </tr>
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Value after 2 years <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">
                        <u>R110,000 + 10% * (R100,000)</u> = <u>R120,000</u>
                      </td>
                    </tr>
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Value after 3 years <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">
                        <u>R120,000 + 10% * (R100,000)</u> = <u>R130,000</u>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div className="col-span-2">
                <div className="flex items-center h-full">
                  <div className="text-sm">
                    <p className="font-bold mb-2">
                      <u>General formula</u>
                    </p>
                    <p>
                      Value after n years: <u>C * (1 + r) * n</u>
                    </p>
                    <p className="my-4 text-sm font-medium">
                      Interest is <u>not</u> paid on the interest earned, only on the original capital.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-span-3">
                <div className="flex flex-col p-2 mx-auto w-full text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
                  <p className="mb-2 text-sm font-medium">
                    <span className="font-bold">Compound interest </span> is calculated on <u>both</u> the original
                    value of the capital and the interest received e.g.
                  </p>
                  <table className="table table-auto text-sm font-semibold ml-2">
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Capital amount <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">R 100,000 (“C”)</td>
                    </tr>
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Interest rate <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">10% (“r”)</td>
                    </tr>
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Value after 1 year <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">
                        Capital + growth <br />
                        <u>R100,000 + 10% * (R100,000)</u> = <u>R110,000</u>
                      </td>
                    </tr>
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Value after 2 years <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">
                        <u>R110,000 + 10% * (R110,000)</u> = <u>R121,000</u>
                      </td>
                    </tr>
                    <tr className="align-top">
                      <td className="pb-2 whitespace-nowrap flex justify-between">
                        Value after 3 years <span className="mx-2 float-right">:</span>
                      </td>
                      <td className="pb-2">
                        <u>R121,000 + 10% * (R121,000)</u> = <u>R133,100</u>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div className="col-span-2">
                <div className="flex items-center h-full">
                  <div className="text-sm">
                    <p className="font-bold mb-2">
                      <u>General formula</u>
                    </p>
                    <p>
                      Value after n years: <u>C * (1 + r) ^ n</u>
                    </p>
                    <p className="my-4 text-sm font-medium">
                      interest is paid on both the original capital AND on the interest earned.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-span-5 mb-1">
                <h2 className="text-lg tracking-tight font-bold text-gray-900 text-center mt-6 mb-6">The rule of 72</h2>
                <div className="flex flex-col p-2 mx-auto w-full text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
                  <p className="mb-2 text-sm font-medium">
                    This is a quick, approximate way to calculate how many years it will take for one’s capital to
                    double at a given
                    <span className="font-bold"> compound </span> interest rate.
                  </p>
                  <p className="mb-2 text-sm font-medium">
                    The <u>approximation</u> is given as : <span className="font-bold">72 / interest rate </span>
                    in percentage.
                  </p>
                  <p className="mb-2 text-sm font-medium">By way of example:</p>
                  <p className="mb-2 ml-2 text-sm font-medium">
                    <span className="mr-6">Interest rate : 10%</span> Years to double capital : 72 / 10 = 7.2 years
                    (actual 7.27)
                  </p>
                  <p className="mb-2 ml-2 text-sm font-medium">
                    <span className="mr-6">Interest rate : 6%</span> Years to double capital : 72 / 6 = 12.0 years
                    (actual 11.90)
                  </p>
                  <p className="mb-2 ml-2 text-sm font-medium">
                    <span className="mr-6">Interest rate : 5%</span> Years to double capital : 72 / 5 = 14.4 years
                    (actual 14.21)
                  </p>
                  <p className="mb-2 text-sm font-medium">
                    One can also reverse this and state the problem as “what rate of interest do I need to earn for my
                    money to double after n years?”
                  </p>
                  <p className="mb-2 ml-2 text-sm font-medium">
                    Years to double capital : 10 Required interest rate : 72 / 10 = 7.2% p.a. (actual 7.18%)
                  </p>
                  <p className="ml-2 text-sm font-medium">
                    Years to double capital : 15 Required interest rate : 72 / 15 = 4.8% p.a. (actual 4.73%)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-2 h-full">
          <div className="mx-auto text-center">
            <h2 className="text-lg tracking-tight font-bold text-gray-900 text-center mt-6 mb-6">
              All investment returns are shown using compound interest?
            </h2>
          </div>
          <Card
            variant="white"
            className="h-full max-h-[calc(100%-120px)]"
          >
            <div className="relative h-[calc(100%-45px)]">
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                Time taken for money to double at different interest rates
              </p>
              <Chart
                options={options}
                series={series}
                type="scatter"
                width="100%"
                height="100%"
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CompoundInterest;
