import logo from "@assets/logo.svg?url";
import React from "react";

interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <img
      src={logo}
      alt="Future You Logo"
      className={className}
    />
  );
};

export default Logo;
