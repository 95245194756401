import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";
import createAxiosInstance from "@api/Api";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import numbers from "@services/algorithms/numbers";
import helpers from "@utils/helpers";
import { FormEvent, useCallback, useEffect, useState } from "react";

interface Miscellaneous {
  // Miscellaneous
  primary_representation: string;
  growth_rate: string;
}

interface GenericInputs {
  // Generic inputs
  effective_date: string;
  projected_inflation_rate: number;
  date_of_birth: string;
  retirement_age: number;
  current_age: number;
  years_to_retirement: number;
  life_expectancy: number;
  retirement_longevity_custom: boolean;
  retirement_longevity: number;
}

interface GrowthCostAssumptions {
  // Growth & cost assumptions
  real_pre_retirement_capital_growth_low: number;
  real_pre_retirement_capital_growth_medium: number;
  real_pre_retirement_capital_growth_high: number;
  nominal_pre_retirement_capital_growth_low: number;
  nominal_pre_retirement_capital_growth_medium: number;
  nominal_pre_retirement_capital_growth_high: number;
  pre_retirement_costs: number;
  real_pre_net_retirement_growth_low: number;
  real_pre_net_retirement_growth_medium: number;
  real_pre_net_retirement_growth_high: number;
  nominal_pre_net_retirement_growth_low: number;
  nominal_pre_net_retirement_growth_medium: number;
  nominal_pre_net_retirement_growth_high: number;
}

interface SmartToolSpecificInputs {
  // Smart tool specific inputs
  annual_pre_tax_income: number;
  current_tax_year: string;
  marginal_tax_rate: number;
  current_monthly_contribution: number;
  equivalent_after_tax_contribution: number;
  retirement_date: string;
  retirement_tax_year: string;
  real_growth_in_income: number;
  nominal_growth_in_income: number;
  nominal_max_taxfree_retirement_contribution: number;
  real_max_taxfree_retirement_contribution: number;
  max_taxfree_retirement_percentage: number;
}

interface Output {
  // Output
  retirement_after_withdrawal_low: number;
  retirement_after_withdrawal_medium: number;
  retirement_after_withdrawal_high: number;
  investment_after_cgt_low: number;
  investment_after_cgt_medium: number;
  investment_after_cgt_high: number;
  after_tax_difference_low: number;
  after_tax_difference_medium: number;
  after_tax_difference_high: number;
}

interface FormData extends Miscellaneous, GenericInputs, GrowthCostAssumptions, SmartToolSpecificInputs, Output {}

const useTaxBenefitsTool = () => {
  const api = createAxiosInstance("standard/wealth-creation/tax-benefit");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();
  const {
    calculateCurrentAge,
    calculateYearsToRetirement,
    calculateRetirementLongevity,
    calculateTaxYear,
    calculateEquivalentAfterTaxContribution,
    calculateRetirementDate,
  } = numbers();
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState<FormData>({
    // Miscellaneous
    primary_representation: "",
    growth_rate: "Low",
    // Generic inputs
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity_custom: false,
    retirement_longevity: 0,
    // Growth & cost assumptions
    real_pre_retirement_capital_growth_low: 0,
    real_pre_retirement_capital_growth_medium: 0,
    real_pre_retirement_capital_growth_high: 0,
    nominal_pre_retirement_capital_growth_low: 0,
    nominal_pre_retirement_capital_growth_medium: 0,
    nominal_pre_retirement_capital_growth_high: 0,
    pre_retirement_costs: 0,
    real_pre_net_retirement_growth_low: 0,
    real_pre_net_retirement_growth_medium: 0,
    real_pre_net_retirement_growth_high: 0,
    nominal_pre_net_retirement_growth_low: 0,
    nominal_pre_net_retirement_growth_medium: 0,
    nominal_pre_net_retirement_growth_high: 0,
    // Smart tool specific inputs
    annual_pre_tax_income: 0,
    current_tax_year: "",
    marginal_tax_rate: 0,
    current_monthly_contribution: 0,
    equivalent_after_tax_contribution: 0,
    retirement_date: "",
    retirement_tax_year: "",
    real_growth_in_income: 0,
    nominal_growth_in_income: 0,
    nominal_max_taxfree_retirement_contribution: 350000,
    real_max_taxfree_retirement_contribution: 350000,
    max_taxfree_retirement_percentage: 27.5,
    // Output
    retirement_after_withdrawal_low: 0,
    retirement_after_withdrawal_medium: 0,
    retirement_after_withdrawal_high: 0,
    investment_after_cgt_low: 0,
    investment_after_cgt_medium: 0,
    investment_after_cgt_high: 0,
    after_tax_difference_low: 0,
    after_tax_difference_medium: 0,
    after_tax_difference_high: 0,
  });

  const { handleNominalChange, handleRealChange, genericInputsCalculations, growthPreRetirementCalculations } =
    growthRateHandler(formData, setFormData);

  const calculateAndSetValues = useCallback(() => {
    const currentTaxYear = calculateTaxYear(formData.effective_date);
    const equivalent_after_tax_contribution = calculateEquivalentAfterTaxContribution(
      formData.current_monthly_contribution,
      formData.marginal_tax_rate
    );
    const retirementDate = calculateRetirementDate(
      new Date(formData.date_of_birth),
      formData.retirement_age.toString()
    );
    const retirementTaxYear = calculateTaxYear(retirementDate);

    // Set form data with calculated values
    setFormData((prevFormData) => ({
      ...prevFormData,
      current_tax_year: currentTaxYear,
      equivalent_after_tax_contribution: equivalent_after_tax_contribution,
      retirement_date: retirementDate,
      retirement_tax_year: retirementTaxYear,
    }));
  }, [
    formData.effective_date,
    formData.current_monthly_contribution,
    formData.marginal_tax_rate,
    formData.retirement_age,
  ]);

  useEffect(() => {
    setTimeout(() => {
      calculateAndSetValues();
    }, 300);
  }, [calculateAndSetValues]);

  useEffect(() => {
    setTimeout(() => {
      genericInputsCalculations();
    }, 300);
  }, [genericInputsCalculations]);

  useEffect(() => {
    setTimeout(() => {
      growthPreRetirementCalculations();
    }, 300);
  }, [growthPreRetirementCalculations]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    //@ts-ignore
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: FormData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);
      console.log(data);

      const { series, labels } = convertChartSeries(data.data);

      setFormData({
        ...formData,
        retirement_after_withdrawal_low: data.retirement_after_withdrawal_low || 0,
        retirement_after_withdrawal_medium: data.retirement_after_withdrawal_medium || 0,
        retirement_after_withdrawal_high: data.retirement_after_withdrawal_high || 0,
        investment_after_cgt_low: data.investment_after_cgt_low || 0,
        investment_after_cgt_medium: data.investment_after_cgt_medium || 0,
        investment_after_cgt_high: data.investment_after_cgt_high || 0,
        after_tax_difference_low: data.after_tax_difference_low || 0,
        after_tax_difference_medium: data.after_tax_difference_medium || 0,
        after_tax_difference_high: data.after_tax_difference_high || 0,
      });
      //@ts-ignore
      setChartSeries(series);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
          dashArray: [0, 0, 5, 0, 5],
        },
      }));
      return data;
    } catch (error: any) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData: any) => {
    const years = Object.values(chartData.years);

    const difference_retirement_vs_investment_after_tax = Object.values(
      chartData.difference_retirement_vs_investment_after_tax
    ).map((value: any) => parseFloat(value.replace(/,/g, "")).toFixed(0));
    const retirement_capital = Object.values(chartData.retirement_capital).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );
    const retirement_net_value_after_withdrawal_tax = Object.values(
      chartData.retirement_net_value_after_withdrawal_tax
    ).map((value: any) => parseFloat(value.replace(/,/g, "")).toFixed(0));
    const investment_capital = Object.values(chartData.investment_capital).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );
    const investment_net_value_after_cgt = Object.values(chartData.investment_net_value_after_cgt).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const series = [
      {
        name: "Difference - retirement vs investment after tax",
        type: "area",
        color: "#ffc000",
        data: difference_retirement_vs_investment_after_tax,
      },
      {
        name: "Retirement capital",
        type: "line",
        color: "#ff0000",
        data: retirement_capital,
      },
      {
        name: "Retirement - net value after withdrawal tax",
        type: "line", // stipple
        color: "#b10202",
        data: retirement_net_value_after_withdrawal_tax,
      },
      {
        name: "Investment capital",
        type: "line",
        color: "#5b9bd5",
        data: investment_capital,
      },
      {
        name: "Investment - net value after CGT",
        type: "line", // stipple
        color: "#40709b",
        data: investment_net_value_after_cgt,
      },
    ];

    const labels = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Year " + year;
    });

    return { series, labels };
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData((prevFormData) => ({
        ...prevFormData,
        growth_rate: data.ufs.growth_rate || "",
        effective_date: data.ufs.effective_date || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || "",
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || 0,
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || 0,
        life_expectancy: data.ufs.life_expectancy || "",
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        retirement_longevity: data.ufs.retirement_longevity
          ? data.ufs.retirement_longevity
          : calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,
        pre_retirement_costs: data.ufs.pre_retirement_costs || "",
        real_pre_retirement_capital_growth_low: data.ufs.real_pre_retirement_capital_growth_low || "",
        real_pre_retirement_capital_growth_medium: data.ufs.real_pre_retirement_capital_growth_medium || "",
        real_pre_retirement_capital_growth_high: data.ufs.real_pre_retirement_capital_growth_high || "",
        nominal_pre_retirement_capital_growth_low: data.ufs.nominal_pre_retirement_capital_growth_low || "",
        nominal_pre_retirement_capital_growth_medium: data.ufs.nominal_pre_retirement_capital_growth_medium || "",
        nominal_pre_retirement_capital_growth_high: data.ufs.nominal_pre_retirement_capital_growth_high || "",
        // smart tool specific inputs
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || "",
        primary_representation: data.ufs.primary_representation || "",
        current_tax_year: calculateTaxYear(data.ufs.effective_date) || "",
        marginal_tax_rate: data.ufs.marginal_tax_rate || 0,
        current_monthly_contribution: data.ufs.current_monthly_contribution || "",
        equivalent_after_tax_contribution:
          calculateEquivalentAfterTaxContribution(data.ufs.current_monthly_contribution, formData.marginal_tax_rate) ||
          0,
        retirement_date: calculateRetirementDate(data.ufs.date_of_birth, data.ufs.retirement_age) || "",
        retirement_tax_year: calculateTaxYear(data.ufs.retirement_date) || "",
        real_growth_in_income: data.ufs.real_growth_in_income || "",
        nominal_growth_in_income: data.ufs.nominal_growth_in_income || "",
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    setFormData,
    handleSubmit,
    chartSeries,
    chartOptions,
    handleNominalChange,
    handleRealChange,
  };
};

export default useTaxBenefitsTool;
