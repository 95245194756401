import Card from "@/components/Element/Card";
import { InputCellProps } from "@/components/Element/Table/InputCell";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import Input from "@/components/Form/Input";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import React, { ChangeEvent, ReactNode, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import InsuranceCharacteristicsTable from "./tables/InsuranceCharacteristicsTable";
import { InsuranceTableData } from "./tables/InsuranceTableData";

interface InsuranceTabOneProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  isVisible: (key: string) => boolean;
  toggleVisibility: (key: string) => void;
  formData: any;
}

export interface AdditionalRow {
  id: number;
  description: string | ReactNode;
  inputs: Array<InputCellProps>;
}

const InsuranceTabOne: React.FC<InsuranceTabOneProps> = ({
  handleSubmit,
  handleChange,
  handleCurrencyChange,
  preventEnterPress,
  isVisible,
  toggleVisibility,
  formData,
}) => {
  const getInsuranceTableData = InsuranceTableData(formData);

  const [insuranceAdditionalRows, setInsuranceAdditionalRows] = useState<AdditionalRow[]>([]);

  const addRow = (
    setStateFunction: React.Dispatch<React.SetStateAction<AdditionalRow[]>>,
    formData: any,
    handleCurrencyChange: (
      value: string | undefined,
      name: string | undefined,
      valueType: "any" | "positive" | "negative"
    ) => void
  ) => {
    const newRow: AdditionalRow = {
      id: Date.now(),
      description: (
        <div className="flex items-center justify-between">
          <Input
            variant="bottomborder"
            autoFocus
            required
            placeholder="Enter description"
          />
          <IoCloseCircleOutline
            onClick={() => removeRow(newRow.id, setStateFunction)}
            color="red"
            className="cursor-pointer"
          />
        </div>
      ),
      inputs: [
        { type: "currency", variant: "green" },
        { type: "number", variant: "green" },
        { type: "date", variant: "green" },
        {
          type: "percentageSwitch",
          variant: "green",
          primaryRepresentation: formData.primary_representation,
          realField: `real_increase_other_${Date.now()}`,
          nominalField: `nominal_increase_other_${Date.now()}`,
          formData: formData,
          onChange: (value, name) => handleCurrencyChange(value, name, "any"),
          name: `increase_other_${Date.now()}`,
        },
      ],
    };
    setStateFunction((prevRows) => [...prevRows, newRow]);
  };

  const removeRow = (id: number, setStateFunction: React.Dispatch<React.SetStateAction<AdditionalRow[]>>) => {
    setStateFunction((prevRows) => prevRows.filter((row) => row.id !== id));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
          <div className="grid grid-cols-2 gap-4 w-full">
            <div>
              <Card
                variant="white"
                className="mb-4"
              >
                <div className="flex items-center mb-2">
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={() => toggleVisibility("GenericTable")}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
              </Card>
            </div>

            <div className="col-span-2">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-3">
                  <h3 className="text-[16px] font-semibold">Insurance characteristics</h3>
                </div>
                <div className="overflow-x-auto space-y-8">
                  <InsuranceCharacteristicsTable
                    formData={formData}
                    handleCurrencyChange={handleCurrencyChange}
                    fixedRows={getInsuranceTableData}
                    additionalRows={insuranceAdditionalRows}
                    removeRow={(id) => removeRow(id, setInsuranceAdditionalRows)}
                    addRow={() =>
                      addRow(setInsuranceAdditionalRows, formData, (value, name, valueType) =>
                        handleCurrencyChange(value, name, valueType)
                      )
                    }
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default InsuranceTabOne;
