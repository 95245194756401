import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";
import createAxiosInstance from "@api/Api";
import calculations from "@services/algorithms/calculations";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import numbers from "@services/algorithms/numbers";
import helpers from "@utils/helpers";
import { ca } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";

const useTargetedInvestmentsTools = () => {
  const api = createAxiosInstance("standard/wealth-creation/targeted-investments");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();

  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementLongevity, calculateRetirementIncome } =
    numbers();

  const {
    calculateForwardInclusive,
    calculateContributionInception,
    calculateOverallAnnualizedGrowth,
    calculateGrowthUntilRetirement,
    calculateTargetIncome,
  } = calculations();

  const [formData, setFormData] = useState({
    // Miscellaneous
    growth_rate: "Low",
    primary_representation: "",
    // Generic inputs
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_date: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity_custom: false,
    retirement_longevity: 0,
    // Growth & cost assumptions
    real_pre_retirement_capital_growth_low: 0,
    real_pre_retirement_capital_growth_medium: 0,
    real_pre_retirement_capital_growth_high: 0,
    nominal_pre_retirement_capital_growth_low: 0,
    nominal_pre_retirement_capital_growth_medium: 0,
    nominal_pre_retirement_capital_growth_high: 0,
    pre_retirement_costs: 0,
    pre_retirement_capital_growth_low: 0,
    pre_retirement_capital_growth_medium: 0,
    pre_retirement_capital_growth_high: 0,
    real_post_retirement_capital_growth_low: 0,
    real_post_retirement_capital_growth_medium: 0,
    real_post_retirement_capital_growth_high: 0,
    nominal_post_retirement_capital_growth_low: 0,
    nominal_post_retirement_capital_growth_medium: 0,
    nominal_post_retirement_capital_growth_high: 0,
    post_retirement_costs: 0,
    post_retirement_capital_growth_low: 0,
    post_retirement_capital_growth_medium: 0,
    post_retirement_capital_growth_high: 0,
    real_pre_net_retirement_growth_low: 0,
    real_pre_net_retirement_growth_medium: 0,
    real_pre_net_retirement_growth_high: 0,
    nominal_pre_net_retirement_growth_low: 0,
    nominal_pre_net_retirement_growth_medium: 0,
    nominal_pre_net_retirement_growth_high: 0,
    real_post_net_retirement_growth_low: 0,
    real_post_net_retirement_growth_medium: 0,
    real_post_net_retirement_growth_high: 0,
    nominal_post_net_retirement_growth_low: 0,
    nominal_post_net_retirement_growth_medium: 0,
    nominal_post_net_retirement_growth_high: 0,
    // smart tool specific inputs
    annual_pre_tax_income: 0,
    post_retirement_income_percentage: 0,
    contribution_commencement_age: 0,
    // Contribution & income growth map
    phases: [],
    overall_annualised_growth_age_from: 0,
    overall_annualised_growth_age_to: 0,
    overall_annualised_growth_period: 0,
    real_overall_annualised_growth: 0,
    nominal_overall_annualised_growth: 0,
    real_growth_until_retirement: 0,
    nominal_growth_until_retirement: 0,
    // Output
    real_estimated_income_at_contribution_inception: 0,
    nominal_estimated_income_at_contribution_inception: 0,
    real_target_income_at_retirement: 0,
    nominal_target_income_at_retirement: 0,
    accumulated_investment_by_current_age_low: 0,
    accumulated_investment_by_current_age_medium: 0,
    accumulated_investment_by_current_age_high: 0,
    estimated_amount_at_retirement_low: 0,
    estimated_amount_at_retirement_medium: 0,
    estimated_amount_at_retirement_high: 0,
    minimum_targeted_capital_low: 0,
    minimum_targeted_capital_medium: 0,
    minimum_targeted_capital_high: 0,
    amount_required_at_retirement_low: 0,
    amount_required_at_retirement_medium: 0,
    amount_required_at_retirement_high: 0,
    // Dirty
    is_dirty: false,
  });

  const {
    handleNominalChange,
    handleRealChange,
    genericInputsCalculations,
    growthPreRetirementCalculations,
    growthPostRetirementCalculations,
    calculateNominalGrowthRate,
  } = growthRateHandler(formData, setFormData);

  useEffect(() => {
    setTimeout(() => {
      genericInputsCalculations();
    }, 300);
  }, [genericInputsCalculations]);

  useEffect(() => {
    setTimeout(() => {
      growthPreRetirementCalculations();
      growthPostRetirementCalculations();
    }, 300);
  }, [growthPreRetirementCalculations, growthPostRetirementCalculations]);

  const handleSubmit = (e) => {
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      setFormData({
        ...formData,
        accumulated_investment_by_current_age_low: data.accumulated_investment_by_current_age_low || 0,
        accumulated_investment_by_current_age_medium: data.accumulated_investment_by_current_age_medium || 0,
        accumulated_investment_by_current_age_high: data.accumulated_investment_by_current_age_high || 0,
        estimated_amount_at_retirement_low: data.estimated_amount_at_retirement_low || 0,
        estimated_amount_at_retirement_medium: data.estimated_amount_at_retirement_medium || 0,
        estimated_amount_at_retirement_high: data.minimum_targeted_capital_low || 0,
        minimum_targeted_capital_low: data.minimum_targeted_capital_low || 0,
        minimum_targeted_capital_medium: data.minimum_targeted_capital_medium || 0,
        minimum_targeted_capital_high: data.minimum_targeted_capital_high || 0,
        amount_required_at_retirement_low: data.amount_required_at_retirement_low || 0,
        amount_required_at_retirement_medium: data.amount_required_at_retirement_medium || 0,
        amount_required_at_retirement_high: data.amount_required_at_retirement_high || 0,
      });

      const { series, labels } = convertChartSeries(data.data);

      setChartSeries(series);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
      }));
      return data;
    } catch (error) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData) => {
    const ages = Object.values(chartData.ages);

    const minimumTargetedCapitalRequired = Object.values(chartData.minimum_targeted_capital_required).map((value) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const projectedCapitalAtWork = Object.values(chartData.projected_capital_at_work).map((value) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const cumulativeContributions = Object.values(chartData.cumulative_contributions).map((value) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const series = [
      {
        name: "Minimum Targeted Capital Required",
        type: "line",
        color: "#4472c4",
        data: minimumTargetedCapitalRequired,
      },
      {
        name: "Projected Capital At Work",
        type: "line",
        color: "#a5a5a5",
        data: projectedCapitalAtWork,
      },
      {
        name: "Cumulative Contributions",
        type: "column",
        color: "#ed7d31",
        data: cumulativeContributions,
      },
    ];

    const labels = ages.map((age, index) => {
      return age;
    });

    return { series, labels };
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        // Miscellaneous
        primary_representation: data.ufs.primary_representation || "",
        growth_rate: data.ufs.growth_rate || "",
        // Generic inputs
        effective_date: data.ufs.effective_date,
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || "",
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || "",
        retirement_date: data.ufs.retirement_date || "",
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || "",
        life_expectancy: data.ufs.life_expectancy || "",
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        retirement_longevity: data.ufs.retirement_longevity
          ? data.ufs.retirement_longevity
          : calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,
        // Growth & cost assumptions
        real_pre_retirement_capital_growth_low: data.ufs.real_pre_retirement_capital_growth_low || "",
        real_pre_retirement_capital_growth_medium: data.ufs.real_pre_retirement_capital_growth_medium || "",
        real_pre_retirement_capital_growth_high: data.ufs.real_pre_retirement_capital_growth_high || "",
        nominal_pre_retirement_capital_growth_low: data.ufs.nominal_pre_retirement_capital_growth_low || "",
        nominal_pre_retirement_capital_growth_medium: data.ufs.nominal_pre_retirement_capital_growth_medium || "",
        nominal_pre_retirement_capital_growth_high: data.ufs.nominal_pre_retirement_capital_growth_high || "",
        pre_retirement_costs: data.ufs.pre_retirement_costs || "",
        real_post_retirement_capital_growth_low: data.ufs.real_post_retirement_capital_growth_low || "",
        real_post_retirement_capital_growth_medium: data.ufs.real_post_retirement_capital_growth_medium || "",
        real_post_retirement_capital_growth_high: data.ufs.real_post_retirement_capital_growth_high || "",
        nominal_post_retirement_capital_growth_low: data.ufs.nominal_post_retirement_capital_growth_low || "",
        nominal_post_retirement_capital_growth_medium: data.ufs.nominal_post_retirement_capital_growth_medium || "",
        nominal_post_retirement_capital_growth_high: data.ufs.nominal_post_retirement_capital_growth_high || "",
        post_retirement_costs: data.ufs.post_retirement_costs || "",
        // smart tool specific inputs
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || "",
        post_retirement_income_percentage: data.ufs.post_retirement_income_percentage || "",
        contribution_commencement_age: Math.floor(data.ufs.current_age) || "",
        // Contribution & income growth map
        overall_annualised_growth_age_from: 0,
        overall_annualised_growth_age_to: 0,
        overall_annualised_growth_period: 0,
        phases: [
          {
            age_from: Math.floor(data.ufs.current_age) || "",
            age_to: Math.floor(data.ufs.current_age) + 1 || "",
            real_salary_growth: 9,
            nominal_salary_growth: 12.37,
            salary_contributions: 10,
            forward_inclusive: 0,
          },
        ],
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Initial phase data structure
   * This sets up the first phase based on the user's current age
   */
  const initialPhases = [
    {
      age_from: Math.floor(formData.current_age),
      age_to: Math.floor(formData.current_age) + 1,
      real_salary_growth: 9,
      nominal_salary_growth: 12.37,
      salary_contributions: 10,
      forward_inclusive: 0,
    },
  ];

  /**
   * State to store phase data
   */
  const [phaseData, setPhaseData] = useState(
    initialPhases.map((phase, index) => ({
      ...phase,
      name: `Phase#${index + 1}`,
      period: phase.age_to - phase.age_from,
      forward_inclusive: calculateForwardInclusive(
        formData.current_age,
        phase.age_to,
        phase.age_to - phase.age_from,
        phase.salary_contributions
      ),
    }))
  );

  /**
   * State to store validation errors for each phase
   */
  const [phaseValidationErrors, setPhaseValidationErrors] = useState({});

  /**
   * Validates a single phase
   * @param {Object} phase - The phase to validate
   * @param {number} index - The index of the phase in the phaseData array
   * @param {Array} allPhases - All phases in the current state
   * @returns {Object} An object containing any validation errors
   */
  const validatePhase = (phase, index, allPhases, retirementAge) => {
    const errors = {};

    // Validate age_from
    if (phase.age_from === null || phase.age_from === "") {
      errors.age_from = "Age from is required";
    } else if (index > 0) {
      const previousPhase = allPhases[index - 1];
      if (phase.age_from < previousPhase.age_to) {
        errors.age_from = `Age from must be greater than or equal to ${previousPhase.age_to}`;
      }
    }

    if (phase.age_from >= retirementAge) {
      errors.age_from = `Age from must be less than ${retirementAge}`;
    }

    // Validate age_to
    if (phase.age_to === null || phase.age_to === "") {
      errors.age_to = "Age to is required";
    } else if (phase.age_to <= phase.age_from) {
      errors.age_to = `Age to must be greater than ${phase.age_from}`;
    }

    if (phase.age_to > retirementAge) {
      errors.age_to = `Age to cannot be greater than ${retirementAge}`;
    }

    return errors;
  };

  // Function to validate all phases
  const validateAllPhases = (phases, retirementAge) => {
    const newErrors = {};
    phases.forEach((phase, index) => {
      const phaseErrors = validatePhase(phase, index, phases, retirementAge);
      if (Object.keys(phaseErrors).length > 0) {
        newErrors[index] = phaseErrors;
      }
    });
    setPhaseValidationErrors(newErrors);
  };

  // Re-validate phases when retirement age changes
  useEffect(() => {
    validateAllPhases(phaseData, formData.retirement_age);
  }, [formData.retirement_age, phaseData]);

  /**
   * Adds a new phase to the phaseData
   */
  const addRow = () => {
    const newIndex = phaseData.length + 1;
    const lastPhase = phaseData[phaseData.length - 1];

    const newPhase = {
      name: `Phase#${newIndex}`,
      age_from: lastPhase ? lastPhase.age_to : "", // Auto-populate age_from with previous phase's age_to
      age_to: "",
      real_salary_growth: "",
      nominal_salary_growth: "",
      salary_contributions: "",
      period: "",
      forward_inclusive: 0,
    };

    setPhaseData([...phaseData, newPhase]);

    // Validate the new phase
    const phaseErrors = validatePhase(newPhase, phaseData.length, [...phaseData, newPhase]);
    setPhaseValidationErrors((prevErrors) => ({
      ...prevErrors,
      [phaseData.length]: phaseErrors,
    }));
  };

  /**
   * Removes a phase from the phaseData
   * @param {number} indexToRemove - The index of the phase to remove
   */
  const removeRow = (indexToRemove) => {
    setPhaseData((currentPhaseData) => {
      const updatedPhases = currentPhaseData.filter((_, index) => index !== indexToRemove);
      const renamedPhases = updatedPhases.map((phase, index) => ({
        ...phase,
        name: `Phase#${index + 1}`,
      }));

      // Update validation errors
      setPhaseValidationErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[indexToRemove];
        // Shift the keys of the remaining errors
        Object.keys(newErrors).forEach((key) => {
          if (Number(key) > indexToRemove) {
            newErrors[Number(key) - 1] = newErrors[key];
            delete newErrors[key];
          }
        });
        return newErrors;
      });

      return renamedPhases;
    });
  };

  /**
   * Determines if the "Add phase" button should be disabled
   */
  const isAddPhaseDisabled = useCallback(() => {
    // If there are no phases, the button should be enabled
    if (phaseData.length === 0) {
      return false;
    }

    const hasErrors = Object.values(phaseValidationErrors).some((errors) => Object.keys(errors).length > 0);

    const hasEmptyFields = phaseData.some(
      (phase) => phase.age_from === null || phase.age_from === "" || phase.age_to === null || phase.age_to === ""
    );

    const isLastPhaseComplete = phaseData[phaseData.length - 1].age_to <= formData.retirement_age;

    // Check if any phase's age_to is equal to or greater than retirement_age
    const hasPhaseReachedRetirementAge = phaseData.some((phase) => phase.age_to >= formData.retirement_age);

    // The button should be disabled if there are errors, empty fields,
    // the last phase exceeds retirement age, or any phase reaches or exceeds retirement age
    return hasErrors || hasEmptyFields || !isLastPhaseComplete || hasPhaseReachedRetirementAge;
  }, [phaseData, phaseValidationErrors, formData.retirement_age]);

  const isCalculateDisabled = useCallback(() => {
    // If there are no phases, the button should be enabled
    if (phaseData.length === 0) {
      return false;
    }

    const hasErrors = Object.values(phaseValidationErrors).some((errors) => Object.keys(errors).length > 0);

    const hasEmptyFields = phaseData.some(
      (phase) => phase.age_from === null || phase.age_from === "" || phase.age_to === null || phase.age_to === ""
    );

    const isLastPhaseComplete = phaseData[phaseData.length - 1].age_to <= formData.retirement_age;

    // The button should be enabled if there are no errors, no empty fields, and the last phase doesn't exceed retirement age
    return hasErrors || hasEmptyFields || !isLastPhaseComplete;
  }, [phaseData, phaseValidationErrors, formData.retirement_age]);

  const updateOverallAnnualisedGrowth = (updatedPhaseData) => {
    let lowestAgeFrom = Infinity;
    let highestAgeTo = -Infinity;
    updatedPhaseData.forEach((phase) => {
      if (!isNaN(phase.age_from) && phase.age_from !== "" && phase.age_from < lowestAgeFrom) {
        lowestAgeFrom = phase.age_from;
      }
      if (!isNaN(phase.age_to) && phase.age_to > highestAgeTo) {
        highestAgeTo = phase.age_to;
      }
    });

    formData.overall_annualised_growth_age_from = lowestAgeFrom === Infinity ? null : lowestAgeFrom;
    formData.overall_annualised_growth_age_to = highestAgeTo === -Infinity ? null : highestAgeTo;
    formData.overall_annualised_growth_period = highestAgeTo - lowestAgeFrom;
  };

  /**
   * Handles changes to a phase's data
   * @param {Event|number} event - The event object from the input change, or a direct number value
   * @param {number} index - The index of the phase being changed
   * @param {string} name - The name of the field being changed
   */
  const onPhaseChange = (event, index, name) => {
    let value = event.target?.value ? event.target.value : event;
    if (value === "") {
      value = null; // Allow empty fields
    } else if (!isNaN(value)) {
      value = Number(value);
    }

    setPhaseData((currentPhaseData) => {
      const updatedPhaseData = currentPhaseData.map((phase, phaseIndex) => {
        if (index === phaseIndex) {
          const updatedPhase = { ...phase, [name]: value };
          const phaseErrors = validatePhase(updatedPhase, index, currentPhaseData, formData.retirement_age);

          setPhaseValidationErrors((prevErrors) => ({
            ...prevErrors,
            [index]: phaseErrors,
          }));
          // Update period if age_from or age_to changed
          if (name === "age_from" || name === "age_to") {
            updatedPhase.period =
              updatedPhase.age_to && updatedPhase.age_from ? Number(updatedPhase.age_to - updatedPhase.age_from) : "";
          }

          // Update nominal_salary_growth if real_salary_growth changed
          if (name === "real_salary_growth") {
            updatedPhase.nominal_salary_growth = calculateNominalGrowthRate(value, formData.projected_inflation_rate);
          }

          // Recalculate forward_inclusive
          updatedPhase.forward_inclusive = calculateForwardInclusive(
            formData.current_age,
            updatedPhase.age_to,
            updatedPhase.age_to - updatedPhase.age_from,
            updatedPhase.salary_contributions
          );

          return updatedPhase;
        }
        return phase;
      });

      formData.real_overall_annualised_growth = calculateOverallAnnualizedGrowth(
        updatedPhaseData,
        formData.primary_representation
      );
      formData.nominal_overall_annualised_growth = calculateNominalGrowthRate(
        formData.real_overall_annualised_growth,
        formData.projected_inflation_rate
      );

      formData.real_growth_until_retirement = calculateGrowthUntilRetirement(
        updatedPhaseData,
        formData.primary_representation
      );
      formData.nominal_growth_until_retirement = calculateNominalGrowthRate(
        formData.real_growth_until_retirement,
        formData.projected_inflation_rate
      );

      updateOverallAnnualisedGrowth(updatedPhaseData);

      return updatedPhaseData;
    });
  };

  useEffect(() => {
    setPhaseData((currentPhaseData) => {
      const updatedPhaseData = currentPhaseData.map((phase, phaseIndex) => {
        if (phaseIndex === 0) {
          const updatedAgeFrom = Math.floor(formData.contribution_commencement_age);
          const updatedAgeTo = updatedAgeFrom + 1;
          return {
            ...phase,
            age_from: updatedAgeFrom,
            age_to: updatedAgeTo,
            period: updatedAgeTo - updatedAgeFrom,
            forward_inclusive: calculateForwardInclusive(
              formData.current_age,
              phase.age_to,
              phase.age_to - phase.age_from,
              phase.salary_contributions
            ),
          };
        }
        return phase;
      });

      updateOverallAnnualisedGrowth(updatedPhaseData);

      return updatedPhaseData;
    });
  }, [formData.contribution_commencement_age]);

  useEffect(() => {
    const realUpdatedEstimatedIncome = calculateContributionInception(
      formData.current_age,
      formData.annual_pre_tax_income,
      formData.phases,
      "real"
    );
    const nominalUpdatedEstimatedIncome = calculateContributionInception(
      formData.current_age,
      formData.annual_pre_tax_income,
      formData.phases,
      "nominal"
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      real_estimated_income_at_contribution_inception: realUpdatedEstimatedIncome,
      nominal_estimated_income_at_contribution_inception: nominalUpdatedEstimatedIncome,
    }));
  }, [formData.annual_pre_tax_income, formData.current_age, formData.phases]);

  useEffect(() => {
    const realTargetIncomeAtRetirement = calculateTargetIncome(
      formData.annual_pre_tax_income,
      formData.real_growth_until_retirement,
      formData.years_to_retirement,
      formData.post_retirement_income_percentage
    );
    const nominalTargetIncomeAtRetirement = calculateTargetIncome(
      formData.annual_pre_tax_income,
      formData.nominal_growth_until_retirement,
      formData.years_to_retirement,
      formData.post_retirement_income_percentage
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      real_target_income_at_retirement: realTargetIncomeAtRetirement,
      nominal_target_income_at_retirement: nominalTargetIncomeAtRetirement,
    }));
  }, [
    formData.annual_pre_tax_income,
    formData.primary_representation == "real"
      ? formData.real_growth_until_retirement
      : formData.nominal_growth_until_retirement,
    formData.years_to_retirement,
    formData.post_retirement_income_percentage,
  ]);

  useEffect(() => {
    formData.phases = phaseData;
    if (!formData.is_dirty) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        overall_annualised_growth_age_from: phaseData[0].age_from,
        overall_annualised_growth_age_to: phaseData[0].age_to,
        overall_annualised_growth_period: phaseData[0].period,
        is_dirty: true,
      }));
    }
  }, [phaseData, formData]);

  return {
    loading,
    error,
    formData,
    setFormData,
    phaseData,
    setPhaseData,
    onPhaseChange,
    addRow,
    removeRow,
    handleSubmit,
    chartSeries,
    chartOptions,
    handleNominalChange,
    handleRealChange,
    phaseValidationErrors,
    isAddPhaseDisabled,
    isCalculateDisabled,
  };
};

export default useTargetedInvestmentsTools;
