import React from "react";
import { Outlet, RouteObject } from "react-router-dom";
import StandardIndex from "@/pages/Standard";
/* Additional */
import Budgeting from "@/pages/Standard/Additional/Budgeting";
import LifeInsurance from "@/pages/Standard/Additional/LifeInsurance";
import SolarEnergy from "@/pages/Standard/Additional/SolarEnergy";
import TwoPotRetirement from "@/pages/Standard/Additional/TwoPotRetirement";
/* Lifestyle */
import BuyVsRent from "@/pages/Standard/Lifestyle/BuyVsRent";
import EducationCost from "@/pages/Standard/Lifestyle/EducationCost";
import LoanAffordability from "@/pages/Standard/Lifestyle/LoanAffordability";
import VehicleCost from "@/pages/Standard/Lifestyle/VehicleCost";
/* Retirement */
import MoneyGrowth from "@/pages/Standard/Retirement/MoneyGrowth";
import MoneyLast from "@/pages/Standard/Retirement/MoneyLast";
import RetirementNeed from "@/pages/Standard/Retirement/RetirementNeed";
import GapAnalysis from "@/pages/Standard/Retirement/GapAnalysis";
/* WealthCreation */
import BonusImpact from "@/pages/Standard/WealthCreation/BonusImpact";
import GoalInvesting from "@/pages/Standard/WealthCreation/GoalInvesting";
import TargetedInvestments from "@/pages/Standard/WealthCreation/TargetedInvestments";
import TaxBenefits from "@/pages/Standard/WealthCreation/TaxBenefits";

const StandardRoutes: React.FC = () => {
  return <Outlet />;
};

export const standardRoutes: RouteObject[] = [
  {
    path: "standard",
    element: <StandardRoutes />,
    children: [
      { path: "", element: <StandardIndex /> },
      {
        path: "retirement",
        children: [
          { path: "retirement-need", element: <RetirementNeed /> },
          { path: "money-growth", element: <MoneyGrowth /> },
          { path: "money-last", element: <MoneyLast /> },
          { path: "gap-analysis", element: <GapAnalysis /> },
        ],
      },
      {
        path: "lifestyle",
        children: [
          { path: "vehicle-cost", element: <VehicleCost /> },
          { path: "buy-vs-rent", element: <BuyVsRent /> },
          { path: "education-cost", element: <EducationCost /> },
          { path: "loan-affordability", element: <LoanAffordability /> },
        ],
      },
      {
        path: "wealth-creation",
        children: [
          { path: "goal-investing", element: <GoalInvesting /> },
          { path: "bonus-impact", element: <BonusImpact /> },
          { path: "tax-benefits", element: <TaxBenefits /> },
          { path: "targeted-investments", element: <TargetedInvestments /> },
        ],
      },
      {
        path: "additional",
        children: [
          { path: "budgeting", element: <Budgeting /> },
          { path: "solar-energy", element: <SolarEnergy /> },
          { path: "two-pot-retirement", element: <TwoPotRetirement /> },
          { path: "life-insurance", element: <LifeInsurance /> },
        ],
      },
    ],
  },
];

export default StandardRoutes;
