import Card from "@/components/Element/Card";
import ChartLoading from "@/components/Element/ChartLoading";
import RadioFull from "@/components/Form/RadioFull";
import words from "@/services/algorithms/words";
import { ChangeEvent, useState } from "react";
import Chart from "react-apexcharts";

interface ChartOption {
  id: string;
  period: "full" | "pre" | "post";
  chartText: string;
}

interface ChartRow {
  label: string;
  periods: {
    label: string;
    options: ChartOption[];
  }[];
}

const chartData: ChartRow[] = [
  {
    label: "Active income",
    periods: [
      {
        label: "Monthly",
        options: [
          { id: "active-income-monthly-full-period", period: "full", chartText: "Full period monthly active income" },
        ],
      },
      {
        label: "Cumulative",
        options: [
          {
            id: "active-income-cumulative-full-period",
            period: "full",
            chartText: "Full period cumulative active income",
          },
        ],
      },
    ],
  },
  {
    label: "Passive income",
    periods: [
      {
        label: "Monthly",
        options: [
          {
            id: "passive-income-monthly-pre-retirement",
            period: "pre",
            chartText: "Pre-retirement monthly passive income",
          },
        ],
      },
      {
        label: "Cumulative",
        options: [
          {
            id: "passive-income-cumulative-pre-retirement",
            period: "pre",
            chartText: "Pre-retirement cumulative passive income",
          },
        ],
      },
    ],
  },
  {
    label: "Composite income",
    periods: [
      {
        label: "Monthly",
        options: [
          {
            id: "composite-income-monthly-full-period",
            period: "full",
            chartText: "Full period monthly composite income",
          },
          {
            id: "composite-income-monthly-pre-retirement",
            period: "pre",
            chartText: "Pre-retirement monthly composite income",
          },
          {
            id: "composite-income-monthly-post-retirement",
            period: "post",
            chartText: "Post-retirement monthly composite income",
          },
        ],
      },
      {
        label: "Cumulative",
        options: [
          {
            id: "composite-income-cumulative-full-period",
            period: "full",
            chartText: "Full period cumulative composite income",
          },
          {
            id: "composite-income-cumulative-pre-retirement",
            period: "pre",
            chartText: "Pre-retirement cumulative composite income",
          },
          {
            id: "composite-income-cumulative-post-retirement",
            period: "post",
            chartText: "Post-retirement cumulative composite income",
          },
        ],
      },
    ],
  },
  {
    label: "Income vs expenditure",
    periods: [
      {
        label: "Monthly",
        options: [
          {
            id: "income-vs-expenditure-monthly-full-period",
            period: "full",
            chartText: "Full period monthly income vs expenditure",
          },
          {
            id: "income-vs-expenditure-monthly-pre-retirement",
            period: "pre",
            chartText: "Pre-retirement monthly income vs expenditure",
          },
          {
            id: "income-vs-expenditure-monthly-post-retirement",
            period: "post",
            chartText: "Post-retirement monthly income vs expenditure",
          },
        ],
      },
      {
        label: "Cumulative",
        options: [
          {
            id: "income-vs-expenditure-cumulative-full-period",
            period: "full",
            chartText: "Full period cumulative income vs expenditure",
          },
          {
            id: "income-vs-expenditure-cumulative-pre-retirement",
            period: "pre",
            chartText: "Pre-retirement cumulative income vs expenditure",
          },
          {
            id: "income-vs-expenditure-cumulative-post-retirement",
            period: "post",
            chartText: "Post-retirement cumulative income vs expenditure",
          },
        ],
      },
    ],
  },
];

const secondChartData: ChartRow[] = [
  {
    label: "Active vs passive",
    periods: [
      {
        label: "Monthly",
        options: [
          {
            id: "active-vs-passive-monthly-full-period",
            period: "full",
            chartText: "Full period monthly active vs passive",
          },
          {
            id: "active-vs-passive-monthly-pre-retirement",
            period: "pre",
            chartText: "Pre retirement monthly active vs passive",
          },
          {
            id: "active-vs-passive-monthly-post-retirement",
            period: "post",
            chartText: "Post retirement monthly active vs passive",
          },
        ],
      },
      {
        label: "Cumulative",
        options: [
          {
            id: "active-vs-passive-cumulative-full-period",
            period: "full",
            chartText: "Full period cumulative active vs passive",
          },
          {
            id: "active-vs-passive-cumulative-pre-retirement",
            period: "pre",
            chartText: "Pre retirement cumulative active vs passive",
          },
          {
            id: "active-vs-passive-cumulative-post-retirement",
            period: "post",
            chartText: "Post retirement cumulative active vs passive",
          },
        ],
      },
    ],
  },
  {
    label: "Passive elements",
    periods: [
      {
        label: "Monthly",
        options: [
          {
            id: "passive-elements-monthly-full-period",
            period: "full",
            chartText: "Full period monthly passive elements",
          },
          {
            id: "passive-elements-monthly-pre-retirement",
            period: "pre",
            chartText: "Pre retirement monthly passive elements",
          },
          {
            id: "passive-elements-monthly-post-retirement",
            period: "post",
            chartText: "Post retirement monthly passive elements",
          },
        ],
      },
      {
        label: "Cumulative",
        options: [
          {
            id: "passive-elements-cumulative-full-period",
            period: "full",
            chartText: "Full period cumulative passive elements",
          },
          {
            id: "passive-elements-cumulative-pre-retirement",
            period: "pre",
            chartText: "Pre retirement cumulative passive elements",
          },
          {
            id: "passive-elements-cumulative-post-retirement",
            period: "post",
            chartText: "Post retirement cumulative passive elements",
          },
        ],
      },
    ],
  },
];

const chartSelectColumns = ["full", "pre", "post"] as const;

interface IncomeVsExpenditureTabTwoProps {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCurrencyChange: (value: string, name: string) => void;
  preventEnterPress?: (event: React.KeyboardEvent<HTMLFormElement>) => void;
  formData: any;
  loading: boolean;
  chartSeries: any;
  chartOptions: any;
}

const IncomeVsExpenditureTabTwo: React.FC<IncomeVsExpenditureTabTwoProps> = ({
  handleSubmit,
  preventEnterPress,
  formData,
  loading,
  chartSeries,
  chartOptions,
}) => {
  const { capitalizeFirstLetter } = words();
  const [selectedChart, setSelectedChart] = useState<string>("");
  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedChart(event.target.value);
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedChart(event.target.value);
  };

  const getSelectedChartText = (): string => {
    for (const row of chartData) {
      for (const period of row.periods) {
        const option = period.options.find((opt) => opt.id === selectedChart);
        if (option) {
          return option.chartText;
        }
      }
    }
    return "";
  };

  const getSecondSelectedChartText = (): string => {
    for (const row of secondChartData) {
      for (const period of row.periods) {
        const option = period.options.find((opt) => opt.id === selectedChart);
        if (option) {
          return option.chartText;
        }
      }
    }
    return "";
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="mx-auto w-full h-full"
      >
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="max-h-[calc(100vh-230px)] h-full overflow-y-auto overflow-x-hidden scrollbar">
            <Card
              variant="white"
              className="mb-4"
            >
              <form onSubmit={handleSubmit}>
                <table className="w-full">
                  <thead>
                    <tr className="border-b">
                      <th className="text-[13px] text-left pb-2">Chart type</th>
                      <th className="text-[13px] text-center pb-2">Period</th>
                      <th className="text-[13px] text-center pb-2">Full period</th>
                      <th className="text-[13px] text-center pb-2">Pre-retirment</th>
                      <th className="text-[13px] text-center pb-2">Post-retirement</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chartData.map((row, rowIndex) => (
                      <>
                        {row.periods.map((period, periodIndex) => (
                          <tr key={`${rowIndex}-${periodIndex}`}>
                            {periodIndex === 0 && (
                              <td
                                rowSpan={row.periods.length}
                                className="text-[12px] text-left font-semibold border-r border-b p-1"
                              >
                                {row.label}
                              </td>
                            )}
                            <td className="text-[12px] text-left font-semibold border-r border-b p-1">
                              {period.label}
                            </td>
                            {chartSelectColumns.map((column) => {
                              const option = period.options.find((opt) => opt.period === column);
                              return (
                                <td
                                  key={column}
                                  className="text-center border-r border-b p-1"
                                >
                                  {option && (
                                    <RadioFull
                                      id={option.id}
                                      name={` `}
                                      slug={option.id}
                                      classTarget={option.id}
                                      handleRadioChange={handleToggleChange}
                                      checked={selectedChart === option.id}
                                      centerToggle
                                    />
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </form>

              <p className="mt-4 text-[12px] font-bold">
                The charts demonstrate the relationship between your net after tax income and your components of
                consumption expenditure, wealth creation and wealth preservations. Note that the volatility of the
                consumption elements is largely a function of the replacement of your vehicles at your stated
                replacement cycle as well as ongoing education expenditure
              </p>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      {capitalizeFirstLetter(formData.primary_representation)} income @
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[50%]">
                      Monthly @date
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[50%]">
                      Cumulative values to date
                    </th>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      30 April 2048{" "}
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      {capitalizeFirstLetter(formData.primary_representation)}
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      {capitalizeFirstLetter(formData.primary_representation)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Gross income </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Gross Tax</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">Net Income</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Consumption</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Wealth creation </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Wealth preservation
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">Residual Income</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <form onSubmit={handleSubmit}>
                <table className="w-full">
                  <thead>
                    <tr className="border-b">
                      <th className="text-[13px] text-left pb-2">Chart type</th>
                      <th className="text-[13px] text-center pb-2">Period</th>
                      <th className="text-[13px] text-center pb-2">Full period</th>
                      <th className="text-[13px] text-center pb-2">Pre-retirment</th>
                      <th className="text-[13px] text-center pb-2">Post-retirement</th>
                    </tr>
                  </thead>
                  <tbody>
                    {secondChartData.map((row, rowIndex) => (
                      <>
                        {row.periods.map((period, periodIndex) => (
                          <tr key={`${rowIndex}-${periodIndex}`}>
                            {periodIndex === 0 && (
                              <td
                                rowSpan={row.periods.length}
                                className="text-[12px] text-left font-semibold border-r border-b p-1"
                              >
                                {row.label}
                              </td>
                            )}
                            <td className="text-[12px] text-left font-semibold border-r border-b p-1">
                              {period.label}
                            </td>
                            {chartSelectColumns.map((column) => {
                              const option = period.options.find((opt) => opt.period === column);
                              return (
                                <td
                                  key={column}
                                  className="text-center border-r border-b p-1"
                                >
                                  {option && (
                                    <RadioFull
                                      id={option.id}
                                      name={` `}
                                      slug={option.id}
                                      classTarget={option.id}
                                      handleRadioChange={handleToggleChange}
                                      checked={selectedChart === option.id}
                                      centerToggle
                                    />
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </form>

              <p className="mt-4 text-[12px] font-bold">
                The selected charts compare your projected income from passive sources i.e. investments & savings
                income, net rental income, retirement capital income and other (net interest, business income) in real
                and nominal, monthly and cumulative, pre-tax terms and shows it in absolute or relative terms (against
                active income and total post-tax income).
              </p>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      {capitalizeFirstLetter(formData.primary_representation)} income @
                    </th>
                    <th
                      colSpan={2}
                      className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[50%]"
                    >
                      Cumulative values from effective date
                    </th>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      30 April 2048{" "}
                    </th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">Monthly</th>
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[25%]">
                      Cumulative
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Investment</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Net rents </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Retirement</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Other</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-bold p-2">Total</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>

            <Card
              variant="white"
              className="mb-2"
            >
              <form onSubmit={handleSubmit}>
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-[14px] text-left pb-4 w-[50%]">Charts</th>
                      <th className="text-[14px] pb-4">Pre-retirement</th>
                      <th className="text-[14px] pb-4">Full period</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-[12px] text-left font-bold">Net bank balance</td>
                      <td>
                        <RadioFull
                          id={"net-bank-balance-pre-retirement"}
                          name={""}
                          slug={"net-bank-balance-pre-retirement"}
                          classTarget="select-chart"
                          handleRadioChange={handleRadioChange}
                          checked={selectedChart === "net-bank-balance-pre-retirement"}
                          centerToggle
                        />
                      </td>
                      <td className="text-[12px] font-semibold">
                        <RadioFull
                          id={"net-bank-balance-full-period"}
                          name={""}
                          slug={"net-bank-balance-full-period"}
                          classTarget="select-chart"
                          handleRadioChange={handleRadioChange}
                          checked={selectedChart === "net-bank-balance-full-period"}
                          centerToggle
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>

              <p className="mt-4 text-[12px] font-bold">
                Note that pattern of your net bank balance <strong>(use AI to build commentary)</strong> e.g.
              </p>
              <ul className="pl-4 list-[disc]">
                <li>
                  <p className="text-[12px] font-bold">
                    {`early phase (to age 50) : high consumption expenditure
(including education) plus fixed mortgage repayments &
associated costs `}
                  </p>
                </li>
                <li>
                  <p className="text-[12px] font-bold">{`mature phase (to formal retirement) : low to zero "loan"
repayments, lower costs of living
`}</p>
                </li>
                <li>
                  <p className="text-[12px] font-bold">{`maintenance phase (to full retirement) : part-time work
covers everyday expenditure, growth in financial assets
contribute to positive net bank balance`}</p>
                </li>
                <li>
                  <p className="text-[12px] font-bold">{`withdrawal phase (to late life) : positive contributions from
passive income > projected expenditure & yields growing
net bank balance until excess medical costs start kicking in`}</p>
                </li>
              </ul>
            </Card>

            <Card
              variant="white"
              className="mb-2"
            >
              <table className="table-auto w-full border border-[#999]">
                <thead>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[50%]">Values@</th>
                    <th
                      rowSpan={2}
                      className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[50%]"
                    >
                      {formData.primary_representation}
                    </th>
                  </tr>
                  <tr className="bg-[#f2b443]">
                    <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">30 April 2048 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Net bank balance</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Cumulative interest paid
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Cumulative interest received
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Maximum overdraft</td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                      Maximum positive balance
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2">
                      .
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
          <div className="mb-1 h-[calc(100vh-240px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {selectedChart && capitalizeFirstLetter(getSelectedChartText())
                  ? capitalizeFirstLetter(getSelectedChartText())
                  : capitalizeFirstLetter(getSecondSelectedChartText())}{" "}
                {formData.primary_representation && <> - {formData.primary_representation}</>}
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="h-full relative">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </>
  );
};

export default IncomeVsExpenditureTabTwo;
