import SelectToggle from "@/components/Form/ToggleSwitch";
import useVehicleCostTool from "@api/Standard/Lifestyle/useVehicleCostTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import { CommaSeparated } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import Change from "@utils/change";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";

const VehicleCost = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();
  const { preventEnterPress } = helpers();

  const {
    loading,
    formData,
    setFormData,
    handleSubmit,
    chartSeries,
    chartOptions,
    handleNominalChange,
    handleRealChange,
  } = useVehicleCostTool();

  const { type, handleChange, handleCurrencyChange, handlePrimaryRepresentationChange } = Change(formData, setFormData);

  const [showHelpPopup, _] = useState();

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              One of the significant “destroyers” of investment capital is vehicle ownership due to the fact that it
              tends to have more of a status than utility purpose, as well as it is a depreciating asset.
            </li>
            <li className="mb-2">
              This tool is designed to demonstrate the impact on a person’s accumulated capital if they had{" "}
              <strong>invested the difference</strong> in ownership costs between two vehicles.
            </li>
            <li className="mb-2">
              Inputs are:
              <ul className="pl-4">
                <li className="my-1">
                  a) Generic: projected inflation rate is shown for illustrative purposes; investment growth rates
                  (opportunity cost) as per standard defaults
                </li>
                <li className="my-1">
                  b) Additional (per car) will include:
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Purchase price, cost of accessories, trade-in value, finance amount, finance term, interest
                      rate
                    </li>
                    <li className="my-1">ii) Insurance costs, maintenance costs, running costs.</li>
                    <li className="my-1">
                      iii) Replacement cycle (years), replacement target (real cost of future replacement vehicle),
                      vehicle depreciation rate
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output shows
              <ul className="pl-4">
                <li className="my-1">
                  a) Table of capital that could have been accumulated by selecting the lower valued vehicle over a
                  target period.
                </li>
                <li className="my-1">
                  b) Chart shows the value of the two comparative vehicles in real terms (RHS) compared to the capital
                  that could have been accumulated (LHS) by investing the difference in cost of ownership.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {/* <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("vehicle-cost-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Opportunity cost of ownership of different vehicles"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Vehicle Cost" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  loading={loading}
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-2 items-end">
                  <div>
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div>
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          alignText="right"
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <div className="col-span-4 mb-4">
                <div className="bg-transparent rounded-md">
                  <div className="p-4 bg-[#fff] rounded-t-md">
                    <h3 className="text-[16px] font-semibold">Smart tool specific inputs</h3>
                  </div>
                  <div className="bg-white px-4 pb-4">
                    <table className="table-auto w-full border border-[#999]">
                      <thead className="bg-[#ffffff]">
                        <tr>
                          <th className="align-middle text-[12px] text-left font-bold p-2 border-r border-t border-solid border-[#999] w-[25%]">
                            Description
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-r border-t border-solid border-[#999] w-[25%]">
                            Vehicle #1
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-r border-t border-solid border-[#999] w-[25%]">
                            Vehicle #2
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-t border-solid border-[#999] w-[25%]">
                            Difference
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-white">
                          <td
                            colSpan={4}
                            className="text-[12px] font-bold p-2 bg-[#C3CBBE]"
                          >
                            <div className="flex items-center">
                              Asset cost
                              <span className="ml-4 cursor-pointer">
                                <VisibilityToggle
                                  keyName="asset_cost"
                                  isVisible={isVisible("asset_cost")}
                                  toggleVisibility={toggleVisibility}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                        {isVisible("asset_cost") ? (
                          <>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999]">
                                Purchase price
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                <Currency
                                  name="v1_price"
                                  id="v1_price"
                                  value={formData.v1_price}
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  valueType="positive"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                <Currency
                                  name="v2_price"
                                  id="v2_price"
                                  value={formData.v2_price}
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  valueType="positive"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999]">
                                <Currency
                                  variant="red"
                                  disabled
                                  name="price_difference"
                                  id="price_difference"
                                  value={formData.price_difference}
                                  onChange={handleCurrencyChange}
                                />
                              </td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999]">
                                Trade-in value
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                <Currency
                                  name="v1_trade_in"
                                  id="v1_trade_in"
                                  value={formData.v1_trade_in}
                                  type="number"
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  allowNegativeValue={true}
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                <Currency
                                  name="v2_trade_in"
                                  id="v2_trade_in"
                                  value={formData.v2_trade_in}
                                  type="number"
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  allowNegativeValue={true}
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999]">
                                <Currency
                                  name="trade_in_differece"
                                  id="trade_in_differece"
                                  value={formData.trade_in_differece}
                                  type="number"
                                  onChange={handleChange}
                                  variant="red"
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                Finance amount
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                <Currency
                                  name="v1_fin_amount"
                                  id="v1_fin_amount"
                                  value={formData.v1_fin_amount}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                <Currency
                                  name="v2_fin_amount"
                                  id="v2_fin_amount"
                                  value={formData.v2_fin_amount}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                                <Currency
                                  name="fin_amount_difference"
                                  id="fin_amount_difference"
                                  value={formData.fin_amount_difference}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                Term (years)
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                <Input
                                  name="v1_term"
                                  id="v1_term"
                                  value={formData.v1_term}
                                  type="number"
                                  onChange={handleChange}
                                  variant="green"
                                  valueType="positive"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                                <Input
                                  name="v2_term"
                                  id="v2_term"
                                  value={formData.v2_term}
                                  type="number"
                                  onChange={handleChange}
                                  variant="green"
                                  valueType="positive"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]"></td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                Interest rate
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Percentage
                                  name="v1_interest"
                                  id="v1_interest"
                                  value={formData.v1_interest}
                                  onChange={(value, name) => handleCurrencyChange(value, name, "positive")}
                                  variant="green"
                                  valueType="positive"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Percentage
                                  name="v2_interest"
                                  id="v2_interest"
                                  value={formData.v2_interest}
                                  onChange={(value, name) => handleCurrencyChange(value, name, "positive")}
                                  variant="green"
                                  valueType="positive"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]"></td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                Annual finance
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                <Currency
                                  name="v1_ann_fin_amount"
                                  id="v1_ann_fin_amount"
                                  value={formData.v1_ann_fin_amount}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                <Currency
                                  name="v2_ann_fin_amount"
                                  id="v2_ann_fin_amount"
                                  value={formData.v2_ann_fin_amount}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-solid border-[#999]">
                                <Currency
                                  name="ann_fin_amount_difference"
                                  id="ann_fin_amount_difference"
                                  value={formData.ann_fin_amount_difference}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                />
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td></td>
                          </tr>
                        )}

                        <tr className="bg-white">
                          <td
                            colSpan={4}
                            className="text-[12px] font-bold p-2 bg-[#C3CBBE]"
                          >
                            <div className="flex items-center">
                              Cost of ownership
                              <span className="ml-4 cursor-pointer">
                                <VisibilityToggle
                                  keyName="asset_preservation"
                                  isVisible={isVisible("asset_preservation")}
                                  toggleVisibility={toggleVisibility}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>

                        {isVisible("asset_preservation") ? (
                          <>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                Annual Insurance
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Currency
                                  name="v1_ann_ins"
                                  id="v1_ann_ins"
                                  value={formData.v1_ann_ins}
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  allowNegativeValue={true}
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Currency
                                  name="v2_ann_ins"
                                  id="v2_ann_ins"
                                  value={formData.v2_ann_ins}
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  allowNegativeValue={true}
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                                <Currency
                                  name="ann_ins_difference"
                                  id="ann_ins_difference"
                                  value={formData.ann_ins_difference}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                  valueType="negative"
                                />
                              </td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                Annual maintenance (service)
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Currency
                                  name="v1_ann_maintenance"
                                  id="v1_ann_maintenance"
                                  value={formData.v1_ann_maintenance}
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  allowNegativeValue={true}
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Currency
                                  name="v2_ann_maintenance"
                                  id="v2_ann_maintenance"
                                  value={formData.v2_ann_maintenance}
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  allowNegativeValue={true}
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                                <Currency
                                  name="ann_maintenance_difference"
                                  id="ann_maintenance_difference"
                                  value={formData.ann_maintenance_difference}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                  valueType="negative"
                                />
                              </td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                Annual running costs (fuel / tyres)
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Currency
                                  name="v1_ann_cost"
                                  id="v1_ann_cost"
                                  value={formData.v1_ann_cost}
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  allowNegativeValue={true}
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Currency
                                  name="v2_ann_cost"
                                  id="v2_ann_cost"
                                  value={formData.v2_ann_cost}
                                  onChange={handleCurrencyChange}
                                  variant="green"
                                  allowNegativeValue={true}
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                                <Currency
                                  name="ann_cost_difference"
                                  id="ann_cost_difference"
                                  value={formData.ann_cost_difference}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                  allowNegativeValue={true}
                                  valueType="negative"
                                />
                              </td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                Total
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Currency
                                  name="v1_ann_cost_total"
                                  id="v1_ann_cost_total"
                                  value={formData.v1_ann_cost_total}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                  allowNegativeValue={true}
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                <Currency
                                  name="v2_ann_cost_total"
                                  id="v2_ann_cost_total"
                                  value={formData.v2_ann_cost_total}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                  allowNegativeValue={true}
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                                <Currency
                                  name="ann_cost_total_difference"
                                  id="ann_cost_total_difference"
                                  value={formData.ann_cost_total_difference}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                  allowNegativeValue={true}
                                />
                              </td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                Percentage of initial value
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                <Percentage
                                  name="v1_percentage_value"
                                  id="v1_percentage_value"
                                  value={formData.v1_percentage_value}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                <Percentage
                                  name="v2_percentage_value"
                                  id="v2_percentage_value"
                                  value={formData.v2_percentage_value}
                                  onChange={handleCurrencyChange}
                                  variant="red"
                                  disabled
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2"></td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td></td>
                          </tr>
                        )}

                        <tr className="bg-white">
                          <td
                            colSpan={4}
                            className="text-[12px] font-bold p-2 bg-[#C3CBBE]"
                          >
                            <div className="flex items-center">
                              Asset replacement
                              <span className="ml-4 cursor-pointer">
                                <VisibilityToggle
                                  keyName="asset_replacement"
                                  isVisible={isVisible("asset_replacement")}
                                  toggleVisibility={toggleVisibility}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                        {isVisible("asset_replacement") ? (
                          <>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                Target value ({formData.primary_representation} annual escalation over initial)
                              </td>
                              {formData.primary_representation == "real" ? (
                                <>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                    <Percentage
                                      name="real_v1_tar_value"
                                      id="real_v1_tar_value"
                                      value={formData.real_v1_tar_value}
                                      onChange={(value, name) => handleCurrencyChange(value, name, "positive")}
                                      variant="green"
                                      valueType="positive"
                                    />
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                    <Percentage
                                      name="real_v2_tar_value"
                                      id="real_v2_tar_value"
                                      value={formData.real_v2_tar_value}
                                      onChange={(value, name) => handleCurrencyChange(value, name, "positive")}
                                      variant="green"
                                      valueType="positive"
                                    />
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                    <Percentage
                                      name="nominal_v1_tar_value"
                                      id="nominal_v1_tar_value"
                                      value={formData.nominal_v1_tar_value}
                                      onChange={(value, name) => handleCurrencyChange(value, name, "positive")}
                                      variant="green"
                                      valueType="positive"
                                    />
                                  </td>
                                  <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                                    <Percentage
                                      name="nominal_v2_tar_value"
                                      id="nominal_v2_tar_value"
                                      value={formData.nominal_v2_tar_value}
                                      onChange={(value, name) => handleCurrencyChange(value, name, "positive")}
                                      variant="green"
                                      valueType="positive"
                                    />
                                  </td>
                                </>
                              )}

                              <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999]"></td>
                            </tr>
                            <tr className="bg-white">
                              <td className="align-middle text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                Replacement cycle (years)
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                <Input
                                  name="v1_rep_cycle"
                                  id="v1_rep_cycle"
                                  value={formData.v1_rep_cycle}
                                  type="number"
                                  onChange={handleChange}
                                  variant="green"
                                  valueType="positive"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                <Input
                                  name="v2_rep_cycle"
                                  id="v2_rep_cycle"
                                  value={formData.v2_rep_cycle}
                                  type="number"
                                  onChange={handleChange}
                                  variant="green"
                                  valueType="positive"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2"></td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td></td>
                          </tr>
                        )}

                        <tr className="bg-white">
                          <td
                            colSpan={4}
                            className="text-[12px] font-bold p-2 bg-[#C3CBBE]"
                          >
                            <div className="flex items-center">
                              Asset depreciation
                              <span className="ml-4 cursor-pointer">
                                <VisibilityToggle
                                  keyName="asset_depreciation"
                                  isVisible={isVisible("asset_depreciation")}
                                  toggleVisibility={toggleVisibility}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                        {isVisible("asset_depreciation") && (
                          <>
                            <tr className="bg-white rounded-md">
                              <td className="align-middle text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                Nominal annual vehicle depreciation
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                <Percentage
                                  name="v1_ann_veh_dep"
                                  id="v1_ann_veh_dep"
                                  value={formData.v1_ann_veh_dep}
                                  onChange={(value, name) => handleCurrencyChange(value, name, "negative")}
                                  variant="green"
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                                <Percentage
                                  name="v2_ann_veh_dep"
                                  id="v2_ann_veh_dep"
                                  value={formData.v2_ann_veh_dep}
                                  onChange={(value, name) => handleCurrencyChange(value, name, "negative")}
                                  variant="green"
                                  valueType="negative"
                                />
                              </td>
                              <td className="align-middle text-center text-[12px] font-medium p-2 border-solid border-[#999]"></td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <Card variant="white">
                <div className=" mb-4">
                  <div className="grid grid-cols-4 gap-6 mb-4 items-center">
                    <div>
                      <h3 className="text-[16px] font-semibold">Output:</h3>
                    </div>
                    <div className="col-span-2">
                      <p className="block mr-2 text-[12px] font-bold text-gray-900 w-full leading-[15px] text-right">
                        Evaluation date - number of years ahead
                      </p>
                    </div>
                    <div>
                      <Input
                        min={5}
                        max={30}
                        type="number"
                        name="future_years_hence"
                        id="future_years_hence"
                        value={formData.future_years_hence}
                        onChange={handleChange}
                        variant="green"
                      />
                    </div>
                  </div>
                  <p className="text-[12px] font-semibold mt-2">
                    The table below shows the "lost potential value" from buying & replacing a more, rather than less
                    expensive vehicle, by investing the difference at the stated growth rates below
                  </p>
                </div>

                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="align-middle text-[12px] text-left font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443] w-[40%]">
                        Net impact at opportunity cost growth rates
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443] w-[20%]">
                        Low
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]v">
                        Medium
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999]">
                        Net growth
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3] text-white">
                        <Percentage
                          name={type ? "real_growth_low" : "nominal_growth_low"}
                          id={type ? "real_growth_low" : "nominal_growth_low"}
                          value={type ? formData.real_growth_low : formData.nominal_growth_low}
                          //@ts-ignore
                          onChange={type ? handleRealChange : handleNominalChange}
                          variant="red"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3] text-white">
                        <Percentage
                          name={type ? "real_growth_medium" : "nominal_growth_medium"}
                          id={type ? "real_growth_medium" : "nominal_growth_medium"}
                          value={type ? formData.real_growth_medium : formData.nominal_growth_medium}
                          //@ts-ignore
                          onChange={type ? handleRealChange : handleNominalChange}
                          variant="red"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3] text-white">
                        <Percentage
                          name={type ? "real_growth_high" : "nominal_growth_high"}
                          id={type ? "real_growth_high" : "nominal_growth_high"}
                          value={type ? formData.real_growth_high : formData.nominal_growth_high}
                          //@ts-ignore
                          onChange={type ? handleRealChange : handleNominalChange}
                          variant="red"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Cumulative opportunity cost
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={type ? formData.real_cumulative_low : formData.nominal_cumulative_low}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={type ? formData.real_cumulative_medium : formData.nominal_cumulative_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={type ? formData.real_cumulative_high : formData.nominal_cumulative_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>
          <div className="col-span-4 mb-1 min-h-[calc(100vh-170px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {capitalizeFirstLetter(formData.primary_representation)} opportunity cost of ownership
              </p>
              <div className="max-h-[calc(100vh-200px)] h-[calc(100%-43px)]">
                {loading ? (
                  <ChartLoading />
                ) : (
                  <>
                    <div className="h-[calc(100vh-250px)] relative">
                      {chartSeries && (
                        <Chart
                          options={chartOptions}
                          series={chartSeries}
                          type="line"
                          width="100%"
                          height="100%"
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default VehicleCost;
