import Input from "@/components/Form/Input";
import useGeneralInformation from "@/services/api/User/useGeneralInformation";
import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface VisibilityState {
  consumptionExpenditure: boolean;
  wealthCreation: boolean;
  wealthPreservation: boolean;
}

interface VisibilityToggleProps {
  keyName: keyof VisibilityState;
  isVisible: boolean;
  toggleVisibility: (key: keyof VisibilityState) => void;
}

const VisibilityToggle: React.FC<VisibilityToggleProps> = ({ keyName, isVisible, toggleVisibility }) => {
  return (
    <>
      {!isVisible ? (
        <FaEye
          onClick={() => toggleVisibility(keyName)}
          className="cursor-pointer"
        />
      ) : (
        <FaEyeSlash
          onClick={() => toggleVisibility(keyName)}
          className="cursor-pointer"
        />
      )}
    </>
  );
};

const IncomeVsExpenditureTable: React.FC = () => {
  const { formData } = useGeneralInformation();

  const [visibility, setVisibility] = useState<VisibilityState>({
    consumptionExpenditure: true,
    wealthCreation: true,
    wealthPreservation: true,
  });

  const toggleVisibility = (key: keyof VisibilityState) => {
    setVisibility((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto">
        <thead>
          <tr>
            <th colSpan={2} />
            <th className="border border-[#999] text-[12px] font-semibold px-2 py-4 bg-[#f2b443]">Current Tax year</th>
            <th className="border border-[#999] text-[12px] font-semibold px-2 py-4 bg-[#f2b443]">Future Tax year</th>
          </tr>
          <tr className="bg-[#f2b443]">
            <th className="border border-[#999] text-[12px] font-semibold p-2 w-[25%]">Description</th>
            <th className="border border-[#999] text-[12px] font-semibold p-2 w-[25%]">Next month</th>
            <th className="border border-[#999] text-[12px] font-semibold p-2 w-[25%]">
              <Input
                variant="red"
                type="date"
                disabled
                showYearsOnly
                value={formData.current_tax_year || ""}
              />
            </th>
            <th className="border border-[#999] text-[12px] font-semibold p-2 w-[25%]">
              <Input
                variant="green"
                type="number"
              />
            </th>
          </tr>
        </thead>
        <tbody className="border border-[#999]">
          <tr>
            <td className="border-b border-r border-[#999] text-[12px] p-2">Active Income (incl. bonus)</td>
            <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">110,000</td>
            <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">1,343,637</td>
            <td className="border-b border-[#999] text-[12px] p-2 text-right">1,831,352</td>
          </tr>
          <tr>
            <td className="border-b border-r border-[#999] text-[12px] p-2">Passive Income</td>
            <td className="border-b border-r border-[#999] text-[12px] p-2 text-right text-red-500">(1,165)</td>
            <td className="border-b border-r border-[#999] text-[12px] p-2 text-right text-red-500">(3,330)</td>
            <td className="border-b border-[#999] text-[12px] p-2 text-right">79,987</td>
          </tr>
          <tr>
            <td className="border-b border-r border-[#999] text-[12px] p-2">less taxation</td>
            <td className="border-b border-r border-[#999] text-[12px] p-2 text-right text-red-500">(30,726)</td>
            <td className="border-b border-r border-[#999] text-[12px] p-2 text-right text-red-500">(489,154)</td>
            <td className="border-b border-[#999] text-[12px] p-2 text-right text-red-500">(760,963)</td>
          </tr>
          <tr className="bg-[#f2e1c3]">
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Net Income after tax</td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right">78,110</td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right">851,153</td>
            <td className="border-b border-[#999] text-[12px] font-semibold p-2 text-right">1,150,376</td>
          </tr>
          {/* Consumption expenditure section */}
          <tr className={`${!visibility.consumptionExpenditure && "border-b border-[#999]"}`}>
            <td
              colSpan={4}
              className={`text-[12px] font-semibold p-2 flex items-center gap-4`}
            >
              Consumption expenditure
              <VisibilityToggle
                keyName="consumptionExpenditure"
                isVisible={visibility.consumptionExpenditure}
                toggleVisibility={toggleVisibility}
              />
            </td>
          </tr>
          {visibility.consumptionExpenditure && (
            <>
              <tr>
                <td className="border-b border-r border-t border-[#999] text-[12px] p-2">
                  a) General living costs (incl. rent & other)
                </td>
                <td className="border-b border-r border-t border-[#999] text-[12px] p-2 text-right">13,900</td>
                <td className="border-b border-r border-t border-[#999] text-[12px] p-2 text-right">168,482</td>
                <td className="border-b border-t border-[#999] text-[12px] p-2 text-right">210,642</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">
                  b) Vehicle (payments, maintenance & running)
                </td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">17,467</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">200,950</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">188,047</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">c) Education costs</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">134,048</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">151,423</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">d) Medical & medical aid</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">2,300</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">29,269</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">56,644</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">e) Loan & overdraft payments</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">1,531</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">33,721</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">98,495</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">f) Security</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">1,000</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">12,061</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">15,079</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">g) Special events</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">0</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">h) Other</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">0</td>
              </tr>
            </>
          )}

          <tr>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2"></td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right bg-[#f2e1c3]">
              36,198
            </td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right bg-[#f2e1c3]">
              578,532
            </td>
            <td className="border-b border-[#999] text-[12px] font-semibold p-2 text-right bg-[#f2e1c3]">720,330</td>
          </tr>

          {/* Wealth creation section */}
          <tr className={`${!visibility.wealthCreation && "border-b border-[#999]"}`}>
            <td
              colSpan={4}
              className={`text-[12px] font-semibold p-2 flex items-center gap-4`}
            >
              Wealth creation
              <VisibilityToggle
                keyName="wealthCreation"
                isVisible={visibility.wealthCreation}
                toggleVisibility={toggleVisibility}
              />
            </td>
          </tr>
          {visibility.wealthCreation && (
            <>
              <tr>
                <td className="border-b border-r border-t border-[#999] text-[12px] p-2">a) Property</td>
                <td className="border-b border-r border-t border-[#999] text-[12px] p-2 text-right">16,020</td>
                <td className="border-b border-r border-t border-[#999] text-[12px] p-2 text-right">177,790</td>
                <td className="border-b border-t border-[#999] text-[12px] p-2 text-right">61,377</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">b) Retirement</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">13,125</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">160,305</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">215,750</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">c) Investment & savings</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">6,500</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">80,282</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">65,965</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">d) Business</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">0</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">e) Other</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">0</td>
              </tr>
            </>
          )}

          <tr>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2"></td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right bg-[#f2e1c3]">
              35,645
            </td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right bg-[#f2e1c3]">
              418,378
            </td>
            <td className="border-b border-[#999] text-[12px] font-semibold p-2 text-right bg-[#f2e1c3]">343,092</td>
          </tr>

          {/* Wealth preservation section */}
          <tr className={`${!visibility.wealthPreservation && "border-b border-[#999]"}`}>
            <td
              colSpan={4}
              className={`text-[12px] font-semibold p-2 flex items-center gap-4`}
            >
              Wealth preservation
              <VisibilityToggle
                keyName="wealthPreservation"
                isVisible={visibility.wealthPreservation}
                toggleVisibility={toggleVisibility}
              />
            </td>
          </tr>
          {visibility.wealthPreservation && (
            <>
              <tr>
                <td className="border-b border-r border-t border-[#999] text-[12px] p-2">
                  a) Personal insurance (life, disability & medical)
                </td>
                <td className="border-b border-r border-t border-[#999] text-[12px] p-2 text-right">3,420</td>
                <td className="border-b border-r border-t border-[#999] text-[12px] p-2 text-right">41,546</td>
                <td className="border-b border-t border-[#999] text-[12px] p-2 text-right">43,131</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">
                  b) Property insurance, maintenance & rates
                </td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">12,635</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">102,879</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">124,694</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">c) Vehicle insurance</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">2,675</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">31,521</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">37,093</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">d) Other insurance</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">5,075</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">60,077</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">64,672</td>
              </tr>
              <tr>
                <td className="border-b border-r border-[#999] text-[12px] p-2">d) Other</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-r border-[#999] text-[12px] p-2 text-right">0</td>
                <td className="border-b border-[#999] text-[12px] p-2 text-right">0</td>
              </tr>
            </>
          )}
          <tr className="">
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2"></td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right bg-[#f2e1c3]">
              23,805
            </td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right bg-[#f2e1c3]">
              236,023
            </td>
            <td className="border-b border-[#999] text-[12px] font-semibold p-2 text-right bg-[#f2e1c3]">269,590</td>
          </tr>
          <tr className="bg-[#f2e1c3]">
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Residual net income</td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right text-red-500">
              (17,538)
            </td>
            <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-right text-red-500">
              (381,779)
            </td>
            <td className="border-b border-[#999] text-[12px] font-semibold p-2 text-right text-red-500">(182,636)</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default IncomeVsExpenditureTable;
