import { LoanDetail } from "@/pages/Standard/Lifestyle/LoanAffordability";
import growthRateHandler from "@/services/algorithms/growthRateHandler";
import createAxiosInstance from "@/services/api/Api";
import helpers from "@/services/utils/helpers";
import { useCallback, useEffect, useState } from "react";
import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";
import numbers from "@services/algorithms/numbers";

function useLoanAffordabilityTool() {
  const api = createAxiosInstance("standard/lifestyle/loan-affordability");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);
  const { calculateRetirementLongevity } = numbers();

  const [formData, setFormData] = useState<{
    date_of_birth: string;
    retirement_age: string;
    life_expectancy: string;
    current_age: string;
    years_to_retirement: string;
    retirement_longevity_custom: boolean;
    retirement_longevity: string;
    growth_rate: string;
    annual_pre_tax_income: string;
    projected_inflation_rate: string;
    real_post_retirement_capital_growth_low: string;
    real_post_retirement_capital_growth_medium: string;
    real_post_retirement_capital_growth_high: string;
    nominal_post_retirement_capital_growth_low: string;
    nominal_post_retirement_capital_growth_medium: string;
    nominal_post_retirement_capital_growth_high: string;
    effective_date: string;
    primary_representation: string;
    fund_source_representation: string;
    net_growth_low: number;
    net_growth_medium: number;
    net_growth_high: number;
    loan_values: LoanDetail[][];
    loan_totals: LoanDetail[];
    marginal_tax_rate: number;
    tax_rate: number;
    final_retirement_pot_low: number;
    final_retirement_pot_medium: number;
    final_retirement_pot_high: number;
    final_investment_pot_low: number;
    final_investment_pot_medium: number;
    final_investment_pot_high: number;
  }>({
    date_of_birth: "",
    retirement_age: "",
    life_expectancy: "",
    current_age: "",
    years_to_retirement: "",
    retirement_longevity_custom: false,
    retirement_longevity: "",
    growth_rate: "Low",
    annual_pre_tax_income: "",
    projected_inflation_rate: "",
    real_post_retirement_capital_growth_low: "",
    real_post_retirement_capital_growth_medium: "",
    real_post_retirement_capital_growth_high: "",
    nominal_post_retirement_capital_growth_low: "",
    nominal_post_retirement_capital_growth_medium: "",
    nominal_post_retirement_capital_growth_high: "",
    effective_date: "",
    primary_representation: "real",
    fund_source_representation: "retirement",
    net_growth_low: 0,
    net_growth_medium: 0,
    net_growth_high: 0,
    loan_values: [[]],
    loan_totals: [],
    marginal_tax_rate: 0,
    tax_rate: 35,
    // Output
    final_retirement_pot_low: 0,
    final_retirement_pot_medium: 0,
    final_retirement_pot_high: 0,
    final_investment_pot_low: 0,
    final_investment_pot_medium: 0,
    final_investment_pot_high: 0,
  });

  const { handleNominalChange, handleRealChange, genericInputsCalculations, growthPreRetirementCalculations } =
    growthRateHandler(formData, setFormData);

  const calculateAndSetValues = useCallback(() => {
    // Set form data with calculated values
    setFormData((prevFormData) => ({
      ...prevFormData,
    }));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      calculateAndSetValues();
    }, 100);
  }, [calculateAndSetValues]);

  useEffect(() => {
    setTimeout(() => {
      genericInputsCalculations();
    }, 100);
  }, [genericInputsCalculations]);

  useEffect(() => {
    setTimeout(() => {
      growthPreRetirementCalculations();
    }, 100);
  }, [growthPreRetirementCalculations]);

  const convertChartSeries = (chartData: any) => {
    const months = Object.values(chartData.months);

    const cumulativeTotalPayments = Object.values(chartData.cumulative_total_payments).map((value) =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );
    const outstandingLoans = Object.values(chartData.outstanding_loans).map((value: string | unknown) =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );
    const lowGrowth = Object.values(chartData.low_growth).map((value: string | unknown): string =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );
    const mediumGrowth = Object.values(chartData.medium_growth).map((value: string | unknown) =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );
    const highGrowth = Object.values(chartData.high_growth).map((value: string | unknown) =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );

    const series = [
      {
        name: "Cumulative Total Payments",
        type: "area",
        data: cumulativeTotalPayments,
        color: "#a5a5a5",
      },
      {
        name: "Outstanding Loans",
        type: "area",
        data: outstandingLoans,
        color: "#4472c4",
      },
      {
        name: "Low Growth",
        type: "line",
        data: lowGrowth,
        color: "#ffc000",
      },
      {
        name: "Medium Growth",
        type: "line",
        data: mediumGrowth,
        color: "#5b9bd5",
      },
      {
        name: "High Growth",
        type: "line",
        data: highGrowth,
        color: "#70ad47",
      },
    ];

    const labels = months.map((month, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Month " + month;
    });

    return { series, labels };
  };

  function handleCurrencyChange(value: string, name: string) {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function handlePrimaryRepresentationChange(value: string) {
    setFormData((prev) => ({
      ...prev,
      primary_representation: value,
    }));
  }

  function handleFundSourceChange(value: string) {
    setFormData((prev) => ({
      ...prev,
      fund_source_representation: value,
    }));
  }

  function handleChange(e: { target: { name: string; value: any } }) {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: any) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      setFormData({
        ...formData,
        final_retirement_pot_low: data.final_retirement_pot_low || 0,
        final_retirement_pot_medium: data.final_retirement_pot_medium || 0,
        final_retirement_pot_high: data.final_retirement_pot_high || 0,
        final_investment_pot_low: data.final_investment_pot_low || 0,
        final_investment_pot_medium: data.final_investment_pot_medium || 0,
        final_investment_pot_high: data.final_investment_pot_high || 0,
      });

      const { series, labels } = convertChartSeries(data.data);

      setChartSeries(series as any);

      setChartOptions((prevOptions: any) => {
        return {
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: labels,
          },
        };
      });
      return data;
    } catch (error) {
      setError(error as any);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("get-stored-data");
      setFormData((prev) => ({
        ...prev,
        ...data.ufs,
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        retirement_longevity: data.ufs.retirement_longevity
          ? data.ufs.retirement_longevity
          : calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
    loading,
    formData,
    handleSubmit,
    handleChange,
    chartSeries,
    chartOptions,
    setChartSeries,
    setChartOptions,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
    handleFundSourceChange,
    handleNominalChange,
    handleRealChange,
    setFormData,
  };
}

export default useLoanAffordabilityTool;
