import React from "react";
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import AppState from "@/context/AppState";
import "@/main.css";
import { Toaster } from "react-hot-toast";
import { authRoutes } from "@/routes/AuthRoutes";
import { commentaryRoutes } from "@/routes/CommentaryRoutes";
import { dashboardRoutes } from "@/routes/DashboardRoutes";
import { premiumRoutes } from "@/routes/PremiumRoutes";
import { standardRoutes } from "@/routes/StandardRoutes";
import ErrorBoundary from "@/components/ErrorBoundary";
import RouteError from "@/components/RouteError";

const AppStateWrapper = () => (
  <AppState>
    <Outlet />
  </AppState>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppStateWrapper />,
    errorElement: <RouteError />,
    children: [...authRoutes, ...dashboardRoutes, ...standardRoutes, ...premiumRoutes, ...commentaryRoutes],
  },
]);

const App: React.FC = () => {
  return (
    <>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
      <Toaster
        toastOptions={{ position: "bottom-right" }}
        containerStyle={{
          bottom: 50,
          right: 50,
        }}
      />
    </>
  );
};

export default App;
