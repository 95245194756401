import { useState, useEffect, useCallback } from "react";
import createAxiosInstance from "@api/Api";
import calculations from "@services/algorithms/calculations";
import helpers from "@utils/helpers";
import numbers from "@services/algorithms/numbers";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import handleSubmitWithToast from "@/services/utils/handleSubmitWithToast";
import useDebounce from "@hooks/useDebounce";

interface FormData {
  growth_rate: string;
  primary_representation: string;
  effective_date: string;
  projected_inflation_rate: number;
  date_of_birth: string;
  retirement_date: string;
  retirement_age: number;
  current_age: number;
  years_to_retirement: number;
  life_expectancy: number;
  retirement_longevity_custom: boolean;
  retirement_longevity: number;
  real_post_retirement_capital_growth_low: number;
  real_post_retirement_capital_growth_medium: number;
  real_post_retirement_capital_growth_high: number;
  nominal_post_retirement_capital_growth_low: number;
  nominal_post_retirement_capital_growth_medium: number;
  nominal_post_retirement_capital_growth_high: number;
  post_retirement_costs: number;
  real_post_net_retirement_growth_low: number;
  real_post_net_retirement_growth_medium: number;
  real_post_net_retirement_growth_high: number;
  nominal_post_net_retirement_growth_low: number;
  nominal_post_net_retirement_growth_medium: number;
  nominal_post_net_retirement_growth_high: number;
  annual_pre_tax_income: number;
  real_growth_in_income: number;
  nominal_growth_in_income: number;
  post_retirement_income_percentage: number;
  nominal_projected_retirement_fund_value: number;
  real_projected_retirement_fund_value: number;
  real_target_income_at_retirement: number;
  nominal_target_income_at_retirement: number;
  multiple_on_retirement: number;
  full_depletion_growth_rate_low: number;
  full_depletion_growth_rate_medium: number;
  full_depletion_growth_rate_high: number;
}

const useMoneyLastTool = () => {
  const api = createAxiosInstance("standard/retirement/money-last");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const { chartOptionsDefault } = helpers();
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementLongevity } = numbers();
  const { calculateMultiple, yearsToDepletion, calculateTargetIncome } = calculations();

  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState<FormData>({
    growth_rate: "Low",
    primary_representation: "",
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_date: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity_custom: false,
    retirement_longevity: 0,
    real_post_retirement_capital_growth_low: 0,
    real_post_retirement_capital_growth_medium: 0,
    real_post_retirement_capital_growth_high: 0,
    nominal_post_retirement_capital_growth_low: 0,
    nominal_post_retirement_capital_growth_medium: 0,
    nominal_post_retirement_capital_growth_high: 0,
    post_retirement_costs: 0,
    real_post_net_retirement_growth_low: 0,
    real_post_net_retirement_growth_medium: 0,
    real_post_net_retirement_growth_high: 0,
    nominal_post_net_retirement_growth_low: 0,
    nominal_post_net_retirement_growth_medium: 0,
    nominal_post_net_retirement_growth_high: 0,
    annual_pre_tax_income: 0,
    real_growth_in_income: 0,
    nominal_growth_in_income: 0,
    post_retirement_income_percentage: 0,
    nominal_projected_retirement_fund_value: 0,
    real_projected_retirement_fund_value: 0,
    real_target_income_at_retirement: 0,
    nominal_target_income_at_retirement: 0,
    multiple_on_retirement: 0,
    full_depletion_growth_rate_low: 0,
    full_depletion_growth_rate_medium: 0,
    full_depletion_growth_rate_high: 0,
  });

  const debouncedData = useDebounce(formData, 100);

  const {
    handleNominalChange,
    handleRealChange,
    calculateFutureValue,
    handleFutureChange,
    handlePresentChange,
    genericInputsCalculations,
    growthPostRetirementCalculations,
  } = growthRateHandler(debouncedData, setFormData);

  const calculateAndSetValues = useCallback(() => {
    const realTargetIncome = calculateTargetIncome(
      debouncedData.annual_pre_tax_income,
      debouncedData.real_growth_in_income,
      debouncedData.years_to_retirement,
      debouncedData.post_retirement_income_percentage
    );
    const nominalTargetIncome = calculateTargetIncome(
      debouncedData.annual_pre_tax_income,
      debouncedData.nominal_growth_in_income,
      debouncedData.years_to_retirement,
      debouncedData.post_retirement_income_percentage
    );
    const multipleOnRetirement = calculateMultiple(
      debouncedData.real_projected_retirement_fund_value,
      realTargetIncome
    );
    const fullDepletionGrowthRateLow = yearsToDepletion(
      debouncedData.real_post_net_retirement_growth_low,
      multipleOnRetirement
    );
    const fullDepletionGrowthRateMedium = yearsToDepletion(
      debouncedData.real_post_net_retirement_growth_medium,
      multipleOnRetirement
    );
    const fullDepletionGrowthRateHigh = yearsToDepletion(
      debouncedData.real_post_net_retirement_growth_high,
      multipleOnRetirement
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      real_target_income_at_retirement: realTargetIncome,
      nominal_target_income_at_retirement: nominalTargetIncome,
      multiple_on_retirement: multipleOnRetirement,
      full_depletion_growth_rate_low: fullDepletionGrowthRateLow,
      full_depletion_growth_rate_medium: fullDepletionGrowthRateMedium,
      full_depletion_growth_rate_high: fullDepletionGrowthRateHigh,
    }));
  }, [
    debouncedData.annual_pre_tax_income,
    debouncedData.effective_date,
    debouncedData.retirement_date,
    debouncedData.real_growth_in_income,
    debouncedData.nominal_growth_in_income,
    debouncedData.post_retirement_income_percentage,
    debouncedData.real_post_net_retirement_growth_low,
    debouncedData.real_post_net_retirement_growth_medium,
    debouncedData.real_post_net_retirement_growth_high,
    debouncedData.post_retirement_costs,
    debouncedData.real_projected_retirement_fund_value,
    debouncedData.years_to_retirement,
  ]);

  useEffect(() => {
    setTimeout(() => {
      calculateAndSetValues();
    }, 100);
  }, [calculateAndSetValues]);
  useEffect(() => {
    setTimeout(() => {
      genericInputsCalculations();
    }, 100);
  }, [genericInputsCalculations]);
  useEffect(() => {
    setTimeout(() => {
      growthPostRetirementCalculations();
    }, 100);
  }, [growthPostRetirementCalculations]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    handleSubmitWithToast(e, formData, setLoading, setError, calculateOutput);
  };

  const calculateOutput = async (formData: any) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      const { series, labels } = convertChartSeries(data.data);

      setChartSeries(series);

      setChartOptions((prevOptions: any) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
      }));
      return data;
    } catch (error) {
      setError(error);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        primary_representation: data.ufs.primary_representation || "",
        growth_rate: data.ufs.growth_rate || "",
        effective_date: data.ufs.effective_date || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || 0,
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || 0,
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || 0,
        retirement_date: data.ufs.retirement_date || "",
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || 0,
        life_expectancy: data.ufs.life_expectancy || 0,
        retirement_longevity_custom: data.ufs.retirement_longevity ? true : false,
        retirement_longevity: data.ufs.retirement_longevity
          ? data.ufs.retirement_longevity
          : calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || 0,
        post_retirement_costs: data.ufs.post_retirement_costs || 0,
        real_post_retirement_capital_growth_low: data.ufs.real_post_retirement_capital_growth_low || 0,
        real_post_retirement_capital_growth_medium: data.ufs.real_post_retirement_capital_growth_medium || 0,
        real_post_retirement_capital_growth_high: data.ufs.real_post_retirement_capital_growth_high || 0,
        nominal_post_retirement_capital_growth_low: data.ufs.nominal_post_retirement_capital_growth_low || 0,
        nominal_post_retirement_capital_growth_medium: data.ufs.nominal_post_retirement_capital_growth_medium || 0,
        nominal_post_retirement_capital_growth_high: data.ufs.nominal_post_retirement_capital_growth_high || 0,
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || 0,
        real_growth_in_income: data.ufs.real_growth_in_income || 0,
        nominal_growth_in_income: data.ufs.nominal_growth_in_income || 0,
        post_retirement_income_percentage: data.ufs.post_retirement_income_percentage || 0,
        nominal_projected_retirement_fund_value:
          calculateFutureValue(
            data.ufs.real_projected_retirement_fund_value,
            data.ufs.projected_inflation_rate,
            data.ufs.years_to_retirement
          ) || 0,
        real_projected_retirement_fund_value: data.ufs.real_projected_retirement_fund_value || 0,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData: any) => {
    const years = Object.values(chartData.years);

    const annualWithdrawal = Object.values(chartData.annual_withdrawals).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const residualValue = Object.values(chartData.residual_values).map((value: any) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const series = [
      {
        name: "Annual withdrawals",
        type: "column",
        color: "#ed7d31",
        data: annualWithdrawal,
      },
      {
        name: "Residual capital",
        type: "line",
        color: "#a5a5a5",
        data: residualValue,
      },
    ];

    const labels = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Year " + year;
    });

    return { series, labels };
  };

  useEffect(() => {
    getStoredData();
  }, []);

  return {
    loading,
    error,
    formData,
    setFormData,
    handleSubmit,
    chartSeries,
    setChartSeries,
    chartOptions,
    setChartOptions,
    handleNominalChange,
    handleRealChange,
    handleFutureChange,
    handlePresentChange,
  };
};

export default useMoneyLastTool;
