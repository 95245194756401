import { ReactNode } from "react";
import { IconContext } from "react-icons";
import { MdClose } from "react-icons/md";

interface HelpModalProps {
  show: boolean;
  closeHelpModal: () => void;
  modalContent?: ReactNode;
}

const HelpModal: React.FC<HelpModalProps> = ({ show, closeHelpModal, modalContent = null }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={`fixed top-0 bottom-0 left-0 right-0 bg-[#00000059] flex justify-center items-center transition-all z-[100] ${show ? "block opacity-100 visible" : "opacity-0 invisible"} `}
    >
      <div className="md:w-[65vw] xs:w-[90vw] h-[auto] max-h-[90vh] scrollbar overflow-y-auto overflow-x-hidden bg-[#fff] z-[110] p-[1rem] rounded-[8px] backdrop-blur shadow-[0_0_1rem_0_rgba(0,_0,_0,_.2)] translate-y-0">
        <div
          className="absolute top-[1rem] right-[1rem] cursor-pointer"
          onClick={closeHelpModal}
        >
          <IconContext.Provider
            value={{
              color: "#44476a",
              className: "text-[15px]",
            }}
          >
            <MdClose />
          </IconContext.Provider>
        </div>
        {modalContent ?? ""}
      </div>
    </div>
  );
};

export default HelpModal;
