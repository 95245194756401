import Card from "@/components/Element/Card";
import Tooltip from "@/components/Element/Tooltip";
import SelectToggle from "@/components/Form/ToggleSwitch";
import LayoutOne from "@/components/GenericInputs/LayoutOne";
import PostRetirement from "@/components/GrowthCostAssumptions/PostRetirement";
import PreRetirement from "@/components/GrowthCostAssumptions/PreRetirement";
import numbers from "@/services/algorithms/numbers";
import useTargetedInvestmentsTool from "@api/Standard/WealthCreation/useTargetedInvestmentsTool";
import ChartLoading from "@components/Element/ChartLoading";
import { CommaSeparated } from "@elements/Output";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import Change from "@utils/change";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { Fragment, useCallback, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { FaExclamationTriangle } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";

const TargetedInvestments = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();
  const { preventEnterPress } = helpers();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    setFormData,
    phaseData,
    onPhaseChange,
    removeRow,
    addRow,
    setPhaseData,
    handleSubmit,
    handleNominalChange,
    handleRealChange,
    phaseValidationErrors,
    isAddPhaseDisabled,
    isCalculateDisabled,
  } = useTargetedInvestmentsTool();

  const { type, handleChange, handleCurrencyChange, handleDecimalChange, handlePrimaryRepresentationChange } = Change(
    formData,
    setFormData
  );

  const [showHelpPopup, setShowHelpPopup] = useState();

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              Tool will assist clients to understand what a reasonable level of savings for retirement would be relative
              to current income, current age and retirement targets.
            </li>
            <li className="mb-2">
              It will similarly present a projected path to retirement given different return expectations.
            </li>
            <li className="mb-2">
              The following information should be noted
              <ul className="pl-4">
                <li className="my-1">
                  a) If one targets a lower future growth rate, then one requires higher historic capital accumulation
                  (and usually higher historic contributions)
                </li>
                <li className="my-1">
                  b) We demonstrate the typical performance of retirement funds throughout each client’s historic
                  capital accumulation period; hence, all histpric representations conform to historic realities and are
                  not adjustable by return, only contribution rate.
                </li>
                <li>
                  c) This allows us to demonstrate realistic targets by age/time to give effect to retirement
                  requirements / other goals.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Target financial investments by age"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Targeted Investments" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto h-full"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <SelectToggle
                  loading={loading}
                  title="Analysis type:"
                  selectedValue={formData?.primary_representation}
                  options={[
                    { value: "nominal", label: "Nominal" },
                    { value: "real", label: "Real" },
                  ]}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  loading={loading}
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  loading={loading}
                  type="submit"
                  disabled={isCalculateDisabled()}
                >
                  Calculate
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible("GenericTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <LayoutOne
                  formData={formData}
                  handleChange={handleChange}
                  handleCurrencyChange={handleCurrencyChange}
                  isVisible={isVisible("GenericTable")}
                />
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible("GrowthTable")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th className="w-[34%]"></th>
                      <th className="text-[12px] w-[22%]">Low</th>
                      <th className="text-[12px] w-[22%]">Medium</th>
                      <th className="text-[12px] w-[22%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <PreRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                    <PostRetirement
                      formData={formData}
                      handleRealChange={handleRealChange}
                      handleNominalChange={handleNominalChange}
                      handleDecimalChange={handleDecimalChange}
                      isVisible={isVisible("GrowthTable")}
                    />
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Smart tool specific inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="SpecificInputs"
                      isVisible={isVisible("SpecificInputs")}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>

                {!isVisible("SpecificInputs") && (
                  <div className="grid grid-cols-2 gap-2 items-center mt-4">
                    <div>
                      <Currency
                        label="Annual pre-tax income"
                        name="annual_pre_tax_income"
                        id="annual_pre_tax_income"
                        value={formData.annual_pre_tax_income}
                        onChange={handleCurrencyChange}
                        variant="green"
                      />
                    </div>

                    <div>
                      <Percentage
                        label="Target income on retirement"
                        name="post_retirement_income_percentage"
                        id="post_retirement_income_percentage"
                        value={formData.post_retirement_income_percentage}
                        onChange={handleCurrencyChange}
                        variant="green"
                        alignText="right"
                      />
                    </div>

                    <div>
                      <Input
                        label="Contribution commencement age"
                        name="contribution_commencement_age"
                        id="contribution_commencement_age"
                        type="number"
                        value={formData.contribution_commencement_age}
                        onChange={handleChange}
                        variant="green"
                      />
                    </div>
                  </div>
                )}
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Contribution & income growth map</h3>
                </div>
                <div className="scrollbar overflow-x-scroll">
                  <table className="table-auto w-full">
                    <thead className="bg-[#ffffff]">
                      <tr className="whitespace-nowrap bg-[#f1f1f1]">
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[130px] w-[130px]">
                          Age related brackets
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[130px] w-[130px]">
                          Age from
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[130px] w-[130px]">
                          Age to
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[130px] w-[130px]">
                          Period
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[130px] w-[130px]">
                          {capitalizeFirstLetter(formData.primary_representation)} salary growth
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[130px] w-[130px]">
                          Salary contributions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {phaseData.map((phase, index) => (
                        <tr key={index}>
                          <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                            <div className="flex items-center leading-[normal] font-bold">
                              {index !== 0 && (
                                <IoCloseCircleOutline
                                  onClick={() => removeRow(index)}
                                  color="red"
                                  className="mr-1 cursor-pointer"
                                />
                              )}

                              {phase.name}
                            </div>
                          </td>
                          <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                            <div className="flex items-center gap-1">
                              {phaseValidationErrors[index]?.age_from && (
                                <Tooltip content={phaseValidationErrors[index].age_from}>
                                  <div className=" text-red-500 cursor-help">
                                    <FaExclamationTriangle />
                                  </div>
                                </Tooltip>
                              )}
                              <Input
                                type="number"
                                name="age_from"
                                id="age_from"
                                value={phase.age_from}
                                onChange={(e) => onPhaseChange(e, index, "age_from")}
                                variant={index === 0 ? "red" : "green"}
                                disabled={index === 0}
                              />
                            </div>
                          </td>
                          <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                            <div className="flex items-center gap-1">
                              {phaseValidationErrors[index]?.age_to && (
                                <Tooltip content={phaseValidationErrors[index].age_to}>
                                  <div className=" text-red-500 cursor-help">
                                    <FaExclamationTriangle />
                                  </div>
                                </Tooltip>
                              )}
                              <Input
                                type="number"
                                name="age_to"
                                id="age_to"
                                value={phase.age_to}
                                onChange={(e) => onPhaseChange(e, index, "age_to")}
                                variant="green"
                              />
                            </div>
                          </td>
                          <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                            <Input
                              type="number"
                              name="period"
                              id="period"
                              value={phase.period}
                              onChange={(e) => onPhaseChange(e, index, "period")}
                              variant={true ? "red" : "green"}
                              disabled={true}
                            />
                          </td>
                          <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                            <Percentage
                              name={`${type ? "real_salary_growth" : "nominal_salary_growth"}`}
                              id={`${type ? "real_salary_growth" : "nominal_salary_growth"}`}
                              value={`${type ? phase.real_salary_growth : phase.nominal_salary_growth}`}
                              onChange={(e) =>
                                onPhaseChange(e, index, type ? "real_salary_growth" : "nominal_salary_growth")
                              }
                              variant={type ? "green" : "red"}
                              disabled={!type}
                            />
                          </td>
                          <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                            <Percentage
                              name="salary_contributions"
                              id="salary_contributions"
                              value={phase.salary_contributions}
                              onChange={(e) => onPhaseChange(e, index, "salary_contributions")}
                              variant={true === "nominal" ? "red" : "green"}
                              disabled={false === "nominal"}
                            />
                          </td>
                        </tr>
                      ))}

                      <tr>
                        <td className="p-2 text-[12px] font-bold border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                          Overall annualised growth
                        </td>
                        <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                          <Input
                            name="overall_annualised_growth_age_from"
                            id="overall_annualised_growth_age_from"
                            value={formData.overall_annualised_growth_age_from}
                            disabled
                            variant="red"
                          />
                        </td>
                        <td className="p-2 text-[12px] font-bold text-center border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                          <Input
                            name="overall_annualised_growth_age_to"
                            id="overall_annualised_growth_age_to"
                            value={formData.overall_annualised_growth_age_to}
                            disabled
                            variant="red"
                          />
                        </td>
                        <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                          <Input
                            name="overall_annualised_growth_period"
                            id="overall_annualised_growth_period"
                            value={formData.overall_annualised_growth_period}
                            disabled
                            variant="red"
                          />
                        </td>
                        <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                          <Percentage
                            name={`${type ? "real_overall_annualised_growth" : "nominal_overall_annualised_growth"}`}
                            id={`${type ? "real_overall_annualised_growth" : "nominal_overall_annualised_growth"}`}
                            value={`${type ? formData.real_overall_annualised_growth : formData.nominal_overall_annualised_growth}`}
                            disabled
                            variant="red"
                          />
                        </td>
                        <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                      </tr>
                      <tr>
                        <td className="p-2 text-[12px] font-bold border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                          Growth until retirement
                        </td>
                        <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                        <td className="p-2 text-[12px] font-bold text-center border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                        <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                        <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                          <Percentage
                            name={`${type ? "real_growth_until_retirement" : "nominal_growth_until_retirement"}`}
                            id={`${type ? "real_growth_until_retirement" : "nominal_growth_until_retirement"}`}
                            value={`${type ? formData.real_growth_until_retirement : formData.nominal_growth_until_retirement}`}
                            disabled
                            variant="red"
                          />
                        </td>
                        <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                      </tr>
                      <tr>
                        <td colSpan={7}>
                          <div className="flex items-center gap-4">
                            <Button
                              onClick={addRow}
                              disabled={isAddPhaseDisabled()}
                              className="mt-4 mb-2"
                              type="button"
                            >
                              Add phase
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Card>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                </div>
                <div className="grid grid-cols-2 gap-6 mb-6 items-center">
                  <div>
                    <Currency
                      disabled
                      label="Estimated income at contribution inception"
                      variant="red"
                      name={`${type ? "real_estimated_income_at_contribution_inception" : "nominal_estimated_income_at_contribution_inception"}`}
                      id={`${type ? "real_estimated_income_at_contribution_inception" : "nominal_estimated_income_at_contribution_inception"}`}
                      value={`${type ? formData.real_estimated_income_at_contribution_inception : formData.nominal_estimated_income_at_contribution_inception}`}
                    />
                  </div>
                  <div>
                    <Currency
                      disabled
                      label="Target income at retirement:"
                      name={`${type ? "real_target_income_at_retirement" : "nominal_target_income_at_retirement"}`}
                      id={`${type ? "real_target_income_at_retirement" : "nominal_target_income_at_retirement"}`}
                      value={`${type ? formData.real_target_income_at_retirement : formData.nominal_target_income_at_retirement}`}
                      variant="red"
                      alignText="right"
                    />
                  </div>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="align-middle text-[12px] text-left font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443] w-[40%]">
                        Description
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443] w-[20%]">
                        Low
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443] w-[20%]">
                        Medium
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2 w-[20%]">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        What should I have accumulated by my current age?
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.accumulated_investment_by_current_age_low}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.accumulated_investment_by_current_age_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.accumulated_investment_by_current_age_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Estimated amount at retirement on this path
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.estimated_amount_at_retirement_low}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.estimated_amount_at_retirement_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.estimated_amount_at_retirement_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Minimum targeted real capital by now
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.minimum_targeted_capital_low}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.minimum_targeted_capital_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.minimum_targeted_capital_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Amount required at retirement at expected growth rates
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.amount_required_at_retirement_low}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.amount_required_at_retirement_medium}
                          prefix="R"
                        />
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        <CommaSeparated
                          value={formData.amount_required_at_retirement_high}
                          prefix="R"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4 mb-1 min-h-[calc(100vh-175px)]">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">Estimated value of investments by age</p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="h-[calc(100vh-243px)] relative">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default TargetedInvestments;
