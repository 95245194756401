import { rawDecimal } from "@elements/Output";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import { ChangeEvent, FC } from "react";
interface LayoutOneProps {
  formData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
  isVisible: any;
}

const LayoutOne: FC<LayoutOneProps> = ({ formData, handleChange, handleCurrencyChange, isVisible }) => {
  return (
    <>
      <div className="grid grid-cols-2 gap-2 items-end">
        <div>
          <Input
            label="Date of analysis"
            name="effective_date"
            id="effective_date"
            type="date"
            value={formData.effective_date}
            onChange={handleChange}
            variant="red"
          />
        </div>
        {isVisible && (
          <>
            <div>
              <Percentage
                label="CPI inflation"
                name="projected_inflation_rate"
                id="projected_inflation_rate"
                value={formData.projected_inflation_rate}
                onChange={handleCurrencyChange}
                variant="red"
                alignText="right"
              />
            </div>
            <div>
              <Input
                label="Date of birth"
                name="date_of_birth"
                id="date_of_birth"
                type="date"
                disabled
                value={formData.date_of_birth}
                onChange={handleChange}
                variant="red"
              />
            </div>
            <div>
              <Input
                label="Retirement age"
                name="retirement_age"
                id="retirement_age"
                type="number"
                value={formData.retirement_age}
                onChange={handleChange}
                variant="red"
                alignText="right"
              />
            </div>
            <div>
              <Input
                label="Age at analysis date"
                name="current_age"
                id="current_age"
                type="text"
                disabled
                value={rawDecimal(formData.current_age)}
                onChange={handleChange}
                variant="red"
              />
            </div>
            <div>
              <Input
                label="Years to retirement"
                name="years_to_retirement"
                id="years_to_retirement"
                disabled
                value={rawDecimal(formData.years_to_retirement)}
                onChange={handleChange}
                variant="red"
                alignText="right"
              />
            </div>
            <div>
              <Input
                label="Life expectancy"
                name="life_expectancy"
                id="life_expectancy"
                type="number"
                disabled
                value={formData.life_expectancy}
                onChange={handleChange}
                variant="red"
              />
            </div>
            <div>
              <Input
                label="Retirement longevity"
                name="retirement_longevity"
                id="retirement_longevity"
                type="number"
                value={formData.retirement_longevity}
                onChange={handleChange}
                variant="red"
                alignText="right"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LayoutOne;
