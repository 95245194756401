import { InputCellProps } from "@/components/Element/Table/InputCell";
import { TableHeader } from "@/components/Element/Table/TableHeader";
import { TableRow } from "@/components/Element/Table/TableRow";
import VisibilityToggle from "@/components/Element/VisibilityToggle";
import Button from "@/components/Form/Button";
import React, { memo, useCallback, useMemo, useState } from "react";
import { HeadingType, vehicleTableHeadings } from "./VehicleTableConstants";
import VehicleTableData from "./VehicleTableData";

export interface CategoryData {
  category: string;
  rowSpan: number;
  rows: {
    description: string;
    inputs: Array<{
      type: "currency" | "text" | "number" | "date" | "percentage" | "percentageSwitch" | "select" | "none";
      name: string;
      variant: "green" | "red";
    }>;
  }[];
}

interface VehicleTableProps {
  fixedRows: CategoryData[];
  formData: any;
  handleCurrencyChange: (
    value: string | undefined,
    name: string | undefined,
    valueType: "any" | "positive" | "negative"
  ) => void;
}

const VehicleTable: React.FC<VehicleTableProps> = ({ fixedRows, formData, handleCurrencyChange }) => {
  const [tableHeadings, setTableHeadings] = useState<HeadingType[]>(initializeTableHeadings());
  const [visibleCategories, setVisibleCategories] = useState<{ [key: string]: boolean }>(
    initializeVisibleCategories(fixedRows)
  );
  const [newColumnIds, setNewColumnIds] = useState<string[]>([]);

  const vehicleTableData = useMemo(() => VehicleTableData(formData), [formData]);

  function initializeTableHeadings(): HeadingType[] {
    return vehicleTableHeadings().map((heading) => ({
      ...heading,
      id: heading.text,
      removable: false,
    }));
  }

  function initializeVisibleCategories(rows: CategoryData[]): { [key: string]: boolean } {
    return Object.fromEntries(rows.map((row) => [row.category, true]));
  }

  const toggleCategoryVisibility = useCallback((category: string) => {
    setVisibleCategories((previousVisibleCategories) => ({
      ...previousVisibleCategories,
      [category]: !previousVisibleCategories[category],
    }));
  }, []);

  const addSecondaryColumn = useCallback(() => {
    const newColumnId = `column-${Date.now()}`;
    setTableHeadings((previousHeadings) => [
      ...previousHeadings,
      {
        text: `Vehicle #${previousHeadings.length - 1}`,
        id: newColumnId,
        removable: true,
      },
    ]);
    setNewColumnIds((prevIds) => [...prevIds, newColumnId]);
  }, []);

  const removeSecondaryColumn = useCallback((columnId: string) => {
    setTableHeadings((previousHeadings) => previousHeadings.filter((heading) => heading.id !== columnId));
    setNewColumnIds((prevIds) => prevIds.filter((id) => id !== columnId)); // Update newColumnIds
  }, []);

  const getAdditionalInputs = useCallback(
    (categoryIndex: number, rowIndex: number): (InputCellProps | undefined)[] => {
      return tableHeadings.slice(3).map((heading) => {
        const category = vehicleTableData[categoryIndex];
        if (!category) return undefined;

        const row = category.rows[rowIndex];
        if (!row) return undefined;

        // Check if the current column is a new column
        const isNewColumn = newColumnIds.includes(heading.id);

        if (!isNewColumn) {
          // Skip rendering inputs for initial columns
          return undefined;
        }

        const input = row.inputs[0];
        if (!input) return undefined;

        return {
          type: input.type,
          name: `${heading.id}_${input.name}`,
          variant: input.variant,
        };
      });
    },
    [tableHeadings, vehicleTableData, newColumnIds]
  );

  const renderCategoryRow = useCallback(
    (categoryData: CategoryData, categoryIndex: number) => {
      const isVisible = visibleCategories[categoryData.category];
      return (
        <TableRow
          key={`${categoryData.category}-header`}
          category={categoryData.category}
          description={categoryData.rows[0].description}
          inputs={[categoryData.rows[0].inputs[0], ...getAdditionalInputs(categoryIndex, 0)]}
          isFirstInCategory={true}
          categoryRowSpan={isVisible ? categoryData.rowSpan : 1}
          visibilityToggle={
            <VisibilityToggle
              keyName={categoryData.category}
              isVisible={isVisible}
              toggleVisibility={() => toggleCategoryVisibility(categoryData.category)}
            />
          }
          isCollapsed={!isVisible}
          formData={formData}
          handleCurrencyChange={handleCurrencyChange}
        />
      );
    },
    [visibleCategories, getAdditionalInputs, toggleCategoryVisibility, formData, handleCurrencyChange]
  );

  const renderDataRows = useCallback(
    (categoryData: CategoryData, categoryIndex: number) => {
      if (!visibleCategories[categoryData.category]) return null;
      return categoryData.rows.slice(1).map((rowData, rowIndex) => (
        <TableRow
          key={`${categoryIndex}-${rowIndex + 1}`}
          description={rowData.description}
          inputs={[rowData.inputs[0], ...getAdditionalInputs(categoryIndex, rowIndex + 1)]}
          formData={formData}
          handleCurrencyChange={handleCurrencyChange}
        />
      ));
    },
    [visibleCategories, getAdditionalInputs, formData, handleCurrencyChange]
  );

  const tableRows = useMemo(
    () =>
      fixedRows.flatMap((categoryData, categoryIndex) => [
        renderCategoryRow(categoryData, categoryIndex),
        renderDataRows(categoryData, categoryIndex),
      ]),
    [fixedRows, renderCategoryRow, renderDataRows]
  );

  return (
    <>
      <table className="table-auto border">
        <TableHeader
          headings={tableHeadings}
          removeColumn={removeSecondaryColumn}
        />
        <tbody>{tableRows}</tbody>
      </table>
      <div className="mt-4">
        <Button onClick={addSecondaryColumn}>Add</Button>
      </div>
    </>
  );
};

export default memo(VehicleTable);
