import Card from "@/components/Element/Card";
import PageTitle from "@/components/Element/PageTitle";
import VerticalNav from "@/components/Element/VerticalNav";
import PremiumToolLayout from "@/layouts/PremiumToolLayout";
import helpers from "@/services/utils/helpers";
import { sections } from "..";

const PersonalInputsPremium = () => {
  const { preventEnterPress } = helpers();
  const handleSubmit = () => {
    console.log("handleSubmit");
  };

  return (
    <PremiumToolLayout
      showBackButton
      backButtonNavigateTo={"/premium"}
      pageTitle="Personal inputs"
    >
      <PageTitle title="Premium | Personal Inputs" />
      <div className="flex">
        <div className="fixed top-[135px] left-0 h-full max-h-[calc(100vh-70px)] bg-transparent overflow-y-auto scrollbar">
          <VerticalNav sections={sections} />
        </div>
        <div className="ml-[200px] p-4">
          <form
            onSubmit={handleSubmit}
            onKeyDown={preventEnterPress}
            className="mx-auto w-full h-full"
          >
            <div className="max-h-[calc(100vh-170px)] h-full overflow-y-auto overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className="flex items-center mb-2">
                  <h3 className="text-[16px] font-semibold">PersonalInputsPremium</h3>
                </div>
              </Card>
            </div>
          </form>
        </div>
      </div>
    </PremiumToolLayout>
  );
};

export default PersonalInputsPremium;
