import { useCallback } from "react";
import calculations from "@services/algorithms/calculations";
import growthRateHandler from "@services/algorithms/growthRateHandler";

const gapAnalysisCallbacks = (formData: any, setFormData: any) => {
  const {
    calculateRequiredMultiple,
    calculateRequiredValue,
    calculateContributionOfIncome,
    calculateRetirementFundValue,
    calculateMultiple,
    yearsToDepletion,
    calculateAdjustedYearsToDepletion,
    calculateAnnualWithdrawal,
    calculateImpactOnLivingStandard,
    calculateAdditionalContribution,
    calculateCapitalInjection,
    calculateRealAdjustedRetirementValue,
    calculateNominalAdjustedRetirementValue,
    calculateAdjustedSustainableAnnualIncome,
    calculateAdjustedTargetIncome,
    calculateTargetIncome,
    calculateDeferRetirement,
  } = calculations();
  const { calculateRealGrowthRate } = growthRateHandler(formData, setFormData);

  const calculateRetirementIncomeValues = useCallback(() => {
    const realTargetIncome = calculateTargetIncome(
      formData.annual_pre_tax_income,
      formData.real_growth_in_income,
      formData.years_to_retirement,
      formData.post_retirement_income_percentage
    );
    const nominalTargetIncome = calculateTargetIncome(
      formData.annual_pre_tax_income,
      formData.nominal_growth_in_income,
      formData.years_to_retirement,
      formData.post_retirement_income_percentage
    );
    const targetIncome = formData.primary_representation === "real" ? realTargetIncome : nominalTargetIncome;

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      target_income: targetIncome,
      real_target_income: realTargetIncome,
      nominal_target_income: nominalTargetIncome,
    }));
  }, [
    formData.annual_pre_tax_income,
    formData.real_growth_in_income,
    formData.nominal_growth_in_income,
    formData.post_retirement_income_percentage,
    formData.primary_representation,
    formData.years_to_retirement,
  ]);

  const calculateProjectedValues = useCallback(() => {
    const realGrowthLow = calculateRealGrowthRate(
      formData.real_pre_retirement_capital_growth_low,
      formData.pre_retirement_costs
    );
    const realGrowthMedium = calculateRealGrowthRate(
      formData.real_pre_retirement_capital_growth_medium,
      formData.pre_retirement_costs
    );
    const realGrowthHigh = calculateRealGrowthRate(
      formData.real_pre_retirement_capital_growth_high,
      formData.pre_retirement_costs
    );

    const realProjectedValueLow = calculateRetirementFundValue(
      realGrowthLow,
      formData.real_growth_in_income,
      formData.current_monthly_contribution,
      formData.current_accumulated_retirement_fund_value,
      formData.years_to_retirement
    );

    const realProjectedValueMedium = calculateRetirementFundValue(
      realGrowthMedium,
      formData.real_growth_in_income,
      formData.current_monthly_contribution,
      formData.current_accumulated_retirement_fund_value,
      formData.years_to_retirement
    );
    const realProjectedValueHigh = calculateRetirementFundValue(
      realGrowthHigh,
      formData.real_growth_in_income,
      formData.current_monthly_contribution,
      formData.current_accumulated_retirement_fund_value,
      formData.years_to_retirement
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_projected_value_low: realProjectedValueLow,
      real_projected_value_medium: realProjectedValueMedium,
      real_projected_value_high: realProjectedValueHigh,
    }));
  }, [
    formData.real_pre_retirement_capital_growth_low,
    formData.pre_retirement_costs,
    formData.real_pre_retirement_capital_growth_medium,
    formData.real_pre_retirement_capital_growth_high,
    formData.real_growth_in_income,
    formData.current_monthly_contribution,
    formData.current_accumulated_retirement_fund_value,
    formData.years_to_retirement,
  ]);

  const calculateAdditionalValues = useCallback(() => {
    const contributionOfIncome = calculateContributionOfIncome(
      formData.current_monthly_contribution,
      formData.annual_pre_tax_income
    );

    const requiredMultipleLow = calculateRequiredMultiple(
      formData.real_post_net_retirement_growth_low,
      formData.retirement_longevity
    );
    const requiredMultipleMedium = calculateRequiredMultiple(
      formData.real_post_net_retirement_growth_medium,
      formData.retirement_longevity
    );
    const requiredMultipleHigh = calculateRequiredMultiple(
      formData.real_post_net_retirement_growth_high,
      formData.retirement_longevity
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      contribution_as_percentage_of_income: contributionOfIncome,
      required_multiple_low: requiredMultipleLow,
      required_multiple_medium: requiredMultipleMedium,
      required_multiple_high: requiredMultipleHigh,
    }));
  }, [
    formData.current_monthly_contribution,
    formData.annual_pre_tax_income,
    formData.real_post_net_retirement_growth_low,
    formData.retirement_longevity,
    formData.real_post_net_retirement_growth_medium,
    formData.real_post_net_retirement_growth_high,
  ]);

  const calculateProjectedMultiples = useCallback(() => {
    const projectedMultipleLow = calculateMultiple(formData.real_projected_value_low, formData.real_target_income);
    const projectedMultipleMedium = calculateMultiple(
      formData.real_projected_value_medium,
      formData.real_target_income
    );
    const projectedMultipleHigh = calculateMultiple(formData.real_projected_value_high, formData.real_target_income);

    const nominalProjectedValueLow = projectedMultipleLow * formData.nominal_target_income;
    const nominalProjectedValueMedium = projectedMultipleMedium * formData.nominal_target_income;
    const nominalProjectedValueHigh = projectedMultipleHigh * formData.nominal_target_income;

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      projected_multiple_low: projectedMultipleLow,
      projected_multiple_medium: projectedMultipleMedium,
      projected_multiple_high: projectedMultipleHigh,
      nominal_projected_value_low: nominalProjectedValueLow,
      nominal_projected_value_medium: nominalProjectedValueMedium,
      nominal_projected_value_high: nominalProjectedValueHigh,
    }));
  }, [
    formData.real_projected_value_low,
    formData.real_target_income,
    formData.real_projected_value_medium,
    formData.real_projected_value_high,
    formData.nominal_target_income,
  ]);

  const calculateDepletionRates = useCallback(() => {
    const fullDepletionGrowthRateLow = yearsToDepletion(
      formData.real_post_net_retirement_growth_low,
      formData.projected_multiple_low
    );
    const fullDepletionGrowthRateMedium = yearsToDepletion(
      formData.real_post_net_retirement_growth_medium,
      formData.projected_multiple_medium
    );
    const fullDepletionGrowthRateHigh = yearsToDepletion(
      formData.real_post_net_retirement_growth_high,
      formData.projected_multiple_high
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      years_to_full_depletion_at_target_income_low: fullDepletionGrowthRateLow,
      years_to_full_depletion_at_target_income_medium: fullDepletionGrowthRateMedium,
      years_to_full_depletion_at_target_income_high: fullDepletionGrowthRateHigh,
    }));
  }, [
    formData.real_post_net_retirement_growth_low,
    formData.projected_multiple_low,
    formData.real_post_net_retirement_growth_medium,
    formData.projected_multiple_medium,
    formData.real_post_net_retirement_growth_high,
    formData.projected_multiple_high,
  ]);

  const calculateRequiredValues = useCallback(() => {
    const realRequiredValueLow = calculateRequiredValue(formData.required_multiple_low, formData.real_target_income);
    const realRequiredValueMedium = calculateRequiredValue(
      formData.required_multiple_medium,
      formData.real_target_income
    );
    const realRequiredValueHigh = calculateRequiredValue(formData.required_multiple_high, formData.real_target_income);
    const nominalRequiredValueLow = calculateRequiredValue(
      formData.required_multiple_low,
      formData.nominal_target_income
    );
    const nominalRequiredValueMedium = calculateRequiredValue(
      formData.required_multiple_medium,
      formData.nominal_target_income
    );
    const nominalRequiredValueHigh = calculateRequiredValue(
      formData.required_multiple_high,
      formData.nominal_target_income
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_required_value_low: realRequiredValueLow,
      real_required_value_medium: realRequiredValueMedium,
      real_required_value_high: realRequiredValueHigh,
      nominal_required_value_low: nominalRequiredValueLow,
      nominal_required_value_medium: nominalRequiredValueMedium,
      nominal_required_value_high: nominalRequiredValueHigh,
    }));
  }, [
    formData.required_multiple_low,
    formData.real_target_income,
    formData.required_multiple_medium,
    formData.required_multiple_high,
    formData.nominal_target_income,
  ]);

  const calculateAnnualWithdrawals = useCallback(() => {
    const realAnnualWithdrawalLow = calculateAnnualWithdrawal(
      formData.real_projected_value_low,
      formData.real_required_value_low,
      formData.real_target_income
    );
    const realAnnualWithdrawalMedium = calculateAnnualWithdrawal(
      formData.real_projected_value_medium,
      formData.real_required_value_medium,
      formData.real_target_income
    );
    const realAnnualWithdrawalHigh = calculateAnnualWithdrawal(
      formData.real_projected_value_high,
      formData.real_required_value_high,
      formData.real_target_income
    );
    const nominalAnnualWithdrawalLow = calculateAnnualWithdrawal(
      formData.nominal_projected_value_low,
      formData.nominal_required_value_low,
      formData.nominal_target_income
    );
    const nominalAnnualWithdrawalMedium = calculateAnnualWithdrawal(
      formData.nominal_projected_value_medium,
      formData.nominal_required_value_medium,
      formData.nominal_target_income
    );
    const nominalAnnualWithdrawalHigh = calculateAnnualWithdrawal(
      formData.nominal_projected_value_high,
      formData.nominal_required_value_high,
      formData.nominal_target_income
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_annual_withdrawal_low: realAnnualWithdrawalLow,
      real_annual_withdrawal_medium: realAnnualWithdrawalMedium,
      real_annual_withdrawal_high: realAnnualWithdrawalHigh,
      nominal_annual_withdrawal_low: nominalAnnualWithdrawalLow,
      nominal_annual_withdrawal_medium: nominalAnnualWithdrawalMedium,
      nominal_annual_withdrawal_high: nominalAnnualWithdrawalHigh,
    }));
  }, [
    formData.real_projected_value_low,
    formData.real_required_value_low,
    formData.real_target_income,
    formData.real_projected_value_medium,
    formData.real_required_value_medium,
    formData.real_projected_value_high,
    formData.real_required_value_high,
    formData.nominal_projected_value_low,
    formData.nominal_required_value_low,
    formData.nominal_target_income,
    formData.nominal_projected_value_medium,
    formData.nominal_required_value_medium,
    formData.nominal_projected_value_high,
    formData.nominal_required_value_high,
  ]);

  const calculateValueDifferences = useCallback(() => {
    const realValueDifferenceLow = formData.real_projected_value_low - formData.real_required_value_low;
    const realValueDifferenceMedium = formData.real_projected_value_medium - formData.real_required_value_medium;
    const realValueDifferenceHigh = formData.real_projected_value_high - formData.real_required_value_high;
    const nominalValueDifferenceLow = formData.nominal_projected_value_low - formData.nominal_required_value_low;
    const nominalValueDifferenceMedium =
      formData.nominal_projected_value_medium - formData.nominal_required_value_medium;
    const nominalValueDifferenceHigh = formData.nominal_projected_value_high - formData.nominal_required_value_high;

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_value_difference_low: realValueDifferenceLow,
      real_value_difference_medium: realValueDifferenceMedium,
      real_value_difference_high: realValueDifferenceHigh,
      nominal_value_difference_low: nominalValueDifferenceLow,
      nominal_value_difference_medium: nominalValueDifferenceMedium,
      nominal_value_difference_high: nominalValueDifferenceHigh,
    }));
  }, [
    formData.real_projected_value_low,
    formData.real_required_value_low,
    formData.real_projected_value_medium,
    formData.real_required_value_medium,
    formData.real_projected_value_high,
    formData.real_required_value_high,
    formData.nominal_projected_value_low,
    formData.nominal_required_value_low,
    formData.nominal_projected_value_medium,
    formData.nominal_required_value_medium,
    formData.nominal_projected_value_high,
    formData.nominal_required_value_high,
  ]);

  const calculateImpactOnLivingStandards = useCallback(() => {
    const impactOnLivingStandardLow = calculateImpactOnLivingStandard(
      formData.real_projected_value_low,
      formData.real_required_value_low
    );
    const impactOnLivingStandardMedium = calculateImpactOnLivingStandard(
      formData.real_projected_value_medium,
      formData.real_required_value_medium
    );
    const impactOnLivingStandardHigh = calculateImpactOnLivingStandard(
      formData.real_projected_value_high,
      formData.real_required_value_high
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      impact_on_living_standard_low: impactOnLivingStandardLow,
      impact_on_living_standard_medium: impactOnLivingStandardMedium,
      impact_on_living_standard_high: impactOnLivingStandardHigh,
    }));
  }, [
    formData.real_projected_value_low,
    formData.real_required_value_low,
    formData.real_projected_value_medium,
    formData.real_required_value_medium,
    formData.real_projected_value_high,
    formData.real_required_value_high,
  ]);

  const calculateRetirementDeferments = useCallback(() => {
    const defermentLow = calculateDeferRetirement(
      formData.current_monthly_contribution,
      formData.real_growth_in_income,
      formData.current_accumulated_retirement_fund_value,
      formData.years_to_retirement,
      formData.real_pre_net_retirement_growth_low,
      formData.real_required_value_low
    );
    const defermentMedium = calculateDeferRetirement(
      formData.current_monthly_contribution,
      formData.real_growth_in_income,
      formData.current_accumulated_retirement_fund_value,
      formData.years_to_retirement,
      formData.real_pre_net_retirement_growth_medium,
      formData.real_required_value_medium
    );
    const defermentHigh = calculateDeferRetirement(
      formData.current_monthly_contribution,
      formData.real_growth_in_income,
      formData.current_accumulated_retirement_fund_value,
      formData.years_to_retirement,
      formData.real_pre_net_retirement_growth_high,
      formData.real_required_value_high
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      years_deferred_low: defermentLow,
      years_deferred_medium: defermentMedium,
      years_deferred_high: defermentHigh,
    }));
  }, [
    formData.current_monthly_contribution,
    formData.real_growth_in_income,
    formData.current_accumulated_retirement_fund_value,
    formData.years_to_retirement,
    formData.real_pre_net_retirement_growth_low,
    formData.real_pre_net_retirement_growth_medium,
    formData.real_pre_net_retirement_growth_high,
    formData.real_required_value_low,
    formData.real_required_value_medium,
    formData.real_required_value_high,
  ]);

  const calculateAdditionalContributions = useCallback(() => {
    const moreMonthlyContributionLow = calculateAdditionalContribution(
      formData.real_value_difference_low,
      formData.real_pre_net_retirement_growth_low,
      formData.years_to_retirement,
      formData.real_growth_in_income
    );
    const moreMonthlyContributionMedium = calculateAdditionalContribution(
      formData.real_value_difference_medium,
      formData.real_pre_net_retirement_growth_medium,
      formData.years_to_retirement,
      formData.real_growth_in_income
    );
    const moreMonthlyContributionHigh = calculateAdditionalContribution(
      formData.real_value_difference_high,
      formData.real_pre_net_retirement_growth_high,
      formData.years_to_retirement,
      formData.real_growth_in_income
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      more_monthly_contribution_low: moreMonthlyContributionLow,
      more_monthly_contribution_medium: moreMonthlyContributionMedium,
      more_monthly_contribution_high: moreMonthlyContributionHigh,
    }));
  }, [
    formData.real_value_difference_low,
    formData.real_pre_net_retirement_growth_low,
    formData.years_to_retirement,
    formData.real_growth_in_income,
    formData.real_value_difference_medium,
    formData.real_pre_net_retirement_growth_medium,
    formData.real_value_difference_high,
    formData.real_pre_net_retirement_growth_high,
  ]);

  const calculateCapitalInjections = useCallback(() => {
    const capitalInjectLow = calculateCapitalInjection(
      formData.real_value_difference_low,
      formData.real_pre_net_retirement_growth_low,
      formData.years_to_retirement
    );
    const capitalInjectMedium = calculateCapitalInjection(
      formData.real_value_difference_medium,
      formData.real_pre_net_retirement_growth_medium,
      formData.years_to_retirement
    );
    const capitalInjectHigh = calculateCapitalInjection(
      formData.real_value_difference_high,
      formData.real_pre_net_retirement_growth_high,
      formData.years_to_retirement
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      capital_inject_low: capitalInjectLow,
      capital_inject_medium: capitalInjectMedium,
      capital_inject_high: capitalInjectHigh,
    }));
  }, [
    formData.real_value_difference_low,
    formData.real_pre_net_retirement_growth_low,
    formData.years_to_retirement,
    formData.real_value_difference_medium,
    formData.real_pre_net_retirement_growth_medium,
    formData.real_value_difference_high,
    formData.real_pre_net_retirement_growth_high,
  ]);

  const calculateAdjustedTargetCapital = useCallback(() => {
    const realAdjustedTargetIncome = calculateAdjustedTargetIncome(
      formData.real_target_income,
      formData.real_growth_in_income,
      formData.defer_retirement_by
    );

    const nominalAdjustedTargetIncome = calculateAdjustedTargetIncome(
      formData.nominal_target_income,
      formData.nominal_growth_in_income,
      formData.defer_retirement_by
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_adjusted_target_income: realAdjustedTargetIncome,
      nominal_adjusted_target_income: nominalAdjustedTargetIncome,
    }));
  }, [
    formData.real_target_income,
    formData.nominal_target_income,
    formData.real_growth_in_income,
    formData.nominal_growth_in_income,
    formData.defer_retirement_by,
    formData.primary_representation,
  ]);

  const calculateAdjustedRetirementLongevity = useCallback(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      adjusted_retirement_longevity: formData.retirement_longevity - formData.defer_retirement_by,
    }));
  }, [formData.retirement_longevity, formData.defer_retirement_by]);

  const calculateAdjustedRetirementValues = useCallback(() => {
    const realAdjustedCapitalAtRetirementLow = calculateRealAdjustedRetirementValue(
      formData.real_growth_in_income,
      parseInt(formData.current_monthly_contribution),
      parseInt(formData.inject_capital),
      formData.years_to_retirement,
      formData.real_pre_net_retirement_growth_low,
      parseInt(formData.current_accumulated_retirement_fund_value),
      parseInt(formData.increase_monthly_contribution),
      parseInt(formData.defer_retirement_by)
    );

    const realAdjustedCapitalAtRetirementMedium = calculateRealAdjustedRetirementValue(
      formData.real_growth_in_income,
      parseInt(formData.current_monthly_contribution),
      parseInt(formData.inject_capital),
      formData.years_to_retirement,
      formData.real_pre_net_retirement_growth_medium,
      parseInt(formData.current_accumulated_retirement_fund_value),
      parseInt(formData.increase_monthly_contribution),
      parseInt(formData.defer_retirement_by)
    );
    const realAdjustedCapitalAtRetirementHigh = calculateRealAdjustedRetirementValue(
      formData.real_growth_in_income,
      parseInt(formData.current_monthly_contribution),
      parseInt(formData.inject_capital),
      formData.years_to_retirement,
      formData.real_pre_net_retirement_growth_high,
      parseInt(formData.current_accumulated_retirement_fund_value),
      parseInt(formData.increase_monthly_contribution),
      parseInt(formData.defer_retirement_by)
    );

    const nominalAdjustedCapitalAtRetirementLow = calculateNominalAdjustedRetirementValue(
      realAdjustedCapitalAtRetirementLow,
      formData.projected_inflation_rate,
      formData.years_to_retirement,
      parseInt(formData.defer_retirement_by)
    );
    const nominalAdjustedCapitalAtRetirementMedium = calculateNominalAdjustedRetirementValue(
      realAdjustedCapitalAtRetirementMedium,
      formData.projected_inflation_rate,
      formData.years_to_retirement,
      parseInt(formData.defer_retirement_by)
    );
    const nominalAdjustedCapitalAtRetirementHigh = calculateNominalAdjustedRetirementValue(
      realAdjustedCapitalAtRetirementHigh,
      formData.projected_inflation_rate,
      formData.years_to_retirement,
      parseInt(formData.defer_retirement_by)
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_adjusted_capital_at_retirement_low: realAdjustedCapitalAtRetirementLow,
      real_adjusted_capital_at_retirement_medium: realAdjustedCapitalAtRetirementMedium,
      real_adjusted_capital_at_retirement_high: realAdjustedCapitalAtRetirementHigh,
      nominal_adjusted_capital_at_retirement_low: nominalAdjustedCapitalAtRetirementLow,
      nominal_adjusted_capital_at_retirement_medium: nominalAdjustedCapitalAtRetirementMedium,
      nominal_adjusted_capital_at_retirement_high: nominalAdjustedCapitalAtRetirementHigh,
    }));
  }, [
    formData.real_growth_in_income,
    formData.current_monthly_contribution,
    formData.inject_capital,
    formData.years_to_retirement,
    formData.real_pre_net_retirement_growth_low,
    formData.current_accumulated_retirement_fund_value,
    formData.increase_monthly_contribution,
    formData.defer_retirement_by,
    formData.real_pre_net_retirement_growth_medium,
    formData.real_pre_net_retirement_growth_high,
    formData.projected_inflation_rate,
  ]);

  const calculateAdjustedIncomes = useCallback(() => {
    const realAdjustedIncomeAtRetirementLow = calculateAdjustedSustainableAnnualIncome(
      formData.real_growth_in_income,
      parseInt(formData.current_monthly_contribution),
      parseInt(formData.real_target_income),
      parseInt(formData.inject_capital),
      formData.years_to_retirement,
      formData.retirement_longevity,
      formData.real_pre_net_retirement_growth_low,
      formData.real_post_net_retirement_growth_low,
      parseInt(formData.current_accumulated_retirement_fund_value),
      parseInt(formData.increase_monthly_contribution),
      parseInt(formData.defer_retirement_by)
    );

    const realAdjustedIncomeAtRetirementMedium = calculateAdjustedSustainableAnnualIncome(
      formData.real_growth_in_income,
      parseInt(formData.current_monthly_contribution),
      parseInt(formData.real_target_income),
      parseInt(formData.inject_capital),
      formData.years_to_retirement,
      formData.retirement_longevity,
      formData.real_pre_net_retirement_growth_medium,
      formData.real_post_net_retirement_growth_medium,
      parseInt(formData.current_accumulated_retirement_fund_value),
      parseInt(formData.increase_monthly_contribution),
      parseInt(formData.defer_retirement_by)
    );
    const realAdjustedIncomeAtRetirementHigh = calculateAdjustedSustainableAnnualIncome(
      formData.real_growth_in_income,
      parseInt(formData.current_monthly_contribution),
      parseInt(formData.real_target_income),
      parseInt(formData.inject_capital),
      formData.years_to_retirement,
      formData.retirement_longevity,
      formData.real_pre_net_retirement_growth_high,
      formData.real_post_net_retirement_growth_high,
      parseInt(formData.current_accumulated_retirement_fund_value),
      parseInt(formData.increase_monthly_contribution),
      parseInt(formData.defer_retirement_by)
    );

    const nominalAdjustedIncomeAtRetirementLow = calculateNominalAdjustedRetirementValue(
      realAdjustedIncomeAtRetirementLow,
      formData.projected_inflation_rate,
      formData.years_to_retirement,
      parseInt(formData.defer_retirement_by)
    );
    const nominalAdjustedIncomeAtRetirementMedium = calculateNominalAdjustedRetirementValue(
      realAdjustedIncomeAtRetirementMedium,
      formData.projected_inflation_rate,
      formData.years_to_retirement,
      parseInt(formData.defer_retirement_by)
    );
    const nominalAdjustedIncomeAtRetirementHigh = calculateNominalAdjustedRetirementValue(
      realAdjustedIncomeAtRetirementHigh,
      formData.projected_inflation_rate,
      formData.years_to_retirement,
      parseInt(formData.defer_retirement_by)
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_adjusted_income_at_retirement_low: realAdjustedIncomeAtRetirementLow,
      real_adjusted_income_at_retirement_medium: realAdjustedIncomeAtRetirementMedium,
      real_adjusted_income_at_retirement_high: realAdjustedIncomeAtRetirementHigh,
      nominal_adjusted_income_at_retirement_low: nominalAdjustedIncomeAtRetirementLow,
      nominal_adjusted_income_at_retirement_medium: nominalAdjustedIncomeAtRetirementMedium,
      nominal_adjusted_income_at_retirement_high: nominalAdjustedIncomeAtRetirementHigh,
    }));
  }, [
    formData.real_growth_in_income,
    formData.current_monthly_contribution,
    formData.real_target_income,
    formData.inject_capital,
    formData.years_to_retirement,
    formData.retirement_longevity,
    formData.real_pre_net_retirement_growth_low,
    formData.real_post_net_retirement_growth_low,
    formData.current_accumulated_retirement_fund_value,
    formData.increase_monthly_contribution,
    formData.defer_retirement_by,
    formData.real_pre_net_retirement_growth_medium,
    formData.real_post_net_retirement_growth_medium,
    formData.real_pre_net_retirement_growth_high,
    formData.real_post_net_retirement_growth_high,
    formData.projected_inflation_rate,
  ]);

  const calculateChangesInSustainableIncome = useCallback(() => {
    const realChangeInSustainableIncomeLow =
      formData.real_adjusted_income_at_retirement_low - formData.real_annual_withdrawal_low;
    const realChangeInSustainableIncomeMedium =
      formData.real_adjusted_income_at_retirement_medium - formData.real_annual_withdrawal_medium;
    const realChangeInSustainableIncomeHigh =
      formData.real_adjusted_income_at_retirement_high - formData.real_annual_withdrawal_high;
    const nominalChangeInSustainableIncomeLow =
      formData.nominal_adjusted_income_at_retirement_low - formData.nominal_annual_withdrawal_low;
    const nominalChangeInSustainableIncomeMedium =
      formData.nominal_adjusted_income_at_retirement_medium - formData.nominal_annual_withdrawal_medium;
    const nominalChangeInSustainableIncomeHigh =
      formData.nominal_adjusted_income_at_retirement_high - formData.nominal_annual_withdrawal_high;

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      real_change_in_sustainable_income_low: realChangeInSustainableIncomeLow,
      real_change_in_sustainable_income_medium: realChangeInSustainableIncomeMedium,
      real_change_in_sustainable_income_high: realChangeInSustainableIncomeHigh,
      nominal_change_in_sustainable_income_low: nominalChangeInSustainableIncomeLow,
      nominal_change_in_sustainable_income_medium: nominalChangeInSustainableIncomeMedium,
      nominal_change_in_sustainable_income_high: nominalChangeInSustainableIncomeHigh,
    }));
  }, [
    formData.real_adjusted_income_at_retirement_low,
    formData.real_annual_withdrawal_low,
    formData.real_adjusted_income_at_retirement_medium,
    formData.real_annual_withdrawal_medium,
    formData.real_adjusted_income_at_retirement_high,
    formData.real_annual_withdrawal_high,
    formData.nominal_adjusted_income_at_retirement_low,
    formData.nominal_annual_withdrawal_low,
    formData.nominal_adjusted_income_at_retirement_medium,
    formData.nominal_annual_withdrawal_medium,
    formData.nominal_adjusted_income_at_retirement_high,
    formData.nominal_annual_withdrawal_high,
  ]);

  const calculateYearsToDepletion = useCallback(() => {
    const adjustedTargetCapital = calculateAdjustedTargetIncome(
      formData.real_target_income,
      formData.real_growth_in_income,
      formData.defer_retirement_by
    );
    const yearsToDepletionLow = calculateAdjustedYearsToDepletion(
      formData.real_adjusted_capital_at_retirement_low,
      adjustedTargetCapital,
      formData.real_post_net_retirement_growth_low
    );
    const yearsToDepletionMedium = calculateAdjustedYearsToDepletion(
      formData.real_adjusted_capital_at_retirement_medium,
      adjustedTargetCapital,
      formData.real_post_net_retirement_growth_medium
    );
    const yearsToDepletionHigh = calculateAdjustedYearsToDepletion(
      formData.real_adjusted_capital_at_retirement_high,
      adjustedTargetCapital,
      formData.real_post_net_retirement_growth_high
    );

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      years_to_depletion_low: yearsToDepletionLow,
      years_to_depletion_medium: yearsToDepletionMedium,
      years_to_depletion_high: yearsToDepletionHigh,
    }));
  }, [
    formData.real_adjusted_capital_at_retirement_low,
    formData.real_target_income,
    formData.real_growth_in_income,
    formData.defer_retirement_by,
    formData.real_post_net_retirement_growth_low,
    formData.real_adjusted_capital_at_retirement_medium,
    formData.real_post_net_retirement_growth_medium,
    formData.real_adjusted_capital_at_retirement_high,
    formData.real_post_net_retirement_growth_high,
  ]);

  const calculateChangesInYearsToDepletion = useCallback(() => {
    const changeInYearsToDepletionLow =
      formData.years_to_depletion_low - formData.years_to_full_depletion_at_target_income_low;
    const changeInYearsToDepletionMedium =
      formData.years_to_depletion_medium - formData.years_to_full_depletion_at_target_income_medium;
    const changeInYearsToDepletionHigh =
      formData.years_to_depletion_high - formData.years_to_full_depletion_at_target_income_high;

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      change_in_years_to_depletion_low: changeInYearsToDepletionLow,
      change_in_years_to_depletion_medium: changeInYearsToDepletionMedium,
      change_in_years_to_depletion_high: changeInYearsToDepletionHigh,
    }));
  }, [
    formData.years_to_depletion_low,
    formData.years_to_full_depletion_at_target_income_low,
    formData.years_to_depletion_medium,
    formData.years_to_full_depletion_at_target_income_medium,
    formData.years_to_depletion_high,
    formData.years_to_full_depletion_at_target_income_high,
  ]);

  return {
    calculateRetirementIncomeValues,
    calculateProjectedValues,
    calculateAdditionalValues,
    calculateProjectedMultiples,
    calculateDepletionRates,
    calculateRequiredValues,
    calculateAnnualWithdrawals,
    calculateValueDifferences,
    calculateImpactOnLivingStandards,
    calculateRetirementDeferments,
    calculateAdditionalContributions,
    calculateCapitalInjections,
    calculateAdjustedTargetCapital,
    calculateAdjustedRetirementLongevity,
    calculateAdjustedRetirementValues,
    calculateAdjustedIncomes,
    calculateChangesInSustainableIncome,
    calculateYearsToDepletion,
    calculateChangesInYearsToDepletion,
  };
};

export default gapAnalysisCallbacks;
