import { CategoryData, RowData } from "./WealthTable";

type InputType = "currency" | "text" | "number" | "date" | "percentage" | "percentageSwitch" | "select";
type Variant = "green" | "red";

interface InputData {
  type: InputType;
  name: string;
  variant: Variant;
  disabled?: boolean;
}

const createInput = (
  type: InputType,
  name: string,
  variant: Variant = "green",
  disabled: boolean = false
): InputData => ({
  type,
  name,
  variant,
  disabled,
});

const createRow = (description: string, ...inputs: InputData[]): RowData => ({
  id: `${description.toLowerCase().replace(/\s+/g, "_")}_${Date.now()}`,
  description,
  inputs,
});

const physicalAssetsRows = [
  createRow(
    "Primary property",
    createInput("currency", "value_primary_property", "red"),
    createInput("currency", "liability_primary_property", "red"),
    createInput("currency", "net_value_primary_property", "red"),
    createInput("percentage", "real_growth_primary_property", "green")
  ),
  createRow(
    "Secondary property #1",
    createInput("currency", "value_secondary_property_1", "red"),
    createInput("currency", "liability_secondary_property_1", "red"),
    createInput("currency", "net_value_secondary_property_1", "red"),
    createInput("percentage", "real_growth_secondary_property_1", "green")
  ),
  createRow(
    "Vehicle/s",
    createInput("currency", "value_vehicles", "red"),
    createInput("currency", "liability_vehicles", "red"),
    createInput("currency", "net_value_vehicles", "red"),
    createInput("percentage", "real_growth_vehicles", "green")
  ),
  createRow(
    "Other assets #1",
    createInput("currency", "value_other_assets_1", "green"),
    createInput("currency", "liability_other_assets_1", "green"),
    createInput("currency", "net_value_other_assets_1", "red"),
    createInput("percentage", "real_growth_other_assets_1", "green")
  ),
  createRow(
    "Sub-total",
    createInput("currency", "value_subtotal_physical", "red", true),
    createInput("currency", "liability_subtotal_physical", "red", true),
    createInput("currency", "net_value_subtotal_physical", "red", true),
    createInput("percentage", "real_growth_subtotal_physical", "green", true)
  ),
];

const financialAssetsRows = [
  createRow(
    "Retirement funds",
    createInput("currency", "value_retirement_funds", "red"),
    createInput("currency", "liability_retirement_funds", "green"),
    createInput("currency", "net_value_retirement_funds", "red"),
    createInput("percentage", "real_growth_retirement_funds", "green")
  ),
  createRow(
    "Investments & savings",
    createInput("currency", "value_investments_savings", "red"),
    createInput("currency", "liability_investments_savings", "green"),
    createInput("currency", "net_value_investments_savings", "red"),
    createInput("percentage", "real_growth_investments_savings", "green")
  ),
  createRow(
    "Loans",
    createInput("currency", "value_loans", "green"),
    createInput("currency", "liability_loans", "green"),
    createInput("currency", "net_value_loans", "green"),
    createInput("percentage", "real_growth_loans", "green")
  ),
  createRow(
    "Net value of business",
    createInput("currency", "value_business", "green"),
    createInput("currency", "liability_business", "green"),
    createInput("currency", "net_value_business", "green"),
    createInput("percentage", "real_growth_business", "green")
  ),
  createRow(
    "Net bank balance",
    createInput("currency", "value_bank_balance", "green"),
    createInput("currency", "liability_bank_balance", "red"),
    createInput("currency", "net_value_bank_balance", "red"),
    createInput("percentage", "real_growth_bank_balance", "green")
  ),
  createRow(
    "Cash on hand",
    createInput("currency", "value_cash_on_hand", "green"),
    createInput("currency", "liability_cash_on_hand", "green"),
    createInput("currency", "net_value_cash_on_hand", "green"),
    createInput("percentage", "real_growth_cash_on_hand", "red")
  ),
  createRow(
    "Other assets #2",
    createInput("currency", "value_other_assets_2", "green"),
    createInput("currency", "liability_other_assets_2", "green"),
    createInput("currency", "net_value_other_assets_2", "green"),
    createInput("percentage", "real_growth_other_assets_2", "green")
  ),
  createRow(
    "Sub-total",
    createInput("currency", "value_subtotal_financial", "red", true),
    createInput("currency", "liability_subtotal_financial", "red", true),
    createInput("currency", "net_value_subtotal_financial", "red", true),
    createInput("percentage", "real_growth_subtotal_financial", "green", true)
  ),
];

const potentialAssetsRows = [
  createRow(
    "Investable % of Human capital",
    createInput("currency", "value_human_capital", "red"),
    createInput("currency", "liability_human_capital", "green"),
    createInput("currency", "net_value_human_capital", "red"),
    createInput("percentage", "real_growth_human_capital", "green")
  ),
  createRow(
    "Sub-total",
    createInput("currency", "value_subtotal_potential", "red", true),
    createInput("currency", "liability_subtotal_potential", "green", true),
    createInput("currency", "net_value_subtotal_potential", "red", true),
    createInput("percentage", "real_growth_subtotal_potential", "green", true)
  ),
];

const createCategory = (category: string, rows: RowData[]): CategoryData => ({
  category,
  rowSpan: rows.length,
  rows,
});

export const wealthTableData = (): CategoryData[] => [
  createCategory("Physical assets", physicalAssetsRows),
  createCategory("Financial assets", financialAssetsRows),
  createCategory("Potential assets", potentialAssetsRows),
];

export default wealthTableData;
